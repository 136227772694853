import EngFacebook from 'assets/images/img_pc_eng_facebook.png'
import EngKCA from 'assets/images/img_pc_eng_kca.png'
import EngNaver from 'assets/images/img_pc_eng_naver.png'
import EngTmon from 'assets/images/img_pc_eng_tmon.png'
import EngWMP from 'assets/images/img_pc_eng_wmp.png'
import EngYoutube from 'assets/images/img_pc_eng_youtube.png'

export const aboutEngValues = {
  reviewData: [
    {
      id: 1,
      userName: '곽*호',
      userReview:
        '하루에 10분만이라도 보자 하고 시작했는데 정말 10분이면 1강을 볼 수 있어서 어려움 없이 다 완강하게 되었고 그 성취감은 일상생활의 자신감 상승에도 도움이 되었다. 무엇보다 계속 영어공부를 할 수 있는 습관이 생겼다는 것에 가장 만족스럽다.'
    },
    {
      id: 2,
      userName: '원*희',
      userReview:
        '완강을 한 이후에도 저에게 영어는 언제나 어려운 문제이지만 야나두 강의를 통해 두려움을 많이 씻어 내었고 영어실력도 부쩍 향상되었음을 느낍니다.'
    },
    {
      id: 3,
      userName: '김*영',
      userReview:
        '처음에는 어떻게 하나 막연했는데 어느새 1년이 지났어요~ 하루하루 강의를 들으며 머리에 들어 오는 것이 있었어요. 꾸준히 하루에 조금씩이라도 강의를 듣는 실천만으로도 도움을 받을 수 있다는 게 신기할 뿐입니다'
    },
    {
      id: 4,
      userName: '윤*정',
      userReview:
        '감사합니다 영어에 대한 두려움이 자신감이 되었습니다. 선생님의 강의가 명쾌하고 요약부분이 정리가 잘 되었고 학교 다닐 때 힘들게 외운 문법이 싶게 설명이 되고 이해가 되면서 다음 강의가 관심이 생기고 다시 듣고 싶었습니다'
    },
    {
      id: 5,
      userName: '최*라',
      userReview:
        '꾸준히 강의를 듣고,보고 하다 보니 소소한 여행용 생활 영어 정도는 가능한정도가 된 거 같습니다. 어느 순간에는 영어에 대한 두려움 없이 외국인과 일상적 대화를 할 수 있는 순간이 올 거라고 생각합니다.'
    },
    {
      id: 6,
      userName: '전*비',
      userReview:
        '말하거나 문장으로 적을 때 굳이 어렵게 생각하지 않고 쉬운 단어들로 짧지만 정확하게 내용을 전달할 수 있다는 걸 알게 되네요.자꾸 말하고 쓰게 해보고 비슷한 형태로 다르게 바꿔 말하거나 쓸 수 있게 반복해주시는 점이 좋은 거 같아요.'
    },
    {
      id: 7,
      userName: '곽*호',
      userReview:
        '하루에 10분만이라도 보자 하고 시작했는데 정말 10분이면 1강을 볼 수 있어서 어려움 없이 다 완강하게 되었고 그 성취감은 일상생활의 자신감 상승에도 도움이 되었다. 무엇보다 계속 영어공부를 할 수 있는 습관이 생겼다는 것에 가장 만족스럽다.'
    },
    {
      id: 8,
      userName: '원*희',
      userReview:
        '완강을 한 이후에도 저에게 영어는 언제나 어려운 문제이지만 야나두 강의를 통해 두려움을 많이 씻어 내었고 영어실력도 부쩍 향상되었음을 느낍니다.'
    },
    {
      id: 9,
      userName: '김*영',
      userReview:
        '처음에는 어떻게 하나 막연했는데 어느새 1년이 지났어요~ 하루하루 강의를 들으며 머리에 들어 오는 것이 있었어요. 꾸준히 하루에 조금씩이라도 강의를 듣는 실천만으로도 도움을 받을 수 있다는 게 신기할 뿐입니다'
    },
    {
      id: 10,
      userName: '윤*정',
      userReview:
        '감사합니다 영어에 대한 두려움이 자신감이 되었습니다. 선생님의 강의가 명쾌하고 요약부분이 정리가 잘 되었고 학교 다닐 때 힘들게 외운 문법이 싶게 설명이 되고 이해가 되면서 다음 강의가 관심이 생기고 다시 듣고 싶었습니다'
    },
    {
      id: 11,
      userName: '최*라',
      userReview:
        '꾸준히 강의를 듣고,보고 하다 보니 소소한 여행용 생활 영어 정도는 가능한정도가 된 거 같습니다. 어느 순간에는 영어에 대한 두려움 없이 외국인과 일상적 대화를 할 수 있는 순간이 올 거라고 생각합니다.'
    },
    {
      id: 12,
      userName: '전*비',
      userReview:
        '말하거나 문장으로 적을 때 굳이 어렵게 생각하지 않고 쉬운 단어들로 짧지만 정확하게 내용을 전달할 수 있다는 걸 알게 되네요.자꾸 말하고 쓰게 해보고 비슷한 형태로 다르게 바꿔 말하거나 쓸 수 있게 반복해주시는 점이 좋은 거 같아요.'
    },
    {
      id: 13,
      userName: '곽*호',
      userReview:
        '하루에 10분만이라도 보자 하고 시작했는데 정말 10분이면 1강을 볼 수 있어서 어려움 없이 다 완강하게 되었고 그 성취감은 일상생활의 자신감 상승에도 도움이 되었다. 무엇보다 계속 영어공부를 할 수 있는 습관이 생겼다는 것에 가장 만족스럽다.'
    },
    {
      id: 14,
      userName: '원*희',
      userReview:
        '완강을 한 이후에도 저에게 영어는 언제나 어려운 문제이지만 야나두 강의를 통해 두려움을 많이 씻어 내었고 영어실력도 부쩍 향상되었음을 느낍니다.'
    },
    {
      id: 15,
      userName: '김*영',
      userReview:
        '처음에는 어떻게 하나 막연했는데 어느새 1년이 지났어요~ 하루하루 강의를 들으며 머리에 들어 오는 것이 있었어요. 꾸준히 하루에 조금씩이라도 강의를 듣는 실천만으로도 도움을 받을 수 있다는 게 신기할 뿐입니다'
    },
    {
      id: 16,
      userName: '윤*정',
      userReview:
        '감사합니다 영어에 대한 두려움이 자신감이 되었습니다. 선생님의 강의가 명쾌하고 요약부분이 정리가 잘 되었고 학교 다닐 때 힘들게 외운 문법이 싶게 설명이 되고 이해가 되면서 다음 강의가 관심이 생기고 다시 듣고 싶었습니다'
    },
    {
      id: 17,
      userName: '최*라',
      userReview:
        '꾸준히 강의를 듣고,보고 하다 보니 소소한 여행용 생활 영어 정도는 가능한정도가 된 거 같습니다. 어느 순간에는 영어에 대한 두려움 없이 외국인과 일상적 대화를 할 수 있는 순간이 올 거라고 생각합니다.'
    },
    {
      id: 18,
      userName: '전*비',
      userReview:
        '말하거나 문장으로 적을 때 굳이 어렵게 생각하지 않고 쉬운 단어들로 짧지만 정확하게 내용을 전달할 수 있다는 걸 알게 되네요.자꾸 말하고 쓰게 해보고 비슷한 형태로 다르게 바꿔 말하거나 쓸 수 있게 반복해주시는 점이 좋은 거 같아요.'
    }
  ],
  videoData: {
    videoItems: [
      {
        category: '야나두 영어 마스터',
        imagePath1:
          'https://english.yanadoocdn.com/upload/yanadoo/pc/sub/beforeAfter/2021/img_media_thumb_ly.png',
        linkPath: 'https://www.youtube.com/embed/qJJaRZffsZU',
        title: '13만 팔로워 모델의 특별한 공부법',
        sortOrder: 1
      },
      {
        category: '야나두 영어 마스터',
        imagePath1:
          'https://english.yanadoocdn.com/upload/yanadoo/pc/sub/beforeAfter/2021/img_media_thumb_yjh_interview.png',
        linkPath: 'https://www.youtube.com/embed/iSVkC786OAk',
        title: '유지형의 1년 Before & After',
        sortOrder: 2
      },
      {
        category: '야나두 영어 마스터',
        imagePath1:
          'https://english.yanadoocdn.com/upload/yanadoo/pc/sub/beforeAfter/2021/img_media_thumb_lh.png',
        linkPath: 'https://www.youtube.com/embed/9xvBaITgJCs',
        title: '외국인과 영어로 처음 대화했습니다',
        sortOrder: 3
      }
    ],
    videoItems2: [
      {
        category: '야나두로 어떻게 공부하면 될까?',
        imagePath1:
          'https://english.yanadoocdn.com/upload/yanadoo/pc/sub/beforeAfter/2021/img_media_thumb_haeley.jpg',
        linkPath: 'https://www.youtube.com/embed/ZTGNX7Im9DI',
        title: '매일해도 질리지 않는 공부법',
        sortOrder: 1
      },
      {
        category: '야나두로 어떻게 공부하면 될까?',
        imagePath1:
          'https://english.yanadoocdn.com/upload/yanadoo/pc/sub/beforeAfter/2021/img_media_thumb_lhj.png',
        linkPath: 'https://www.youtube.com/embed/z7v30Dd4vtY',
        title: '밀착케어 해야만 하는 이유',
        sortOrder: 2
      },
      {
        category: '야나두로 어떻게 공부하면 될까?',
        imagePath1:
          'https://english.yanadoocdn.com/upload/yanadoo/pc/sub/beforeAfter/2021/img_media_thumb_pp.jpg',
        linkPath: 'https://www.youtube.com/embed/dRSKTIRWClI',
        title: '복습까지 챙겨주는 AI 트레이닝',
        sortOrder: 3
      }
    ],
    videoItems3: [
      {
        category: '리얼 수강후기 모아보기',
        imagePath1:
          'https://english.yanadoocdn.com/upload/yanadoo/pc/sub/beforeAfter/2021/img_media_thumb_ajb.png',
        linkPath: 'https://www.youtube.com/embed/ysukN2bUc7s',
        title: '언택트 시대 나만의 영업 준비 방법',
        sortOrder: 1
      },
      {
        category: '리얼 수강후기 모아보기',
        imagePath1:
          'https://english.yanadoocdn.com/upload/yanadoo/pc/sub/beforeAfter/2021/img_media_thumb_kgj.png',
        linkPath: 'https://www.youtube.com/embed/WCf0bdxYYHA',
        title: '30년만에 다시 도전한 영어공부',
        sortOrder: 2
      },
      {
        category: '리얼 수강후기 모아보기',
        imagePath1:
          'https://english.yanadoocdn.com/upload/yanadoo/pc/sub/beforeAfter/2021/img_media_thumb_mhg.jpg',
        linkPath: 'https://www.youtube.com/embed/5jZcaBqLsLg',
        title: '지금도 늦지 않은 영어 도전기',
        sortOrder: 3
      }
    ]
  },
  winnerData: [
    {
      id: 1,
      Title: '소비자 만족도\n1위',
      Description: '2017.11 발표',
      linkUrl: '',
      imgUrl: EngKCA
    },
    {
      id: 2,
      Title: '검색어 트렌드\n1위',
      Description: '2017.07~현재',
      linkUrl: '',
      imgUrl: EngNaver
    },
    {
      id: 3,
      Title: '영어회화 부문\n팔로워1위',
      Description: '2018.01 기준',
      linkUrl: '',
      imgUrl: EngFacebook
    },
    {
      id: 4,
      Title: '동영상 누적 조회\n2000만',
      Description: '2018.01 기준',
      linkUrl: '',
      imgUrl: EngYoutube
    },
    {
      id: 5,
      Title: '영어회화 부문\n인기1위',
      Description: '2018.01 기준',
      linkUrl: '',
      imgUrl: EngTmon
    },
    {
      id: 6,
      Title: '영어회화 부문\n판매1위',
      Description: '2018.01 기준',
      linkUrl: '',
      imgUrl: EngWMP
    }
  ],
  lectureData: [
    {
      id: 1,
      subTitle: '야나두 히트상품',
      mainTitle: '10분 패키지',
      linkUrl: '/promotion/bestAwards'
    },
    {
      id: 2,
      subTitle: '초강력 동기부여',
      mainTitle: '장학금 시스템',
      linkUrl: '/promotion/scholarShip'
    },
    {
      id: 3,
      subTitle: '온라인 밀착과외',
      mainTitle: '1:1 보이스케어',
      linkUrl: '/promotion/voiceCare'
    },
    {
      id: 4,
      subTitle: '내 실력 정밀진단',
      mainTitle: '무료 레벨테스트',
      linkUrl: '/levelTest/start'
    },
    {
      id: 5,
      subTitle: '10분의 기적',
      mainTitle: '야나두 커리큘럼',
      linkUrl: '/courseList'
    }
  ]
}
