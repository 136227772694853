import React, { useEffect } from 'react'

import OAuth from 'common/OAuth'

// ETD-3489 last update
export default function ShareKakao({ meta, pathname, className }) {
  // 카카오 공유하기
  const shareKakao = () => {
    if (meta) {
      window.Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: meta.title,
          description: meta.description,
          imageUrl: meta.og,
          imageWidth: 600,
          imageHeight: 300,
          link: {
            mobileWebUrl: pathname ? 'https://www.yanadoo.co.kr' + pathname : window.location.href,
            androidExecParams: pathname ? 'https://www.yanadoo.co.kr' + pathname : window.location.href
          }
        },
        installTalk: true,
        buttons: [
          {
            title: '웹으로 이동',
            link: {
              mobileWebUrl: pathname ? 'https://www.yanadoo.co.kr' + pathname : window.location.href,
              webUrl: pathname ? 'https://www.yanadoo.co.kr' + pathname : window.location.href
            }
          }
        ]
      })
    }
  }

  useEffect(() => {
    OAuth.injectScript('https://developers.kakao.com/sdk/js/kakao.js', () => {
      if (!window.Kakao.isInitialized()) {
        window.Kakao.init(OAuth.KAKAO_JS_KEY)
      }
    })
  }, [])

  return (
    <button className={className} onClick={shareKakao}>
      카톡 공유하기
    </button>
  )
}
