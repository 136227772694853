export const hideChannelTalkPaths = [
  'mypage',
  'myclass',
  'mall',
  'levelTestAdvanced/exam',
  'levelTestAdvanced/result',
  'levelTestAdvanced/answer',
  'AInativespeakertalk',
  'AInativespeakertalk/chat',
  'community/srr/review',
  'courseReview',
  '/promotion-landing/applePackageUI',
  '/promotion-landing/macbookPackage_performance',
  '/promotion-landing/SRRfullpackage_performance',
  '/promotion-landing/laptopPackage_performance',
  '/promotion-landing/bestAwardsDBOnly_performance',
  '/promotion-landing/ipadDouble',
  '/webview/ai/intro',
  '/webview/ai/chat',
  '/webview/ai/qna',
  '/webview/ai/srr/qna',
  '/webview/login',
  '/webview/loginSuccess',
  '/webview/travel',
  '/webview/travel/chat'
]

export const CHANNEL_TALK_KEY = '627cbef3-bd97-440b-a8a5-8412e492630b'

export const CHANNEL_TALK_IMAGE_URL =
  'https://cf.channel.io/pub-file/2132/649d4fd96117d2e6f8da/0623_caeneoltogmobailbeoteun_sujeong2'
