import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import useGetAiMessage from 'hooks/useGetFeeds'
import ModalEnd from 'pages/aiTalk/components/ModalEnd'
import ModalRecommend from 'pages/aiTalk/components/ModalRecommend'
import ModalTalkShare from 'pages/aiTalk/components/ModalTalkShare'
import useScrollStore from 'pages/aiTalk/store/useScrollStore'
import s from 'pages/chatBot/ChatBot.module.css'

import ChatFeed from 'components/chat/ChatFeed'
import ChatInput from 'components/chat/ChatInput'
import MetaInfo from 'components/common/MetaInfo'

import TitleBar from './components/TitleBar'

export default function AppAInativespeakerChat() {
  const navigate = useNavigate()
  const location = useLocation()
  const messageBoxRef = useRef()
  const [searchParams] = useSearchParams()
  const userSeq = searchParams.get('seq')
  const { scroll, setScroll } = useScrollStore()
  const { feed, isLoading, addedFeed, postMessage } = useGetAiMessage(
    location.state !== null ? location.state.promptType : null
  )

  const [endModal, setEndModal] = useState(false)
  const [rcmModal, setRcmModal] = useState(false)
  const [shareModal, setShareModal] = useState(false)

  const scrollToBottom = () => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight
    }
    setScroll(false)
  }

  const handleCloseEndModal = () => {
    setEndModal(false)
  }
  const handleOpenEndModal = () => {
    setEndModal(true)
  }
  const handleOpenRcm = () => {
    handleCloseEndModal()
    setRcmModal(true)
  }
  const handleCloseRcm = () => {
    setRcmModal(false)
    navigate(`/webview/ai/intro?seq=${userSeq}`)
  }

  useEffect(() => {
    let setTimeMessage
    if (userSeq) {
      setTimeMessage = setTimeout(() => {
        postMessage({ userSeq: userSeq, message: 'hi' })
      }, 100)
    }

    return () => clearTimeout(setTimeMessage)
  }, [userSeq])

  useEffect(() => {
    scrollToBottom()
  }, [feed, scroll])

  return (
    <>
      <MetaInfo
        props={{
          title: '나만의 AI 원어민 친구! AI 원어민톡',
          description: '언제 어디서나 편하게 찐친과 톡하듯이!',
          site_name: '나와 관심사가 딱 맞는 AI 친구와 대화하기',
          imgsrc: 'https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/img_og_aiTalk_v2.png'
        }}
      />
      {!rcmModal && (
        <TitleBar title={'AI 원어민 톡 · 나만의 AI 원어민 친구'} onBtnClick={handleOpenEndModal} />
      )}
      <article className={s.aiChat}>
        <section className={s.chatContainer} ref={messageBoxRef}>
          <div className={s.inner}>
            {location && location.state !== null && (
              <ChatFeed
                feed={feed}
                aiInfo={location.state.info}
                isLoading={isLoading}
                userSeq={userSeq}
              />
            )}
          </div>
        </section>
        <section className={s.chatFooter}>
          <div className={s.inner}>
            <ChatInput handleMessages={addedFeed} isLoading={isLoading} />
          </div>
        </section>
      </article>
      {endModal && (
        <ModalEnd
          close={location.state && !location.state.payUser ? handleOpenRcm : handleCloseRcm}
          confirm={handleCloseEndModal}
        />
      )}
      {rcmModal && <ModalRecommend close={handleCloseRcm} />}
      {shareModal && <ModalTalkShare close={() => setShareModal(false)} />}
    </>
  )
}
