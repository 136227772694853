export const HOST_CDN =
  'https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2023/dec/renew/'

export const BEST_AWARDS_HOST_CDN =
  'https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2024/'

export const releaseChatValues = [
  {
    srcPc: `${HOST_CDN}img_pc_chat02_v2.png`,
    srcMo: `${HOST_CDN}img_mo_chat01.png`,
    alt: '이분한테 영어 배우고 싶다'
  },
  {
    srcPc: `${HOST_CDN}img_pc_chat01_v2.png`,
    srcMo: `${HOST_CDN}img_mo_chat02.png`,
    alt: '오드리 스앵님한테 배우고 싶다'
  },
  {
    srcPc: `${HOST_CDN}img_pc_chat03_v2.png`,
    srcMo: `${HOST_CDN}img_mo_chat03.png`,
    alt: '쏙쏙 강의가 들어오네여'
  },
  {
    srcPc: `${HOST_CDN}img_pc_chat05_v2.png`,
    srcMo: `${HOST_CDN}img_pc_chat04.png`,
    alt: '선생님 강의 들으려면 어떤 강의 수강해야 하나요?'
  },
  {
    srcPc: `${HOST_CDN}img_pc_chat04_v2.png`,
    srcMo: `${HOST_CDN}img_pc_chat05.png`,
    alt: '나도 저 학원 가고 싶음 ㅋ'
  }
]
export const reviewImgValues = [
  {
    srcPc: `${HOST_CDN}img_pc_review01.png`,
    srcMo: `${HOST_CDN}img_mo_review01.png`
  },
  {
    srcPc: `${HOST_CDN}img_pc_review02.png`,
    srcMo: `${HOST_CDN}img_mo_review02_v.png`
  },
  {
    srcPc: `${HOST_CDN}img_pc_review03.png`,
    srcMo: `${HOST_CDN}img_mo_review03.png`
  },
  {
    srcPc: `${HOST_CDN}img_pc_review04.png`,
    srcMo: `${HOST_CDN}img_mo_review04.png`
  },
  {
    srcPc: `${HOST_CDN}img_pc_review05.png`,
    srcMo: `${HOST_CDN}img_mo_review05.png`
  }
]
export const packageReviewImgValues = [
  {
    srcPc: `${HOST_CDN}img_pc_review-package01.jpg`,
    srcMo: `${HOST_CDN}img_m_review-package01.jpg`,
    srcVideo: `${HOST_CDN}video_pc_review-package01.mov`,
    title: '섀도잉까지 할 수 있어서 효율적입니다',
    description:
      '강의가 짧고 카테고라이징이 매우 잘 돼 있고, AI 연습 같은 학습 코너도 독학러분들께 딱이에요',
    writer: 'Y대생 예아님'
  },
  {
    srcPc: `${HOST_CDN}img_pc_review-package02.jpg`,
    srcMo: `${HOST_CDN}img_m_review-package02.jpg`,
    srcVideo: `${HOST_CDN}video_pc_review-package02.mov`,
    title: '왕초보 영어의 핵심이 다 있어요',
    description:
      '쉬운 표현 위주로 꾸준히 강의를 들으면서 말할 수 있도록 강좌가 구성돼 있어서 추천드리고 싶어요',
    writer: '외국계 직장인 드리밍조이님'
  },
  {
    srcPc: `${HOST_CDN}img_pc_review-package03.jpg`,
    srcMo: `${HOST_CDN}img_m_review-package03.jpg`,
    srcVideo: `${HOST_CDN}video_pc_review-package03.mov`,
    title: '영어의 기본기를 빠르게 잡고 싶다면 추천!',
    description:
      '영어를 진짜 잘 몰라도 주어 동사라도 뱉어볼 줄 알아야 하잖아요. 기본기 잡기에 진짜 좋은 강의에요.',
    writer: '프로 독학러 버킷리스트 예스님'
  }
]
export const pointChatValues = [
  {
    srcPc: `${HOST_CDN}img_pc_point-chat01.png`,
    srcMo: `${HOST_CDN}img_mo_point-chat01.png`,
    alt: '어머니가 미군 부대에서 2년간 피아노 반주를 하셨는데 영어는 한 마디도 못하셨거든요'
  },
  {
    srcPc: `${HOST_CDN}img_pc_point-chat02.png`,
    srcMo: `${HOST_CDN}img_mo_point-chat02.png`,
    alt: '그때부터 어떻게하면 한국인들에게 영어 회화를 쉽게 가르칠 수 있을지 고민했어요'
  },
  {
    srcPc: `${HOST_CDN}img_pc_point-chat03.png`,
    srcMo: `${HOST_CDN}img_mo_point-chat03.png`,
    alt: '그렇게 강의를 찍었던 것이 야나두의 시초가 됐고요'
  }
]
export const pointCarouselValues = [
  {
    srcPc: `${HOST_CDN}img_pc_point02.gif`,
    srcMo: `${HOST_CDN}img_pc_point02.gif`,
    alt: '10분 패키지 강의 이미지'
  },
  {
    srcPc: `${HOST_CDN}img_pc_point0202.gif`,
    srcMo: `${HOST_CDN}img_pc_point0202.gif`,
    alt: '앱 예시 이미지'
  },
  {
    srcPc: `${HOST_CDN}img_pc_point0203.gif`,
    srcMo: `${HOST_CDN}img_pc_point0203.gif`,
    alt: '앱 예시 이미지'
  },
  {
    srcPc: `${HOST_CDN}img_pc_point0204.png`,
    srcMo: `${HOST_CDN}img_mo_point0204.png`,
    alt: '앱 예시 이미지'
  }
]
export const pointChartValues = [
  {
    srcPc: `${HOST_CDN}img_pc_chart01.png`,
    srcMo: `${HOST_CDN}img_mo_chart03.png`,
    alt: '기초'
  },
  {
    srcPc: `${HOST_CDN}img_pc_chart02.png`,
    srcMo: `${HOST_CDN}img_mo_chart02.png`,
    alt: '특화'
  },
  {
    srcPc: `${HOST_CDN}img_pc_chart03.png`,
    srcMo: `${HOST_CDN}img_mo_chart01.png`,
    alt: '심화'
  }
]
export const curriculumImgValues = [
  [
    {
      srcPc: `${HOST_CDN}img_pc_curr0101.png`,
      srcMo: `${HOST_CDN}img_mo_curr0101.png`,
      alt: '입문회화/원예나/20강 기초 회화'
    },
    {
      srcPc: `${HOST_CDN}img_pc_curr0102_v2.png`,
      srcMo: `${HOST_CDN}img_mo_curr0102_v2.png`,
      alt: '앞자리 영어/원예나/71강 기초 회화'
    },
    {
      srcPc: `${HOST_CDN}img_pc_curr0103.png`,
      srcMo: `${HOST_CDN}img_mo_curr0103.png`,
      alt: '1분 원샷 영어/오드리/200강 입문회화'
    },
    {
      srcPc: `${HOST_CDN}img_pc_curr0106.png`,
      srcMo: `${HOST_CDN}img_mo_curr0106.png`,
      alt: '버디톡/오드리/40강 기초 영어 회화'
    },
    {
      srcPc: `${HOST_CDN}img_pc_curr0105.png`,
      srcMo: `${HOST_CDN}img_mo_curr0105.png`,
      alt: '1mm 스피킹/에일린/23강 입문회화'
    },
    {
      srcPc: `${HOST_CDN}img_pc_curr0104.png`,
      srcMo: `${HOST_CDN}img_mo_curr0104.png`,
      alt: '마법패턴 & 명대사패턴/원예나/20강 기초 영어 패턴'
    }
  ],
  [
    {
      srcPc: `${HOST_CDN}img_pc_curr0201.png`,
      srcMo: `${HOST_CDN}img_mo_curr0201.png`,
      alt: '5분 발음교정/원예나/20강 기초 발음'
    },
    {
      srcPc: `${HOST_CDN}img_pc_curr0202.png`,
      srcMo: `${HOST_CDN}img_mo_curr0202.png`,
      alt: '리얼 영단어/원예나/141강 기초 단어'
    },
    {
      srcPc: `${HOST_CDN}img_pc_curr0203.png`,
      srcMo: `${HOST_CDN}img_mo_curr0203.png`,
      alt: '리얼 영작문/원예나/36강 입문 영작문'
    },
    {
      srcPc: `${HOST_CDN}img_pc_curr0204.png`,
      srcMo: `${HOST_CDN}img_mo_curr0204.png`,
      alt: '써먹는 보카/에일린&설비/67강 입문&초급 단어'
    },
    {
      srcPc: `${HOST_CDN}img_pc_curr0205.png`,
      srcMo: `${HOST_CDN}img_mo_curr0205.png`,
      alt: '90일 패턴쉐도잉/야나두 연구소/90강 입문 영어 패턴'
    }
  ],
  [
    {
      srcPc: `${HOST_CDN}img_pc_curr0301_v3.png`,
      srcMo: `${HOST_CDN}img_mo_curr0301_v2.png`,
      alt: '리얼 대화 영어/오드리/69강 실전 회화'
    },
    {
      srcPc: `${HOST_CDN}img_pc_curr0302.png`,
      srcMo: `${HOST_CDN}img_mo_curr0302.png`,
      alt: '이디엄이 뭔대영/오드리/21강 원어민 회화'
    },
    {
      srcPc: `${HOST_CDN}img_pc_curr0303.png`,
      srcMo: `${HOST_CDN}img_mo_curr0303.png`,
      alt: '리얼 데일리 영어/오드리/40강 원어민 회화'
    },
    {
      srcPc: `${HOST_CDN}img_pc_curr0304.png`,
      srcMo: `${HOST_CDN}img_mo_curr0304.png`,
      alt: '콩글리시 타파하기/피터/15강 원어민 회화'
    },
    {
      srcPc: `${HOST_CDN}img_pc_curr0305.png`,
      srcMo: `${HOST_CDN}img_mo_curr0305.png`,
      alt: '리얼 밀당 발음/에리카/20강 원어민 발음'
    },
    {
      srcPc: `${HOST_CDN}img_pc_curr0306.png`,
      srcMo: `${HOST_CDN}img_mo_curr0306.png`,
      alt: '리얼 리듬 영어/래릿/20강 원어민 회화'
    },
    {
      srcPc: `${HOST_CDN}img_pc_curr0307.png`,
      srcMo: `${HOST_CDN}img_mo_curr0307.png`,
      alt: '진짜 날라리 영어/날라리데이브/40강 원어민 회화'
    },
    {
      srcPc: `${HOST_CDN}img_pc_curr0308.png`,
      srcMo: `${HOST_CDN}img_mo_curr0308.png`,
      alt: '리얼 몰입 영어/지나/20강 원어민 회화'
    },
    {
      srcPc: `${HOST_CDN}img_pc_curr0309.png`,
      srcMo: `${HOST_CDN}img_mo_curr0309.png`,
      alt: '리얼 스피킹/원예나/100강 실전 회화'
    }
  ]
]
export const realLectureValues = [
  {
    srcPc: `${HOST_CDN}img_pc_real01.png`,
    srcMo: `${HOST_CDN}img_mo_real01.png`,
    srcVideo: `${HOST_CDN}video_pc_real01.mp4`,
    alt: '앞자리 영어 - 원예나'
  },
  {
    srcPc: `${HOST_CDN}img_pc_real02_v2.png`,
    srcMo: `${HOST_CDN}img_mo_real02_v2.png`,
    srcVideo: `${HOST_CDN}video_pc_real02.mp4`,
    alt: '리얼대화영어 입문편 - 오드리'
  }
]
export const careReviewValues = [
  {
    title: '10년 동안 외국인을 만나도 한마디를 못했는데',
    writer: '수강생 끝********님',
    content:
      '66일만에 학원에서 만난 외국인 친구와 완벽하진 못하지만 여러가지 표현을 사용하여 소통을 할 수 있게 되었어요. 입으로 반복하는 습관을 들여서 나도 모르게 말이 툭 나오니까 신기했습니다'
  },
  {
    title: '매일 장학금이 쌓이다보니 동기부여가 됩니다',
    writer: '수강생 우*****님',
    content:
      '이렇게 거의 4~5개월 동안 꾸준히 하다보니 습관이 된 것 같아 앞으로 장학금이 없어도 꾸준히 영어 공부를 할 수 있을것 같아요!'
  },
  {
    title: '막막했던 영어가 편해지고 가까워졌어요',
    writer: '수강생 하**님',
    content: '이젠 습관처럼 매일매일 꾸준히 듣고 연습하고 복습 할겁니다..good luck'
  },
  {
    title: '쉽게 영어를 마주할 수 있어서 좋네요 ^^',
    writer: '수강생 천****님',
    content:
      '쉽게 영어를 접할 수 있게 해준 야나두 정말 고맙고, 남은 파트도 잘 따라해 영어가 입에서 나오길 기도합니다. 여러분 늦지않았습니다. 61년생 저도 합니다!!'
  },
  {
    title: '입으로 따라하니 영어가 금방 늘어요!',
    writer: '수강생 워**님',
    content:
      '처음엔 부끄러웠는데 하다보니 자신감도 생기고 하루라도 야나두를 안 하면 어색해서 꼭 하고 자는 습관이 생겼어요! 원어민과 자연스레 소통하는 날까지 입으로 열심히 따라하겠습니다!'
  },
  {
    title: '회화가 안 돼 중도 포기했던 분에게 추천합니다',
    writer: '수강생 a******님',
    content:
      '마음먹고 영어회화 공부를 시작하다 말다 하곤했는데, 매일 10분씩 너무 이해하기 쉽게 구성돼 있어 말문이 터졌어요~^^'
  },
  {
    title: '60을 바라보며 한번도 성공하지 못한 일에 도전합니다',
    writer: '수강생 손**님',
    content: '이 성공이 더 큰 성공으로 다가오기를 바라며 화이팅 해봅니다.'
  }
]
export const teacherValues = [
  {
    name: '원예나',
    description: '야나두 대표 강사',
    imgSrc: `https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2023/june/img_pc_bestawards_teacher_1.png`
  },
  {
    name: '오드리',
    description: '230만의 유튜버 영어 강사',
    imgSrc: `https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2023/june/img_pc_bestawards_teacher_2.png`
  },
  {
    name: '에일린',
    description: '연예인 전담 강사',
    imgSrc: `https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2023/june/img_pc_bestawards_teacher_3.png`
  },
  {
    name: '설비',
    description: '외국어 특강 전문 강사',
    imgSrc: `https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2023/june/img_pc_bestawards_teacher_4.png`
  }
]

export const challengeValues = [
  {
    pc: 'https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2024/apr/img_pc_challenge_01_v2.png',
    mo: 'https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2024/apr/img_m_challenge_01_v3.png',
    bgcolor: '#262220',
    title: '야나두 습돈챌이란?',
    summary:
      '출석 일수에 따라 장학금을 지급하는 제도입니다. 66일 동안 간단하지만 꾸준하게 영어 말하기 습관을 만들어보는 것 어떨까요?'
  },
  {
    pc: 'https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2024/apr/img_pc_challenge_02.png',
    mo: 'https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2024/apr/img_m_challenge_02.png',
    bgcolor: '#fff',
    title: '습관이 돈이 되는 습돈챌!',
    summary:
      '하루10분 꾸준한 습관 루틴, 성공할 수 있을까 고민되시나요? 습돈챌에 도전한 전체 인원 중 65%는 챌린지에 성공했어요. 차곡차곡 쌓이는 장학금 보상 누적 장학금만 벌써 88억!'
  },
  {
    pc: 'https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2024/apr/img_pc_challenge_03.png',
    mo: 'https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2024/apr/img_m_challenge_03.png',
    bgcolor: '#f2f2f2',
    title: '습돈챌 참여 방법',
    summary:
      '1. 야나두 앱에서 장학금 도전 신청, 2. 도전 신청 다음날부터 매일 강의 듣기, 3. 총 장학금 84,000원 수령!'
  }
]

export const bestAwardsValues = {
  package: [
    {
      id: 'basic',
      name: '베이직',
      orderCode: '10MIN_BASIC',
      augustEventOrderCode: '10MIN_BASIC',
      finalPrice: '49,933'
    },
    {
      id: 'premium',
      name: '프리미엄',
      orderCode: 'RE_10MINUTES_PREMIUM_TRAINING',
      augustEventOrderCode: '10MINUTES_PREMIUM_AUGUST',
      finalPrice: '58,250',
      Installment: '/12개월 무이자 할부시'
    },
    {
      id: 'forever',
      name: '평생수강',
      orderCode: 'RE_10MINUTES_FOREVER_1',
      augustEventOrderCode: '10MINUTES_FOREVER_AUGUST',
      finalPrice: '129,166'
    }
  ],
  metaInfo: {
    title: '누적 수강생 170만, 야나두 10분 패키지',
    description: '2024 NEW! 170만 수강생이 검증한 왕초보 회화 솔루션',
    site_name: '1억뷰의 스타 강사와 함께 시작해보세요',
    imgsrc: `${BEST_AWARDS_HOST_CDN}img_og_bestawards.png`
  }
}
