import { useState, useRef, useEffect, useCallback } from 'react'

import ModalPortal from 'ModalPortal'
import AuthService from 'services/authService'

import api from 'common/api'
import * as config from 'common/config'
import BtnMore from 'components/BtnMore'
import ModalCheckPackage from 'components/modal/ModalCheckPackage'
import ModalEventReview from 'components/modal/ModalEventReview'
import ModalWrap from 'components/promotion/modal/ModalWrap'
import useModalLogin from 'store/useModalLogin'

import ModalCounsel from './ModalCounsel'
import ReviewItem from './ReviewItem'

export default function ItemApply({ eventData, eventSeq, eventContent }) {
  const eventType = eventData.addApplyType.toLowerCase()
  const userInfo = AuthService.getUserInfo()
  const commentInput = useRef()
  const detailRef = useRef(null)

  const [boardModalOpen, setBoardModalOpen] = useState(false)
  const [checkModalOpen, setCheckModalOpen] = useState(false)
  const [comment, setComment] = useState('')
  const [commentData, setCommentData] = useState([])
  const [currentIdx, setCurrentIdx] = useState(null)
  const [pageData, setPageData] = useState([])
  const [modal, setModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const { setIsModalLogin } = useModalLogin()

  const handleOpenBoardModal = useCallback((e) => {
    if (!AuthService.getUserInfo()) {
      handleOpenLoginModal()
    } else {
      document.body.classList.add('modal-open')
      setBoardModalOpen(true)
    }
  }, [])

  const handleCloseBoardModal = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setBoardModalOpen(false)
  }, [])

  const handleOpenLoginModal = useCallback((e) => {
    document.body.classList.add('modal-open')
    setIsModalLogin(true)
  }, [])

  const handleCommentValue = (e) => {
    setComment(e.target.value)
  }

  const handleCheckMember = () => {
    if (!AuthService.getUserInfo()) {
      handleOpenLoginModal()
    }
  }

  const handleCloseCheckModal = () => {
    setCheckModalOpen(false)
  }

  // 이벤트 댓글 리스트 조회
  const getCommentData = (idx) => {
    api
      .get(`/v2/event/apply/${eventType}/list?index=${idx}&size=6&eventSeq=${eventSeq}`)
      .then((response) => {
        setCommentData([...commentData, ...response.data.data.content])
        setPageData(response.data.data)
        setCurrentIdx(idx)
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  // 댓글 이벤트 등록
  const postReply = () => {
    if (comment === '') {
      alert('댓글을 입력해주세요.')
      commentInput.current.focus()

      return false
    }

    const data = {
      eventSeq: eventSeq,
      comment: comment
    }

    api
      .post('/v2/event/apply/comment', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)
          commentInput.current.focus()

          return false
        }

        alert('댓글 작성이 완료되었습니다!')
        window.location.reload()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleModalControl = (value) => {
    if (!modal) {
      const str = value.replaceAll("'", '"')
      const data = JSON.parse(str)
      setModalData(data)
    }
    setModal(!modal)
  }

  const handleAmountCheck = (href) => {
    if (eventData.relatedPackages && eventData.remainingPackages > 0) {
      window.open(href, '_blank')
    } else {
      setCheckModalOpen(true)
    }
  }

  const handleClickEvent = (e) => {
    e.preventDefault()
    const target = e.target.parentNode

    if (target.nodeName === 'A') {
      const href = target.attributes.href.value

      if (href.startsWith('#event')) {
        // value[0] = eventType (eventType: String)(#eventModal, #eventAmount)
        // value[1] = Object || string
        // sample => #eventModal@{'banner':{'pc': 'bnr_pc_counsel_stanbyme.png','mo':'bnr_m_counsel_stanbyme.png'},'category':'standbymego','btn':'스탠바이미Go 무료 신청하기','color':'#188521'}
        const value = href.split('@')

        switch (value[0]) {
          case '#eventModal':
            handleModalControl(value[1])
            break

          default:
            handleAmountCheck(value[1])
            break
        }
        return
      }

      if (!userInfo && (href.indexOf('/mypage') > -1 || href.indexOf('/cart') > -1)) {
        setIsModalLogin(true)
        return
      }

      window.open(href, '_blank')
    }
  }

  const handleSuccess = (duplication) => {
    if (!duplication) {
      alert(
        '이미 상담 신청하셨어요!\n해당 번호로는 더 이상 접수 불가합니다.\n기존에 등록해주신 번호로 전화 연락드리겠습니다.'
      )
    } else {
      alert('영어 회화 목표에 다가가셨네요!\n요청하신 시간에 전화 연락 드리겠습니다.')
      window.dataLayer.push({
        event: 'lead',
        product_name: '팝업이벤트'
      })
    }
  }

  useEffect(() => {
    if (eventData.addApplyType === 'BOARD' || eventData.addApplyType === 'COMMENT') {
      getCommentData(1)
    }

    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <section className="event-participate-col">
        <div className="inner-col">
          <div className="event-area">
            <div
              onClick={handleClickEvent}
              ref={detailRef}
              dangerouslySetInnerHTML={{ __html: eventContent }}
            ></div>
          </div>
          {eventData.eventStatus === 'PROGRESS' ? (
            eventData.addApplyType === 'COMMENT' ? (
              <div className="participate-type comment">
                <div className="participate-area">
                  <p>
                    댓글 이벤트 참여
                    <span>{pageData.totalElements}</span>
                  </p>
                  <button type="submit" className="btn btn-register" onClick={postReply}>
                    <span>등록하기</span>
                  </button>
                  <form name="replyFrm">
                    <div className="reply-box">
                      <textarea
                        placeholder="댓글을 입력해주세요."
                        value={comment}
                        ref={commentInput}
                        onChange={handleCommentValue}
                        onClick={handleCheckMember}
                      />
                    </div>
                  </form>
                </div>
                <div
                  className={
                    'participate-list' +
                    (pageData.numberOfElements < pageData.totalElements &&
                    currentIdx < pageData.totalPages
                      ? ''
                      : ' line')
                  }
                >
                  <ul>
                    {commentData &&
                      commentData.map((list, idx) => (
                        <ReviewItem key={idx} list={list} type={eventData.addApplyType} />
                      ))}
                  </ul>
                  {pageData.numberOfElements < pageData.totalElements &&
                  currentIdx < pageData.totalPages ? (
                    <BtnMore
                      type={'PAGINATION'}
                      dataFunction={getCommentData}
                      currentIdx={currentIdx}
                    />
                  ) : null}
                </div>
              </div>
            ) : eventData.addApplyType === 'BOARD' ? (
              <div className="participate-type board">
                <div className="participate-area">
                  <p>
                    작성 이벤트 참여
                    <span>{pageData.totalElements}</span>
                  </p>
                  <button type="button" className="btn btn-register" onClick={handleOpenBoardModal}>
                    <span>등록하기</span>
                  </button>
                </div>
                <div className="participate-list">
                  <ul>
                    {commentData &&
                      commentData.map((list, idx) => (
                        <ReviewItem key={idx} list={list} type={eventData.addApplyType} />
                      ))}
                  </ul>
                  {pageData.numberOfElements < pageData.totalElements &&
                  currentIdx < pageData.totalPages ? (
                    <BtnMore
                      type={'PAGINATION'}
                      dataFunction={getCommentData}
                      currentIdx={currentIdx}
                    />
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="participate-type"></div>
            )
          ) : null}
        </div>
      </section>
      {boardModalOpen ? (
        <ModalPortal>
          <ModalEventReview
            btnClose
            handleCloseModal={handleCloseBoardModal}
            eventSeq={eventSeq}
            userInfo={userInfo}
          />
        </ModalPortal>
      ) : null}
      {checkModalOpen && (
        <ModalWrap onClose={handleCloseCheckModal}>
          <ModalCheckPackage onClose={handleCloseCheckModal} />
        </ModalWrap>
      )}
      {modal && (
        <ModalPortal>
          <ModalCounsel
            banner={modalData.banner}
            pCategory={modalData.category}
            btnText={modalData.btn}
            btnBgColor={modalData.color}
            btnColor={'#fff'}
            onClose={handleModalControl}
            eventName={'팝업이벤트'}
            response
            onSuccess={handleSuccess}
          />
        </ModalPortal>
      )}
    </>
  )
}
