import React, { useEffect, useRef, useState } from 'react'
import styles from './Form.module.css'
import api from 'common/api'
import { API_HOST } from 'common/config'
import ModalPrivacyAgree from 'components/promotion/modal/ModalPrivacyAgree'
import ModalWrap from 'components/promotion/modal/ModalWrap'
import SuccessModal from './SuccessModal'
import { getUtm } from 'components/promotion/utile/utile'

export default function Form() {
  // 개인정보 수집 약관
  const [modalControl, setModalControl] = useState({ privacy: false, success: false })
  const handleModalToggle = (key) => {
    setModalControl({ [key]: !modalControl[key] })
  }

  const formRef = useRef(null)
  const formFieldInit = {
    category: 'bestAwardsDBonly',
    userName: '',
    phone: '',
    counselTime: '아무때나',
    utm: getUtm() || '',
    privacyAgreeYn: false,
    smsAgreeYn: false
  }
  const [formData, setFormData] = useState(formFieldInit)

  const handelInputChange = (e) => {
    const { id, value } = e.target
    setFormData((prev) => ({ ...prev, [id]: value }))
  }

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target
    setFormData((prev) => ({
      ...prev,
      [id]: checked
    }))
  }

  const validateField = (field, message, isCheckbox) => {
    if (isCheckbox) {
      if (!formData[field]) {
        alert(message)
        return false
      }
      return true
    } else {
      if (formData[field] === '') {
        alert(message)
        formRef.current.querySelector(`input[id="${field}"]`).focus()
        return false
      }
      return true
    }
  }

  const nameValidCheck = (e) => {
    const regexp = /[a-z0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'\\]/g
    const value = formData.userName
    if (regexp.test(value)) {
      alert('이름은 한글만 입력가능 합니다.')
      setFormData((prev) => ({ ...prev, userName: value.replace(regexp, '') }))
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // 유효성 체크
    if (
      validateField('userName', '이름을 입력해 주세요.') &&
      validateField('phone', '전화번호를 입력해 주세요.') &&
      validateField('counselTime', ' 입력해 주세요.') &&
      validateField('privacyAgreeYn', '개인정보 수집/이용에 동의해 주세요.', true)
    ) {
      const dataBody = {
        ...formData,
        privacyAgreeYn: formData.privacyAgreeYn ? 'Y' : 'N',
        smsAgreeYn: formData.smsAgreeYn ? 'Y' : 'N'
      }

      api
        .post(`${API_HOST}/v2/inquiry/consultation/tel`, JSON.stringify(dataBody))
        .then((res) => {
          const message = res.data.meta.message
          const duplication = res.data.data

          if (message === 'SUCCESS') {
            if (!duplication) {
              alert(
                '이미 상담 신청하셨어요!\n해당 번호로는 더 이상 접수 불가합니다.\n기존에 등록해주신 번호로 전화 연락드리겠습니다.'
              )
            } else {
              handleModalToggle('success')
              window.dataLayer.push({ event: 'lead', product_name: 'new10분영어2' })
            }
            setFormData(formFieldInit)
          } else {
            alert('통신장애가 발생되었습니다.')
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  const btnRef = useRef(null)
  useEffect(() => {
    const observer = new IntersectionObserver((e) => {
      e.forEach((ele) => {
        // Enter
        if (ele.isIntersecting) {
          ele.target.classList.add(styles.active)
        } else {
          ele.target.classList.remove(styles.active)
        }
      })
    })

    observer.observe(btnRef.current)

    return () => {
      observer.disconnect()
    }
  })

  return (
    <>
      <form ref={formRef} className={styles.form}>
        <div className={styles.formInner}>
          <ul className={styles.formInputs}>
            <li>
              <label htmlFor="userName">이름</label>
              <input
                id="userName"
                type="text"
                name="userName"
                value={formData.userName}
                onChange={handelInputChange}
                onKeyUp={nameValidCheck}
                placeholder="이름을 입력해주세요"
              />
            </li>
            <li>
              <label htmlFor="phone">휴대폰 번호</label>
              <input
                type="number"
                id="phone"
                name="phone"
                value={formData.phone}
                placeholder="번호를 입력해주세요"
                onChange={handelInputChange}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, '')
                }}
              />
            </li>
            <li>
              <label htmlFor="counsel">전화 상담</label>
              <div className={styles.counselContainer}>
                <div className={styles.counselTit}>
                  <p>
                    <span>상담 가능 시간</span>
                    <span>평일 : 10:00 ~ 18:00</span>
                  </p>
                </div>
                <div className={styles.counselOptions}>
                  <select id="counselTime" name="counseltime" onChange={handelInputChange}>
                    <option value="아무때나">아무때나</option>
                    <option value="10:00 ~ 10:30">10:00 ~ 10:30</option>
                    <option value="11:00 ~ 11:30">11:00 ~ 11:30</option>
                    <option value="11:30 ~ 12:00">11:30 ~ 12:00</option>
                    <option value="12:00 ~ 12:30">12:00 ~ 12:30</option>
                    <option value="13:30 ~ 14:00">13:30 ~ 14:00</option>
                    <option value="14:00 ~ 14:30">14:00 ~ 14:30</option>
                    <option value="14:30 ~ 15:00">14:30 ~ 15:00</option>
                    <option value="15:00 ~ 15:30">15:00 ~ 15:30</option>
                    <option value="15:30 ~ 16:00">15:30 ~ 16:00</option>
                    <option value="16:30 ~ 17:00">16:30 ~ 17:00</option>
                    <option value="17:00 ~ 17:30">17:00 ~ 17:30</option>
                    <option value="17:30 ~ 18:00">17:30 ~ 18:00</option>
                  </select>
                </div>
              </div>
            </li>
          </ul>

          <ul className={styles.formAgree}>
            <li>
              <label>
                <input
                  type="checkbox"
                  id="privacyAgreeYn"
                  name="privacyAgreeYn"
                  checked={formData.privacyAgreeYn}
                  onChange={handleCheckboxChange}
                />
                <em></em>개인정보 수집·이용 동의[필수]
                <button
                  type="button"
                  onClick={() => {
                    handleModalToggle('privacy')
                  }}
                >
                  [약관보기]
                </button>
              </label>
            </li>
            <li>
              <label>
                <input
                  type="checkbox"
                  id="smsAgreeYn"
                  name="smsAgreeYn"
                  checked={formData.smsAgreeYn}
                  onChange={handleCheckboxChange}
                />
                <em></em>
                SMS 광고 및 마케팅 이용 동의[선택]
              </label>
            </li>
          </ul>
          <button ref={btnRef} type="submit" className={styles.submitBtn} onClick={handleSubmit}>
            상담 신청하기
          </button>
        </div>
      </form>
      {modalControl.privacy && (
        <ModalWrap>
          <ModalPrivacyAgree
            toggle={() => {
              handleModalToggle('privacy')
            }}
          />
        </ModalWrap>
      )}
      {modalControl.success && (
        <ModalWrap
          onClose={() => {
            handleModalToggle('success')
          }}
        >
          <SuccessModal
            onClose={() => {
              handleModalToggle('success')
            }}
          />
        </ModalWrap>
      )}
    </>
  )
}
