import React from 'react';
import * as main from "common/main"
import { useState, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import ModalPortal from 'ModalPortal';
import ModalVod from 'components/modal/ModalVod';
import { useMediaQuery } from 'react-responsive';

//style
import 'swiper/swiper.scss';

SwiperCore.use([Pagination, Autoplay]);

export default function ClassList ({ itemGroup }) {
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)
    const [modalOpen, setModalOpen] = useState(false);
    const [mediaKey, setMediaKey] = useState(null);
		const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false;

		const swiperSpaceBetween = deviceCheck ? 8 : 30;
		const swiperSlidesPerView = deviceCheck ? 1.2 : 3;

    const handleOpenModal = useCallback((url, e) => {
        e.preventDefault();
        document.body.classList.add('modal-open');
        setModalOpen(true);
        setMediaKey(url);
    }, []);

    const handleCloseModal = useCallback((e) => {
        document.body.classList.remove('modal-open');
        setModalOpen(false);
    }, []);

    return (
      <>
        <section className="free-class-col slide-col">
          <div className="inner-col">
            <div className="item-content-col">
              <h3>{itemGroup.title}</h3>
              <p>{itemGroup.subTitle}</p>
                {itemGroup && itemGroup.groupName && (
                  <h3>{itemGroup.groupName}</h3>
                )}
                <div className="entrance-slide-box card">
                  {itemGroup && itemGroup.mainItems &&
                    <Swiper
                      className="swiper-container"
                      spaceBetween={swiperSpaceBetween}
                      slidesPerView={swiperSlidesPerView}
                      navigation={{
                        // Both prevEl & nextEl are null at render so this does not work
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                      }}
                      onSwiper={(swiper) => {
                        // Delay execution for the refs to be defined
                        setTimeout(() => {
                          // Override prevEl & nextEl now that refs are defined
                          swiper.params.navigation.prevEl = navigationPrevRef.current
                          swiper.params.navigation.nextEl = navigationNextRef.current

                          // Re-init navigation
                          swiper.navigation.destroy()
                          swiper.navigation.init()
                          swiper.navigation.update()
                        })
                      }}
                    >
                      {itemGroup.mainItems.map((item, idx) => {
                      return (
                        <SwiperSlide
                            key={idx}
                            className="swiper-slide"
                        >
                          <a herf="#" onClick={(e) => handleOpenModal(item.linkPath, e)}>
                            <div className="item">
                              <div className="thumb" style={{ backgroundImage: `url(${item.imagePath1})`, }}>
                                <div className="itme-info">
                                  <p>
                                    <strong>{item.title}</strong>
                                  </p>
                                  <span>영상 미리 보기</span>
                                </div>
                                <span className="play-ico"></span>
                              </div>
                            </div>
                          </a>
                        </SwiperSlide>
                      );
                    })}
                    </Swiper>
                  }
                  <div>
                    <button type="button" className="btn-slide prev" ref={navigationPrevRef}><em className="blind">PREV</em></button>
                    <button type="button" className="btn-slide next" ref={navigationNextRef}><em className="blind">NEXT</em></button>
                  </div>
                </div>
            </div>
              {modalOpen ? (
                <ModalPortal>
                  <ModalVod
                    btnClose
                    handleCloseModal={handleCloseModal}
                    iframeUrl={mediaKey}
                  />
                </ModalPortal>
              ) : null}
          </div>
        </section>
      </>
    );
};
