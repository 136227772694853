import AiFeedIntro from './AiFeedIntro'
import s from './AiInformation.module.css'

export default function AiInformation({ data }) {
  const { images, info, key } = data
  return (
    <div className={s.aiInfo}>
      <img className={s.aiImg} src={images.circle} alt={info.fullname} />
      <dl>
        <dt className={s.aiName}>{info.fullname}</dt>
        <dd className={s.aiDesc}>
          {info.age} / {info.mbti} / {info.job} <i className={s.dot}></i> {info.location}
        </dd>
        <dd className={s.aiHash}>
          {info.hashtag.map((item, index) => {
            return <span key={index}>{item}</span>
          })}
        </dd>
      </dl>
      <div className={s.aiNoti}>
        <p>
          <AiFeedIntro ai={key} />
        </p>
      </div>
    </div>
  )
}
