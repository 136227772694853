import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import styled, { ThemeProvider } from 'styled-components';
import theme from 'common/theme';
import api from 'common/api';
import { scrollMove } from '../../common/common';
import BoardBanner from '../../components/board/BoardBanner';
import InfoTextBox from '../../components/board/InfoTextBox';
import BoardSearch from 'components/board/BoardSearch';
import Pagination from '../../components/Pagination';
import BoardListItem from '../../components/board/BoardListItem';
import NoContent from '../../components/NoContent';
import TabBoard from './TabBoard';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ImgBannerFree from '../../assets/images/ico_board_banner_free_356x356.png';
import ImgBannerStudy from '../../assets/images/ico_board_banner_study_356x356.png';
import ImgBannerSrr from '../../assets/images/ico_board_banner_srr_356x356.png';
import BgBannerSrr from '../../assets/images/bg_board_banner_srr.png';
import icoImg from '../../assets/images/ico_no_qna_285x285.png';

// 커뮤니티 게시판 컴포넌트 입니다.
// 게시판 타입을 props 키워드로 받고, 해당 키워드에 맞는 게시물 목록을 노출합니다.
// 현재 연동중인 게시판 리스트. (게시판 추가 시 하위 데이터 수정)
// FREE: 수강생 Talk, STUDY: 학습 Q&A, SRR: 스르르 학습지 Talk
export default function Board({ type }) {
  // 각 게시판별 상세 정보 (필수값인 경우 * 표기해둠)
  const boardInfo = {
    FREE: {
      title: `수강생 Talk`, // * 게시판 제목 (상단 배너 노출이름)
      contents: `야나두의 학습 미션을 인증하고 \n도전 각오를 자유롭게 나눠보세요.`, // * 상단 배너 텍스트
      bgFullbox: `#F7F7FC`, // * 상단 배너 background style.
      bgInnerBox: `url(${ImgBannerFree}) no-repeat 100% 50% / 17.8rem auto`, // * 상단 배너 inner background style.
      infoText: `수강생 Talk 글 작성은 야나두 APP에서 가능합니다.`, // * 상단 info 문구.
      noContentText: `아직 작성된 게시물이 없습니다.`, // * 컨텐츠가 없을때, 표기 문구.
      apiContentsList: `/v2/community-tab/community/list/post`, // * 해당 게시글 목록 api 키값.
      apiContentsCount: `/v2/community-tab/community/list/post-count`, // * 해당 게시글 총 개수 api 키값.
      apiSearchSelectList: `/v2/community-tab/community/list/post/search-type`, // 하단 셀렉트 박스 호출 api url.
    },
    STUDY: {
      title: `학습 Q&A`,
      contents: `학습 관련 궁금한 점은 무엇이든 물어보세요! \n야나두가 해결해드려요.`,
      bgFullbox: `#F7F7FC`,
      bgInnerBox: `url(${ImgBannerStudy}) no-repeat 100% 50% / 17.8rem auto`,
      infoText: `학습 Q&A 작성은 야나두 APP에서 가능합니다.`,
      noContentText: `아직 작성된 질문이 없습니다.`,
      apiContentsList: `/v2/community-tab/lecture-question/list`,
      apiContentsCount: `/v2/community-tab/lecture-question/count`,
      apiSearchSelectList: `/v2/community-tab/lecture-question/list/search-type`,
    },
    SRR: {
      title: `스르르 학습지 Talk`,
      contents: `하루 한 장으로 영어가 스르르~\n스르르 학습지의 영어 소통 공간입니다.`,
      bgFullbox: `url(${BgBannerSrr}) repeat-y 50% 50% / 100% auto`,
      bgInnerBox: `url(${ImgBannerSrr}) no-repeat 100% 50% / 17.8rem auto`,
      infoText: `글 작성은 야나두 APP에서 가능 (학습지 구매자에 한함)`,
      noContentText: `아직 작성된 게시물이 없습니다.`,
      apiContentsList: `/v2/community-tab/community/list/post`,
      apiContentsCount: `/v2/community-tab/community/list/post-count`,
    },
  }[type];
  const location = useLocation();
  const nowParams = location.search;
  const seqSRR = 108;
  const paramCheck = (params, keyword) => {
    return params.split(keyword + `=`)[1]?.split('&')[0];
  };
  const [contentsData, setContentsData] = useState([]);
  const [searchSelect, setSearchSelect] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isSearchMode, setSearchMode] = useState(false);
  const [paramItems, setParamItems] = useState(nowParams);
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false;
  const [pageIndex, setPageIndex] = useState(
    paramCheck(nowParams, 'pageIndex') !== undefined
      ? Number(paramCheck(nowParams, 'pageIndex'))
      : 1
  );
  const [pageSize, setPageSize] = useState(deviceCheck ? 5 : 10);
  const [totalPageSize, setTotalPageSize] = useState(1);
  const skeletonArr = new Array(5).fill('');
  const [communitySeq, setCommunitySeq] = useState(
    paramCheck(nowParams, 'communitySeq') !== undefined ? paramCheck(nowParams, 'communitySeq') : 0
  );
  const [searchType, setSearchType] = useState(
    paramCheck(nowParams, 'searchType') !== undefined ? paramCheck(nowParams, 'searchType') : ''
  );
  const [searchWord, setSearchWord] = useState(
    paramCheck(nowParams, 'searchWord') !== undefined ? paramCheck(nowParams, 'searchWord') : ''
  );

  const handleCurrentIdx = (idx) => {
    setPageIndex(idx);
  };

  const addComma = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  // 검색 타입 리스트 호출. (하단 검색 selectbox)
  const searchSelectList = () => {
    if (boardInfo.apiSearchSelectList !== undefined) {
      api
        .get(boardInfo.apiSearchSelectList)
        .then((response) => {
          const data = response.data.data;
          setSearchSelect(type === `STUDY` ? data.searchTypes : data);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setSearchSelect([
        { description: `제목`, searchType: `TITLE` },
        { description: `작성자`, searchType: `REGISTER_NAME` },
      ]);
    }
  };

  // YYYY-MM-DD HH:mm:SS 타입을 YYYY.MM.DD로 변환.
  const returnDate = (dateItem) => {
    return dateItem.replaceAll('-', '.').split(' ')[0];
  };

  const totalPageReturn = (itemCount) => {
    let param = [];
    let paramText = '';
    if (communitySeq > 0 && type !== 'SRR') {
      param.push({ name: `communitySeq`, key: communitySeq });
    } else if (type === 'SRR') {
      param.push({ name: `communitySeq`, key: seqSRR });
    }
    if (searchType !== `` && searchWord !== ``) {
      param.push({ name: `searchType`, key: searchType }, { name: `searchWord`, key: searchWord });
    }
    if (param.length > 0) {
      param.forEach(
        (item, idx) => (paramText += (idx === 0 ? `?` : `&`) + item.name + `=` + item.key)
      );
    }
    api
      .get(boardInfo.apiContentsCount + paramText)
      .then((response) => {
        const data = response.data.data;
        data.totalCount === 0 && type === `STUDY`
          ? setTotalPageSize(itemCount)
          : setTotalPageSize(data.totalCount);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // user 이벤트에 의한 contentsList filter 처리.
  const targetContentsList = () => {
    const paramUpdate =
      `?pageIndex=` +
      pageIndex +
      (communitySeq > 0 && type !== 'SRR' ? `&communitySeq=` + communitySeq : ``) +
      (searchType !== `` && searchWord !== ``
        ? `&searchType=` + searchType + `&searchWord=` + searchWord
        : ``);
    const apiURL =
      boardInfo.apiContentsList +
      paramUpdate +
      `&pageSize=` +
      pageSize +
      (type === 'SRR' ? `&communitySeq=${seqSRR}` : ``);

    window.history.pushState(null, null, `list` + paramUpdate);
    searchType !== `` && searchWord !== `` ? setSearchMode(true) : setSearchMode(false);
    setParamItems(paramUpdate);
    apiGetContentsList(apiURL);
  };

  // reset contentsList.
  const resetContentsList = () => {
    setPageIndex(1);
    const paramSearchType =
      paramCheck(nowParams, 'searchType') !== undefined ? paramCheck(nowParams, 'searchType') : '';
    const paramSearchWord =
      paramCheck(nowParams, 'searchWord') !== undefined ? paramCheck(nowParams, 'searchWord') : '';
    setSearchType(paramSearchType);
    setSearchWord(paramSearchWord);
    const apiURL =
      boardInfo.apiContentsList +
      `?pageIndex=1&pageSize=` +
      pageSize +
      (type === 'SRR' ? `&communitySeq=${seqSRR}` : ``) +
      (paramSearchType !== `` && paramSearchWord !== ``
        ? `&searchType=` + paramSearchType + `&searchWord=` + paramSearchWord
        : ``);
    apiGetContentsList(apiURL);
  };

  // contentsList api 호출.
  const apiGetContentsList = (apiURL) => {
    api
      .get(apiURL)
      .then((response) => {
        setLoading(false);
        if (response.data.data) {
          const data = response.data.data;
          const dataList = type === `STUDY` ? data.questions : data.posts;
          setContentsData(dataList);
          totalPageReturn(dataList.length);
        } else {
          setContentsData([]);
          totalPageReturn();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    setLoading(true);
    searchSelectList();
    scrollMove(0, 0);
    resetContentsList();
  }, [type]);

  useEffect(() => {
    setLoading(true);
    setPageSize(deviceCheck ? 5 : 10);
    targetContentsList();
    handleCurrentIdx(pageIndex);
  }, [pageIndex, deviceCheck, pageSize, communitySeq, searchType, searchWord]);

  return (
    <ThemeProvider theme={theme}>
      <BoardBanner
        title={boardInfo.title}
        contents={boardInfo.contents}
        bgFullbox={boardInfo.bgFullbox}
        bgInnerBox={boardInfo.bgInnerBox}
      />
      {deviceCheck ? <InfoTextBox text={boardInfo.infoText} /> : null}
      <theme.SmInnerBox>
        {isLoading ? (
          <BoardBox>
            <SkeletonTheme baseColor="#f4f4f4" highlightColor="#f8f8f8">
              <div className="board-info">
                <p>
                  <Skeleton width={'30%'} />
                </p>
              </div>
              <BoardList>
                <ul>
                  {skeletonArr &&
                    skeletonArr.map((item, idx) => {
                      return (
                        <li key={idx}>
                          <SkeletonBox>
                            <dl>
                              <dt>
                                <Skeleton width={'18rem'} />
                              </dt>
                              <dd>
                                <Skeleton width={'6rem'} />
                              </dd>
                            </dl>
                            <Skeleton />
                            <dl>
                              <dt>
                                <Skeleton width={'4rem'} inline={true} />
                                <Skeleton width={'4rem'} inline={true} />
                                <Skeleton width={'4rem'} inline={true} />
                              </dt>
                              <dd>
                                <Skeleton width={'8rem'} />
                              </dd>
                            </dl>
                          </SkeletonBox>
                        </li>
                      );
                    })}
                </ul>
              </BoardList>
            </SkeletonTheme>
          </BoardBox>
        ) : (
          <BoardBox>
            {type === 'SRR' && <TabBoard activeTab={'LIST'} />}
            <div className="board-info">{deviceCheck ? null : <p>{boardInfo.infoText}</p>}</div>
            <BoardList>
              <ul>
                {contentsData && contentsData.length <= 0 ? (
                  <>
                    <li className="no-content">
                      <NoContent
                        icoImg={icoImg}
                        titleMsg={isSearchMode ? `검색 결과가 없습니다.` : boardInfo.noContentText}
                      />
                    </li>
                  </>
                ) : (
                  contentsData.map((item, idx) => {
                    return (
                      <BoardListItem
                        key={idx}
                        best={item.isBest}
                        link={
                          `/community/` +
                          type.toLowerCase() +
                          `/detail/` +
                          (type === `STUDY` ? item.questionSeq : item.communityPostSeq) +
                          paramItems
                        }
                        subTextTitle={
                          type === `STUDY` ? (
                            item.classes?.className
                          ) : type === `SRR` ? (
                            <span> {returnDate(item.registDate)}</span>
                          ) : type === `FREE` ? (
                            item.community?.title
                          ) : null
                        }
                        subTextDate={type === `FREE` ? <em> {returnDate(item.registDate)}</em> : ``}
                        contents={item.title}
                        subInfo={
                          type === `STUDY` ? (
                            <>
                              {item.isReply ? <em>답변완료 </em> : ``}
                              {returnDate(item.registDate)}
                            </>
                          ) : (
                            <>
                              <span>조회수 {addComma(Number(item.hitCount))}</span>
                              <span>좋아요 {addComma(Number(item.likeCount))}</span>
                              <span>댓글 {addComma(Number(item.commentCount))}</span>
                            </>
                          )
                        }
                        userName={item.registUser.nickName}
                      />
                    );
                  })
                )}
              </ul>
            </BoardList>
            {contentsData && contentsData.length > 0 ? (
              <Pagination
                type={'IDX'}
                handleCurrentIdx={handleCurrentIdx}
                totalPages={Math.ceil(totalPageSize / pageSize)}
                limitPage={5}
                maxNum={5}
                minNum={0}
                currentSeq={pageIndex}
                pageIdx={pageIndex}
                jumpingPrevBtn={true}
                jumpingNextBtn={true}
              />
            ) : null}
            {contentsData && contentsData.length <= 0 && !isSearchMode ? null : (
              <BoardSearch
                searchTypeList={searchSelect}
                setSearchType={setSearchType}
                setSearchWord={setSearchWord}
                setPageIndex={setPageIndex}
                searchWord={searchWord}
              />
            )}
          </BoardBox>
        )}
      </theme.SmInnerBox>
    </ThemeProvider>
  );
}

const BoardBox = styled.div`
  padding: 8rem 0;
  .board-info {
    display: flex;
    margin-bottom: 2rem;
    justify-content: flex-start;
    align-items: end;
    p {
      flex: 1 auto;
      font-weight: 400;
      font-size: 1.4rem;
      color: #717188;
      &:before {
        content: '- ';
      }
    }
  }
  .pagination {
    padding-bottom: 6rem;
  }
  @media ${({ theme }) => theme.device.mobile} {
    padding: 0 0 3.2rem;
    .board-info {
      display: none;
    }
  }
`;

const BoardList = styled.div`
  display: block;
  width: 100%;
  border-top: 0.3rem solid #000;
  .no-content {
    padding: 8rem 0 12rem;
  }
  @media ${({ theme }) => theme.device.mobile} {
    border-top: 0.1rem solid #e7e7f0;
    .no-content {
      padding: 0;
    }
  }
`;

const SkeletonBox = styled.div`
  padding: 1.5rem 0;
  border-bottom: 0.1rem solid #eee;
  .react-loading-skeleton {
    height: 1.4rem;
  }
  dl {
    display: flex;
    width: 100%;
    height: 3rem;
    padding: 0.5rem 0;
    justify-content: center;
    align-items: center;
    dt {
      width: 50%;
      span {
        margin-right: 0.5rem;
      }
    }
    dd {
      width: 50%;
      text-align: right;
      span {
        margin-left: 0.5rem;
      }
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    padding: 4rem;
    .react-loading-skeleton {
      height: 2.8rem;
    }
  }
`;
