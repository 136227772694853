import { Navigate } from 'react-router'

import AppDownload from 'pages/appDownload'
import AppDownloadVoca from 'pages/appDownloadVoca'
import Custom from 'pages/custom'
import CustomPreview from 'pages/customPreview'
import GnbPreview from 'pages/mainPreview'
import Temp from 'pages/temp'

import RedirectTo from 'components/RedirectTo'

const EtcRoutes = [
  { path: 'main-preview', element: <GnbPreview /> },
  { path: 'common/appDownload', element: <AppDownload /> },
  { path: 'common/appDownloadVoca', element: <AppDownloadVoca /> },
  { path: 'custom/:pageUrl', element: <Custom /> },
  { path: 'custom-preview/:pageUrl', element: <CustomPreview /> },
  {
    path: 'freepass/cheerupSong',
    element: <Navigate replace to="/promotion/cheerupSongSeason3" />
  },
  {
    path: 'yafit/partner-gym/:hashParam',
    element: <RedirectTo htmlFileName="yafitExperience" />
  },
  { path: 'view/temp', element: <Temp /> },
  { path: '*', element: <Navigate replace to="/" /> }
]

export default EtcRoutes
