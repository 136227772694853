import { useState } from 'react'
import Lottie from 'react-lottie'
import { useLocation, useNavigate } from 'react-router'

import usePlatform from 'hooks/usePlatform'
import * as aiTalkApi from 'pages/aiTalk/api/aiTalkApi'
import LottieData from 'pages/aiTalk/lottieBubble.json'
import { v4 as uuid } from 'uuid'

import s from './CharacterItem.module.css'
// import ModalAlert from './ModalAlert'
import ModalConfirm from './ModalConfirm'
import ModalRecommend from './ModalRecommend'
import { aiConversationValues } from '../config'

export default function CharacterItem({ data, userSeq, setModalLogin }) {
  const navigate = useNavigate()
  const location = useLocation()
  const { isPlatform } = usePlatform(location.pathname)
  const [startModal, setStartModal] = useState(false)
  const [hover, setHover] = useState(false)
  const [rcmModal, setRcmModal] = useState(false)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const handleOpenModal = async (select) => {
    // setCharacter(select)
    setStartModal(true)
  }

  const handleCloseModal = () => {
    setStartModal(false)
  }

  const handleNavigate = (type, charInfo, payUser) => {
    const navigateState = { promptType: type, info: charInfo, payUser: payUser }
    if (isPlatform) {
      navigate(`/webview/ai/chat?seq=${userSeq}`, { state: navigateState })
    } else {
      navigate('/AInativespeakertalk/chat', { state: navigateState })
    }
  }

  const handleUsable = async (char) => {
    if (!userSeq) {
      handleCloseModal()
      setModalLogin(true)
      return false
    }

    const resp = await aiTalkApi.fetchUsable(userSeq, char)
    if (!resp.payUser && char !== resp.promptType) {
      setStartModal(false)
      setRcmModal(true)
      return false
    }

    window.dataLayer.push({
      event: 'AIchat',
      product_name: char
    })

    handleNavigate(char, aiConversationValues[char], resp.payUser)
  }

  return (
    <>
      <div className={s.charItem}>
        <img src={data.images.rectangle} alt={data.info.fullname} />
        <div className={`${s.charInfo}`}>
          <div className={s.charInfoBox}>
            <dl>
              <dt>{data.info.fullname}</dt>
              <dd>
                {data.info.age} / {data.info.mbti} / {data.info.job}
              </dd>
              <dd>{data.info.location}</dd>
            </dl>
            <ul>
              {data.info.hashtag.map((hash) => {
                return <li key={uuid()}>{hash}</li>
              })}
            </ul>
          </div>
        </div>
        <div className={`${s.charHover} ${hover ? s.hoverView : s.hoverNotView}`}>
          <div className={s.charHoverInner}>
            <p>
              {data.info.hi}
              <br />
              {data.info.intro}
            </p>
            <span>{data.info.comment}</span>
            <div>
              <button onClick={() => setHover(false)} className={s.hoverClose}>
                닫기
              </button>
            </div>
          </div>
        </div>
        <div className={s.charBtnBox}>
          <button className={s.charBtn} onClick={() => handleOpenModal(data.key)}>
            <div className={s.lottieBubble}>
              <Lottie options={defaultOptions} />
            </div>
            {data.info.firstname}
            {data.afterfix} 대화 시작하기
          </button>
          <button onClick={() => setHover(true)} className={s.btnViewInfo}>
            자기소개 보기
          </button>
        </div>
      </div>
      {startModal && (
        <ModalConfirm
          close={handleCloseModal}
          confirm={() => handleUsable(data.key)}
          aiInfo={data}
          title="자연스러운 대화 Tip"
        />
      )}
      {/* {alert.open && <ModalAlert close={() => setAlert({ ...alert, open: !alert.open })} confirm={() => handleUsable(alert.name)} name={alert.name} usable={character} />} */}
      {rcmModal && <ModalRecommend close={() => setRcmModal(false)} char={data} />}
    </>
  )
}
