import DetailEntrance from 'pages/product/DetailEntrance'
import SearchResult from 'pages/searchResult'
import StoreProduct from 'pages/store/detail'
import StoreProductPreview from 'pages/store/detailPreview'
import StoreCategory from 'pages/store/id'
import StoreList from 'pages/store/list'
import { ClassStore } from 'router/OutletStore'

import RedirectTo from 'components/RedirectTo'

const ClassRoutes = [
  {
    path: 'store/*',
    element: <ClassStore />,
    children: [
      { path: 'list', element: <StoreList /> },
      { path: ':categorySeq', element: <StoreCategory /> },
      { path: 'detail/:packageSeq', element: <StoreProduct /> },
      { path: 'detail-preview/:packageSeq', element: <StoreProductPreview /> },
      { path: 'entrance/:packageSeq', element: <DetailEntrance /> },
      { path: 'milchakCare', element: <RedirectTo htmlFileName="milchakCare" /> },
      { path: 'milchakCareReview', element: <RedirectTo htmlFileName="milchakReview" /> }
    ]
  },
  { path: 'search-result', element: <SearchResult /> }
]

export default ClassRoutes
