import Lottie from 'react-lottie'

import AiInformation from 'pages/aiTalk/components/AiInformation'
import LottieData from 'pages/aiTalk/lottieData.json'

import s from './Chat.module.css'
import TypingMessage from './TypingMessage'
import UserMessage from './UserMessage'

// ETD-3489 last update
export default function ChatFeed({ feed, aiInfo, isLoading, userSeq }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <div className={s.chatFeed}>
      <AiInformation data={aiInfo} />
      <ul className={s.feedList}>
        {feed.map((item, index) => {
          return (
            <li className={`${s.feedItem} ${s[item.type]}`} key={index}>
              {item.type === 'Ai' ? <TypingMessage text={item.msg} speed={50} aiInfo={aiInfo} userSeq={userSeq} /> : <UserMessage userSeq={userSeq} msg={item.msg} />}
            </li>
          )
        })}
        {isLoading || feed.length <= 0 ? (
          <li className={s.feedItem}>
            <div className={s.aiMessage}>
              <div className={s.msgThumb}>
                <img src={aiInfo.images.circle} alt={aiInfo.info.fullname} />
              </div>
              <dl>
                <dt className={s.msgName}>{aiInfo.info.firstname}</dt>
                <dd className={s.message}>
                  <div className={s.lottieBox}>
                    <Lottie options={defaultOptions} />
                  </div>
                </dd>
              </dl>
            </div>
          </li>
        ) : null}
      </ul>
    </div>
  )
}
