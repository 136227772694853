import s from '../EnglishHome.module.css';
import Form from './form/Form';

export default function ContactSection() {
  return (
    <section className={s.contact}>
      <div className={s.inner}>
        <hgroup className={s.titleGroup}>
          <h6>잠깐! 아직도 고민된다면?</h6>
          <h3>
            전문가와 평생수강
            <br className={`${s.hide} ${s.disM}`} /> 상담 받아보세요
          </h3>
        </hgroup>
        <Form />
      </div>
    </section>
  );
}
