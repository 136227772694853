import staticLevelImage01 from './images/img_level_1.jpg'
import staticLevelImage02 from './images/img_level_2.jpg'
import staticLevelImage03 from './images/img_level_3.jpg'
import staticLevelImage04 from './images/img_level_4.jpg'
import staticLevelImage05 from './images/img_level_5.jpg'
import staticLevelImage06 from './images/img_level_6.jpg'
import lv1LevelImage from './images/img_recommend-lv1-level.png'
import lv1ProductImage from './images/img_recommend-lv1-product.png'
import lv2LevelImage from './images/img_recommend-lv2-level.png'
import lv2ProductImage from './images/img_recommend-lv2-product.png'
import lv3LevelImage from './images/img_recommend-lv3-level.png'
import lv3ProductImage from './images/img_recommend-lv3-product.png'
import lv4LevelImage from './images/img_recommend-lv4-level.png'
import lv4ProductImage from './images/img_recommend-lv4-product.png'
import lv5ProductImage from './images/img_recommend-lv5-product.png'
import lv6ProductImage from './images/img_recommend-lv6-product.png'
import { LTA_HOST_CDN } from '../config'

export const currentLevelValues = {
  lv1: {
    text: 'Absolute beginner',
    gifSrc: `${LTA_HOST_CDN}img_level_1.gif`,
    jpgSrc: staticLevelImage01
  },
  lv2: { text: 'Beginner', gifSrc: `${LTA_HOST_CDN}img_level_2.gif`, jpgSrc: staticLevelImage02 },
  lv3: {
    text: 'Beginner - high',
    gifSrc: `${LTA_HOST_CDN}img_level_3.gif`,
    jpgSrc: staticLevelImage03
  },
  lv4: {
    text: 'Intermediate - low',
    gifSrc: `${LTA_HOST_CDN}img_level_4.gif`,
    jpgSrc: staticLevelImage04
  },
  lv5: {
    text: 'Intermediate',
    gifSrc: `${LTA_HOST_CDN}img_level_5.gif`,
    jpgSrc: staticLevelImage05
  },
  lv6: {
    text: 'Intermediate - high',
    gifSrc: `${LTA_HOST_CDN}img_level_6.gif`,
    jpgSrc: staticLevelImage06
  }
}

export const evalTextValues = [
  {
    lv1: {
      title: <>영어 입문부터 시작해야 해요.</>,
      subTitle: <>의사소통이 힘들어요.</>
    }
  },
  {
    lv2: {
      title: (
        <>
          기본 의사소통을 하기 위한 <br />
          말하기 기초 뼈대가 필요해요!
        </>
      ),
      subTitle: (
        <>
          지금은 몇몇 단어로 가장 기본적 의사만 <br />
          전달할 수 있어요.
        </>
      )
    }
  },
  {
    lv3: {
      title: (
        <>
          익숙하고 단순한 상황에서 <br />
          자신에 대해 표현하거나 <br />
          간단한 의사를 전달할 수 있어요!
        </>
      ),
      subTitle: <>하지만 대화가 종종 중단이 되곤 해요.</>
    }
  },
  {
    lv4: {
      title: (
        <>
          익숙한 몇몇 상황에서는 <br />
          대화가 잘 이루어지고 <br />
          나의 의사를 잘 표현할 수 있어요!
        </>
      ),
      subTitle: (
        <>
          하지만 어려운 어휘나 문장구조를 <br />
          사용하는 데는 익숙하지 않아요.
        </>
      )
    }
  },
  {
    lv5: {
      title: (
        <>
          친숙한 상황에서는 <br />
          영어를 상당히 잘 이해하고 <br />
          구사할 수 있어요.
        </>
      ),
      subTitle: (
        <>
          하지만 익숙지 않은 상황이나 주제에 대한 대화를 <br />할 때 아직 이해가 부족하고 실수가
          있어요.
        </>
      )
    }
  },
  {
    lv6: {
      title: (
        <>
          난이도 높은 어휘를 잘 구사하며 <br />
          조금 어려운 레벨의 <br /> 대화/발표까지 가능해요.
        </>
      ),
      subTitle: <>언어 구사능력이 기본 이상이에요.</>
    }
  }
]

export const recommendCourseValues = {
  lv1: {
    productTitle: '영어와 친해져야 하는',
    productImageSrc: lv1ProductImage,
    productAlt: '앞자리 영어',
    productText: (
      <>
        영어 문장 구조의 원리를 이해하면 <br />
        원하는 문장이 3초 안에 술술~ <br />
        앞자리 훈련법으로 영어 뼈대를 튼튼하게 만들고 <br />
        5주 만에 완성되는 자신감과 실력을 경험해 보세요!
      </>
    ),
    keywords: ['👄 예나쌤과 함께하는 말하기 훈련', '💡 핵심만 머리에 쏙쏙'],
    levelTitle: (
      <>
        <h6>원예나 앞자리 학습법에서 제공하는 학습법으로</h6>
        <h3>Lv.3까지 올릴 수 있어요</h3>
      </>
    ),
    levelImageSrc: lv1LevelImage,
    levelText: (
      <>
        <strong>원예나쌤 one point!</strong> <br />
        진짜 10분 영어의 핵심은 회화에요! <br />
        패턴 하나로 수만 개의 문장을 만들 수 있는 <br />
        설계된 최적의 10분!
      </>
    ),
    productUrl: '/promotion-landing/frontEnglish.html',
    productCode: 'AJR_ALLINONE_2024_02'
  },
  lv2: {
    productTitle: '기초를 탄탄하게!',
    productImageSrc: lv2ProductImage,
    productAlt: '10분 패키지',
    productText: (
      <>
        패턴 하나로 수천개의 문장이 가능한 비법! <br />
        쉬운 표현 위주로 꾸준히 강의를 들으면 <br />
        말할 수 있어요. 야나두 왕초보 영어 핵심이 <br />
        담겨있는 10분 학습법을 경험해 보세요!
      </>
    ),
    keywords: ['☝🏻 하루 10분', '🥇 동기부여 장학금', '🗣 1:1 케어'],
    levelTitle: (
      <>
        <h6>10분 패키지에서 제공하는 학습법으로</h6>
        <h3>Lv.4까지 올릴 수 있어요</h3>
      </>
    ),
    levelImageSrc: lv2LevelImage,
    levelText: (
      <>
        <strong>원예나쌤 one point!</strong> <br />
        진짜 10분 영어의 핵심은 회화에요! <br />
        패턴 하나로 수만 개의 문장을 만들 수 있는 <br />
        설계된 최적의 10분!
      </>
    ),
    productUrl: '/promotion-landing/bestAwards',
    productCode: 'RE_10MINUTES_PREMIUM'
  },
  lv3: {
    productTitle: '입이 떨어지지 않는',
    productImageSrc: lv3ProductImage,
    productAlt: '오드리 버디톡',
    productText: (
      <>
        나의 상황에 맞는 문장을 만드는 훈련을 통해 <br />
        일상생활에 바로 적용 가능해요! <br />
        더 짧게! 더 빠르게! 숏폼 보듯이 짧은 강의로 <br />
        구성되어 있어서 부담없이 들을 수 있어요. <br />
        실제 원어민이 사용하는 표현을 <br />
        오드리쌤과 함께 익혀보세요!
      </>
    ),
    keywords: ['🎬 트렌디한 짧은 강의', '😎 상황별 대화', '🗣 친구와 대화하듯'],
    levelTitle: (
      <>
        <h6>오드리 버디톡에서 제공하는 학습법으로</h6>
        <h3>Lv.5까지 올릴 수 있어요</h3>
      </>
    ),
    levelImageSrc: lv3LevelImage,
    levelText: (
      <>
        <strong>오드리쌤 one point!</strong> <br />
        뉘앙스를 살려서 말하는 훈련법으로 <br />
        친구와 바로 써먹는 버디톡 제가 알려드릴게요!
      </>
    ),
    productUrl: '/promotion-landing/buddytalk',
    productCode: 'BUDDYTALK_TRAINING'
  },
  lv4: {
    productTitle: '원어민 사고력이 필요한',
    productImageSrc: lv4ProductImage,
    productAlt: '스르르 학습지',
    productText: (
      <>
        귀로 듣고 손으로 직접 쓰면서 높아지는 기억력! <br />
        아는 단어로 문장을 만들어서 원어민처럼 생각하고 <br />
        정확한 발음으로 대화하면 상상이 현실이 돼요~ <br />
        입이 트이는 단계별 순환 커리큘럼으로 <br />
        자유로운 소통이 되는 학습법을 경험해 보세요!
      </>
    ),
    keywords: ['☝🏻 하루에 한장', '💕 설렘 가득 습관 형성 키트'],
    levelTitle: (
      <>
        <h6>스르르 마스터팩에서</h6>
        <h3>Lv.6까지 올릴 수 있어요</h3>
      </>
    ),
    levelImageSrc: lv4LevelImage,
    levelText: (
      <>
        <strong>비키쌤 one point!</strong> <br />
        원어민 영어의 특징과 차이점으로 <br />
        자연스러운 영어가 되는 법 제가 도와드릴게요! <br />
      </>
    ),
    productUrl: '/promotion-landing/SRRfullpackage.html',
    productCode: 'SRR_NEWYEAR_LV2'
  },
  lv5: {
    productTitle: '원어민 사고력이 필요한',
    productImageSrc: lv5ProductImage,
    productAlt: '스르르 학습지',
    productText: (
      <>
        귀로 듣고 손으로 직접 쓰면서 높아지는 기억력! <br />
        아는 단어로 문장을 만들어서 원어민처럼 생각하고 <br />
        정확한 발음으로 대화하면 상상이 현실이 돼요~ <br />
        입이 트이는 단계별 순환 커리큘럼으로 <br />
        자유로운 소통이 되는 학습법을 경험해 보세요!
      </>
    ),
    keywords: ['☝🏻 하루에 한장', '💕 설렘 가득 습관 형성 키트'],
    productUrl: '/promotion-landing/SRRfullpackage.html',
    productCode: 'SRR_NEWYEAR_LV2'
  },
  lv6: {
    productTitle: '원어민식 실전 영어가 필요한',
    productImageSrc: lv6ProductImage,
    productAlt: '리얼 스피킹 영어',
    productText: (
      <>
        경험을 통해 나오는 원어민식 영어! <br />
        기초를 넘어서 실전 감각을 키우기 위한 맞춤 <br />
        커리큘럼. 퀴즈를 통해 상황 속 억양과 제스처를 <br />
        리얼하게 느끼고 핵심 표현을 맞춰보세요~ <br />
        실제 상황으로 현지 영어를 경험해 보세요!
      </>
    ),
    keywords: ['😎 오늘부터 글로벌 인싸', '🤝 외국인 친구 만들기'],
    productUrl: '/promotion-landing/realEnglish.html',
    productCode: 'REAL_ENG'
  }
}
