import React, { Fragment } from 'react'

import { v4 as uuid } from 'uuid'

import s from '../aiTalk.module.css'

export default function SpecialItem({ data }) {
  const { img, bubble, title, detail } = data
  return (
    <div className={s.specialItem}>
      <div className={s.bubble}>
        {bubble.split('/n').map((text, index) => {
          return (
            <Fragment key={uuid()}>
              {index === 1 ? <br /> : null}
              {text}
            </Fragment>
          )
        })}
      </div>
      <img className={s.aiThumb} src={img} alt={title} />
      <dl>
        <dt>
          {title.split('/n').map((text, index) => {
            return (
              <Fragment key={uuid()}>
                {index === 1 ? <br /> : null}
                {text}
              </Fragment>
            )
          })}
        </dt>
        <dd>
          {detail.split('/n').map((text, index) => {
            return (
              <Fragment key={uuid()}>
                {index === 1 ? <br /> : null}
                {text}
              </Fragment>
            )
          })}
        </dd>
      </dl>
    </div>
  )
}
