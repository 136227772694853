import React, { useRef } from 'react'
import { Link } from 'react-router-dom'

import styled, { ThemeProvider } from 'styled-components'

import theme from 'common/theme'

import SortSelectBox from './SortSelectBox'
import IcoArrowRight from '../../../assets/images/ico_arrow_right_g.svg'
import IcoNoClass from '../../../assets/images/ico_no_class_420x420.png'
import NoContent from '../../NoContent'
import useInfiniteScroll from '../hooks/useInfiniteScroll'

const MINIMUM_PRICE = 50_000
const MINIMUM_MONTH = 1

export default function Test({ title, storeData, storeType, detailLink, setSort, sort, isEmpty }) {
  const triggerPointRef = useRef(null) // 목록을 로드하는 트리거 포인트 Node Ref
  const { currentData, isLoading, isReachedLimit } = useInfiniteScroll(storeData, triggerPointRef, 12)

  return (
    <ThemeProvider theme={theme}>
      <StoreTitle>
        <strong>{title && title === '전체' ? '전체보기' : title}</strong>
        {storeType !== 'mall' ? <SortSelectBox setSort={setSort} sort={sort} /> : null}
        {!!detailLink ? <Link to={detailLink}>더 보러 가기</Link> : null}
      </StoreTitle>
      <ul className="class-items">
        {isEmpty ? (
          <NoContents>
            <NoContent icoImg={IcoNoClass} titleMsg={'등록된 클래스가 없습니다.'} />
          </NoContents>
        ) : (
          <>
            {storeType !== 'mall' ? (
              <>
                {storeData &&
                  currentData.map((item, idx) => (
                    <li key={idx}>
                      <Link
                        to={{
                          pathname: `/store/detail/${item.packageSeq}`
                        }}
                      >
                        <ThumbBox>
                          <Picture>
                            <img src={item.thumbnailPath} alt={item.packageName} loading="lazy" />
                          </Picture>
                          <SaleStatusBox status={item.saleStatus ? item.saleStatus : null}>
                            {item.saleStatus === 'BEFORE' ? (
                              <div>
                                <span>판매 예정</span>
                              </div>
                            ) : item.saleStatus === 'END' ? (
                              <div>
                                <span>Sold out</span>
                              </div>
                            ) : null}
                          </SaleStatusBox>
                          {/* 클래스가 포함 되어 있을 경우 클래스 수 표시 */}
                          {item.classCount > 0 && <ClassNumText>{item.classCount} 클래스 포함</ClassNumText>}
                          {item.saleTypeCode === 'EARLY_BIRD' && <EarlyText>얼리버드</EarlyText>}
                        </ThumbBox>

                        <CategoryBox>
                          <div>
                            <span className="category">{item.packageCategory}</span>
                            &nbsp;&middot;&nbsp;
                            <span className="leader">{item.teacherName}</span>
                          </div>
                          <p className="ellipsis line-clamp-2">
                            <strong>{item.packageName}</strong>
                          </p>
                        </CategoryBox>
                      </Link>
                    </li>
                  ))}
              </>
            ) : (
              //대학몰일때
              <>
                {storeData &&
                  currentData.map((item, idx) => (
                    <li key={idx}>
                      <Link
                        to={{
                          pathname: `/mall/detail/${item.packageSeq}`
                        }}
                      >
                        <ThumbBox>
                          <Picture>
                            <img src={item.thumbnailPath} alt={item.packageName} loading="lazy" />
                          </Picture>
                          <SaleStatusBox status={item.saleStatus ? item.saleStatus : null}>
                            {item.saleStatus === 'BEFORE' ? (
                              <div>
                                <span>판매 예정</span>
                              </div>
                            ) : item.saleStatus === 'END' ? (
                              <div>
                                <span>Sold out</span>
                              </div>
                            ) : null}
                          </SaleStatusBox>
                          {/* 클래스가 포함 되어 있을 경우 클래스 수 표시 */}
                          {item.classCount > 0 && <ClassNumText>{item.classCount} 클래스 포함</ClassNumText>}
                          {item.saleTypeCode === 'EARLY_BIRD' && <EarlyText>얼리버드</EarlyText>}
                        </ThumbBox>
                        <CategoryBox>
                          <div>
                            <span className="category">{item.packageCategory}</span>
                            &nbsp;&middot;&nbsp;
                            <span className="leader">{item.teacherName}</span>
                          </div>
                          <p className="ellipsis line-clamp-2">
                            <strong>{item.packageName}</strong>
                          </p>
                        </CategoryBox>
                        <PriceBox>
                          {/* 얼리버드면 얼리버드가격 노출 */}
                          {item.saleTypeCode === 'EARLY_BIRD' ? (
                            <>
                              <p>
                                <em className="discount">{addComma(item.exceptOptionSalePrice)}</em>
                                {/* 얼리버드 할인율로 바꿔야됨 */}
                                {/* <mark>
                              {item.exceptOptionEarlyBirdDiscountPercent} %
                            </mark> */}
                              </p>
                              <p>
                                <strong>{addComma(item.exceptOptionMembershipSalePrice)}</strong>
                              </p>
                            </>
                          ) : (
                            // 얼리버드가 아닐때
                            <>
                              <p>
                                <em className="mall-discount">{addComma(item.originalPrice)}</em>
                                <em className="mall-count">{addComma(item.exceptOptionSalePrice)}</em>
                              </p>
                              <p>
                                <span className="discount-percent">{item.discountPercent}%</span>
                                {item.exceptOptionSalePrice !== item.exceptOptionMembershipSalePrice ? (
                                  <>
                                    {item.exceptOptionMembershipSalePrice && (
                                      <>
                                        {item.exceptOptionMembershipSalePrice <= MINIMUM_PRICE || item.learningMonth <= MINIMUM_MONTH ? (
                                          <strong className="no-month">{addComma(Number(item.exceptOptionMembershipSalePrice))}</strong>
                                        ) : (
                                          <>
                                            <strong>{addComma(Math.round(item.monthlyMembershipSalePrice))}</strong>
                                            <span>{item.learningMonth}</span>
                                          </>
                                        )}
                                        <mark>입학회원</mark>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {/* 일반판매가와 멤버쉽할인가가 동일하면 일반판매가만 노출 */}
                                    {item.exceptOptionMembershipSalePrice && (
                                      <>
                                        {item.exceptOptionMembershipSalePrice <= MINIMUM_PRICE || item.learningMonth <= MINIMUM_MONTH ? (
                                          <strong className="no-month">{addComma(Number(item.exceptOptionSalePrice))}</strong>
                                        ) : (
                                          <>
                                            <strong>{addComma(Math.round(item.monthlySalePrice))}</strong>
                                            <span>{item.learningMonth}</span>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                                {/* 멤버쉽 할인가가 아예 없는 경우에는 일반판매가 노출 */}
                                {!item.exceptOptionMembershipSalePrice && (
                                  <>
                                    {item.exceptOptionMembershipSalePrice && (
                                      <>
                                        {item.exceptOptionMembershipSalePrice <= MINIMUM_PRICE || item.learningMonth <= MINIMUM_MONTH ? (
                                          <strong className="no-month">{addComma(Number(item.exceptOptionSalePrice))}</strong>
                                        ) : (
                                          <>
                                            <strong>{addComma(Math.round(item.monthlySalePrice))}</strong>
                                            <span>{item.learningMonth}</span>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </p>
                            </>
                          )}
                        </PriceBox>
                      </Link>
                    </li>
                  ))}
              </>
            )}
          </>
        )}
      </ul>

      {!isReachedLimit && <Loader ref={triggerPointRef} style={{ visibility: `${isLoading ? 'visible' : 'hidden'}` }} />}
    </ThemeProvider>
  )
}

const addComma = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const StoreTitle = styled.h3`
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-transform: uppercase;
  strong {
    flex: 1 auto;
    font-size: 2.5rem;
    color: #000;
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 2.8rem;
    }
  }
  a {
    font-weight: 700;
    font-size: 1.8rem;
    color: #717188 !important;
    padding-right: 2.8rem;
    background: url(${IcoArrowRight}) no-repeat 100% 30% / 2.4rem auto;
    @media ${({ theme }) => theme.device.mobile} {
      padding-right: 3.2rem;
      font-size: 2.4rem;
      background: url(${IcoArrowRight}) no-repeat 100% 0 / 2.8rem auto;
    }
  }
`

const ThumbBox = styled.div`
  position: relative;
  border-radius: 0.5rem;
  background: #f7f7fc;
  overflow: hidden;
`

const Picture = styled.picture`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
  }

  @media ${({ theme }) => theme.device.mobile} {
    height: auto;
    min-height: 22rem;
  }
`

const SaleStatusBox = styled.div`
  &::after {
    content: '';
    display: block;
    padding-bottom: 67%;
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 3;

    & > span {
      display: inline-block;
      width: 11rem;
      padding: 0.8rem 0;
      font-weight: 700;
      font-size: 1.8rem;
      color: ${(props) => (props.status === 'BEFORE' ? '#28284B' : '#fff')};
      background-color: ${(props) => (props.status === 'BEFORE' ? '#fff' : '#ff5647')};
      border-radius: 2rem;
      text-align: center;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    &::after {
      padding-bottom: 68%;
    }

    & > div {
      height: auto;

      & > span {
        width: 13.8rem;
        padding: 0.8rem 0;
        font-size: 2.2rem;
      }
    }
  }
`

const ClassNumText = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3.8rem;
  font-size: 1.2rem;
  line-height: 3.8rem;
  color: #fff;
  background: rgba(59, 59, 77, 0.9);
  text-align: center;
  transform: translateY(100%);
  transition: all 0.3s;
  z-index: 5;

  a:hover & {
    transform: none;
  }

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`

const EarlyText = styled.span`
  position: absolute;
  right: 0.6rem;
  bottom: 0.6rem;
  width: 6.4rem;
  height: 2.6rem;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2.6rem;
  color: #a0a0b6;
  background-color: #fff;
  border-radius: 0.2rem;
  border: 0.1rem solid #e7e7f0;
  box-sizing: border-box;
  text-align: center;
  z-index: 1;

  @media ${({ theme }) => theme.device.mobile} {
    bottom: 1.2rem;
    right: 1.2rem;
    width: 10rem;
    height: 4rem;
    line-height: 4rem;
    font-size: 2.2rem;
    border: 0.2rem solid #e7e7f0;
    border-radius: 0.2rem;
  }
`
const CategoryBox = styled.div`
  margin-bottom: 1rem;
  p {
    height: 4.4rem;
    line-height: 1.4;
    font-weight: 700;
  }

  div {
    color: #a0a0b6;
    flex: 0 0 30%;
    display: flex;
    margin: 1.1rem 0 0.9rem 0;
    flex-wrap: wrap;

    span {
      display: inline-block;
      font-size: 1.2rem;
      color: #a0a0b6;
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    /* margin-bottom: 1.6rem; */
    p {
      height: 6rem;
      font-size: 2.2rem;
    }
    div {
      display: flex;
      flex: 1;
      display: block;
      margin: 1.6rem 0 0.5rem 0;
      position: relative;
      span {
        padding: 0.5rem 0;
        font-size: 2.2rem;
      }
    }
  }
`
const PriceBox = styled.div`
  border-top: 1px solid #e7e7f0;
  padding-top: 1rem;
  border-radius: 0 !important;
  p {
    position: relative;
    margin-bottom: 0.4rem;
  }

  em,
  mark {
    font-weight: 400;
    font-size: 1.2rem;
  }
  .discount-percent {
    display: inline-block;
    padding-right: 0.4rem;
    font-weight: 700;
    font-size: 2rem;
    color: #ff5647;
  }

  strong {
    font-size: 1.9rem;
    font-weight: 700;
    margin-right: 0.5rem;

    &:not(.no-month):before {
      content: '월';
      display: inline-block;
      margin-right: 0.7rem;
    }

    &:after {
      content: '원';
    }
  }

  em:after {
    content: '원';
    font-weight: 700;
    font-size: 1rem;
  }

  em:after {
    font-size: 1rem;
  }

  strong {
    display: inline-block;

    & + span {
      display: inline-block;
      margin: 0 1rem 0 0;
      color: #a0a0b6;
      font-size: 1.3rem;
      vertical-align: middle;

      &:before {
        content: '(';
      }

      &:after {
        content: '개월)';
      }
    }

    & + mark,
    & + span + mark {
      display: inline-block;
      min-width: 4.5rem;
      height: 1.8rem;
      margin-bottom: 0.3rem;
      padding: 0 0.4rem;
      color: #0074d6;
      background-color: rgba($color: #c8e1ff, $alpha: 0.5);
      font-size: 1rem;
      font-weight: 700;
      border-radius: 0.2rem;
      box-sizing: border-box;
      line-height: 1.9;
      vertical-align: middle;
      text-align: center;
    }
  }
  mark {
    color: #ff5647;
    background-color: transparent;
  }

  .mall-discount {
    display: inline-block;
    margin-right: 0.5rem;
    font-weight: 700;
    color: #cfcfda;
    text-decoration: line-through;
  }
  .mall-count {
    display: inline-block;
    font-weight: 700;
    color: #8d8da0;
  }

  .discount {
    display: inline-block;
    margin-right: 1rem;
    font-weight: 700;
    color: #8d8da0;
    text-decoration: line-through;
    vertical-align: middle;
  }
  @media ${({ theme }) => theme.device.mobile} {
    padding-top: 1.6rem;
    margin-top: 1.1rem;
    p + p {
      padding-bottom: 2.5rem;
    }

    p:last-child strong {
      font-size: 2.8rem;
    }

    .discount {
      & + mark {
        max-width: 9rem;
        height: 3.5rem;
        margin: 0 0 0.6rem;
        padding: 0 0.7rem;
        font-size: 1.6rem;
        border-radius: 0.4rem;
        box-sizing: border-box;
        line-height: 2;
        text-align: center;
      }
    }
    .discount-percent {
      position: absolute;
      top: -3rem;
      right: 0;
      font-size: 2.8rem;
    }

    em,
    mark {
      font-weight: 400;
      font-size: 2rem;
    }

    strong {
      font-size: 2.8rem;
    }

    strong:after {
      font-size: 2.8rem;
    }

    em:after {
      font-size: 2rem;
    }

    strong {
      & + span {
        position: absolute;
        bottom: 0rem;
        left: 0;
        font-size: 2rem;
        font-weight: 400;
        margin-left: 0.4rem;
      }
      & + mark,
      & + span + mark {
        max-width: 9rem;
        height: 3.5rem;
        margin: 1rem 0 0.6rem;
        padding: 0 0.7rem;
        font-size: 1.6rem;
        border-radius: 0.4rem;
        box-sizing: border-box;
        line-height: 2;
        text-align: center;
      }
    }
  }
`
const NoContents = styled.div`
  padding: 20rem 25rem 25rem 25rem;
  @media ${({ theme }) => theme.device.mobile} {
    padding: 0 0 10rem 0;
  }
`

const Loader = styled.div`
  width: 28px;
  margin: 25px auto 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, rgb(255, 185, 0) 94%, #0000) top/8px 8px no-repeat, conic-gradient(#0000 30%, rgb(255, 185, 0));
  mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #000 0);
  animation: l13 1s infinite linear;

  @keyframes l13 {
    100% {
      transform: rotate(1turn);
    }
  }
`
