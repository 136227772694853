import { useParams } from 'react-router-dom'
import useGetEventList from 'hooks/useGetEventList'
import Pagination from 'components/Pagination'
import NoContent from 'components/NoContent'
import TabMain from './TabMain'
import ListItem from './ListItem'
import 'assets/styles/event.scss'
import icoImg from 'assets/images/ico_no_event_285x285.png'

export default function EventEnd() {
  const { currentSeq } = useParams()
  const [endData, totalPages] = useGetEventList(currentSeq, 'END')

  return (
    <>
      <TabMain activeTab={'END'} />
      <section className="list-col list">
        <div className="inner-col">
          <div className="list-box">
            <ul className="tab-content end-event active">
              {endData.length > 0 ? (
                endData.map((item, idx) => (
                  <li key={idx}>
                    <ListItem item={item} />
                  </li>
                ))
              ) : (
                <NoContent icoImg={icoImg} titleMsg={'등록된 이벤트가 없습니다.'} />
              )}
            </ul>
            {endData.length > 0 ? <Pagination type={'SEQ'} totalPages={totalPages} limitPage={5} maxNum={5} minNum={0} pathName={'/event/end'} currentSeq={currentSeq} /> : null}
          </div>
        </div>
      </section>
    </>
  )
}
