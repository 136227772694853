import { useRef, useEffect, useState, useCallback } from 'react'
import ReactCanvasConfetti from 'react-canvas-confetti'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

import EngStyled from 'assets/styles/engStyled.js'
import ModalPortal from 'ModalPortal'
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import api from 'common/api'
import ModalNotice from 'components/modal/ModalNotice'
import ModalVod from 'components/modal/ModalVod'

import { aboutEngValues } from './config'
import BgCurriculumM from 'assets/images/bg_m_curriculum_table.png'
import BgCurriculum from 'assets/images/bg_pc_curriculum_table.png'
import BgHotRank01 from 'assets/images/bg_pc_hotrank_01_v2.png'
import BgHotRank02 from 'assets/images/bg_pc_hotrank_02_v3.png'
import BgHotRank03 from 'assets/images/bg_pc_hotrank_03_v2.png'
import ImgFadeAi01 from 'assets/images/img_ai_fade_05.png'
import ImgFadeAi02 from 'assets/images/img_ai_fade_06.png'
import ImgAppCalligraphyPhoneM from 'assets/images/img_m_fixed_phone2.png'
import ImgAppCalligraphyM from 'assets/images/img_m_splash.png'
import ImgVisualM from 'assets/images/img_m_visual.png'
import ImgYouCanDoM from 'assets/images/img_m_youcando.png'
import Img10minuteThumb from 'assets/images/img_pc_10minute_thumb.png'
import ImgInfoAi from 'assets/images/img_pc_ai_info.png'
import ImgPhoneAi from 'assets/images/img_pc_ai_phone.png'
import ImgEngBook from 'assets/images/img_pc_eng_book.png'
// import ImgAppCalligraphy from 'assets/images/img_pc_app_info.png';
import ImgAppCalligraphyPhone from 'assets/images/img_pc_fixed_phone.png'
import ImgAppCalligraphyTxt from 'assets/images/img_pc_fixed_txt.png'
import ImgPreview from 'assets/images/img_pc_preview_01.png'
import ImgProve from 'assets/images/img_pc_prove.png'
import ImgAppCalligraphy from 'assets/images/img_pc_splash.png'
import ImgVisual from 'assets/images/img_pc_visual.png'
import ImgYouCanDo from 'assets/images/img_pc_youcando.png'

SwiperCore.use([Pagination, Autoplay, Navigation])

export default function AboutEng() {
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false
  const canvasStyles = {
    position: 'absolute',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2
  }
  const refAnimationInstance = useRef(null)

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance
  }, [])
  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio)
      })
  }, [])
  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 50,
      startVelocity: 55
    })

    makeShot(0.2, {
      spread: 100
    })

    makeShot(0.35, {
      spread: 150,
      decay: 0.91,
      scalar: 0.8
    })

    makeShot(0.1, {
      spread: 200,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    })

    makeShot(0.1, {
      spread: 200,
      startVelocity: 45
    })
  }, [makeShot])

  const [activeSection, setActiveSection] = useState(false)
  const tryPointRef = useRef(null)
  const aiPointRef = useRef(null)
  const appPointRef = useRef(null)

  const scrollCheck = () => {
    if (!tryPointRef.current) return
    if (
      0 < tryPointRef.current.getBoundingClientRect().bottom &&
      tryPointRef.current.getBoundingClientRect().top < 400
    ) {
      setActiveSection('section1')
    } else if (
      0 < aiPointRef.current.getBoundingClientRect().bottom &&
      aiPointRef.current.getBoundingClientRect().top < 400
    ) {
      setActiveSection('section2')
    } else if (
      0 < appPointRef.current.getBoundingClientRect().bottom &&
      appPointRef.current.getBoundingClientRect().top < 400
    ) {
      setActiveSection('section3')
    } else {
      setActiveSection(null)
    }
  }
  useEffect(() => {
    if (activeSection === 'section2') {
      const timeout = setTimeout(() => {
        fire()
      }, 500)

      return () => clearTimeout(timeout)
    }
  }, [activeSection])

  useEffect(() => {
    window.addEventListener('scroll', scrollCheck)

    return () => {
      window.removeEventListener('scroll', scrollCheck)
      document.body.classList.remove('modal-open')
    }
  }, [])

  const [modalOpen, setModalOpen] = useState(false)
  const [modalOpenNotice, setModalOpenNotice] = useState(false)
  const [mediaKey, setMediaKey] = useState(null)

  const phoneRef = useRef()
  const userNameRef = useRef()
  const agreeRef = useRef()
  const location = useLocation()

  const [toggleChecked, setToggleChecked] = useState(false)

  // 개인정보 활용 동의 내용보기
  const [agreeInfo, setAgreeInfo] = useState(false)

  // input value 값
  const [validStatus, setValidStatus] = useState({
    phone: false,
    userName: false
  })

  // 상담 신청하기 input value
  const [inputValue, setInputValue] = useState({
    phone: '',
    userName: '',
    privacyAgreeYn: true,
    smsAgreeYn: false
  })

  const handleOpenModal = useCallback((url) => {
    // e.preventDefault();
    document.body.classList.add('modal-open')
    setModalOpen(true)
    setMediaKey(url)
  }, [])

  const handleCloseModal = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalOpen(false)
  }, [])

  const handleOpenModalNotice = useCallback(() => {
    document.body.classList.add('modal-open')
    setModalOpenNotice(true)
  }, [])

  const handleCloseModalNotice = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalOpenNotice(false)
  }, [])

  let delayCheck = false

  const openConsultingForm = (e) => {
    setToggleChecked(!toggleChecked)
  }

  const handleNameCheck = (e) => {
    const nameRegExp = /[a-z0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'\\]/g

    if (nameRegExp.test(e.target.value)) {
      userNameRef.current.focus()
      alert('이름은 한글만 입력가능 합니다.')

      return false
    }

    if (!e.target.value) {
      setInputValue({ ...inputValue, userName: '' })
      setValidStatus({ ...validStatus, userName: false })

      return false
    }

    setInputValue({ ...inputValue, userName: e.target.value })
    setValidStatus({ ...validStatus, userName: true })

    return true
  }

  const handlePhoneCheck = (e) => {
    let phoneRegExp = /^[0-9]*$/

    if (!phoneRegExp.test(e.target.value)) {
      phoneRef.current.focus()

      return false
    }

    setInputValue({ ...inputValue, phone: e.target.value })
    setValidStatus({ ...validStatus, phone: true })

    return true
  }

  // 동의 체크박스
  const handleCheckedItem = (e) => {
    const { name } = e.target
    setInputValue({
      ...inputValue,
      [name]: !inputValue[name]
    })
  }

  const postForm = () => {
    const regPhone = /^((01[1|6|7|8|9])[1-9]+[0-9]{6,7})|(010[1-9][0-9]{7})$/

    if (!userNameRef.current.value) {
      alert('이름을 입력해주세요.')
      userNameRef.current.focus()
      return false
    }
    if (!phoneRef.current.value) {
      alert('연락처를 입력해주세요.')
      phoneRef.current.focus()
      return false
    }
    if (
      inputValue.phone.length < 10 ||
      inputValue.phone.length > 11 ||
      !regPhone.test(inputValue.phone)
    ) {
      phoneRef.current.focus()
      setValidStatus({ ...validStatus, phone: false })
      alert('올바른 전화번호를 입력해주세요 (예: 010-1234-1234)')
      return false
    }
    if (!agreeRef.current.checked) {
      alert('개인정보 수집에 동의해주세요.')
      return false
    }

    if (delayCheck) {
      alert('잠시 후에 시도해 주세요')
    } else {
      delayCheck = true
      deviceCheck ? postConsultation('main_mobile') : postConsultation('main_pc')
    }
  }

  const getUtm = () => {
    const query = new URLSearchParams(location.search)
    let utm = ''
    try {
      utm =
        query.get('utm_source') +
        '&' +
        query.get('utm_medium') +
        '&' +
        query.get('utm_campaign') +
        '&' +
        query.get('utm_content')
      utm = utm.replace(/null/g, '').replace(/&&/g, '&').replace(/&+$/, '')
    } catch (e) {}
    return utm
  }

  const postConsultation = (category) => {
    const data = {
      category,
      phone: phoneRef.current.value, // 전화번호
      userName: userNameRef.current.value, // 신청자 이름
      utm: getUtm(),
      privacyAgreeYn: inputValue.privacyAgreeYn ? 'Y' : 'N',
      smsAgreeYn: inputValue.smsAgreeYn ? 'Y' : 'N'
    }

    api
      .post('/v2/inquiry/consultation/tel', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)
          delayCheck = false
          return false
        }
        alert('상담 신청이 완료되었습니다.')
        delayCheck = false
        window.location.reload()
      })
      .catch((e) => {
        console.log(e)
        delayCheck = false
      })
  }

  function ResultCard({ children, title, desc, ...props }) {
    return (
      <EngStyled.ResultListItem {...props}>
        <p>{desc}</p>
        <strong>{title}</strong>
      </EngStyled.ResultListItem>
    )
  }
  function ProgramCard({ children, title, desc, link, badge, ...props }) {
    return (
      <EngStyled.ProgramItem {...props}>
        <Link to={link}>
          <p>{desc}</p>
          {badge && <span>{badge}</span>}
          <strong>{title}</strong>
        </Link>
      </EngStyled.ProgramItem>
    )
  }
  function RankingCard({ children, ranking, title, link, badge, backgroundImg, ...props }) {
    return (
      <EngStyled.HotRankItem {...props}>
        <Link to={link}>
          <div style={{ backgroundImage: `URL(${backgroundImg})` }}></div>
          <p>
            <em>{ranking}위</em>
            <strong>{title}</strong>
            {badge && <span>{badge}</span>}
          </p>
        </Link>
      </EngStyled.HotRankItem>
    )
  }
  function VideoCard({ title, buttonText, videoData, url }) {
    return (
      <EngStyled.VideoBox>
        <EngStyled.VideoTitle>
          <strong>{title}</strong>
          {/* <Link target="_blank" to={url}>
            {buttonText}
          </Link> */}
        </EngStyled.VideoTitle>
        <EngStyled.VideoList>
          {videoData &&
            videoData.map((video, idx) => {
              return (
                <li key={idx}>
                  <button
                    type="button"
                    onClick={() => {
                      handleOpenModal(video.linkPath)
                    }}
                  >
                    <EngStyled.ThumbBox>
                      <EngStyled.Picture>
                        <img src={video.imagePath1} alt={video.title} />
                      </EngStyled.Picture>
                      <div></div>
                    </EngStyled.ThumbBox>
                  </button>
                </li>
              )
            })}
        </EngStyled.VideoList>
      </EngStyled.VideoBox>
    )
  }

  return (
    <>
      <EngStyled.ThemeBox>
        <EngStyled.VisualSection>
          <EngStyled.InnerCol>
            <EngStyled.H3>메인비주얼</EngStyled.H3>
            {deviceCheck ? (
              <img src={ImgVisualM} alt="야, 너두 영어 할 수있어" />
            ) : (
              <img src={ImgVisual} alt="야, 너두 영어 할 수있어" />
            )}
            <p>
              2023년은 여러분도 <br />
              영어, 할 수 있습니다.
            </p>
          </EngStyled.InnerCol>
        </EngStyled.VisualSection>

        <EngStyled.TrySection
          ref={tryPointRef}
          className={activeSection === 'section1' ? 'active' : null}
        >
          <EngStyled.InnerCol>
            <EngStyled.TryTxtBox>
              <img src={ImgEngBook} alt="영어책" />
              <div>
                <strong>
                  진짜 괜찮아요, <br /> 진심 걱정마요
                </strong>
                <p>10분이면 정말로 할 수 있어요.</p>
              </div>
            </EngStyled.TryTxtBox>
            <EngStyled.TryVodBox>
              <div>
                <strong>왜냐하면</strong>
                <p>직접 경험해보시면 알 거예요.</p>
              </div>
              <button onClick={() => handleOpenModal('https://www.youtube.com/embed/6M7rhZoKDuc')}>
                <img src={Img10minuteThumb} alt="원예나 영상 썸네일" />
              </button>
            </EngStyled.TryVodBox>
          </EngStyled.InnerCol>
        </EngStyled.TrySection>

        <EngStyled.QuestionSection>
          <EngStyled.InnerCol>
            <EngStyled.H3>
              <strong>그럼에도 긴가민가 하시다면?!</strong>
            </EngStyled.H3>
          </EngStyled.InnerCol>
        </EngStyled.QuestionSection>

        <EngStyled.AISection>
          <EngStyled.InnerCol>
            <EngStyled.H3>
              영어, 인간적으로 안된다면 <strong>AI로 되게 해 줄게!</strong>
            </EngStyled.H3>
            <EngStyled.AIMotionBox
              ref={aiPointRef}
              className={activeSection === 'section2' ? 'active' : null}
            >
              <img src={ImgPhoneAi} alt="" className="phone" />
              <img src={ImgFadeAi01} alt="" className="fade01" />
              <img src={ImgFadeAi02} alt="" className="fade02" />
              <img src={ImgInfoAi} alt="" className="info" />
            </EngStyled.AIMotionBox>
            {/* <button onClick={fire}>aaa</button> */}
          </EngStyled.InnerCol>
          <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
        </EngStyled.AISection>

        <EngStyled.ProgramSection bgColor="F7F7FC">
          <EngStyled.InnerCol>
            <EngStyled.H3 className="no-line">
              믿고 따라오시면 자연스럽게 영어가 됩니다.
              <strong>동기부여 확실하게 챙겨드립니다.</strong>
            </EngStyled.H3>
            <EngStyled.ProgramList>
              <ProgramCard
                bgColor="fff"
                title={'뇌가 선택한 10분'}
                desc={'1시간 같은 학습효과'}
                link={'/promotion/brain'}
                // badge={''}
              />

              <ProgramCard
                bgColor="fff"
                title={'장학금 시스템'}
                desc={'초강력 동기부여'}
                link={'/promotion/scholarShip'}
                badge={'Best'}
              />

              <ProgramCard
                bgColor="fff"
                title={'1:1 보이스케어'}
                desc={'온라인 밀착과외'}
                link={'/promotion/voiceCare'}
                // badge={''}
              />
            </EngStyled.ProgramList>
          </EngStyled.InnerCol>
        </EngStyled.ProgramSection>
        <EngStyled.AppSection
          ref={appPointRef}
          className={activeSection === 'section3' ? 'active' : null}
        >
          <EngStyled.InnerCol>
            <EngStyled.H3>
              영어포기 3대 포인트를 극복한 야나두 앱이라면
              <strong>영어 진짜로 할 수 있습니다.</strong>
            </EngStyled.H3>
            <div className="splash-box">
              {deviceCheck ? (
                <img src={ImgAppCalligraphyM} alt="" className="ani" />
              ) : (
                <img src={ImgAppCalligraphy} alt="" className="ani" />
              )}

              <img src={ImgAppCalligraphyTxt} alt="" className="txt" />
              {deviceCheck ? (
                <img src={ImgAppCalligraphyPhoneM} alt="" className="phone" />
              ) : (
                <img src={ImgAppCalligraphyPhone} alt="" className="phone" />
              )}
            </div>
          </EngStyled.InnerCol>
        </EngStyled.AppSection>
        <EngStyled.ReviewSection>
          <EngStyled.InnerCol>
            <EngStyled.H3>
              실제 수강생이 증명하는 효과 <strong>"야, 나두 했어"</strong>
            </EngStyled.H3>
            <EngStyled.ReviewTitleBox>
              <span>작성자</span>
              <span>내용</span>
            </EngStyled.ReviewTitleBox>
            <EngStyled.ReviewContBox className="slide-box">
              <Swiper
                direction={'vertical'}
                autoHeight={true}
                modules={[Pagination]}
                className="mySwiper"
                slidesPerView={6}
                loop={true}
                spaceBetween={0}
                touchRatio={0}
                allowTouchMove={false}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false
                }}
              >
                {aboutEngValues?.reviewData?.map((list) => {
                  return (
                    <SwiperSlide key={list.id}>
                      <strong>{list.userName}</strong>
                      <p>{list.userReview}</p>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </EngStyled.ReviewContBox>
          </EngStyled.InnerCol>
        </EngStyled.ReviewSection>
        <EngStyled.YoutubeSection>
          <EngStyled.InnerCol>
            <button
              type="button"
              onClick={() => {
                handleOpenModal('https://www.youtube.com/embed/wsFxL8aUkP8')
              }}
            >
              <img
                src="https://english.yanadoocdn.com/upload/yanadoo/pc/sub/beforeAfter/2020/img_media_thumb_bna_collection.png"
                alt="올해의 영어 성공기 모음집"
              />
            </button>
            <VideoCard
              title={'야나두 영어 마스터'}
              buttonText={'리얼 수강생 기운 직접 받아보기'}
              videoData={aboutEngValues.videoData.videoItems}
              url={'#;'}
            />

            <VideoCard
              title={'야나두로 어떻게 공부하면 될까?'}
              buttonText={'리얼 수강생 활용 노하우가 있다?!'}
              videoData={aboutEngValues.videoData.videoItems2}
              url={'#;'}
            />

            <VideoCard
              title={'리얼 수강후기 모아보기'}
              buttonText={'이분들 영어로 OOO까지 한다는데?!'}
              videoData={aboutEngValues.videoData.videoItems3}
              url={'#;'}
            />
          </EngStyled.InnerCol>
        </EngStyled.YoutubeSection>
        <EngStyled.ProgramSection bgColor="FFF">
          <EngStyled.InnerCol>
            <EngStyled.H3>
              당신에게도 있었던 10분으로 많은 분들이 해냈습니다.
              <strong>당신도 영어 할 수 있습니다!</strong>
            </EngStyled.H3>
            <EngStyled.ProgramList>
              <ProgramCard
                bgColor="F7F7FC"
                title={'영어 레벨 테스트'}
                desc={'아, 나두 할 수 있나?'}
                link={'/levelTest/start/'}
                // badge={''}
              />

              <ProgramCard
                bgColor="F7F7FC"
                title={'기본 커리큘럼'}
                desc={'야나두 10분 영어 강좌'}
                link={'/courseList'}
              />

              <ProgramCard
                bgColor="F7F7FC"
                title={'야나두 무료특강'}
                desc={'야나두 미리보기'}
                link={'/promotion/previewYanadoo'}
              />
            </EngStyled.ProgramList>
          </EngStyled.InnerCol>
        </EngStyled.ProgramSection>
        <EngStyled.ResultSection>
          <EngStyled.ResultGraphMotion>그래프</EngStyled.ResultGraphMotion>
          <EngStyled.InnerCol>
            <EngStyled.H3>
              <strong>
                중요한 건 결과! <br /> 그래서 결국 야나두
              </strong>
            </EngStyled.H3>
            <EngStyled.ResultList>
              <ResultCard title={'실력향상 97.1'} desc={'‘영어 실력이 늘었어요’'} />
              <ResultCard title={'추천 98.5'} desc={'‘친구에게 추천 할 거에요’'} />
              <ResultCard title={'만족 99.3'} desc={'‘강의에 너무 만족해요.’'} />
            </EngStyled.ResultList>
            <EngStyled.ResultParagraph>
              *2019년 12월 유료 수강생 4,854명 조사결과
            </EngStyled.ResultParagraph>
          </EngStyled.InnerCol>
        </EngStyled.ResultSection>
        <EngStyled.ProveSection>
          <EngStyled.InnerCol>
            <EngStyled.H3>
              <strong>
                결과로 증명하는 <br />
                야나두 입체 커리큘럼
              </strong>
              영어로 의사소통하기 위한 듣고 말하는 <br />
              모든 영역을 다각도로 한번에 체득할 수 있습니다.
            </EngStyled.H3>
            <img src={ImgProve} alt="영어말하기, 문백,듣기,구조,어순,단어,억양,발음,뉘양스,문화" />
          </EngStyled.InnerCol>
        </EngStyled.ProveSection>
        <EngStyled.CurriculumSection>
          <EngStyled.InnerCol>
            <EngStyled.H3>
              <strong>
                차근차근 따라하면 <br />
                어느새 입에서 영어가 툭!
              </strong>
              하루 10분으로 완성하는 기초 영어 말하기 최적화 커리큘럼
            </EngStyled.H3>
            {/* <CurriculumBox> */}
            <Link to={'/courseList'}>
              {deviceCheck ? <img src={BgCurriculumM} alt="" /> : <img src={BgCurriculum} alt="" />}
            </Link>
            {/* <Link to={'/courseList'}>야나두 커리큘럼 입체적으로 보기</Link> */}
          </EngStyled.InnerCol>
        </EngStyled.CurriculumSection>
        <EngStyled.VodSection>
          <EngStyled.InnerCol>
            {deviceCheck ? (
              <EngStyled.H3>
                말하기는 지식으로 하는 게 아닙니다. <br />
                영어라는 언어를 감각적으로 체득해야 합니다.
                <strong>
                  1등 야나두만의 성공 커리큘럼으로 <br />
                  발전하는 영어 실력을 확인하세요.
                </strong>
              </EngStyled.H3>
            ) : (
              <EngStyled.H3>
                말하기는 지식으로 하는 게 아닙니다. 영어라는 언어를 감각적으로 체득해야 합니다.
                <strong>
                  1등 야나두만의 성공 커리큘럼으로 <br />
                  발전하는 영어 실력을 확인하세요.
                </strong>
              </EngStyled.H3>
            )}
            <button
              onClick={() =>
                handleOpenModal(
                  'https://player.vimeo.com/video/287421022?h=880240ff08&title=0&byline=0&portrait=0'
                )
              }
            >
              <span>재생</span>
            </button>
          </EngStyled.InnerCol>
        </EngStyled.VodSection>
        <EngStyled.BannerSection>
          <EngStyled.InnerCol>
            <EngStyled.H3>
              지금 나의 영어실력이 궁금하다면?!
              <strong>
                100만 개 레벨테스트
                <br />
                진단데이터로 알아보기
              </strong>
            </EngStyled.H3>
            <Link to={'/levelTest/start'}>지금 진단결과를 받아보세요 &gt;</Link>
          </EngStyled.InnerCol>
        </EngStyled.BannerSection>
        <EngStyled.HotRankSection>
          <EngStyled.InnerCol>
            <EngStyled.H3>
              이미 성공한 수강생들의 선택! <strong>HOT RANKING</strong>
            </EngStyled.H3>
            <EngStyled.HotRankList>
              <Swiper
                className="mySwiper"
                spaceBetween={deviceCheck ? 30 : 90}
                slidesPerView={deviceCheck ? 1.5 : 3}
              >
                <SwiperSlide>
                  <RankingCard
                    ranking={1}
                    title={'10분\n패키지'}
                    link={'/promotion/bestAwards'}
                    // badge={'히트상품'}
                    backgroundImg={BgHotRank01}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <RankingCard
                    ranking={2}
                    title={'스르르\n학습지'}
                    link={'/promotion/SRRfullpackage'}
                    backgroundImg={BgHotRank02}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <RankingCard
                    ranking={3}
                    title={'iPad 10세대\n패키지'}
                    link={'/promotion/tabPackage10th'}
                    backgroundImg={BgHotRank03}
                  />
                </SwiperSlide>
              </Swiper>
            </EngStyled.HotRankList>
          </EngStyled.InnerCol>
        </EngStyled.HotRankSection>

        <EngStyled.PreviewSection>
          <EngStyled.InnerCol>
            <EngStyled.H3>
              미리 들어보는 성공비결 <strong>야나두 강의 미리보기</strong>
            </EngStyled.H3>
            <button
              onClick={() =>
                handleOpenModal(
                  'https://player.vimeo.com/video/372302792?h=dc42611e0d&title=0&byline=0&portrait=0'
                )
              }
            >
              <img src={ImgPreview} alt="오늘의 패턴 show me the money" />
            </button>
          </EngStyled.InnerCol>
        </EngStyled.PreviewSection>
        <EngStyled.YoucandoSection>
          <EngStyled.InnerCol>
            <EngStyled.H3>
              아직 늦지 않았습니다.
              <strong>
                2023년은 여러분도
                <br />
                영어, 할 수 있습니다.
              </strong>
            </EngStyled.H3>
            {deviceCheck ? (
              <img src={ImgYouCanDoM} alt="야 너두 할 수 있어" />
            ) : (
              <img src={ImgYouCanDo} alt="야 너두 할 수 있어" />
            )}
          </EngStyled.InnerCol>
        </EngStyled.YoucandoSection>
        <EngStyled.WinnerSection>
          <EngStyled.InnerCol className="inner-col">
            <EngStyled.H3>
              <strong>No,1 Story</strong>포기하지 않는 정신이, 지금의 1등 야나두를 만들었습니다.
            </EngStyled.H3>
            <Swiper
              autoplay={{
                delay: 2500,
                disableOnInteraction: false
              }}
              slidesPerView={'auto'}
              centeredSlides={true}
              loop={true}
              spaceBetween={deviceCheck ? 32 : 30}
              className="mySwiper"
            >
              {aboutEngValues?.winnerData?.map((list) => {
                return (
                  <SwiperSlide key={list.id}>
                    <EngStyled.CompanyBox>
                      <img src={list.imgUrl} alt="" />
                      <strong>{list.Title}</strong>
                      <span>{list.Description}</span>
                    </EngStyled.CompanyBox>
                  </SwiperSlide>
                )
              })}
            </Swiper>
            <Link to={'/promotion/company'}>100% 야나두 성공법 &gt;</Link>
          </EngStyled.InnerCol>
        </EngStyled.WinnerSection>
        <EngStyled.LectureSection>
          <EngStyled.InnerCol>
            <EngStyled.H3>
              야나두가 더 궁금하다면 <strong>야나두 자세히 보기</strong>
            </EngStyled.H3>
            <EngStyled.LectureList>
              {aboutEngValues?.lectureData?.map((list, idx) => {
                return (
                  <EngStyled.LectureItem key={list.id} bgPosition={idx}>
                    {list.linkType === 'video' ? (
                      <a
                        href="#;"
                        onClick={() =>
                          handleOpenModal(
                            'https://player.vimeo.com/video/372302792?h=dc42611e0d&title=0&byline=0&portrait=0'
                          )
                        }
                      >
                        <p>
                          {list.subTitle}
                          <strong>{list.mainTitle}</strong>
                        </p>
                      </a>
                    ) : (
                      <Link to={list.linkUrl}>
                        <p>
                          {list.subTitle}
                          <strong>{list.mainTitle}</strong>
                        </p>
                      </Link>
                    )}
                  </EngStyled.LectureItem>
                )
              })}
            </EngStyled.LectureList>
          </EngStyled.InnerCol>
        </EngStyled.LectureSection>

        <EngStyled.ConsultingBox>
          <EngStyled.InnerCol>
            <EngStyled.FlexBox>
              <h3>
                전문가 추천받고
                <br />
                <strong>맞춤 패키지 알아보기</strong>
              </h3>
              <form name="consultingForm" action="#">
                <div>
                  {!deviceCheck && (
                    <>
                      <input
                        type="text"
                        placeholder="이름"
                        name="userName"
                        ref={userNameRef}
                        value={inputValue.userName}
                        onChange={handleNameCheck}
                      />
                      <input
                        type="number"
                        placeholder="연락처 '-'없이 작성해주세요."
                        name="phone"
                        ref={phoneRef}
                        value={inputValue.phone}
                        onChange={handlePhoneCheck}
                      />
                    </>
                  )}
                  <button type="button" onClick={deviceCheck ? openConsultingForm : postForm}>
                    <span>상담 신청하기</span>
                  </button>
                </div>
                {!deviceCheck && (
                  <>
                    <EngStyled.CheckLabel htmlFor="requiredCheck">
                      <input
                        type="checkbox"
                        id="requiredCheck"
                        name="privacyAgreeYn"
                        ref={agreeRef}
                        checked={inputValue.privacyAgreeYn ? true : false}
                        onChange={handleCheckedItem}
                      />
                      <span></span>
                      <p>[필수] 개인정보 수집·이용 동의 </p>
                      <button type="button" onClick={handleOpenModalNotice}>
                        약관보기
                      </button>
                    </EngStyled.CheckLabel>
                    <EngStyled.CheckLabel htmlFor="requiredCheckSMS">
                      <input
                        type="checkbox"
                        id="requiredCheckSMS"
                        name="smsAgreeYn"
                        checked={inputValue.smsAgreeYn ? true : false}
                        onChange={handleCheckedItem}
                      />
                      <span></span>
                      <p>[선택] SMS 광고 및 마케팅 이용 동의</p>
                    </EngStyled.CheckLabel>
                  </>
                )}
              </form>
            </EngStyled.FlexBox>
          </EngStyled.InnerCol>
        </EngStyled.ConsultingBox>

        {deviceCheck && (
          <EngStyled.ConsultingMobileBox toggle={toggleChecked}>
            <button type="button" onClick={openConsultingForm}>
              <span>상담 신청 모달 닫기</span>
            </button>
            <form name="consultingForm" action="#">
              <div>
                <input
                  type="text"
                  placeholder="이름"
                  name="userName"
                  ref={userNameRef}
                  value={inputValue.userName}
                  onChange={handleNameCheck}
                />
                <input
                  type="number"
                  placeholder="연락처 '-'없이 작성해주세요."
                  name="phone"
                  ref={phoneRef}
                  value={inputValue.phone}
                  onChange={handlePhoneCheck}
                />
              </div>
              <EngStyled.CheckLabel htmlFor="requiredCheck">
                <input
                  type="checkbox"
                  id="requiredCheck"
                  name="privacyAgreeYn"
                  ref={agreeRef}
                  checked={inputValue.privacyAgreeYn ? true : false}
                  onChange={handleCheckedItem}
                />
                <span></span>
                <p>[필수] 개인정보 수집·이용 동의 </p>
                <button type="button" onClick={handleOpenModalNotice}>
                  약관보기
                </button>
              </EngStyled.CheckLabel>
              <EngStyled.CheckLabel htmlFor="requiredCheckSMS">
                <input
                  type="checkbox"
                  id="requiredCheckSMS"
                  name="smsAgreeYn"
                  checked={inputValue.smsAgreeYn ? true : false}
                  onChange={handleCheckedItem}
                />
                <span></span>
                <p>[선택] SMS 광고 및 마케팅 이용 동의</p>
              </EngStyled.CheckLabel>
              <button type="button" onClick={postForm}>
                <span>상담 신청하기</span>
              </button>
            </form>
          </EngStyled.ConsultingMobileBox>
        )}

        {modalOpen ? (
          <ModalPortal>
            <ModalVod btnClose handleCloseModal={handleCloseModal} iframeUrl={mediaKey} />
          </ModalPortal>
        ) : null}

        {modalOpenNotice ? (
          <ModalPortal>
            <ModalNotice
              title={'개인정보 수집 및 이용 동의 약관'}
              btnClose
              handleCloseModal={handleCloseModalNotice}
            />
          </ModalPortal>
        ) : null}
      </EngStyled.ThemeBox>
    </>
  )
}
