import { useState } from 'react'
import Lottie from 'react-lottie'

import TranslateBtn from 'pages/aiTalk/components/TranslateBtn'
import LottieData from 'pages/aiTalk/lottieData.json'

import s from './Chat.module.css'
import ButtonAudio from 'pages/aiTalk/components/ButtonAudio'
import AuthService from 'services/authService'

// ETD-3489 last update
export default function UserMessage({ msg, userSeq }) {
  const [translateText, setTranslateText] = useState(null)
  const [transLoading, setTransLoading] = useState(false)
  const [translated, setTranslated] = useState(false)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <>
      <p className={`${s.message} ${s.userMessage}`}>
        {msg}
        <div className={s.btnGroup}>
          <ButtonAudio text={msg} yellow userSeq={`${userSeq}`} />
          <TranslateBtn type={'USER'} message={msg} translated={translated} translateText={translateText} setTransLoading={setTransLoading} setTranslated={setTranslated} setTranslateText={setTranslateText} />
        </div>
      </p>
      {transLoading && !translateText && (
        <p className={s.translateText}>
          <div className={s.lottieBox}>
            <Lottie options={defaultOptions} />
          </div>
        </p>
      )}
      {translateText && translated && <p className={s.translateText}>{translateText}</p>}
    </>
  )
}
