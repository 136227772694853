import { useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { v4 as uuid } from 'uuid'

import 'swiper/swiper.scss'
import SpecialItem from './SpecialItem'
import s from '../aiTalk.module.css'

export default function SwiperAiSpacial({ data }) {
  const [swiepr, setSwiper] = useState(null)
  const swiperParams = {
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true
    },
    centeredSlides: true,
    loop: true,
    pagination: { el: '#specialPagination', clickable: true, bulletActiveClass: s.bulletActive },
    onSwiper: setSwiper
  }
  return (
    <>
      <Swiper {...swiperParams}>
        {data.map((item, index) => {
          return (
            <SwiperSlide key={uuid()}>
              <SpecialItem data={item} />
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div id="specialPagination" className={s.specialPagination}></div>
    </>
  )
}
