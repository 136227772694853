import React, { useState, useCallback } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'

import api from 'common/api'
import * as config from 'common/config'

import ModalReviewWrite from './modal/ModalReviewWrite'
import NoContent from './NoContent'
import ModalPortal from '../../src/ModalPortal'
import '../assets/styles/myClassList.scss'
import icoImg from '../assets/images/ico_no_class_420x420.png'
import ModalCertificate from '../components/modal/ModalCertificate'

export default function MyClassList({ myClassData, tabType, dataType }) {
  // 1. tabType: 마이클래스-전체수강내역 버튼 구분 필요 (DEFAULT / HISTORY)
  // * 수강증 버튼이 필요하지 않는 이상 DEFAULT로 넘겨주면 됨.
  // 2. dataType: 필요한 데이터 타입 (ALL / RUNNING / END)
  // * ALL: 내가 신청한 클래스 목록 (수강종료 제외)
  // * RUNNING: 내가 수강중인 클래스 목록
  // * END: 수강 종료된 클래스 목록

  const navigate = useNavigate()
  const runningList = myClassData.filter((list) => list.learningAttendingStatus === 'RUNNING')
  const endList = myClassData.filter((list) => list.learningAttendingStatus === 'END')
  const [modalopen, setModalopen] = useState(false)
  const [modalopen2, setModalopen2] = useState(false)
  const [activeStudyGroupUserSeq, setActiveStudyGroupUserSeq] = useState(null)
  const [subscription, setSubscription] = useState(null)
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1025 })

    return isDesktop ? children : null
  }

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 })

    return isMobile ? children : null
  }

  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ')
      return getDate[0]
    } else {
      return null
    }
  }

  const alertMsg = (studySeq, status, version) => {
    if (status === 'REFUND') {
      alert('환불 진행중에는 서비스를 이용할 수 없습니다.')
    } else if (status === 'READY') {
      alert('아직 수강 전입니다. 앱에서 수강 시작 처리를 해주세요.')
    } else if (status === 'NIPOWOOPO') {
      alert('본 강의는 앱에서만 이용이 가능합니다.')
    } else {
      version === 'YND_ENG'
        ? navigate('/myclass/detail/eng/' + studySeq)
        : navigate('/myclass/detail/' + studySeq)
    }
  }

  const handleOpenModal = useCallback((seq, subscription) => {
    document.body.classList.add('modal-open')
    setModalopen(true)
    setActiveStudyGroupUserSeq(seq)
    setSubscription(subscription)
  }, [])

  const handleCloseModal = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopen(false)
  }, [])

  const handleOpenModal2 = useCallback((seq) => {
    document.body.classList.add('modal-open')
    setModalopen2(true)
    setActiveStudyGroupUserSeq(seq)
  }, [])

  const handleCloseModal2 = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopen2(false)
  }, [])

  // 내가 작성한 수강 후기가 있다면 데이터 업데이트
  const getMyreview = (
    setTextareaValue,
    setTextareaLength,
    setLearningReviewSeq,
    handleGetPoint,
    handleUpdateImage
  ) => {
    api
      .get('/v2/review/learning-review/' + activeStudyGroupUserSeq)
      .then((response) => {
        if (response.data.meta.code === 200) {
          setTextareaValue(response.data.data.contents)
          setTextareaLength(response.data.data.contents.length)
          setLearningReviewSeq(response.data.data.learningReviewSeq)
          handleGetPoint(response.data.data.satisfaction)
          handleUpdateImage(response.data.data.attachments)
        }
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  // 수강 후기 등록
  const postMyreview = (classData, textareaValue, satisfaction, registerImage) => {
    const data = {
      classSeq: classData.classSeq,
      contents: textareaValue,
      satisfaction: satisfaction,
      studyGroupUserSeq: activeStudyGroupUserSeq,
      attachments: registerImage
    }

    api
      .post('/v2/review/learning-review', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)

          return false
        }

        alert('수강 후기 등록이 완료되었습니다.')
        window.location.reload()
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  // 수강 후기 수정
  const editMyReview = (learningReviewSeq, textareaValue, satisfaction, registerImage) => {
    const data = {
      learningReviewSeq: learningReviewSeq,
      contents: textareaValue,
      satisfaction: satisfaction,
      attachments: registerImage
    }

    api
      .put('/v2/review', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)

          return false
        }

        alert('수강 후기 수정이 완료되었습니다.')
        window.location.reload()
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  return (
    <>
      <div className="class-list-area">
        {myClassData.length > 0 ? (
          <ul className={dataType === 'END' ? 'end-list' : null}>
            {(dataType && dataType === 'ALL'
              ? myClassData
              : dataType === 'RUNNING'
              ? runningList
              : dataType === 'END'
              ? endList
              : null
            ).map((list, idx) => (
              // 클래스 상태별 버튼 스타일 (환불은 제외됨)
              <li
                key={idx}
                className={
                  tabType === 'DEFAULT' && list.learningAttendingStatus === 'RUNNING'
                    ? 'proceed'
                    : list.learningAttendingStatus === 'READY'
                    ? 'before-proceed'
                    : list.learningAttendingStatus === 'REFUND'
                    ? 'refund'
                    : null
                }
              >
                <Desktop>
                  <dl className="class-info-box">
                    <dt>
                      {list.packageTypeCode === 'COMBINATION' || 'NIPOWOOPO' ? (
                        <span className="package-name">{list.packageName}</span>
                      ) : null}
                      <p className="ellipsis line-clamp-2">{list.className}</p>
                      {list.subscriptionType !== 'U_PLUS' ? (
                        <>
                          {list.beginDate && list.closeDate && (
                            <span className="date">
                              <em>{formatDate(list.beginDate)}</em>~
                              <em>{formatDate(list.closeDate)}</em>
                            </span>
                          )}
                        </>
                      ) : null}
                    </dt>
                    <dd>
                      {list.subscriptionType !== 'U_PLUS' ? (
                        <p className="dday">
                          <span>{list.remainingDays}</span>
                        </p>
                      ) : null}
                      {list.packageTypeCode !== 'SRR' && (
                        <>
                          <p className="attend">{list.attendanceDays}</p>
                          <p className="progress">
                            <span>
                              {list.versionCode === 'YND_CLS' && list.classCardAggregateV2
                                ? list.classCardAggregateV2.achievementRate
                                : list.versionCode === 'YND_ENG' && list.classCardAggregateVO
                                ? list.classCardAggregateVO.progressRate
                                : tabType === 'HISTORY' && list.classCardAggregateV2
                                ? list.classCardAggregateV2.achievementRate
                                : list.achievementRate}
                            </span>
                          </p>
                        </>
                      )}
                      {list.packageTypeCode !== 'SRR' ? (
                        <div className="btn-form">
                          {tabType && tabType === 'HISTORY' ? (
                            <>
                              <button
                                type="button"
                                className="btn-certificate"
                                onClick={() =>
                                  handleOpenModal(list.studyGroupUserSeq, list.subscriptionType)
                                }
                              >
                                <span>수강증</span>
                              </button>
                              {list.versionCode === 'YND_CLS' && (
                                <button
                                  type="button"
                                  className={
                                    'btn-review' + (list.reviewAnswerYn === 'Y' ? ' disabled' : '')
                                  }
                                  onClick={() => handleOpenModal2(list.studyGroupUserSeq)}
                                  disabled={list.reviewAnswerYn === 'Y' ? true : false}
                                >
                                  <span>후기 작성</span>
                                </button>
                              )}
                            </>
                          ) : (
                            <button
                              type="button"
                              onClick={(e) =>
                                alertMsg(
                                  list.versionCode === 'YND_CLS'
                                    ? list.studyGroupUserSeq
                                    : list.versionCode === 'YND_ENG'
                                    ? list.studyGroupSeq
                                    : '',
                                  list.packageTypeCode === 'NIPOWOOPO'
                                    ? list.packageTypeCode
                                    : list.learningAttendingStatus,
                                  list.versionCode
                                )
                              }
                            >
                              <span>
                                {list.learningAttendingStatus === 'RUNNING'
                                  ? '수강하기'
                                  : list.learningAttendingStatus === 'READY'
                                  ? '수강 시작 전'
                                  : '반품/환불 진행중'}
                              </span>
                            </button>
                          )}
                        </div>
                      ) : (
                        <div className="myclass-noti-box">
                          <p className="myclass-noti-text">전용 앱을 이용해 주세요</p>
                        </div>
                      )}
                      {list.learningAttendingStatus === 'READY' && list.classBeginDate ? (
                        <em>(시작일: {formatDate(list.classBeginDate)})</em>
                      ) : null}
                    </dd>
                  </dl>
                </Desktop>
                <Mobile>
                  <a
                    href="#;"
                    onClick={(e) =>
                      tabType === 'HISTORY'
                        ? e.preventDefault()
                        : alert('앱 전용 학습 상품입니다. 야나두 앱을 통해 학습을 진행해주세요.')
                    }
                  >
                    <dl className="class-info-box">
                      <dt>
                        {list.packageTypeCode === 'COMBINATION' || 'NIPOWOOPO' ? (
                          <span className="package-name ellipsis">{list.packageName}</span>
                        ) : null}
                        <p className="ellipsis">{list.className}</p>
                        {list.subscriptionType !== 'U_PLUS' ? (
                          <>
                            {list.beginDate && list.closeDate && (
                              <span className="date">
                                <em>{formatDate(list.beginDate)}</em>~
                                <em>{formatDate(list.closeDate)}</em>
                              </span>
                            )}
                          </>
                        ) : null}
                      </dt>
                      <dd>
                        {list.subscriptionType !== 'U_PLUS' ? (
                          <p className="dday">
                            <span>{list.remainingDays}</span>
                          </p>
                        ) : null}
                        {list.packageTypeCode !== 'SRR' && (
                          <>
                            <p className="attend">{list.attendanceDays}</p>
                            <p className="progress">
                              <span>
                                {list.versionCode === 'YND_CLS' && list.classCardAggregateV2
                                  ? list.classCardAggregateV2.achievementRate
                                  : list.versionCode === 'YND_ENG' && list.classCardAggregateVO
                                  ? list.classCardAggregateVO.progressRate
                                  : list.achievementRate}
                              </span>
                            </p>
                          </>
                        )}
                        <div
                          className={'btn-form' + (list.versionCode === 'YND_CLS' ? '' : ' only')}
                        >
                          {tabType && tabType === 'HISTORY' ? (
                            <>
                              <button
                                type="button"
                                className="btn-certificate"
                                onClick={() =>
                                  handleOpenModal(list.studyGroupUserSeq, list.subscriptionType)
                                }
                              >
                                <span>수강증</span>
                              </button>
                              {list.versionCode === 'YND_CLS' && (
                                <button
                                  type="button"
                                  className={
                                    'btn-review' + (list.reviewAnswerYn === 'N' ? ' disabled' : '')
                                  }
                                  onClick={() => handleOpenModal2(list.studyGroupUserSeq)}
                                  disabled={list.reviewAnswerYn === 'N' ? true : false}
                                >
                                  <span>후기 작성</span>
                                </button>
                              )}
                            </>
                          ) : null}
                        </div>
                      </dd>
                    </dl>
                  </a>
                </Mobile>
              </li>
            ))}
          </ul>
        ) : tabType === 'HISTORY' ? (
          <div className="class-empty history">
            <NoContent icoImg={icoImg} titleMsg={'수강내역이 없습니다.'} />
          </div>
        ) : (
          <div className="none">
            {dataType === 'END' ? '수강내역이 없습니다.' : '수강중인 클래스가 없습니다.'}
          </div>
        )}
      </div>

      {modalopen ? (
        <ModalPortal>
          <ModalCertificate
            btnClose
            studySeq={activeStudyGroupUserSeq}
            handleCloseModalCertificate={handleCloseModal}
            subscriptionType={subscription}
          />
        </ModalPortal>
      ) : null}

      {modalopen2 ? (
        <ModalPortal>
          <ModalReviewWrite
            title={'수강 후기 작성'}
            btnClose
            studySeq={activeStudyGroupUserSeq}
            handleCloseModalReviewWrite={handleCloseModal2}
            getMyreview={getMyreview}
            postMyreview={postMyreview}
            editMyReview={editMyReview}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
