export function userAgent() {
  // userAgent OS
  let userAgent = navigator.userAgent.toLowerCase()
  let returnUserAgent
  if (userAgent.search('android') > -1) {
    returnUserAgent = 'aos'
  } else if (
    userAgent.search('iphone') > -1 ||
    userAgent.search('ipod') > -1 ||
    userAgent.search('ipad') > -1 ||
    userAgent.search('macintosh') > -1
  ) {
    returnUserAgent = 'ios'
  }
  return returnUserAgent
}

// 공통 함수
function isEmpty(val) {
  return (
    val === undefined ||
    val === null ||
    val === '' ||
    (val !== null && typeof val === 'object' && !Object.keys(val).length)
  )
}

function isNotEmpty(val) {
  return !isEmpty(val)
}

export function callNative(iOSFunction, androidFunction, defaultFunction) {
  let device = userAgent()

  try {
    if (device === 'ios') {
      iOSFunction()
    } else if (device === 'aos') {
      androidFunction()
    } else if (isNotEmpty(defaultFunction)) {
      defaultFunction()
    } else {
      return
    }
  } catch (e) {
    if (isNotEmpty(defaultFunction)) {
      defaultFunction()
    }
  }
}

export function callIOS(message) {
  window.webkit.messageHandlers.yndiOSScriptHandler.postMessage(message)
}

export function setTitle(title) {
  if (isEmpty(title)) {
    return
  }

  callNative(
    function () {
      callIOS({ action: 'setTitle', title: title })
    },
    function () {
      window.Android.setTitle(title)
    }
  )
}

export function openExternalURL(url) {
  callNative(
    function () {
      callIOS({ action: 'openExternalURL', value: url })
    },
    function () {
      window.Android.openExternalURL(url)
    }
  )
}

export function closeWebView() {
  callNative(
    function () {
      callIOS({ action: 'closeWebView' })
    },
    function () {
      window.Android.closeWebView()
    }
  )
}

// SNS 공유하기 함수 입니다. 웹뷰에서 공유하기 시도 시 네이티브
// shareType : kakaotalk(카카오톡), kakaostory(카카오스토리), band(네이버밴드)
// shareUrl : 공유할 URL
// shareText : 공유 문구
export function NativeSahreSNS(shareType, shareUrl, shareText, shareImage) {
  callNative(
    function () {
      callIOS({
        action: 'callShare',
        type: shareType,
        url: shareUrl,
        text: shareText,
        image: shareImage
      })
    },
    function () {
      window.Android.callShare(shareType, shareUrl, shareText, shareImage)
    }
  )
}
