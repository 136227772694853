import React, { useState, useCallback, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import ModalPortal from '../../ModalPortal';
import ModalReviewWrite from '../../components/modal/ModalReviewWrite';
import Lnb from '../../components/Lnb';
import NoContent from '../../components/NoContent';
import TabMain from './TabMain';
import TabClassListCLS from './TabClassListCLS';
import TabQna from './TabQna';
import api from 'common/api';
import * as config from 'common/config';
import icoImg from '../../assets/images/ico_no_mobile_420x420.png';

export default function MyClassDetailCLS() {
  const { studySeq } = useParams();
  const { state } = useLocation();
  const activeTabMenu = state && state.tabMenu;

  const [tabMenu, setTabMenu] = useState(activeTabMenu ? activeTabMenu : 1);
  const [modalopen, setModalopen] = useState(false);
  const [curriculumData, setCurriculumData] = useState(null);
  const [lectureData, setLectureData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1025 });

    return isDesktop ? children : null;
  };

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 });

    return isMobile ? children : null;
  };

  const handleTabShow = (id) => {
    setTabMenu(id);
  };

  const handleOpenModal = useCallback((e) => {
    document.body.classList.add('modal-open');
    setModalopen(true);
  }, []);

  const handleCloseModal = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalopen(false);
  }, []);

  // 내가 작성한 수강 후기가 있다면 데이터 업데이트
  const getMyreview = (setTextareaValue, setTextareaLength, setLearningReviewSeq, handleGetPoint, handleUpdateImage) => {
    api
      .get('/v2/review/learning-review/' + studySeq)
      .then((response) => {
        if (response.data.meta.code === 200) {
          setTextareaValue(response.data.data.contents);
          setTextareaLength(response.data.data.contents.length);
          setLearningReviewSeq(response.data.data.learningReviewSeq);
          handleGetPoint(response.data.data.satisfaction);
          handleUpdateImage(response.data.data.attachments);
        }
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error']);
      });
  };

  // 수강 후기 등록
  const postMyreview = (classData, textareaValue, satisfaction, registerImage) => {
    const data = {
      classSeq: classData.classSeq,
      contents: textareaValue,
      satisfaction: satisfaction,
      studyGroupUserSeq: studySeq,
      attachments: registerImage,
    };

    api
      .post('/v2/review/learning-review', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message);

          return false;
        }

        alert('수강 후기 등록이 완료되었습니다.');
        window.location.reload();
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error']);
      });
  };

  // 수강 후기 수정
  const editMyReview = (learningReviewSeq, textareaValue, satisfaction, registerImage) => {
    const data = {
      learningReviewSeq: learningReviewSeq,
      contents: textareaValue,
      satisfaction: satisfaction,
      attachments: registerImage,
    };

    api
      .put('/v2/review', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message);

          return false;
        }

        alert('수강 후기 수정이 완료되었습니다.');
        window.location.reload();
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error']);
      });
  };

  const getCurriculumList = () => {
    api
      .get('/v2/curriculum/web/' + studySeq)
      .then((response) => {
        setCurriculumData(response.data.data);
        setLectureData(response.data.data.lectures);
        setIsLoading(false);
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error']);
      });
  };

  useEffect(() => {
    if (studySeq) {
      getCurriculumList();
    }
  }, [studySeq]);

  useEffect(() => {
    // 페이지 벗어날때 모달 클래스 삭제
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  return (
    curriculumData &&
    !isLoading && (
      <>
        <div className="myclass-col">
          <div className="inner-col">
            <div className="container mypage-sub">
              <Lnb />
              <section className="content">
                <TabMain activeTab={'MYCLASS'} />
                <section className="class-detail-area">
                  <Desktop>
                    <div className="class-info">
                      <dl>
                        <dt>
                          <p>{curriculumData.className}</p>
                        </dt>
                        <dd>
                          <p>
                            {curriculumData.teacherName && <em>{curriculumData.teacherName} 선생님</em>}
                            <em>
                              {curriculumData.contentType}
                              강의
                            </em>
                            <em className="total-class">총 {curriculumData.lectureCount}강</em>
                          </p>
                          {curriculumData.subscriptionType !== 'U_PLUS' ? (
                            <p>
                              수강기간 <span>{curriculumData.beginDate}</span>~<span>{curriculumData.closeDate}</span>
                            </p>
                          ) : null}
                        </dd>
                      </dl>
                      <button type="button" className="btn-class-review" onClick={handleOpenModal}>
                        <span>수강후기 작성하기</span>
                      </button>
                    </div>
                    <div className="class-check">
                      <dl>
                        <dt>
                          <p>
                            PC 수강 전<br />꼭 확인하세요!
                          </p>
                        </dt>
                        <dd>
                          <p>
                            <span>모든 미션은 야나두 앱에서만 제공됩니다.</span>
                            <span>PC와 모바일 앱 간의 이어보기는 연동이 되지 않습니다.</span>
                          </p>
                        </dd>
                      </dl>
                    </div>
                    <div className="myclass-tab-area">
                      <ul>
                        <li className={tabMenu === 1 ? 'active' : null}>
                          <a href="#;" className="tab-item" onClick={() => handleTabShow(1)}>
                            강의 리스트
                          </a>
                        </li>
                        <li className={tabMenu === 2 ? 'active' : null}>
                          <a href="#;" className="tab-item" onClick={() => handleTabShow(2)}>
                            학습 문의
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="myclass-content-box myclass-detail">
                      <div className={'tab-content tab-course-list' + (tabMenu === 1 ? ' active' : '')}>
                        {tabMenu === 1 && <TabClassListCLS curriculumData={curriculumData} lectureData={lectureData} studySeq={studySeq} />}
                      </div>
                      <div className={'tab-content tab-qna' + (tabMenu === 2 ? ' active' : '')}>
                        {tabMenu === 2 && <TabQna studySeq={studySeq} classSeq={curriculumData.classSeq} lectureData={lectureData} />}
                      </div>
                    </div>
                  </Desktop>
                  <Mobile>
                    <div className="class-empty myclass">
                      <NoContent icoImg={icoImg} titleMsg={'PC버전과 야나두앱에서만 이용할 수 있는 서비스입니다.'} />
                    </div>
                  </Mobile>
                </section>
              </section>
            </div>
          </div>
        </div>
        {modalopen ? (
          <ModalPortal>
            <ModalReviewWrite
              title={'수강 후기 작성'}
              btnClose
              studySeq={studySeq}
              handleCloseModalReviewWrite={handleCloseModal}
              getMyreview={getMyreview}
              postMyreview={postMyreview}
              editMyReview={editMyReview}
            />
          </ModalPortal>
        ) : null}
      </>
    )
  );
}
