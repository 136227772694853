import styled, { ThemeProvider, keyframes } from "styled-components";
import theme from "common/theme";

import BgYellowRound from "../images/bg_yellow_round.png";
import BgYouCanDO from "../images/bg_pc_youcando.png";
import BgYouCanDOM from "../images/bg_m_youcando.png";

import BgLevelTest from "../images/bg_pc_level_data.png";
import BgVod from "../images/bg_pc_curriculum_vod.png";
import BgVodM from "../images/bg_m_curriculum_vod.png";

import BtnPlayerVod from "../images/btn_pc_curriculum_vod.png";

import BgResultGraph from "../images/bg_pc_graphx2.png";
import BgResultGraphLight from "../images/bg_pc_graph_light_v2.png";

import BgResultGraphM from "../images/bg_m_graph.png";
import BgResultGraphLightM from "../images/bg_m_graph_light_v2.png";

import IcoArrow from "../images/ico_pc_arrow_64x64b.png";
import BgYellowRound02 from "../images/bg_yellow_round_v2.png";
import BgApp from "../images/bg_pc_app.png";

import BgYellowUnderline from "../images/bg_yellow_underline.png";
import BgYellowUnderline02 from "../images/bg_yellow_underline_v2.png";
import BgQuestion from "../images/bg_pc_question.png";
import IcoPlay from "../images/ico_play_300x300_w.png";
import IcoArrowRight from "../images/ico_arrow_8x14b.png";

import ImgLectureList from "../images/img_lecture_list_v3.png";

import IcoArrowDown from "../images/ico_arrow_down_96x96w.png";
import IcoCheckWhite from "../images/ico_check_38x30w.png";
import IcoCheckBlack from "../images/ico_check_38x30b.png";

function ThemeBox({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

const VisualSection = styled.section`
  background: #ffb900;
  padding: 8.5rem 0 0;

  h3 {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
  }

  p {
    position: absolute;
    top: 4.8rem;
    left: 7rem;
    font-size: 1.8rem;
    color: #28284b;
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0;
    background: #ffb900;

    & > div {
      padding: 0;
    }
    h3 {
      display: none;
    }
    p {
      display: none;
    }
    img {
      padding: 0 !important;
    }
  }
`;

const boxFade = keyframes`
  0% {
    transform: translateY(20%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const TrySection = styled.section`
  padding: 15rem 0;
  background: #fff;

  &.active {
    img,
    button {
      animation: ${boxFade} 1s 0.1s linear forwards;
    }
  }
`;

const TryTxtBox = styled.div`
  /* display: flex; */
  position: relative;
  margin: 0 0 10rem;

  img {
    width: 37rem;
    opacity: 0;
  }

  div {
    position: absolute;
    top: 5rem;
    left: 28rem;
    z-index: 1;

    strong {
      font-size: 4.8rem;
    }

    p {
      font-size: 2.8rem;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 12rem;

    img {
      width: 45%;
    }

    div {
      position: absolute;
      top: 16%;
      left: 43%;
      z-index: 1;

      strong {
        font-size: 4rem;
      }

      p {
        margin-top: 1.6rem;
      }
    }
  }
`;

const TryVodBox = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;

  button {
    display: block;
    position: relative;
    width: 78rem;
    opacity: 0;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10rem;
      height: 10rem;
      background: url(${BtnPlayerVod}) no-repeat center / contain;
    }
  }

  div {
    position: absolute;
    top: 10rem;
    left: 10rem;
    z-index: 1;

    strong {
      font-size: 4.8rem;
    }

    p {
      font-size: 2.8rem;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
    align-items: flex-end;

    button {
      width: 95%;
    }

    div {
      position: unset;
      width: 93%;
      margin: 0 0 2.5rem;

      strong {
        font-size: 4rem;
      }

      p {
        margin-top: 1.6rem;
      }
    }
  }
`;

const QuestionSection = styled.section`
  padding: 10rem 0;
  background: #f7f7fc url(${BgQuestion}) no-repeat top center / 100%;

  h3 {
    position: relative;
    z-index: 0;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 5rem;
      left: 50%;
      width: 62.2rem;
      height: 2.4rem;
      background: url(${BgYellowUnderline}) no-repeat top center / 100%;
      transform: translateX(-50%);
      z-index: -1;
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    background-size: cover;

    h3 {
      &::before {
        width: 48rem;
      }
    }
  }
`;

const AISection = styled.section`
  position: relative;
  padding: 15rem 0;

  h3 {
    margin: 0 0 12rem;
  }
  @media ${({ theme }) => theme.device.mobile} {
    & > div {
      padding: 0;
    }
  }
`;
const AIMotionBox = styled.div`
  position: relative;
  width: 90rem;
  margin: 0 auto;

  .phone {
    display: block;
    position: absolute;
    top: -8rem;
    left: 50%;
    width: 50.4rem;
    transform: translateX(-50%);
    z-index: 5;
  }

  .fade01 {
    width: 36rem;
    transition: 0.5s linear;
    transition-delay: 0.1s;
    transform: translateX(30%);
    opacity: 0.5;
    z-index: 3;
  }

  .fade02 {
    width: 36rem;
    transition: 0.5s linear;
    transition-delay: 0.1s;
    transform: translateX(22%);
    opacity: 0.5;
    z-index: 3;
  }

  .info {
    display: block;
    margin: -3rem auto 0;
  }
  &.active {
    .fade01 {
      transform: translateX(-17%);
      opacity: 1;
    }
    .fade02 {
      transform: translateX(67%);
      opacity: 1;
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    width: 55rem;
    .phone {
      top: -4rem;
      width: 33rem;
    }
    .fade01,
    .fade02 {
      width: 26rem;
      transform: translateX(5%);
    }

    .info {
      /* width: 80%; */
    }

    &.active {
      .fade01 {
        transform: translateX(-17%);
      }
      .fade02 {
        transform: translateX(28%);
      }
    }
  }
`;
const zoomIn = keyframes`
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.2);
    }
`;
const AppSection = styled.section`
  padding: 15rem 0 0;
  background: #000 url(${BgApp}) no-repeat top center / cover;
  h3 {
    position: relative;
    color: #fff;
    z-index: 0;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 9rem;
      left: 50%;
      width: 56.8rem;
      height: 8.7rem;
      background: url(${BgYellowUnderline}) no-repeat top center / 100%;
      transform: translateX(-50%);
      z-index: -1;
    }
  }
  .splash-box {
    position: relative;
    width: 50rem;
    height: 60.9rem;
    margin: 7rem auto 0;
    overflow: hidden;
  }
  img.phone {
    width: 50rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  img.txt {
    position: absolute;
    top: 64%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30rem;
    z-index: 1;
  }
  img.ani {
    display: block;
    width: 50rem;
    height: 61rem;
  }
  &.active {
    img.ani {
      animation: ${zoomIn} 3s ease-in forwards;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 15rem 0 0;
    background-position: 100%;
    & > div {
      padding: 0;
    }
    h3 {
      &::before {
        width: 47.8rem;
      }
    }

    .splash-box {
      position: relative;
      width: 100%;
      height: 78rem;
      margin: unset;
      overflow: hidden;
    }
    img.phone {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 75rem;
      margin: 0 auto;
    }
    img.txt {
      position: absolute;
      top: 64%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30rem;
      z-index: 1;
    }
    img.ani {
      display: block;
      /* width: 50rem;
      height: 90rem; */
      margin: 15rem auto 0;
    }
    &.active {
      img.ani {
        animation: ${zoomIn} 3s ease-in forwards;
      }
    }
  }
`;
const ReviewSection = styled.section`
  padding: 15rem 0;
  h3 {
    position: relative;
    margin: 0 0 7rem;
    z-index: 0;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 9rem;
      left: 50%;
      width: 26rem;
      height: 2.4rem;
      background: url(${BgYellowUnderline02}) no-repeat top center / 100%;
      transform: translateX(-50%);
      z-index: -1;
    }
  }
  a {
    display: block;
    width: 58rem;
    height: 8rem;
    margin: 8rem auto 0;
    font-weight: 400;
    font-size: 2.4rem;
    color: #000;
    line-height: 8rem;
    background: #ffb900;
    border-radius: 5rem;
    text-align: center;
  }

  @media ${({ theme }) => theme.device.mobile} {
    h3 {
      &::before {
        top: 8rem;
        width: 24rem;
      }
    }
  }
`;
const ReviewTitleBox = styled.div`
  display: flex;
  padding: 2.4rem;
  border-top: 3px solid #717188;
  border-bottom: 1px solid #717188;

  span {
    flex: 1 20%;
    font-size: 1.8rem;
    color: #717188;
    text-align: center;
    &:nth-child(2) {
      flex: 1 80%;
    }
  }
`;

const ReviewContBox = styled.div`
  border-bottom: 1px solid #dfdfea;
  overflow: hidden;

  .swiper-container {
    height: 70rem !important;
  }

  .swiper-slide {
    display: table;
    table-layout: fixed;
    font-size: 1.8rem;
    background: #fff;

    box-sizing: border-box;

    & + .swiper-slide {
      border-top: 1px solid #dfdfea;
    }

    strong {
      display: table-cell;
      vertical-align: middle;
      width: 20%;
      text-align: center;
    }

    p {
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      padding: 0 3rem 0 0;
      box-sizing: border-box;
    }
  }

  .swiper-slide-active {
    background: #f7f7fc;
  }

  @media ${({ theme }) => theme.device.mobile} {
    .swiper-container {
      height: 117rem !important;
    }

    .swiper-slide {
      font-size: 2rem;
    }
  }
`;
const YoutubeSection = styled.section`
  padding: 16rem 0;
  background: #f7f7fc;

  @media ${({ theme }) => theme.device.mobile} {
    > div {
      padding: 0;

      > button {
        padding: 0 4.2rem;
        box-sizing: border-box;
      }
    }
  }
`;
const InnerCol = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 3rem;
  @media ${({ theme }) => theme.device.mobile} {
    padding: 0 4.2rem;
  }
`;
const VideoBox = styled.div`
  margin-top: 11.7rem;
`;

const VideoTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  strong {
    font-size: 4rem;
  }

  a {
    position: relative;
    padding-right: 2.2rem;
    font-weight: 400;
    font-size: 2.4rem;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      width: 0.8rem;
      height: 1.4rem;
      margin-top: -0.8rem;
      background: url(${IcoArrowRight}) 50% 50% no-repeat;
      background-size: 100% auto;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0 4.2rem;

    a {
      display: none;
    }
  }
`;

const VideoList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.4rem;

  li {
    flex: 1 1 auto;

    & + li {
      margin-left: 1.4rem;
    }

    button {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;

      &:hover {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0.4);
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          width: 10rem;
          height: 10rem;
          margin: auto;
          z-index: 2;
          background: url(${IcoPlay}) 50% 50% no-repeat;
          background-size: 100% auto;
        }
      }
    }
  }

  + div {
    display: none;
    margin-top: 3.2rem;
    padding: 0 4.2rem;

    a {
      display: block;
      width: 100%;
      padding: 1.8rem 0 1.8rem;
      text-align: center;
      border: 1px solid #b7b7c8;
      border-radius: 0.8rem;
      box-sizing: border-box;

      span {
        position: relative;
        padding-right: 2.2rem;
        font-weight: 400;
        font-size: 2.4rem;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          width: 0.8rem;
          height: 1.4rem;
          margin-top: 0.1rem;
          transform: translateY(-50%);
          background: url(${IcoArrowRight}) 50% 50% no-repeat;
          background-size: 100% auto;
        }
      }
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0 4.2rem;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      flex: 0 0 56%;

      & + li {
        margin-left: 2.8rem;
      }
    }

    + div {
      display: block;
    }
  }
`;

const ThumbBox = styled.div`
  position: relative;
  overflow: hidden;

  & + div {
    &::after {
      content: "";
      display: block;
      padding-bottom: 67%;
    }
  }
`;

const Picture = styled.picture`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
  }

  + div {
    &::after {
      content: "";
      display: block;
      padding-bottom: 56%;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    height: auto;
    min-height: 22rem;
  }
`;
const ProgramSection = styled.section`
  padding: 15rem 0;
  /* background: #fff; */
  background-color: ${(props) => "#" + props.bgColor || "transparent"};

  &:first-child {
    h3:before {
      display: none;
    }
  }
  h3 {
    position: relative;
    z-index: 0;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 3rem;
      left: 50%;
      width: 56.8rem;
      height: 8.7rem;
      background: url(${BgYellowRound02}) no-repeat top center / 100%;
      transform: translateX(-50%);
      z-index: -1;
    }

    &.no-line::before {
      display: none;
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    h3 {
      &::before {
        width: 48.8rem;
      }
    }
  }
`;
const ProgramList = styled.ul`
  display: flex;
  margin: 6rem 0 0;
  justify-content: center;

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;
const ProgramItem = styled.li`
  position: relative;
  width: 37.6rem;
  margin: 0 0 0 2rem;
  padding: 5.7rem 3.2rem;
  background-color: ${(props) => "#" + props.bgColor || "red"};

  border-radius: 2rem;
  box-sizing: border-box;

  &:first-child {
    margin: 0;
  }
  a {
    display: block;
    p {
      display: inline-block;
      font-weight: 400;
      font-size: 2.4rem;
      vertical-align: middle;
    }
    strong {
      display: block;
      margin: 1.2rem 0 0;
      font-size: 3.2rem;
    }
    span {
      display: inline-block;
      margin: 0 0 0 1rem;
      padding: 0.5rem 1rem 0.3rem;
      font-weight: 700;
      font-size: 1.2rem;
      color: #fff;
      background: #ff5647;
      border-radius: 3px;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 3.2rem;
      transform: translateY(-50%);
      width: 3.2rem;
      height: 3.2rem;
      background: url(${IcoArrow}) no-repeat center / contain;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 80%;
    margin: 3.2rem 0 0;

    &:first-child {
      margin-top: 0;
    }
  }
`;
const BoxMove = keyframes`
    0% {
      background-color: #060e19;
      left: -40%;
      /* top: 0px; */
    }

    100% {
      background-color: #060e19;
      left: 40%;
      /* top: 100%; */
    }
`;

const ResultSection = styled.section`
  position: relative;
  background: #060e19;
  height: 73rem;
  color: #fff;
  z-index: 2;
  overflow: hidden;

  & > div:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(-50%);
    width: 100%;
    box-sizing: border-box;
    z-index: 2;
  }
  h3 {
    line-height: 5.6rem;
    text-align: left;
  }
  @media ${({ theme }) => theme.device.mobile} {
    height: 102rem;
    & > div:nth-child(2) {
      bottom: unset;
    }
  }
`;

const ResultGraphMotion = styled.div`
  position: relative;
  max-width: 192rem;
  height: 100%;
  margin: 0 auto;
  font-size: 0;
  z-index: 2;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: url(${BgResultGraphLight}) no-repeat center / cover;
    animation: ${BoxMove} 2s 1s infinite linear;
    z-index: 1;

    @media ${({ theme }) => theme.device.mobile} {
      background: url(${BgResultGraphLightM}) no-repeat center / 100%;
    }
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: url(${BgResultGraph}) no-repeat top center / auto 100%;
    z-index: 2;

    @media ${({ theme }) => theme.device.mobile} {
      background: url(${BgResultGraphM}) no-repeat center / 100% auto;
    }
  }
`;

const ResultList = styled.ul`
  display: flex;
  margin: 14rem 0 0;
  justify-content: space-between;
  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
  }
`;
const ResultListItem = styled.li`
  p {
    margin: 0 0 2.4rem;
    font-size: 2rem;
  }
  strong {
    font-size: 4.8rem;

    &:after {
      content: "%";
      display: inline-block;
      font-size: 3.2rem;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin: 8rem 0 0;

    &:first-child {
      margin-top: 0;
    }
  }
`;
const ResultParagraph = styled.p`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.2rem;
  color: #a0a0b6;

  @media ${({ theme }) => theme.device.mobile} {
    position: unset;
    margin: 6rem 0 0;
  }
`;
const ProveSection = styled.section`
  padding: 12rem 0;
  background: #f7f7fc;

  & > div {
    display: flex;
    justify-content: space-between;
  }
  h3 {
    margin: 2rem 0 0;
    text-align: left;

    strong {
      margin: 0 0 3.2rem;
      line-height: 5.6rem;
    }
  }
  img {
    display: inline-block;
    width: 50rem;
    text-align: right;
  }
  @media ${({ theme }) => theme.device.mobile} {
    & > div {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
    }
    h3 {
      margin: 0 0 8rem;
      text-align: center;
    }
  }
`;
const CurriculumSection = styled.section`
  padding: 15rem 0;
  text-align: center;
  h3 {
    margin: 0 0 10rem;

    strong {
      margin: 0 0 1.6rem;
      line-height: 5.6rem;
    }
  }
  a {
    display: block;
  }

  @media ${({ theme }) => theme.device.mobile} {
    h3 {
      font-size: 2.4rem;
    }
  }
`;
const VodSection = styled.section`
  padding: 16rem 0 14.7rem;
  background: #a36112 url(${BgVod}) no-repeat top center;
  color: #fff;

  h3 {
    strong {
      margin: 1.6rem 0 0;
      line-height: 5.6rem;
    }
  }
  button {
    display: block;
    width: 100%;
    padding: 10rem 0;

    span {
      display: block;
      width: 10rem;
      height: 10rem;
      margin: 0 auto;
      font-size: 0;
      background: url(${BtnPlayerVod}) no-repeat center / 100%;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 12rem 0 27rem;
    background: #b07a22 url(${BgVodM}) no-repeat bottom center / contain;

    h3 {
      font-size: 2.4rem;

      strong {
        font-size: 4rem;
      }
    }
  }
`;
const BannerSection = styled.section`
  padding: 15rem 0 14rem;
  background: #000 url(${BgLevelTest}) no-repeat bottom center;
  & > div {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  h3 {
    color: #fff;
    text-align: left;

    strong {
      margin: 1.6rem 0 0;
      line-height: 5.6rem;
    }
  }

  a {
    display: block;
    width: 48rem;
    height: 8rem;
    margin: 0 0 1rem;
    font-weight: 400;
    font-size: 2.4rem;
    color: #000;
    line-height: 8rem;
    background: #ffb900;
    border-radius: 5rem;
    text-align: center;
  }
  @media ${({ theme }) => theme.device.mobile} {
    & > div {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    h3 {
      text-align: center;
    }
    a {
      margin: 8rem 0 0;
    }
  }
`;
const HotRankSection = styled.section`
  padding: 16rem 0;
  background: #fff;

  @media ${({ theme }) => theme.device.mobile} {
    > div {
      padding: 0;
    }
  }
`;
const HotRankList = styled.ul`
  margin-top: 8rem;

  @media ${({ theme }) => theme.device.mobile} {
    .swiper-container {
      padding: 0 4.2rem;
    }
  }
`;
const HotRankItem = styled.li`
  min-height: 38.5rem;
  margin: 0 0 0 7.5%;
  background-size: 100%;
  background-repeat: no-repeat;

  &:first-child {
    margin: 0;
  }
  a {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    color: ${(props) => "#" + props.fontColor || "transparent"} !important;

    div {
      position: relative;
      width: 100%;
      height: auto;
      background-position: 50% 50%;
      background-size: cover;
      border-radius: 0.5rem;

      &::after {
        content: "";
        display: block;
        padding-top: 137%;
      }
    }

    p {
      position: absolute;
      top: 4rem;
      left: 4rem;
    }
  }
  em {
    display: inline-block;
    padding: 0.2rem 1.5rem 0.4rem;
    font-weight: 700;
    font-size: 2rem;
    line-height: initial;
    color: #fff;
    background: #717188;
    border-radius: 5px;
    box-sizing: border-box;
  }

  strong {
    display: block;
    margin: 1.5rem 0;
    font-size: 3.2rem;
    white-space: break-spaces;
  }

  span {
    display: inline-block;
    padding: 0.5rem 1rem;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: initial;
    color: #fff;
    background: #ff5647;
    border-radius: 5px;
    box-sizing: border-box;
  }
`;
const PreviewSection = styled.section`
  padding: 16rem 0;
  background: #f7f7fc;

  h3 {
    color: #000;

    + button {
      margin-top: 7rem;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 14rem 0;

    h3 {
      + button {
        margin-top: 5.5rem;
      }
    }
  }
`;

const YoucandoSection = styled.section`
  min-height: 50rem;
  padding: 16rem 0;
  background: #3b4351 url(${BgYouCanDO}) no-repeat top center;

  h3 {
    color: #fff;
    text-align: left;

    strong {
      margin: 1.6rem 0 0;
      line-height: 5.6rem;
    }
  }
  img {
    display: block;
    position: absolute;
    top: 8rem;
    right: 0;
    width: 64.6rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    min-height: unset;
    padding: 16rem 0 65rem;
    background: #010912 url(${BgYouCanDOM}) no-repeat top center / 100%;
    img {
      display: block;
      position: absolute;
      top: 26rem;
      right: 0;
      width: 76rem;
    }
  }
`;

const WinnerSection = styled.section`
  padding: 16rem 0;
  background: #fff;
  overflow: hidden;

  h3 {
    position: relative;
    margin: 0;
    padding: 0 3rem;
    font-weight: 400;
    font-size: 2.4rem;
    z-index: 0;
    text-align: left;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0rem;
      left: 0rem;
      width: 29.8rem;
      height: 17rem;
      background: url(${BgYellowRound}) no-repeat left 50% / auto 100%;
      z-index: -1;
    }
    & > strong {
      margin: 0 0 3rem;
      font-weight: 700;
      font-size: 12rem;
    }
  }

  a {
    display: block;
    position: absolute;
    top: 32rem;
    left: 3rem;
    width: 32.4rem;
    height: 6.4rem;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 6.4rem;
    background: #ffb900;
    border-radius: 5rem;
    box-sizing: border-box;
    text-align: center;
    z-index: 2;
  }

  .swiper-container {
    margin-top: 13rem;
    overflow: unset !important;
  }

  .swiper-slide {
    width: 27.6rem;
    height: 27.6rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    .swiper-container {
      margin-top: 18rem;
    }

    h3 {
      margin: 0;
      font-size: 2.4rem;
      text-align: left;
      &::before {
        top: 0;
        left: 0;
        width: 24rem;
        height: 13rem;
      }

      & > strong {
        font-size: 8rem;
      }
    }

    a {
      top: 23rem;
      height: 8rem;
      font-size: 2.4rem;
      line-height: 8rem;
    }
  }
`;

const CompanyBox = styled.div`
  display: flex;
  width: 27.6rem;
  height: 27.6rem;
  border: 1px solid #fff;
  border-radius: 100%;
  box-shadow: 0 0 20px 0px #dbdbdb8c;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: break-spaces;
  overflow: hidden;

  img {
    display: block;
    width: 8rem;
    height: 8rem;
  }
  strong {
    display: block;
    margin: 2.4rem 0 0.8rem;
    font-weight: 700;
    font-size: 2rem;
  }

  span {
    font-weight: 400;
    font-size: 1.2rem;
  }
`;

const BtnMoreView = styled.button``;

const LectureSection = styled.section`
  padding: 16rem 0;
  background-color: #f7f7fc;
`;

const H3 = styled.h3`
  font-weight: 400;
  font-size: 2.8rem;
  text-align: center;
  line-height: 1.5;

  strong {
    display: block;
    font-size: 4.8rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    strong {
      font-size: 4rem;
    }
  }
`;

const LectureList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 8rem;

  @media ${({ theme }) => theme.device.mobile} {
    justify-content: flex-start;
  }
`;

const LectureItem = styled.li`
  position: relative;
  flex: 0 1 31%;
  margin: 0 1rem 3.8rem;
  /* margin-bottom: 3.8rem; */
  background-color: #fff;
  border-radius: 2.4rem;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);

  a {
    display: block;

    &::after {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }

  &::before {
    content: "";
    position: absolute;
    right: 4.8rem;
    bottom: 5.6rem;
    width: 12rem;
    height: 12rem;
    background-image: url(${ImgLectureList});
    background-repeat: no-repeat;
    background-position: ${(props) =>
      `0 -${props.bgPosition * 12}rem` || "0 0"};
    background-size: 12rem auto;
  }

  p {
    position: absolute;
    top: 5.6rem;
    left: 4.8rem;
    font-size: 2rem;
    line-height: 1.6;

    strong {
      display: block;
      font-size: 2.8rem;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    flex: 0 1 48%;
    /* margin-bottom: 3.2rem; */
    margin: 0 0 3.2rem;

    &:nth-of-type(2n) {
      margin-left: 2rem;
    }

    &::before {
      right: 3.2rem;
      bottom: 3.2rem;
    }

    p {
      top: 4rem;
      left: 3.2rem;
      font-size: 2.4rem;
    }
  }
`;

const ConsultingBox = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  padding: 2.4rem 0;
  background-color: #000;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 3.8rem 4.2rem;
  }
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    flex: 0 0 auto;
    width: 25rem;
    font-weight: 400;
    font-size: 2.8rem;
    color: #fff;
    white-space: nowrap;
  }

  form {
    flex: 1 1 auto;
    padding-left: 6.6rem;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      input,
      button {
        flex: 1 1 30%;
        width: auto;
        height: 4.8rem;
        box-sizing: border-box;

        + input,
        + button {
          margin-left: 1.2rem;
        }
      }

      input {
        width: 100%;
        padding: 0 2.4rem;
        font-weight: 400;
        font-size: 1.8rem;
        color: #28284b;
        background-color: #fff;
      }

      button {
        font-weight: 700;
        font-size: 1.8rem;
        color: #000;
        background-color: #ffb900;
        border-radius: 0.4rem;
      }
    }
  }

  label {
    margin-top: 1.7rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    h3 {
      font-size: 2.8rem;
    }

    form {
      padding-left: 9.6rem;

      div {
        button {
          height: 8rem;
          font-size: 2.8rem;
        }
      }
    }
  }
`;

const CheckLabel = styled.label`
  display: inline-flex;
  align-items: center;

  & + label {
    margin-left: 5rem;
  }

  p {
    font-weight: 400;
    font-size: 1.6rem;
    color: #fff;
  }

  input {
    & + span {
      display: inline-block;
      position: relative;
      width: 2.6rem;
      height: 2.6rem;
      margin-right: 1rem;
      border-radius: 50%;
      background: #cfcfda;
      vertical-align: middle;
      cursor: pointer;

      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1.3rem;
        height: 1.1rem;
        background: url(${IcoCheckWhite}) 50% 50%/100% auto no-repeat;
      }
    }

    &:checked {
      & + span {
        background-color: #ffb900;

        &:before {
          background-image: url(${IcoCheckBlack});
        }
      }
    }
  }

  button {
    margin-left: 0.8rem;
    font-weight: 400;
    font-size: 1.6rem;
    color: #ffb900;
    text-decoration: underline;
  }

  @media ${({ theme }) => theme.device.mobile} {
    display: flex;

    & + label {
      margin-left: 0;
    }
  }
`;

const ConsultingMobileBox = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6;
  padding: 4.8rem 4.2rem;
  background: #000;
  transform: ${(props) =>
    props.toggle ? "translateY(0)" : "translateY(150%)"};
  transition: all 0.3s;

  > button {
    position: absolute;
    top: -5.5rem;
    left: 50%;
    width: 15rem;
    height: 5.5rem;
    margin-left: -7.5rem;
    background: #000 url(${IcoArrowDown}) 50% 50% no-repeat;
    background-size: 25% auto;

    span {
      position: absolute;
      clip: rect(0 0 0 0);
      width: 1px;
      height: 1px;
      margin: -1px;
      overflow: hidden;
    }

    &::before {
      content: "";
      position: absolute;
      left: -5.4rem;
      top: 0;
      width: 0;
      height: 0;
      border-bottom: 5.6rem solid #000;
      border-left: 5.6rem solid transparent;
    }

    &::after {
      content: "";
      position: absolute;
      right: -5.4rem;
      top: 0;
      width: 0;
      height: 0;
      border-bottom: 5.6rem solid #000;
      border-right: 5.6rem solid transparent;
    }
  }

  form {
    input {
      display: block;
      width: 100%;
      height: 8rem;
      padding: 0 2.4rem;
      background-color: #fff;
      box-sizing: border-box;

      & + input {
        margin-top: 3.2rem;
      }
    }

    label {
      margin-top: 2.4rem;

      input {
        & + span {
          width: 4rem;
          height: 4rem;
          margin-right: 1.6rem;

          &::before {
            width: 1.7rem;
            height: 1.3rem;
          }
        }
      }

      p {
        font-size: 2.4rem;
      }

      button {
        margin-left: auto;
        font-size: 2.4rem;
      }

      & + button {
        display: block;
        width: 100%;
        margin-top: 2.4rem;
        padding: 2.4rem 0 2.3rem;
        font-weight: 700;
        font-size: 2.8rem;
        color: #000;
        background-color: #ffb900;
        border-radius: 0.4rem;
      }
    }
  }
`;

const EngStyled = {
  ThemeBox,
  VisualSection,
  QuestionSection,
  TrySection,
  TryTxtBox,
  TryVodBox,
  AISection,
  AIMotionBox,
  AppSection,
  ReviewSection,
  ReviewTitleBox,
  ReviewContBox,
  YoutubeSection,
  InnerCol,
  VideoBox,
  VideoTitle,
  VideoList,
  ThumbBox,
  Picture,
  ProgramSection,
  ProgramList,
  ProgramItem,
  ResultSection,
  ResultGraphMotion,
  ResultList,
  ResultListItem,
  ResultParagraph,
  ProveSection,
  CurriculumSection,
  VodSection,
  BannerSection,
  HotRankSection,
  HotRankList,
  HotRankItem,
  PreviewSection,
  YoucandoSection,
  WinnerSection,
  CompanyBox,
  BtnMoreView,
  LectureSection,
  H3,
  LectureList,
  LectureItem,
  ConsultingBox,
  FlexBox,
  CheckLabel,
  ConsultingMobileBox,
};

export default EngStyled;
