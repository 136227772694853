const alertMessage = {
  FORM_USERNAME_REQUIRED: '이름을 입력해 주세요.',
  FORM_USERNAME_VALIDATION: '이름은 한글만 입력가능 합니다.',
  FORM_PHONE_REQUIRED: '전화번호를 입력해 주세요.',
  FORM_COUNSEL_REQUIRED: '상담 가능 시간을 선택해 주세요',
  FORM_PHONE_VALIDATION: '올바른 전화번호를 입력해 주세요.',
  FORM_PRIVACY_REQUIRED: '개인정보 수집에 동의해 주세요.',
  CONSULTATION_COMPLETE: '빠른 상담 신청이 완료되었습니다.'
}

export default alertMessage
