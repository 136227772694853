import { useEffect } from 'react'

import useAlertBeforeUnload from 'hooks/useAlertBeforeUnload'
import useLevelTestAdvancedExamStore from 'store/levelTestAdvanced/useLevelTestAdvancedExamStore'
import useLevelTestAdvancedStore from 'store/levelTestAdvanced/useLevelTestAdvancedStore'

import AnalyzingSection from './components/AnalyzingSection'
import QuestionSection from './components/QuestionSection'
import SelfCheckSection from './components/SelfCheckSection'
import styles from './LevelTestAdvancedExam.module.scss'
import { skipMode } from '../../../store/levelTestAdvanced/useLevelTestAdvancedExamStore'

export default function LevelTestAdvancedExam() {
  const [currentSection, resetExamState] = useLevelTestAdvancedExamStore((state) => [
    state.currentSection,
    state.resetState
  ])
  const resetState = useLevelTestAdvancedStore((state) => state.resetState)

  useEffect(() => {
    //TODO Skip mode 개발 완료후 삭제
    skipMode || resetState()
    return () => {
      skipMode || resetExamState()
    }
  }, [])

  useAlertBeforeUnload(
    '레벨테스트를 종료하시겠습니까?\n레벨테스트는 저장되지 않으며, 처음부터 다시 진행해야 합니다.'
  )

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        {/* currentSection에 따라 다른 섹션을 렌더링합니다. */}
        {
          {
            question: <QuestionSection />, //문항
            selfCheck: <SelfCheckSection />, //자가진단
            analyzing: <AnalyzingSection /> // 분석중
          }[currentSection]
        }
      </div>
    </div>
  )
}
