import React, { useState, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import '../../assets/styles/help.scss';
import ModalPortal from "../../ModalPortal";
import ModalMyInquiry from "../../components/modal/ModalMyInquiry";
import api from 'common/api';
import * as config from "common/config"

function ServiceCenter() {
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 });

    return isMobile ? children : null;
  };

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 });

    return isDesktop ? children : null;
  };

  const [modalopenMyInquiry, setModalopenMyInquiry] = useState(false);

  // 1:1 문의
  const handleOpenModalMyInquiry = useCallback((e) => {
    document.body.classList.add('modal-open');
    setModalopenMyInquiry(true);
  }, []);

  const handleCloseModalMyInquiry = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalopenMyInquiry(false);
  }, []);

  // const goEnglishYanadoo = (isMobile) => {
  //   api
  //     .get('/v2/old/myClass/uuid')
  //     .then((response) => {
  //       if (response.data.meta?.code === 200) {
  //         let form = document.getElementsByName("blankForm")[0];
  //         if (isMobile) {
  //           form.action = `${config.YANADOO1_MOBILE_HOST}/v2/myclass/expedition/main`;
  //         } else {
  //           form.action = `${config.YANADOO1_HOST}/v2/myclass/expedition/main`;
  //         }
  //         form.uuid.value = response.data.data.uuid;
  //         form.submit();
  //       } else {
  //         alert(config.MESSAGE['common-error']);
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  const callCenter = () => {
    const callTo =
      "tel:1600-0563";
    window.location.href = callTo;
  }
  const goPlayStore = () => {
    const playStore =
      "https://play.google.com/store/apps/details?id=kr.co.yanadoo.app2";
    window.location.href = playStore;
  }
  const goAppStore = () => {
    const appStore =
      "https://apps.apple.com/kr/app/id1539582420";
    window.location.href = appStore;
  }
  return (
    <>
      <div className="help-col">
        <div className="inner-col">
          <div className="container mypage-sub">
            <section className="content">
              <h3>고객센터</h3>
              <div className="help-info-col">
                <p>
                  <strong>1600&ndash;0563</strong>
                  <span>운영시간 9:00 &#126; 18:00</span>
                  <span>&#40;주말, 공휴일 제외&#41;</span>
                </p>
                <div className="help-btn-area">
                  <button type="button" onClick={callCenter} className="btn-consulting-call">
                    <span>전화상담 1600&ndash;0563</span>
                  </button>
                  <button type="button" className="btn-consulting-kakao" onClick={() => window.open("https://pf.kakao.com/_xeWxjMu", "_blank")}>
                    <span>카카오톡 상담</span>
                  </button>
                  <button type="button" onClick={handleOpenModalMyInquiry} className="btn-inquiry">
                    <span>1:1 문의하기</span>
                  </button>
                </div>
              </div>
              <div className="help-list-col">
                <ul>
                  <li className="faq">
                    <Link to="/service/faq/all/1">
                      <span>자주 묻는 질문</span>
                    </Link>
                  </li>
                  <li className="notice">
                    <Link to="/service/notice/1">
                      <span>공지사항</span>
                    </Link>
                  </li>
                  <li className="refund">
                    <Link to="/service/refunddetail">
                      <span>환불 규정 안내</span>
                    </Link>
                  </li>
                </ul>
                <ul>
                  {/* <li>
                    <Desktop>
                      <button type="button" className="go-mypage"
                        onClick={() => { goEnglishYanadoo(false) }}>
                        <span>1.0 장학금 바로가기</span>
                      </button>
                    </Desktop>
                    <Mobile>
                      <button type="button" className="go-mypage"
                        onClick={() => { goEnglishYanadoo(true) }}>
                        <span>1.0 장학금 바로가기</span>
                      </button>
                    </Mobile>
                  </li> */}
                  <li>
                    <Link to="/service/yanadoo/service">
                      <span>이용약관 및 개인정보 처리 방침</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="app-download-col">
                <p>앱 다운로드</p>
                <div className="download-btn-area">
                  <button type="button" className="btn-aos" onClick={goPlayStore}>
                    <i className="blind">AOS 다운로드</i>
                  </button>
                  <button type="button" className="btn-ios" onClick={goAppStore}>
                    <i className="blind">IOS 다운로드</i>
                  </button>
                </div>
              </div>
              <form name="blankForm" method="get" target="_blank">
                <input type="hidden" name="uuid"/>
              </form>
            </section>
          </div>
        </div>
      </div>
      {modalopenMyInquiry ? (
        <ModalPortal>
          <ModalMyInquiry
            btnClose
            handleCloseModal={handleCloseModalMyInquiry}
          />
        </ModalPortal>
      ) : null}
    </>
  );
}

export default ServiceCenter;
