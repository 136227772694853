import React from 'react'

import ModalPortal from 'ModalPortal'

import s from './ModalAiNadoo.module.scss'

const CAUTION_DATA = [
  {
    title: '자주 묻는 질문을 참고하여 답변해줘요.',
    content: '개인정보 확인이 필요하거나 오류 문의는 카카오톡 상담 혹은 1:1 문의로 질문해 주세요.'
  },
  {
    title: '작성하신 내용을 활용할 수 있어요.',
    content:
      '홍보, 이벤트 등으로 활용 및 서비스 개선을 위해 모든 데이터를 저장하고 있어요. 필요 시 대화 내용을 확인할 수 있습니다.'
  },
  {
    title: '개인정보를 입력하지 마세요.',
    content: '개인정보를 포함한 민감한 정보는 절대로 노출하거나 공유하지 마세요.'
  },
  {
    title: '답변 내용을 신뢰하지 마세요.',
    content:
      '최근 정보가 업데이트 되거나 정책이 변경되었다면 바로 반영되지 않아 답변이 부정확할 수 있어요.'
  },
  { content: 'AI 나두 서비스는 회사의 사정에 따라 사전고지 없이 변경 또는 종료될 수 있어요.' }
]

export default function ModalCaution({ onClose }) {
  return (
    <ModalPortal>
      <div className={s.modalAinadoo}>
        <div className={s.ainadooNotice}>
          <div className={s.noticeBox}>
            <h3 className={s.noticeTitle}>AI 나두 서비스 유의사항</h3>
            <div className={s.list}>
              {CAUTION_DATA.map(({ title, content }, index) => (
                <dl key={index}>
                  {title && (
                    <dt>
                      <span>{index + 1}.</span>
                      {title}
                    </dt>
                  )}
                  {content && <dd>{content}</dd>}
                </dl>
              ))}
            </div>
            <button type="button" onClick={onClose} className={s.btnClose}>
              확인
            </button>
          </div>
        </div>
      </div>
    </ModalPortal>
  )
}
