import { useMediaQuery } from 'react-responsive'

import { v4 as uuid } from 'uuid'

import SpecialItem from './SpecialItem'
import SwiperAiSpacial from './SwiperAiSpacial'
import s from '../aiTalk.module.css'
import { spacialAiValues } from '../config'

export default function SectionSpecial() {
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  return (
    <section className={s.special}>
      <div className={s.inner}>
        <hgroup className={s.titleGroup}>
          <h3>
            야나두 AI 친구가
            <br />
            특별한 이유
          </h3>
        </hgroup>
        <div className={s.aiList}>
          {isMobile ? (
            <SwiperAiSpacial data={spacialAiValues} />
          ) : (
            <ul>
              {spacialAiValues.map((item) => {
                return (
                  <li key={uuid()}>
                    <SpecialItem data={item} />
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </div>
    </section>
  )
}
