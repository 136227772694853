import s from '../aiTalk.module.css'
import { gifAiChatValue, gifAiAudioValue } from '../config'

export default function SectionAlways() {
  return (
    <section className={s.always}>
      <div className={s.inner}>
        <hgroup className={s.titleGroup}>
          <h3>
            언제 어디서나
            <br />
            AI 친구와 함께!
          </h3>
          <p>
            내가 원하는 장소, 상황에 맞게 AI 친구와 대화해 보세요.
            <br />
            일상 속에서 영어가 더 가깝게 느껴집니다.
          </p>
        </hgroup>
        <div className={s.gifContainer}>
          <ul>
            <li>
              <div className={`${s.gifBox} ${s.gifChat}`}>
                <img src={gifAiChatValue} alt="지하철이라 누가 들을까 걱정된다면? 텍스트로 대화가 가능해요." />
              </div>
              <p>
                지하철이라 누가 들을까 걱정된다면?
                <br />
                텍스트로 대화가 가능해요.
              </p>
            </li>
            <li>
              <div className={s.gifBox}>
                <img src={gifAiAudioValue} alt="집에서 편하게 있을때는? 음성 키보드로 대화가 가능해요." />
              </div>
              <p>
                집에서 편하게 있을때는?
                <br />
                음성 키보드로 대화가 가능해요.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}
