import React from 'react';
import { Link } from 'react-router-dom';

export default function NoContent({ icoImg, titleMsg, descMsg, linkUrl, titleUrl }) {
  return (
    <>
      <div className="no-content">
        {icoImg && (
          <span
            className="ico"
            style={{ backgroundImage: `url(${icoImg})` }}
          ></span>
        )}
        <>
          <p className="title">{titleMsg}</p>
          <p className="desc">{descMsg}</p>
          {linkUrl && (<Link to={linkUrl}>{titleUrl}</Link>)}
        </>
      </div>
    </>
  );
}
