import { v4 as uuid } from 'uuid'

import ResponsiveImage from 'components/ResponsiveImage'

import s from '../aiTalk.module.css'

export default function AccordionList({ data }) {
  return (
    <ul className={s.deviceVoice}>
      {data.map((item) => {
        return (
          <li key={uuid()}>
            <ResponsiveImage pc={item.pc} mo={item.mo} alt={item.text} maxWidth={1024} />
            <span>{item.text}</span>
          </li>
        )
      })}
    </ul>
  )
}
