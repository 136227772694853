import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import ModalShare from './ModalShare'
import ShareBand from './share/ShareBand'
import ShareCopy from './share/ShareCopy'
import ShareKakao from './share/ShareKakao'
import s from '../aiTalk.module.css'
import { metaValues } from '../config'
import { useLocation } from 'react-router'
import usePlatform from 'hooks/usePlatform'
import Kakao from 'webview/ai/components/share/Kakao'
import Band from 'webview/ai/components/share/Band'

export default function Share() {
  const location = useLocation()
  const { isPlatform } = usePlatform(location.pathname)
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  const [shareModal, setShareModal] = useState(false)
  return (
    <>
      {isMobile ? (
        <button className={s.btnShare} onClick={() => setShareModal(true)}>
          공유하기
        </button>
      ) : (
        <div className={s.share}>
          {isPlatform ? (
            <>
              <Kakao className={s.kakao} meta={metaValues} />
              <Band className={s.band} meta={metaValues} />
            </>
          ) : (
            <>
              <ShareKakao className={s.kakao} meta={metaValues} />
              <ShareBand className={s.band} meta={metaValues} />
            </>
          )}
          <ShareCopy className={s.copy} />
        </div>
      )}
      {isMobile && shareModal && (
        <ModalShare close={() => setShareModal(false)}>
          <div className={s.shareModal}>
            <button className={s.btnShareClose} onClick={() => setShareModal(false)}>
              close
            </button>
            <h3>
              AI 원어민 톡을
              <br />
              친구에게 공유해 보세요.
            </h3>
            <div className={s.share}>
              {isPlatform ? (
                <>
                  <Kakao className={s.kakao} meta={metaValues} />
                  <Band className={s.band} meta={metaValues} />
                </>
              ) : (
                <>
                  <ShareKakao className={s.kakao} meta={metaValues} />
                  <ShareBand className={s.band} meta={metaValues} />
                </>
              )}
              <ShareCopy className={s.copy} />
            </div>
          </div>
        </ModalShare>
      )}
    </>
  )
}
