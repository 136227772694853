import React from 'react';
import styles from './SuccessModal.module.css';

export default function SuccessModal({ onClose }) {
  return (
    <div className={styles.modal}>
      <p>
        <strong>영어 회화 목표에 다가가셨네요!</strong>요청하신 시간에 전화 연락 드리겠습니다.
      </p>
      <button type='button' onClick={onClose}>
        확인
      </button>
    </div>
  );
}
