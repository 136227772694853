import ClassHistory from 'pages/myclass/ClassHistory'
import HomeMyclass from 'pages/myclass/Home'
import MyClassDetailCLS from 'pages/myclass/MyClassDetailCLS'
import MyClassDetailENG from 'pages/myclass/MyClassDetailENG'
import MyClassQnaDetail from 'pages/myclass/QnaDetail'
import MyClassQna from 'pages/myclass/TabQna'
import { Myclass } from 'router/OutletStore'

import PrivateRoute from 'common/PrivateRoute'

const MyclassRoutes = [
  {
    path: 'myclass/*',
    element: (
      <PrivateRoute>
        <Myclass />
      </PrivateRoute>
    ),
    children: [
      { path: ':id', element: <HomeMyclass /> },
      { path: 'detail/eng/:studySeq', element: <MyClassDetailENG /> },
      { path: 'detail/:studySeq', element: <MyClassDetailCLS /> },
      { path: 'qna', element: <MyClassQna /> },
      { path: 'qna/detail/:currentSeq', element: <MyClassQnaDetail /> },
      { path: 'history', element: <ClassHistory /> }
    ]
  }
]

export default MyclassRoutes
