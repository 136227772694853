import { useEffect, useRef, useState } from 'react'

import ModalToast from 'pages/aiTalk/components/ModalToast'
import useChatStateStore from 'pages/aiTalk/store/useChatStateStore'

import s from './Chat.module.css'

export default function ChatInput({ handleMessages, isLoading }) {
  const [message, setMessage] = useState({ type: 'User', msg: '' })
  const [toast, setToast] = useState(false)
  const { chatState, setChatState } = useChatStateStore()
  const inputRef = useRef(null)

  const handleOnChange = (e) => {
    e.preventDefault()
    if (e.target.value.trim().length >= 200) {
      setToast(true)
    }
    setMessage({ ...message, msg: e.target.value })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (message.msg === '') return

    handleMessages(message)
    setMessage({ ...message, msg: '' })
    setChatState(false)
  }

  useEffect(() => {
    let setTimeToast
    if (toast) {
      setTimeToast = setTimeout(() => {
        setToast(false)
      }, 3000)
    }
    return () => clearTimeout(setTimeToast)
  }, [toast])

  useEffect(() => {
    if (chatState) inputRef.current.focus()
  }, [chatState])

  return (
    <>
      <form onSubmit={handleSubmit} className={s.inputContainer}>
        <input className={s.input} type="text" value={message.msg} onChange={handleOnChange} maxLength={200} placeholder={!chatState ? 'AI 친구가 답변을 작성하는 중입니다.' : '나의 답변 차례입니다.'} disabled={!chatState} ref={inputRef} />
        <button className={s.btnSend} onClick={handleSubmit}>
          <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
            <path className={message.msg.length > 0 ? s.btnActive : s.btnDisable} fillRule="evenodd" clipRule="evenodd" d="M25.9996 2.36328C39.0536 2.36328 49.636 12.9456 49.636 25.9996C49.636 39.0536 39.0536 49.636 25.9996 49.636C12.9456 49.636 2.36328 39.0536 2.36328 25.9996C2.36328 12.9456 12.9456 2.36328 25.9996 2.36328Z" fill="#DFDFEA" />
            <path d="M17.334 24.4245L26.0007 15.7578L34.6673 24.4245" stroke="white" strokeWidth="3" strokeLinecap="round" />
            <path d="M26 16.5449L26 37.0298" stroke="white" strokeWidth="3" strokeLinecap="round" />
          </svg>
        </button>
      </form>
      {toast && <ModalToast />}
    </>
  )
}
