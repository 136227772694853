import { useState } from 'react'

import AccordionList from './AccordionList'
import s from '../aiTalk.module.css'
import { androidValues, iosValues } from '../config'

export default function SectionVoice() {
  const [open, setOpen] = useState({ aos: true, ios: false })
  return (
    <section className={s.voice}>
      <div className={s.inner}>
        <hgroup className={s.titleGroup}>
          <h3>음성 키보드 설정 방법</h3>
        </hgroup>
        <div className={s.accordion}>
          <dl className={open.aos ? '' : s.hide}>
            <dt>
              안드로이드 기기일 경우
              <button onClick={() => setOpen({ ...open, aos: !open.aos })}>drop down</button>
            </dt>
            <dd>
              <AccordionList data={androidValues} />
              <ul className={s.deviceNoti}>
                <li>음성 채팅은 모바일 디바이스 환경에서 이용하실 수 있습니다.</li>
                <li>갤럭시 기종 기준으로 작성되었으며, 디바이스 환경마다 설정 방식이 상이할 수 있습니다.</li>
              </ul>
            </dd>
          </dl>
          <dl className={open.ios ? '' : s.hide}>
            <dt>
              아이폰일 경우
              <button onClick={() => setOpen({ ...open, ios: !open.ios })}>drop down</button>
            </dt>
            <dd>
              <AccordionList data={iosValues} />
              <ul className={s.deviceNoti}>
                <li>음성 채팅은 모바일 디바이스 환경에서 이용하실 수 있습니다.</li>
                <li>디바이스 환경마다 설정 방식이 상이할 수 있습니다.</li>
              </ul>
            </dd>
          </dl>
        </div>
      </div>
    </section>
  )
}
