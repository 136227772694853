import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import styled, { css } from 'styled-components'
import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'

import { serviceBannerListValues } from '../config'
import BgEnglishMO from 'assets/images/bg_m_service_english.png'
import BgUniversityMO from 'assets/images/bg_m_service_university.png'
import BgYafitMO from 'assets/images/bg_m_service_yafit_v3.png'
import BgEnglishPC from 'assets/images/bg_pc_service_english.png'
import BgUniversityPC from 'assets/images/bg_pc_service_university.png'
import BgYafitPC from 'assets/images/bg_pc_service_yafit_v3.png'
import IcoArrow from 'assets/images/ico_arrow_126x126b.png'
import IcoPlus from 'assets/images/ico_close_80x80b.png'

SwiperCore.use([Autoplay])

export default function ServiceSection({ deviceCheck }) {
  const [activeCard, setActiveCard] = useState([])

  const handleOnClick = (card) => {
    if (deviceCheck) {
      if (activeCard.includes(card)) {
        setActiveCard(activeCard.filter((e) => e !== card))
      } else {
        setActiveCard([...activeCard, card])
      }
    }
  }
  return (
    <>
      <IntroduceSection>
        <InnerCol>
          <ServiceList>
            <ServiceListItem type="English" device={deviceCheck ? 'MOBILE' : 'PC'} clickEvent={activeCard.includes('English') ? 'ACTIVE' : null}>
              <picture>
                <img src={deviceCheck ? BgEnglishMO : BgEnglishPC} alt="" />
              </picture>
              <div>
                <span>야나두 영어</span>
                <p>
                  하루 10분으로 쉽게
                  <br />
                  영어를 마스터하는 신기한 방법
                  <Link to="/promotion/bestAwards"></Link>
                </p>
                <IcoPlusEm onClick={() => handleOnClick('English')} />
                <ToggleBox>
                  <p>
                    누구에게나 어려운 영어를 가장 쉽게 배우는 방법을
                    <br />
                    야나두의 집요 연구소가 개발했습니다.
                  </p>
                  <p>
                    같은 10분이어도 야나두의 10분은 1시간의 학습 효과를 자랑합니다.
                    <br />
                    영어 잠재력이 넘치는 당신에게 딱 맞는 10분 학습법을 선택하세요!
                  </p>
                </ToggleBox>
              </div>
            </ServiceListItem>
            <ServiceListItem type="Yafit" device={deviceCheck ? 'MOBILE' : 'PC'} clickEvent={activeCard.includes('Yafit') ? 'ACTIVE' : null}>
              <picture>
                <img src={deviceCheck ? BgYafitMO : BgYafitPC} alt="" />
              </picture>
              <div>
                <span>야나두 피트니스</span>
                <p>
                  10명 중 8명은
                  <br />
                  꾸준히 운동 중<Link to="/promotion/yafitPremium"></Link>
                </p>
                <IcoPlusEm onClick={() => handleOnClick('Yafit')} />
                <ToggleBox>
                  <p>
                    수만 명의 라이더들과 전세계 랜드마크를
                    <br />
                    함께 달리며 미션마다 쏟아지는
                    <br />
                    마일리지를 쌓아보세요.
                    <br />
                    현금같은 마일리지는 최고의 동기부여가 되죠!
                  </p>
                  <p>
                    실감 나는 메타버스 세상에서
                    <br />
                    언제, 어디서든 함께 달리는 동료들과
                    <br />
                    여행하듯 즐겁게 운동해 보세요.
                  </p>
                </ToggleBox>
              </div>
            </ServiceListItem>
            <ServiceListItem type="University" device={deviceCheck ? 'MOBILE' : 'PC'} clickEvent={activeCard.includes('University') ? 'ACTIVE' : null}>
              <picture>
                <img src={deviceCheck ? BgUniversityMO : BgUniversityPC} alt="" />
              </picture>
              <div>
                <span>야나두 클래스</span>
                <p>
                  쉽게 시작하는
                  <br />
                  자기계발
                  <Link to="/store/list"></Link>
                </p>
                <IcoPlusEm onClick={() => handleOnClick('University')} />

                <ToggleBox>
                  <p>새로운 지식과 경험이 필요한 시기에 야나두가 제안하는 강의와 클래스로 실력을 업그레이드 해보세요. 삶의 새로운 도전이 펼쳐집니다.</p>
                </ToggleBox>
              </div>
            </ServiceListItem>
          </ServiceList>

          <SlideBox>
            <Swiper
              direction={'vertical'}
              slidesPerView={1}
              loop={serviceBannerListValues.length > 1 ? true : false}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false
              }}
            >
              {serviceBannerListValues?.map((list) => {
                return (
                  <SwiperSlide key={list.id}>
                    <GoodsBox bedgeTextColor={list.bedgeTextColor} bedgeBgColor={list.bedgeBgColor} bgColor={list.bgColor} textColor={list.textColor}>
                      {list.link.includes('http') ? (
                        <a href={list.link} target="_blank" rel="noreferrer">
                          {!list.bedge ? null : <span>{list.bedge}</span>}
                          <p>{list.bannerText}</p>
                        </a>
                      ) : (
                        <Link to={list.link}>
                          {!list.bedge ? null : <span>{list.bedge}</span>}
                          <p>{list.bannerText}</p>
                        </Link>
                      )}
                    </GoodsBox>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </SlideBox>
        </InnerCol>
      </IntroduceSection>
    </>
  )
}

const IntroduceSection = styled.section`
  padding: 18rem 0;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 12rem 0;
  }
`

const InnerCol = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 3rem;
  box-sizing: border-box;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0 4.2rem;
  }
`

const ServiceList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0 3rem;
    box-sizing: border-box;
  }
`

const ServiceListItem = styled.li`
  flex: 0 1 auto;
  position: relative;
  width: 48.5%;
  margin-bottom: 3rem;
  box-sizing: border-box;
  border-radius: 1.6rem;
  overflow: hidden;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 8rem 7.2rem;
    box-sizing: border-box;

    & > span {
      display: block;
      height: 4.8rem;
      opacity: 1;
      font-weight: 700;
      font-size: 4rem;
    }

    & > p {
      margin-top: 1.6rem;
      font-size: 2rem;
      line-height: 1.4;
      color: #717188;
      word-break: keep-all;

      a {
        color: #717188;
      }
    }
  }

  > picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f7f7fc;
    overflow: hidden;

    > img {
      width: 100%;
      height: 100%;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: -ms-optimize-contrast;
    }
  }

  ${(props) => {
    if (props.type === 'English') {
      return css`
        width: 100%;

        &::after {
          content: '';
          display: block;
          padding-bottom: 42.3%;
        }
      `
    } else if (props.type === 'Yafit') {
      return css`
        &::after {
          content: '';
          display: block;
          padding-bottom: 125.3%;
        }
      `
    } else if (props.type === 'University') {
      return css`
        &::after {
          content: '';
          display: block;
          padding-bottom: 125.3%;
        }
      `
    }
  }}

  ${(props) =>
    props.device === 'PC' &&
    css`
      &:hover {
        picture {
          img {
            opacity: 0;
            transform: scale(1.1);
          }
        }

        > div {
          & > span {
            height: 0;
            opacity: 0;
            transition: height 0.3s ease;
          }

          & > p {
            margin-top: 0;
            font-weight: 700;
            font-size: 4rem;
            color: #000;
            transition: margin-top 0.3s ease;

            a {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 10;
            }
          }

          & > em {
            display: none;

            + p {
              transition: opacity 0.3s ease;
            }
          }

          & > div {
            opacity: 1;
            transition: opacity 0.3s ease;
          }
        }
      }
    `}


  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin-bottom: 3.2rem;
    border-radius: 3rem;

    &::after {
      padding-bottom: 118.3%;
    }

    > div {
      padding: 7.2rem 5.6rem;

      & > span {
        height: 5.2rem;
        font-size: 4.4rem;
      }

      & > p {
        margin-top: 1.6rem;
        font-size: 2.4rem;
        transition: margin-top 0.3s ease;

        a {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 10;
        }
      }
    }

    ${(props) =>
      props.clickEvent === 'ACTIVE' &&
      css`
        picture {
          img {
            opacity: 0;
            transform: scale(1.1);
          }
        }

        > div {
          & > span {
            height: 0;
            font-size: 2.4rem;
            opacity: 0;
          }

          & > p {
            margin-top: 0;
            font-weight: 700;
            font-size: 4rem;
            color: #000;
          }

          & > em {
            display: none;
          }

          & > div {
            opacity: 1;
            transition: opacity 0.3s ease;

            p {
              font-size: 2.4rem;
              word-break: keep-all;
            }
          }
        }
      `}
  }
`

const ToggleBox = styled.div`
  position: absolute;
  right: 7.2rem;
  bottom: 8rem;
  left: 7.2rem;
  font-size: 2rem;
  line-height: 1.3;
  color: #717188;
  box-sizing: border-box;
  opacity: 0;

  & > p {
    + p {
      margin-top: 3.5rem;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    right: 5.6rem;
    bottom: 7.2rem;
    left: 5.6rem;
  }
`

const IcoPlusEm = styled.em`
  position: absolute;
  bottom: 7.2rem;
  left: 7.2rem;
  z-index: 9;
  width: 4.2rem;
  height: 4.2rem;
  background: #ffb900 url(${IcoPlus}) no-repeat 50% 50% / 2.3rem auto;
  border-radius: 50%;
  cursor: pointer;

  @media ${({ theme }) => theme.device.mobile} {
    z-index: 11;
    width: 5.6rem;
    height: 5.6rem;
    background-size: 3.7rem auto;
  }
`

const SlideBox = styled.div`
  border-radius: 1.6rem;
  overflow: hidden;

  .swiper-container {
    height: 11.4rem;
    border-radius: 1.6rem;
    overflow: hidden;
  }

  .swiper-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin: 0 3rem;
    border-radius: 3rem;

    .swiper-container {
      height: 14.8rem;
      border-radius: 3rem;
    }
  }
`

const GoodsBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  font-weight: 700;
  font-size: 2.8rem;
  background: ${(props) => (props.bgColor ? `${props.bgColor}` : '#000')};

  & > a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding: 4.3rem 7.2rem 3.7rem;
    box-sizing: border-box;
  }

  p {
    flex: 1 1 auto;
    color: ${(props) => (props.textColor ? `${props.textColor}` : '#fff')};
  }

  span {
    flex: 0 1 auto;
    min-width: 4.8rem;
    margin-right: 1.6rem;
    padding: 0.5rem;
    font-size: 1.8rem;
    color: ${(props) => (props.bedgeTextColor ? `${props.bedgeTextColor}` : '#fff')};
    background: ${(props) => (props.bedgeBgColor ? `${props.bedgeBgColor}` : '#fff')};
    text-align: center;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 7.2rem;
    bottom: 0;
    width: 4.2rem;
    height: 4.2rem;
    margin: auto;
    background: url(${IcoArrow}) no-repeat 50% 50% / 4.2rem auto;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -ms-optimize-contrast;
  }

  @media ${({ theme }) => theme.device.mobile} {
    & > a {
      padding: 4.1rem 9rem 3.9rem 3.2rem;
    }

    p {
      white-space: pre-wrap;
    }

    span {
      width: 6.2rem;
      margin-right: 2.4rem;
      font-size: 2rem;
    }

    &::after {
      right: 3.2rem;
    }
  }
`
