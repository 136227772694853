import React, { useState, useEffect } from 'react'

import ModalBack from 'pages/aiNadoo/components/ModalBack'
import ModalCaution from 'pages/aiNadoo/components/ModalCaution'
import { closeWebView } from 'webview/bridge'

import { getCookie, setCookie } from 'common/Cookie'

import s from './Webview.module.scss'

export default function AinadooTitleBar({ title }) {
  const [caution, setCaution] = useState(false) // 초기에는 모달을 보이지 않음
  const [back, setBack] = useState(false)

  useEffect(() => {
    window.pressedBackButton = function () {
      pressedBackButton()
    }

    // 컴포넌트가 마운트 될 때 쿠키를 확인하고 모달을 표시할지 결정
    if (getCookie('CautionModal') !== 'N') {
      setCaution(true) // 쿠키가 없거나 'N'이 아니면 모달을 표시
    }

    return () => {
      delete window.pressedBackButton
    }
  }, [])

  const pressedBackButton = () => {
    setBack(!back)
  }

  const handleOpenCaution = () => {
    setCaution(true) // 버튼 클릭 시 쿠키와 관계없이 모달을 열기
  }

  const handleCloseCaution = () => {
    setCookie('CautionModal', 'N', 1) // 모달 닫을 때 쿠키 설정, 1일 동안 유지
    setCaution(false) // 모달을 닫음
  }

  return (
    <>
      <hgroup className={s.titleBar}>
        <button className={s.btnBack} onClick={pressedBackButton}>
          webview out
        </button>
        <h1 className={s.title}>{title}</h1>
        <button className={s.btnInfo} onClick={handleOpenCaution}>
          Info
        </button>
      </hgroup>
      {caution && <ModalCaution onClose={handleCloseCaution} />}
      {back && <ModalBack onConfirm={() => closeWebView()} onClose={pressedBackButton} />}
    </>
  )
}
