import React, { useState, useRef, useEffect } from 'react';
import api from 'common/api';
import * as config from 'common/config';
import '../../assets/styles/modal.scss';

export default function ModalPartnershipInquiry({
  btnClose,
  handleCloseModal,
}) {
  let fileMaxSize = 2 * 1024 * 1024;
  const [selectFileName, setSelectFileName] = useState('');
  const [selectFileData, setSelectFileData] = useState('');
  const [uploadImg, setUploadImg] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [flag, setFlag] = useState(false);
  const selectRef = useRef();
  const companyNameRef = useRef();
  const managerRef = useRef();
  const numberRef = useRef();
  const emailRef = useRef();
  const agreeRef = useRef();
  const contentRef = useRef();

  const onChangeSelect = (e) => {
    const selectOptions = e.target.options;
    const selectValue = selectOptions[selectRef.current.selectedIndex].value;
    if (selectValue !== 'default') {
      setFlag(true);
    }
  };

  const handleInputFile = (e) => {
    setSelectFileName(e.target.value);
    setSelectFileData(e.target.files[0]);
  };

  const handleOnlyNumber = (e) => {
    const regex = /^[0-9\b -]{0,11}$/;
    if (regex.test(e.target.value)) {
      setInputValue(e.target.value);
    }
  };

  // 이미지 업로드
  const postImage = (e) => {
    const formData = new FormData();

    formData.append('file', selectFileData);
    formData.append('resizeType', 'DEFAULT');

    api
      .post('/v2/file/image-upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.data.meta.code !== 200) {
          if (response.data.meta.message) {
            alert(response.data.meta.message);
          } else {
            alert(config.MESSAGE['common-error']);
          }
          return;
        }

        setUploadImg(response.data.data[0]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  let delayCheck = false;

  const handleSubmitBtn = () => {
    if (uploadImg && uploadImg.fileSize > fileMaxSize) {
      alert('파일은 2M 이하만 등록 가능 합니다.');
      setSelectFileName('');
      return false;
    }

    if (!flag) {
      alert('문의 구분을 선택해주세요.');
      return false;
    }

    if (companyNameRef.current.value.trim().length == 0) {
      alert('회사명을 입력해주세요.');
      companyNameRef.current.focus();
      return false;
    }

    if (managerRef.current.value.trim().length == 0) {
      alert('담당자를 입력해주세요.');
      managerRef.current.focus();
      return false;
    }

    if (numberRef.current.value.trim().length == 0) {
      alert('연락처를 입력해주세요.');
      numberRef.current.focus();
      return false;
    }

    if (emailRef.current.value.trim().length == 0) {
      alert('이메일을 입력해주세요.');
      emailRef.current.focus();
      return false;
    }

    if (!agreeRef.current.checked) {
      alert('개인정보 수집에 동의해주세요.');
      return false;
    }

    if (delayCheck) {
      alert('잠시 후에 시도해 주세요');
    } else {
      delayCheck = true;
      postConsultationPartnership();
    }
  };

  // 제휴 문의 등록 API
  const postConsultationPartnership = () => {
    const data = {
      corpType: selectRef.current.value, // 문의구분 (1:  광고 제휴, 2: 컨텐츠 제휴, 3: 판매 제휴, 7: 기업/단체 강연)
      companyName: companyNameRef.current.value, // 회사명
      userName: managerRef.current.value, // 담당자
      phone: numberRef.current.value, // 연락처
      email: emailRef.current.value, // 이메일
      content: contentRef.current.value, // 문의내용 및 요청사항
      attachFile1: uploadImg ? uploadImg.filePath : '', // 첨부파일
      tel: '', // 연락처 (API 필수 전달값으로 빈값 적용)
      title: '', // 문의제목 (API 필수 전달값으로 빈값 적용)
    };

    console.log(data);

    api
      .post('/v2/inquiry/consultation/corporation', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message);
          delayCheck = false;
          return false;
        }
        alert('제휴 문의가 완료되었습니다.');
        delayCheck = false;
        window.location.href = window.location.href
          .replace('?partnershipInquiry', '')
          .replace('&partnershipInquiry', '');
      })
      .catch((e) => {
        console.log(e);
        delayCheck = false;
      });
  };

  useEffect(() => {
    if (selectFileData) {
      postImage();
    }
  }, [selectFileData]);

  return (
    <>
      <div className="modal-wrap">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <button
              type="button"
              className="btn-modal-close"
              onClick={handleCloseModal}
            >
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            <strong>제휴 문의</strong>
          </div>
          <div className="modal-content">
            <div className="modal-form-col inquiry">
              <dl>
                <dt className="ico-red-dot">문의 구분</dt>
                <dd>
                  <select
                    name=""
                    className="modal-select-box"
                    ref={selectRef}
                    onChange={onChangeSelect}
                    defaultValue="default"
                  >
                    <option value="default" disabled hidden>
                      선택해주세요
                    </option>
                    <option value="1">광고 제휴</option>
                    <option value="2">콘텐츠 제휴</option>
                    <option value="3">판매 제휴</option>
                    <option value="7">기업/단체 강연</option>
                  </select>
                </dd>
              </dl>
              <dl>
                <dt className="ico-red-dot">회사명</dt>
                <dd>
                  <input
                    ref={companyNameRef}
                    type="text"
                    maxLength="15"
                    placeholder="회사명을 입력해주세요."
                  />
                </dd>
              </dl>
              <dl>
                <dt className="ico-red-dot">담당자</dt>
                <dd>
                  <input
                    ref={managerRef}
                    type="text"
                    maxLength="15"
                    placeholder="담당자를 입력해주세요."
                  />
                </dd>
              </dl>
              <dl>
                <dt className="ico-red-dot">연락처</dt>
                <dd>
                  <input
                    value={inputValue}
                    onChange={handleOnlyNumber}
                    ref={numberRef}
                    type="text"
                    placeholder="연락처를 입력해주세요."
                  />
                </dd>
              </dl>
              <dl>
                <dt className="ico-red-dot">이메일</dt>
                <dd>
                  <input
                    ref={emailRef}
                    type="text"
                    maxLength="25"
                    placeholder="이메일을 입력해주세요."
                  />
                </dd>
              </dl>
              <dl>
                <dt>문의내용 및 요청사항</dt>
                <dd>
                  <textarea
                    placeholder="문의내용을 입력해주세요"
                    ref={contentRef}
                    className="basic-textarea"
                  ></textarea>
                </dd>
              </dl>
              <dl>
                <dt>첨부파일</dt>
                <dd className="flex-form">
                  <span className="txt ellipsis active">{selectFileName}</span>
                  <input
                    type="file"
                    accept="image/*"
                    id="fileUpload"
                    onChange={handleInputFile}
                  />
                  <label className="btn active" htmlFor="fileUpload">
                    파일찾기
                  </label>
                </dd>
                <dd className="noti">
                  <p>
                    첨부파일은 10MB까지 등록하실 수 있습니다. <br />
                    (등록가능 파일 : jpg,jpeg,png,bmp,gif,pdf,doc,docx,ppt,
                    pptx,xls,xlsx,hwp)
                  </p>
                </dd>
              </dl>
              <dl>
                <dt>개인정보 수집&#47;이용에 대한 동의</dt>
                <dd>
                  <div className="scroll-box">
                    야나두는 귀하의 제휴 문의에 대한 처리 및 회신을 위하여
                    귀하의 개인정보를 수집 및 처리하고자 합니다. 내용을 자세히
                    읽으신 후 동의여부를 결정하여 주시기 바랍니다.
                    <br />
                    <br />
                    1. 개인정보 수집 이용목적
                    <br />
                    &#183; 상담, 제휴 접수 및 처리
                    <br />
                    2. 개인정보 수집 항목
                    <br />
                    &#183; 회사명, 담당자명, 연락처, 이메일, 문의내용, 첨부파일
                    <br />
                    3. 개인정보 이용기간 및 보유기간
                    <br />
                    &#183; 개인정보 수집목적 달성 및 업무처리 완료 후 즉시
                    파기합니다.
                    <br />
                    4. 동의를 거부할 권리 및 동의 거부에 따른 불이익
                    <br />
                    &#183; 귀하는 개인정보 수집 및 이용에 대한 동의를 거부할
                    권리가 있으며, 동의를 거부하는 경우 상담을 받으실 수
                    없습니다.
                  </div>
                </dd>
              </dl>
              <label htmlFor="agree" className="basic-input-check">
                <input ref={agreeRef} id="agree" type="checkbox" />
                <i className="ico-checkbox"></i>
                <span>개인정보 수집 및 이용에 동의합니다.</span>
              </label>
              <div className="btn-flex-form">
                <button className="btn active" onClick={handleSubmitBtn}>
                  <span>문의하기</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
