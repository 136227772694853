import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import styled, { ThemeProvider } from 'styled-components'
import theme from 'common/theme'
import api from 'common/api'
import { scrollMove } from '../../common/common'
import BoardBanner from '../../components/board/BoardBanner'
import Pagination from '../../components/Pagination'
import BoardListReviewItem from '../../components/board/BoardListReviewItem'
import NoContent from '../../components/NoContent'
import TabBoard from './TabBoard'
import CommunityInfo from 'components/CommunityInfo'
import ReviewThumbs from 'pages/community/components/ReviewThumbs'
import SkeletonReview from './SkeletonReview'
import CardImage from 'components/CardImage'
import { SkeletonTheme } from 'react-loading-skeleton'
import ImgBannerSrr from '../../assets/images/ico_board_banner_srr_356x356.png'
import BgBannerSrr from '../../assets/images/bg_board_banner_srr.png'
import icoImg from '../../assets/images/ico_no_qna_285x285.png'

export default function Board({ type }) {
  const [cardImages, setCardImages] = useState([])
  const [imageIsLoading, setImageIsLoading] = useState(true)

  const boardInfo = {
    REVIEW: {
      title: `학습지 후기`,
      contents: `하루 한 장으로 영어가 스르르~\n스르르 학습지의 영어 소통 공간입니다.`,
      bgFullbox: `url(${BgBannerSrr}) repeat-y 50% 50% / 100% auto`,
      bgInnerBox: `url(${ImgBannerSrr}) no-repeat 100% 50% / 17.8rem auto`,
      noContentText: `아직 작성된 게시물이 없습니다.`,
      apiContentsList: `/v2/community-tab/srr-learning-review/list`,
      apiContentsCount: `/v2/community-tab/srr-learning-review/count`
    }
  }[type]
  const location = useLocation()
  const nowParams = location.search
  const paramCheck = (params, keyword) => {
    return params.split(keyword + `=`)[1]?.split('&')[0]
  }
  const [contentsData, setContentsData] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [paramItems, setParamItems] = useState(nowParams)
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false
  const [pageIndex, setPageIndex] = useState(paramCheck(nowParams, 'pageIndex') !== undefined ? Number(paramCheck(nowParams, 'pageIndex')) : 1)
  const [pageSize, setPageSize] = useState(5)
  const [totalPageSize, setTotalPageSize] = useState(1)
  const skeletonArr = new Array(5).fill('')
  const [communitySeq, setCommunitySeq] = useState(paramCheck(nowParams, 'communitySeq') !== undefined ? paramCheck(nowParams, 'communitySeq') : 0)

  const handleCurrentIdx = (idx) => {
    setPageIndex(idx)
  }

  // YYYY-MM-DD HH:mm:SS 타입을 YYYY.MM.DD로 변환.
  const returnDate = (dateItem) => {
    return dateItem.replaceAll('-', '.').split(' ')[0]
  }

  const getAttachments = (size) => {
    api
      .get(`v2/community-tab/srr-learning-review/attachments?pageIndex=1&pageSize=${size}`)
      .then((response) => {
        setCardImages(response.data.data?.attachments)
        setImageIsLoading(false)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const totalPageReturn = (itemCount) => {
    let param = []
    let paramText = ''
    if (param.length > 0) {
      param.forEach((item, idx) => (paramText += (idx === 0 ? `?` : `&`) + item.name + `=` + item.key))
    }
    api
      .get(boardInfo.apiContentsCount + paramText)
      .then((response) => {
        const data = response.data.data

        setTotalPageSize(data.totalCount)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // user 이벤트에 의한 contentsList filter 처리.
  const targetContentsList = () => {
    const paramUpdate = `?pageIndex=` + pageIndex + (communitySeq > 0 && type !== 'SRR' ? `&communitySeq=` + communitySeq : ``)

    const apiURL = boardInfo.apiContentsList + paramUpdate + `&pageSize=` + pageSize

    window.history.pushState(null, null, type === 'REVIEW' ? `review` + paramUpdate : `list` + paramUpdate)

    setParamItems(paramUpdate)
    apiGetContentsList(apiURL)
  }

  // reset contentsList.
  const resetContentsList = () => {
    setPageIndex(1)
    const apiURL = boardInfo.apiContentsList + `?pageIndex=1&pageSize=` + pageSize

    apiGetContentsList(apiURL)
  }

  // contentsList api 호출.
  const apiGetContentsList = (apiURL) => {
    api
      .get(apiURL)
      .then((response) => {
        setLoading(false)
        if (response.data.data) {
          const dataList = response.data.data.reviews
          setContentsData(dataList)
          totalPageReturn(dataList.length)
        } else {
          setContentsData([])
          totalPageReturn()
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    setLoading(true)
    scrollMove(0, 0)
    resetContentsList()
  }, [type])

  useEffect(() => {
    setLoading(true)
    targetContentsList()
    handleCurrentIdx(pageIndex)
  }, [pageIndex, communitySeq])

  useEffect(() => {
    getAttachments(10)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <BoardBanner title={boardInfo.title} contents={boardInfo.contents} bgFullbox={boardInfo.bgFullbox} bgInnerBox={boardInfo.bgInnerBox} />
      <BoardBox>
        <theme.CardInnerBox>
          {isLoading ? (
            <SkeletonTheme baseColor="#f7f7fc" highlightColor="#eeeeee">
              <BoardList>
                <ul>
                  {skeletonArr &&
                    skeletonArr.map((item, idx) => {
                      return (
                        <li key={idx}>
                          <SkeletonReview deviceCheck={deviceCheck} />
                        </li>
                      )
                    })}
                </ul>
              </BoardList>
            </SkeletonTheme>
          ) : (
            <>
              {type === 'REVIEW' && <TabBoard activeTab={'REVIEW'} />}
              <h3>이미지 후기</h3>
              <CardImage isLoading={imageIsLoading} cardImages={cardImages} deviceCheck={deviceCheck} cardImagesLength={cardImages.length} type={'SRR'} />
              <h3>후기</h3>
              <BoardList>
                <ul>
                  {contentsData && contentsData.length <= 0 ? (
                    <>
                      <li className="no-content">
                        <NoContent icoImg={icoImg} titleMsg={boardInfo.noContentText} />
                      </li>
                    </>
                  ) : (
                    contentsData.map((item, idx) => {
                      return (
                        <li key={idx}>
                          <BoardListBox>
                            <BoardListReviewItem deviceCheck={deviceCheck} link={`/community/` + type.toLowerCase() + `/detail/` + item.learningReviewSeq + paramItems} title={item.classes.name} contents={item.contents} registDate={returnDate(item.registDate)} backgroundImage={item.registUser.profileImageUrl} userName={item.registUser.nickName} satisfaction={item.satisfaction} />
                            {item.attachments ? <ReviewThumbs item={item} comment /> : undefined}
                            <CommunityInfo link={`/community/` + type.toLowerCase() + `/detail/` + item.learningReviewSeq + paramItems} data={item} type={type} isLoading={!isLoading} />
                          </BoardListBox>
                        </li>
                      )
                    })
                  )}
                </ul>
              </BoardList>
              {contentsData && contentsData.length > 0 ? <Pagination type={'IDX'} handleCurrentIdx={handleCurrentIdx} totalPages={Math.ceil(totalPageSize / pageSize)} limitPage={5} maxNum={5} minNum={0} currentSeq={pageIndex} pageIdx={pageIndex} jumpingPrevBtn={true} jumpingNextBtn={true} /> : null}
            </>
          )}
        </theme.CardInnerBox>
      </BoardBox>
    </ThemeProvider>
  )
}

const BoardBox = styled.div`
  padding: 8rem 0;
  background-color: #f7f7fc;

  h3 {
    margin-bottom: 1.4rem;
    font-weight: 700;
    font-size: 1.8rem;
    color: #28284b;
  }

  .pagination {
    padding-bottom: 6rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0 4.2rem 3.2rem;

    h3 {
      margin-bottom: 2.4rem;
      font-size: 2.8rem;
    }
  }
`

const BoardList = styled.div`
  display: block;
  width: 100%;

  .no-content {
    padding: 8rem 0 12rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    border-top: 0.1rem solid #e7e7f0;

    .no-content {
      padding: 0;
    }
  }
`

const BoardListBox = styled.div`
  position: relative;
  margin-bottom: 1.5rem;
  background-color: #fff;
  border-radius: 2rem;
  box-shadow: 0 0 2rem rgb(0 0 0 / 10%);
  overflow: hidden;

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 3rem;
    box-shadow: 0.1rem 0.1rem 2.4rem rgb(0 0 0 / 10%);
  }
`
