import classNames from 'classnames'
import useHideHeader from 'hooks/useHideHeader'

import HeaderBanner from 'components/HeaderBanner'

import MainHeader from './components/MainHeader'
import SubHeader from './components/subHeader'

import styles from './Header.module.scss'
import useHeader from './hooks/useHeader'

export default function Header() {
  const { headerRef, currentSubIndex, isMain } = useHeader()
  const { isHideHeader, headerHeight } = useHideHeader()
  const top = isHideHeader ? `-${headerHeight + 1}px` : 0

  return (
    <>
      {!isMain && <HeaderBanner />}
      <header ref={headerRef} className={classNames(styles.header)} style={{ top }}>
        {isMain && <MainHeader />}
        {!isMain && currentSubIndex !== null && <SubHeader />}
      </header>
    </>
  )
}
