export const API_HOST = process.env.REACT_APP_API_HOST
export const YANADOO_DOMAIN = process.env.REACT_APP_YANADOO_DOMAIN
export const YANADOO_HOST = process.env.REACT_APP_YANADOO_HOST
export const YANADOO1_HOST = process.env.REACT_APP_YANADOO1_HOST
export const YANADOO1_MOBILE_HOST = process.env.REACT_APP_YANADOO1_MOBILE_HOST
export const PAYMENT_HOST = process.env.REACT_APP_PAYMENT_API_HOST
export const AUTH_API_HOST = process.env.REACT_APP_AUTH_API_HOST
export const YANADOO_CDN_HOST = 'https://english.yanadoocdn.com/upload/yanadoo/new'
export const YANADOO_AI_HOST = process.env.REACT_APP_AI_HOST

export const SIGN_URL = {
  'sign-in': '/login',
  'sign-out': AUTH_API_HOST + '/auth/v2/sign-out',
  'get-token': AUTH_API_HOST + '/auth/v2/sign-in/get-token',
  'get-refresh-token': AUTH_API_HOST + '/auth/v2/sign-in/get-access-token',
  'get-user': AUTH_API_HOST + '/auth/v2/user'
}
export const LINK_URL = {
  'package-detail': '/store/detail/'
}

export const MESSAGE = {
  unauthorized: '토큰이 만료되어 자동으로 로그아웃 되었습니다. 로그인 페이지로 이동합니다.',
  'common-error': '오류가 발생했습니다.'
}
