import React from 'react';

export default function RoadMap({first, itemGroup, type, handleClickClass}){

  return (
    <>
		 	<section className="roadmap-col" style={first && {'marginTop' : '0'}}>
				<div className="inner-col">
					{first && <h3>클래스 로드맵</h3>}
					<p className="sub-title">{itemGroup.groupName}</p>
					<ul>
						{itemGroup &&
							itemGroup.mainItems &&
							itemGroup.mainItems.map((item, idx) => (
									type === 'MINDSET'
									? <li key={item.sortOrder} className={'mindset' + ` item-0${item.sortOrder}`}>
											<a href="#;" onClick={handleClickClass}>
												<p>
													<strong>{item.title}</strong>
													<span>{item.subTitle}</span>
												</p>
											</a>
										</li>
											
									: <li key={item.sortOrder}>
											<a href="#;" onClick={handleClickClass}>
												<p className="ellipsis">{item.title}</p>
											</a>
										</li>	
							))
						}
					</ul>
				</div>
			</section>
    </>
  );
};
