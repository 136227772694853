import React from 'react';
import { Link } from 'react-router-dom';
import * as main from 'common/main';
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
//style
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

SwiperCore.use([Pagination, Autoplay]);

export default function BestSlide({ itemGroup, handleClickClass }){
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false;
  const swiperSpaceBetween = deviceCheck ? 8 : 30;
  const swiperSlidesPerView = deviceCheck ? 1.2 : 4;

  return (
    <>
      <section className="slide-col">
        <div className="inner-col">
          <div className="item-content-col">
            {itemGroup && itemGroup.title && (
              <h3>{itemGroup.title}</h3>
            )}
            <div className="entrance-slide-box card">
                {itemGroup && itemGroup.mainItems &&
                  <Swiper
                    className="swiper-container"
                    spaceBetween={swiperSpaceBetween}
                    slidesPerView={swiperSlidesPerView}
                    navigation={{
                      // Both prevEl & nextEl are null at render so this does not work
                      prevEl: navigationPrevRef.current,
                      nextEl: navigationNextRef.current,
                    }}
                    onSwiper={(swiper) => {
                      // Delay execution for the refs to be defined
                      setTimeout(() => {
                        // Override prevEl & nextEl now that refs are defined
                        swiper.params.navigation.prevEl = navigationPrevRef.current
                        swiper.params.navigation.nextEl = navigationNextRef.current

                        // Re-init navigation
                        swiper.navigation.destroy()
                        swiper.navigation.init()
                        swiper.navigation.update()
                      })
                    }}
                  >
                    {itemGroup.mainItems.map((item, idx) => {
                        return (
                            <SwiperSlide
                                key={idx}
                                className="swiper-slide card-item"
                            >
                                <a href="#;" onClick={handleClickClass}>
                                    <div className="item">
                                        <span className="badge">BEST {item.sortOrder}</span>
                                        <div className="thumb" style={{ backgroundImage: `url(${item.imagePath1})`, }} />
                                        <div className="info">
                                            <p className="ellipsis">
                                              <span>
                                                {item.category === 'ENGLISH'
                                                  ? '영어'
                                                  : item.category === 'CAREER'
                                                  ? '커리어'
                                                  : item.category === 'MONEY'
                                                  ? '머니'
                                                  : item.category === 'FITNESS'
                                                  ? '피트니스'
                                                  : item.category === 'MINDSET'
                                                  ? '마인드셋'
                                                  : ''
                                                }
                                              </span>·
                                              <span>{item.teacherName}</span>
                                            </p>
                                            <p className="ellipsis"><strong>{item.title}</strong></p>
                                            <span>{item.reviewScore}</span>
                                        </div>
                                    </div>
                                </a>
                            </SwiperSlide>
                        );
                    })}
                  </Swiper>
                }
                <button type="button" className="btn-slide prev" ref={navigationPrevRef}><em className="blind">PREV</em></button>
                <button type="button" className="btn-slide next" ref={navigationNextRef}><em className="blind">NEXT</em></button>
              </div>
          </div>
        </div >
    </section >
    </>
  );
};
