import Cookies from 'universal-cookie'

import * as config from 'common/config'

const cookies = new Cookies()

export const setCookie = (name, value, date) => {
  let expireDate = new Date()
  expireDate.setDate(expireDate.getDate() + date)
  document.cookie =
    name +
    '=' +
    escape(value) +
    '; path=/; cross-site-cookie=bar; SameSite=None; Secure; expires=' +
    expireDate.toGMTString() +
    ';'

  // return cookies.set(name, value, {
  //   expires: expireDate,
  // });
}
export const setCookieByDate = (name, value, expires) => {
  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; path=/; cross-site-cookie=bar; Secure; expires=${expires}`
}

export const setCookieForSubDomain = (name, value, date) => {
  let expireDate = new Date()
  expireDate.setDate(expireDate.getDate() + date)
  document.cookie =
    name +
    '=' +
    escape(value) +
    '; path=/; domain=' +
    config.YANADOO_DOMAIN +
    '; SameSite=None; Secure; expires=' +
    expireDate.toGMTString() +
    ';'
}

export const getCookie = (name) => {
  return cookies.get(name)
}

export const removeCookie = (name) => {
  return cookies.remove(name, { path: '/', domain: config.YANADOO_DOMAIN })
}

export const allCookies = () => {
  return cookies.getAll()
}
