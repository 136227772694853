import React, { useState } from 'react'

import api from 'common/api'
import * as config from 'common/config'
import ModalCertificate from 'components/modal/ModalCertificate'
import ModalReviewWrite from 'components/modal/ModalReviewWrite'
import ModalWrap from 'components/promotion/modal/ModalWrap'
import { formatDate } from 'components/promotion/utile/utile'
import ResponsiveImage from 'components/ResponsiveImage'

import s from './packageList.module.css'

export default function ClassList({ myClassData, packageTypeCode }) {
  const HOST_CDN = 'https://english.yanadoocdn.com/upload/yanadoo/new/common'
  const [modalControl, setModalControl] = useState({
    certificate: false,
    reviewWrite: false
  })
  const [seq, setSeq] = useState(null)
  const [packageType, setPackageType] = useState(null)

  const handleModalOpen = (key, seq, packageType) => {
    setModalControl((prev) => ({ ...prev, [key]: true }))
    setSeq(seq)
    setPackageType(packageType)
  }
  const handleModalClose = () => {
    setModalControl({ certificate: false, reviewWrite: false })
  }
  const getMyreview = (
    setTextareaValue,
    setTextareaLength,
    setLearningReviewSeq,
    handleGetPoint,
    handleUpdateImage
  ) => {
    api
      .get('/v2/review/learning-review/' + seq)
      .then((response) => {
        if (response.data.meta.code === 200) {
          console.log(response.data)
          setTextareaValue(response.data.data.contents)
          setTextareaLength(response.data.data.contents.length)
          setLearningReviewSeq(response.data.data.learningReviewSeq)
          handleGetPoint(response.data.data.satisfaction)
          handleUpdateImage(response.data.data.attachments)
        }
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  // 수강 후기 등록
  const postMyreview = (classData, textareaValue, satisfaction, registerImage) => {
    const data = {
      classSeq: classData.classSeq,
      contents: textareaValue,
      satisfaction: satisfaction,
      studyGroupUserSeq: seq,
      attachments: registerImage
    }

    api
      .post('/v2/review/learning-review', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)

          return false
        }

        alert('수강 후기 등록이 완료되었습니다.')
        window.location.reload()
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  // 수강 후기 수정
  const editMyReview = (learningReviewSeq, textareaValue, satisfaction, registerImage) => {
    const data = {
      learningReviewSeq: learningReviewSeq,
      contents: textareaValue,
      satisfaction: satisfaction,
      attachments: registerImage
    }

    api
      .put('/v2/review', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)

          return false
        }

        alert('수강 후기 수정이 완료되었습니다.')
        window.location.reload()
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  return (
    <div className={s.classListContainer}>
      <hgroup>
        <p className={s.title}>{myClassData.className}</p>
        <div className={s.classInfo}>
          <p className={s.teacher}>{myClassData.teacherName}</p>
          {myClassData.subscriptionType === 'U_PLUS' || myClassData.learningAttendingStatus === 'READY' ? null : (
            <p className={s.date}>
              {formatDate(myClassData.beginDate)}~{formatDate(myClassData.closeDate)}
            </p>
          )}
        </div>
        <div className={`${s.classInfo2} ${s.disP}`}>
          {myClassData.subscriptionType !== 'U_PLUS' ? <p className={s.dday}>D-{myClassData.remainingDays}</p> : null}
          {packageTypeCode !== 'SRR' && (
            <>
              <p className={s.attend}>{myClassData.attendanceDays}</p>
              <p className={s.progress}>
                {myClassData.versionCode === 'YND_CLS' && myClassData.classCardAggregateV2
                  ? myClassData.classCardAggregateV2.achievementRate
                  : myClassData.versionCode === 'YND_ENG' && myClassData.classCardAggregateVO
                  ? myClassData.classCardAggregateVO.progressRate
                  : myClassData.achievementRate}
                %
              </p>
            </>
          )}
        </div>
      </hgroup>
      <div className={s.classBtnBox}>
        {packageTypeCode !== 'SRR' && (
          <button
            className={`${myClassData.learningAttendingStatus === 'READY' ? s.disabled : null} `}
            onClick={() => {
              handleModalOpen('certificate', myClassData.studyGroupUserSeq, myClassData.myClassType)
            }}
          >
            {myClassData.learningAttendingStatus === 'READY' ? (
              <ResponsiveImage
                pc={`${HOST_CDN}/ico_medal_disabled.png`}
                mo={`${HOST_CDN}/ico_medal_disabled.png`}
                alt="메달 아이콘"
                maxWidth={1024}
              />
            ) : (
              <ResponsiveImage
                pc={`${HOST_CDN}/ico_medal.png`}
                mo={`${HOST_CDN}/ico_medal.png`}
                alt="메달 아이콘"
                maxWidth={1024}
              />
            )}
            수강증
          </button>
        )}
        {myClassData.versionCode === 'YND_CLS' && (
          <button
            className={`${myClassData.learningAttendingStatus === 'READY' ? s.disabled : null} `}
            onClick={() => {
              handleModalOpen('reviewWrite', myClassData.studyGroupUserSeq, myClassData.myClassType)
            }}
          >
            {myClassData.learningAttendingStatus === 'READY' ? (
              <ResponsiveImage
                pc={`${HOST_CDN}/ico_pen_disabled.png`}
                mo={`${HOST_CDN}/ico_pen_disabled.png`}
                alt="메달 아이콘"
                maxWidth={1024}
              />
            ) : (
              <ResponsiveImage
                pc={`${HOST_CDN}/ico_pen.png`}
                mo={`${HOST_CDN}/ico_pen.png`}
                alt="메달 아이콘"
                maxWidth={1024}
              />
            )}
            후기작성
          </button>
        )}
      </div>
      {Object.entries(modalControl).map(([key, flag]) => {
        if (flag) {
          return (
            <ModalWrap key={key} onClose={handleModalClose}>
              {key === 'certificate' ? (
                <ModalCertificate
                  btnClose
                  studySeq={seq}
                  packageType={packageType}
                  handleCloseModalCertificate={handleModalClose}
                />
              ) : (
                <ModalReviewWrite
                  title={'수강 후기 작성'}
                  btnClose
                  studySeq={seq}
                  packageType={packageType}
                  handleCloseModalReviewWrite={handleModalClose}
                  getMyreview={getMyreview}
                  postMyreview={postMyreview}
                  editMyReview={editMyReview}
                />
              )}
            </ModalWrap>
          )
        }
      })}
    </div>
  )
}
