import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NoContent from '../../components/NoContent';
import Pagination from '../../components/Pagination';
import api from 'common/api';
import * as config from 'common/config';
import '../../assets/styles/main.scss';

export default function TabQna({ studySeq, classSeq, lectureData }) {
  const [pageIdx, setPageIdx] = useState(1);
  const [qnaListData, setQnaListData] = useState([]);
  const [selectListData, setSelectListData] = useState([]);
  const [allTotalPages, setAllTotalPages] = useState(null);
  const [selectTotalPages, setSelectTotalPages] = useState(null);
  const [isMounted, setIsMounted] = useState(true);
  const [lecture, setLecture] = useState('default');

  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ');
      return getDate[0];
    } else {
      return null;
    }
  }

  const handleCurrentIdx = (idx) => {
    setPageIdx(idx);
  };

  const handleLectureCheck = (e) => {
    const targetSeq = e.target.value;
    setLecture(targetSeq);

    if (targetSeq === 'default') {
      getQnaListData();
    } else {
      getSelectLectureData(targetSeq);
    }
  };

  const getQnaListData = () => {
    api
      .get(
        '/v2/lecture-question/list?index=' +
          pageIdx +
          '&size=10&keys=classSeq&types=equal&values=' +
          classSeq
      )
      .then((response) => {
        if (isMounted) {
          setQnaListData(response.data.data.content);
          setAllTotalPages(response.data.data.totalPages);
        }
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error']);
      });
  };

  const getSelectLectureData = (lectureSeq) => {
    api
      .get(
        '/v2/lecture-question/list?index=1&size=10&keys=classSeq&types=equal&values=' +
          classSeq +
          '&keys=lectureSeq&types=equal&values=' +
          lectureSeq
      )
      .then((response) => {
        setSelectListData(response.data.data.content);
        setSelectTotalPages(response.data.data.totalPages);
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error']);
      });
  };

  useEffect(() => {
    getQnaListData();

    return () => {
      setIsMounted(false);
    };
  }, [classSeq, isMounted, pageIdx]);

  useEffect(() => {
    setPageIdx(1);
  }, [lecture]);

  return (
    <>
      <div className="search-box">
        <select
          id="class-select"
          name="lectureTitle"
          onChange={handleLectureCheck}
        >
          <option value="default">전체</option>
          {lectureData &&
            lectureData.map((list) => (
              <option key={list.sortOrder} value={list.lectureSeq}>
                {list.title}
              </option>
            ))}
        </select>
      </div>
      <table className="table-form">
        <colgroup>
          <col width="20%" />
          <col width="" />
          <col width="18%" />
          <col width="14%" />
          <col width="12%" />
        </colgroup>
        <thead>
          <tr>
            <th>강의명</th>
            <th>문의 내용</th>
            <th>작성일</th>
            <th>닉네임</th>
            <th>답변여부</th>
          </tr>
        </thead>
        <tbody>
          {qnaListData.length > 0 || selectListData > 0 ? (
            (lecture === 'default' ? qnaListData : selectListData).map(
              (qnaItem, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <p className="ellipsis">{qnaItem.lectureTitle}</p>
                    </td>
                    <td>
                      <Link
                        to={`/myclass/qna/detail/${qnaItem.questionSeq}`}
                        state={{ studySeq: studySeq }}
                      >
                        <strong className="ellipsis">{qnaItem.contents}</strong>
                      </Link>
                    </td>
                    <td>
                      <p>{formatDate(qnaItem.registDate)}</p>
                    </td>
                    <td>
                      <p className="ellipsis">{qnaItem.registUserName}</p>
                    </td>
                    <td>
                      <p
                        className={
                          'check' +
                          (qnaItem.replyStatus === 'Y' ? ' completed' : '')
                        }
                      >
                        {qnaItem.replyStatus === 'Y' ? '답변완료' : '답변대기'}
                      </p>
                    </td>
                  </tr>
                );
              }
            )
          ) : (
            <tr>
              <td colSpan="5">
                <NoContent titleMsg={'등록된 문의가 없습니다.'} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {(lecture === 'default' && qnaListData.length > 0) ||
      (lecture !== 'default' && selectListData.length > 0) ? (
        <Pagination
          type={'IDX'}
          handleCurrentIdx={handleCurrentIdx}
          totalPages={lecture === 'default' ? allTotalPages : selectTotalPages}
          limitPage={10}
          maxNum={10}
          minNum={0}
          pageIdx={pageIdx}
        />
      ) : null}
    </>
  );
}
