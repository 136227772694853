import { YANADOO_CDN_HOST } from 'common/config'

export const YANADOO_CDN_HOST_BENEFIT = `${YANADOO_CDN_HOST}/promotion/newBenefit/`

export const introImageValues = [
  {
    pc: `${YANADOO_CDN_HOST_BENEFIT}img_intro01_pc_v2.png`,
    mo: `${YANADOO_CDN_HOST_BENEFIT}img_intro01_m_v2.png`,
    alt: 'Gift 01. PICK 3 인기강의 무료체험권 원하는 강의 3개 골라 7일간 무료체험'
  },
  {
    pc: `${YANADOO_CDN_HOST_BENEFIT}img_intro02_pc.png`,
    mo: `${YANADOO_CDN_HOST_BENEFIT}img_intro02_m.png`,
    alt: 'Gift 02. 신규회원 웰컴 할인 쿠폰 지급 가입 즉시 자동 발급'
  },
  {
    pc: `${YANADOO_CDN_HOST_BENEFIT}img_intro03_pc.png`,
    mo: `${YANADOO_CDN_HOST_BENEFIT}img_intro03_m.png`,
    alt: 'Gift 03. 여행 공략집 PDF 파일 무료 증정 해외여행 200% 즐기기'
  }
]

export const gift01ImageValues = [
  {
    pc: `${YANADOO_CDN_HOST_BENEFIT}img_freepass01_pc.png`,
    mo: `${YANADOO_CDN_HOST_BENEFIT}img_freepass01_m.png`,
    alt: '야나두 영어 스르르학습지'
  },
  {
    pc: `${YANADOO_CDN_HOST_BENEFIT}img_freepass02_pc.png`,
    mo: `${YANADOO_CDN_HOST_BENEFIT}img_freepass02_m.png`,
    alt: '야나두 영어 스르르학습지'
  },
  {
    pc: `${YANADOO_CDN_HOST_BENEFIT}img_freepass03_pc.png`,
    mo: `${YANADOO_CDN_HOST_BENEFIT}img_freepass03_m.png`,
    alt: '야나두 클래스 요가'
  }
]

export const channelImageValues = [
  {
    pc: `${YANADOO_CDN_HOST_BENEFIT}img_channel01_pc.png`,
    mo: `${YANADOO_CDN_HOST_BENEFIT}img_channel01_m.png`,
    alt: 'Step 1. 카카오톡 검색창에 야나두 검색'
  },
  {
    pc: `${YANADOO_CDN_HOST_BENEFIT}img_channel02_pc.png`,
    mo: `${YANADOO_CDN_HOST_BENEFIT}img_channel02_m.png`,
    alt: 'Step 2. 채널 리스트에서 [채널 추가] 버튼 클릭'
  },
  {
    pc: `${YANADOO_CDN_HOST_BENEFIT}img_channel03_pc.png`,
    mo: `${YANADOO_CDN_HOST_BENEFIT}img_channel03_m.png`,
    alt: 'Step 3. 카카오톡 검색창에 야나두 검색'
  },
  {
    pc: `${YANADOO_CDN_HOST_BENEFIT}img_channel04_pc.png`,
    mo: `${YANADOO_CDN_HOST_BENEFIT}img_channel04_m.png`,
    alt: 'Step 4. 홈페이지 내 쿠폰/수강권에서 [쿠폰 등록] 버튼 클릭 후 쿠폰 코드 입력'
  }
]

export const recommendLectureValues = [
  {
    thumbnail: {
      pc: `${YANADOO_CDN_HOST_BENEFIT}img_recommend01.png`,
      mo: `${YANADOO_CDN_HOST_BENEFIT}img_recommend01_m.png`
    },
    title: '10분 패키지',
    description: '왕초보도 말문 트이는 데일리 영어공부',
    hashes: ['기초회화'],
    link: '/promotion-landing/bestAwards'
  },
  {
    thumbnail: {
      pc: `${YANADOO_CDN_HOST_BENEFIT}img_recommend02.png`,
      mo: `${YANADOO_CDN_HOST_BENEFIT}img_recommend02_m.png`
    },
    title: '스르르  학습지 마스터팩',
    description: '하루 한장, 영어가 되는 10개월 코스',
    hashes: ['기초회화', '영어문장'],
    link: '/promotion-landing/SRRfullpackage.html'
  },
  {
    thumbnail: {
      pc: `${YANADOO_CDN_HOST_BENEFIT}img_recommend03.png`,
      mo: `${YANADOO_CDN_HOST_BENEFIT}img_recommend03_m.png`
    },
    title: '스르르 학습지 커리어팩',
    description: '실전 회화! 직장인에게 꼭 필요한 영어',
    hashes: ['비즈니스영어', '영어이메일'],
    link: '/store/detail/1007205'
  },
  {
    thumbnail: {
      pc: `${YANADOO_CDN_HOST_BENEFIT}img_recommend04.png`,
      mo: `${YANADOO_CDN_HOST_BENEFIT}img_recommend04_m.png`
    },
    title: '오드리 버디톡',
    description: '친구와 쓰는 리얼 생활 밀착 표현을 배워요',
    hashes: ['실전회화'],
    link: '/promotion-landing/buddytalk'
  },
  {
    thumbnail: {
      pc: `${YANADOO_CDN_HOST_BENEFIT}img_recommend05.png`,
      mo: `${YANADOO_CDN_HOST_BENEFIT}img_recommend05_m.png`
    },
    title: '원예나 앞자리 영어',
    description: '3초면 영어 문장이 툭! 5주 속성 문장 완성',
    hashes: ['기초회화', '영어문장'],
    link: '/promotion-landing/frontEnglish.html'
  },
  {
    thumbnail: {
      pc: `${YANADOO_CDN_HOST_BENEFIT}img_recommend06.png`,
      mo: `${YANADOO_CDN_HOST_BENEFIT}img_recommend06_m.png`
    },
    title: '평생수강 패키지',
    description: '영어 성공할 때까지!',
    link: '/promotion-landing/bestAwardsDBOnly'
  },
  {
    thumbnail: {
      pc: `${YANADOO_CDN_HOST_BENEFIT}img_recommend07.png`,
      mo: `${YANADOO_CDN_HOST_BENEFIT}img_recommend07_m.png`
    },
    title: (
      <>
        평생<span>수강</span> + LG gram
      </>
    ),
    description: '최신형 LG gram 15와 영어 시작하세요!',
    link: '/promotion-landing/laptopPackage.html'
  },
  {
    thumbnail: {
      pc: `${YANADOO_CDN_HOST_BENEFIT}img_recommend08.png`,
      mo: `${YANADOO_CDN_HOST_BENEFIT}img_recommend08_m.png`
    },
    title: (
      <>
        평생<span>수강</span> + Apple 풀패키지
      </>
    ),
    description: 'Apple 풀패키지와 영어 시작하세요!',
    link: '/promotion-landing/applePackage'
  },
  {
    thumbnail: {
      pc: `${YANADOO_CDN_HOST_BENEFIT}img_recommend09.png`,
      mo: `${YANADOO_CDN_HOST_BENEFIT}img_recommend09_m.png`
    },
    title: (
      <>
        평생<span>수강</span> + Macbook Air
      </>
    ),
    description: 'Macbook Air와 영어 시작하세요!',
    link: '/promotion-landing/macbookPackage.html'
  },
  {
    thumbnail: {
      pc: `${YANADOO_CDN_HOST_BENEFIT}img_recommend10.png`,
      mo: `${YANADOO_CDN_HOST_BENEFIT}img_recommend10_m.png`
    },
    title: (
      <>
        평생<span>수강</span> + iPad 10세대
      </>
    ),
    description: 'iPad 10세대와 영어 시작하세요!',
    link: '/promotion-landing/tabPackage10th.html'
  },
  {
    thumbnail: {
      pc: `${YANADOO_CDN_HOST_BENEFIT}img_recommend11.png`,
      mo: `${YANADOO_CDN_HOST_BENEFIT}img_recommend11_m.png`
    },
    title: (
      <>
        평생<span>수강</span> + 스탠바이미
      </>
    ),
    description: '최신형 스탠바이미고와 영어 시작하세요!',
    link: '/promotion-landing/standByMe'
  }
]

export const gift03ImageValues = [
  {
    pc: `${YANADOO_CDN_HOST_BENEFIT}img_pdf-card01_pc.png`,
    mo: `${YANADOO_CDN_HOST_BENEFIT}img_pdf-card01_m.png`,
    alt: '병원 | 다리가 아파요 | I have a pain on my leg '
  },
  {
    pc: `${YANADOO_CDN_HOST_BENEFIT}img_pdf-card02_pc.png`,
    mo: `${YANADOO_CDN_HOST_BENEFIT}img_pdf-card02_m.png`,
    alt: '교통편 | 버스는 어디서 타요? | Where can I take the bus? '
  },
  {
    pc: `${YANADOO_CDN_HOST_BENEFIT}img_pdf-card03_pc.png`,
    mo: `${YANADOO_CDN_HOST_BENEFIT}img_pdf-card03_m.png`,
    alt: '입국심사 | 저 관광 하러 왔어요 | I’m here for sighteeing '
  },
  {
    pc: `${YANADOO_CDN_HOST_BENEFIT}img_pdf-card04_pc.png`,
    mo: `${YANADOO_CDN_HOST_BENEFIT}img_pdf-card04_m.png`,
    alt: '쇼핑 | 면세 받을 수 있나요? | Can I get a tax refund? '
  }
]
