import React, { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import ModalPortal from 'ModalPortal'
import AuthService from 'services/authService'
import useFloatingElementStore from 'store/useFloatingElementStore'
import useModalLogin from 'store/useModalLogin'

import api from 'common/api'
import ModalCourseInquiry from 'components/modal/ModalCourseInquiry'
import ModalPartnershipInquiry from 'components/modal/ModalPartnershipInquiry'

import ModalMyInquiry from './modal/ModalMyInquiry'
import IcoAndroid from 'assets/images/ico_android_download_70x70.png'
import IcoBand from 'assets/images/ico_band_70x70.png'
import IcoBlog from 'assets/images/ico_blog_70x70.png'
import IcoFacebook from 'assets/images/ico_facebook_70x70.png'
import IcoInsta from 'assets/images/ico_insta_70x70.png'
import IcoIos from 'assets/images/ico_ios_download_70x70.png'
import IcoNaverPost from 'assets/images/ico_naver_post_70x70.png'
import Logo from 'assets/images/img_footer_logo_444x106.png'

const Footer = () => {
  const location = useLocation()
  const pramChk = location.search.split('?')
  const pram = pramChk.length > 1 ? pramChk[1].split('&')[pramChk[1].split('&').length - 1] : ''
  const param2 = pramChk.length > 1 ? pramChk[1].split('&')[0] : '' // couponDownload
  const couponCode = pram
    ? pramChk[1].split('&')[pramChk[1].split('&').length - 1].split('=')[1]
    : ''

  const [modalopenMyInquiry, setModalopenMyInquiry] = useState(false)
  const [modalopenCourseInquiry, setModalopenCourseInquiry] = useState(false)
  const [modalopenPartnershipInquiry, setModalopenPartnershipInquiry] = useState(false)
  const { setIsModalLogin } = useModalLogin()

  // 개별 쿠폰 다운로드 API
  const downLoadCoupon = (couponCode) => {
    const data = {
      couponCode: couponCode
    }
    api
      .post(`/v2/coupon-publish?couponCode=${data.couponCode}`)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)
          window.location.href = '/promotion-landing/yafitLite.html'
        } else {
          alert('쿠폰 다운로드가 완료되었습니다!')
          window.location.href = '/promotion-landing/yafitLite.html'
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (pram === 'courseInquiry') {
      handleOpenModalCourseInquiry()
    } else if (pram === 'partnershipInquiry') {
      handleOpenModalPartnershipInquiry()
    } else if (pram === 'myInquiry') {
      handleOpenModalMyInquiry()
    }
    // 야핏 쿠폰
    if (param2 === 'couponDownload') {
      downLoadCoupon(couponCode)
    }
  }, [])

  const handleChangeSelect = (e) => {
    window.open(e.target.value, '_blank')
  }

  // 단체 수강 문의
  const handleOpenModalCourseInquiry = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenCourseInquiry(true)
  }, [])

  const handleCloseModalCourseInquiry = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenCourseInquiry(false)
    let pramWord = 'courseInquiry'
    if (window.location.href.indexOf(pramWord) != -1) {
      window.location.href = window.location.href
        .replace('?' + pramWord, '')
        .replace('&' + pramWord, '')
    }
  }, [])

  // 제휴 문의
  const handleOpenModalPartnershipInquiry = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenPartnershipInquiry(true)
  }, [])

  const handleCloseModalPartnershipInquiry = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenPartnershipInquiry(false)
    let pramWord = 'partnershipInquiry'
    if (window.location.href.indexOf(pramWord) != -1) {
      window.location.href = window.location.href
        .replace('?' + pramWord, '')
        .replace('&' + pramWord, '')
    }
  }, [])

  // 1:1 문의
  const handleOpenModalMyInquiry = useCallback((e) => {
    if (!AuthService.getUserInfo()) {
      handleOpenModalLogin()
    } else {
      document.body.classList.add('modal-open')
      setModalopenMyInquiry(true)
    }
  }, [])

  const handleCloseModalMyInquiry = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenMyInquiry(false)
    let pramWord = 'myInquiry'
    if (window.location.href.indexOf(pramWord) != -1) {
      window.location.href = window.location.href
        .replace('?' + pramWord, '')
        .replace('&' + pramWord, '')
    }
  }, [])

  const handleOpenModalLogin = (e) => {
    document.body.classList.add('modal-open')
    setIsModalLogin(true)
  }

  useEffect(() => {
    if (pram === 'courseInquiry') {
      handleOpenModalCourseInquiry()
    } else if (pram === 'partnershipInquiry') {
      handleOpenModalPartnershipInquiry()
    } else if (pram === 'myInquiry') {
      handleOpenModalMyInquiry()
    }
  }, [])
  const { floatingElementHeight } = useFloatingElementStore()
  const footerMarginTop = isNaN(floatingElementHeight) ? 0 : floatingElementHeight
  return (
    <>
      <footer style={{ marginBottom: footerMarginTop }}>
        <div className="inner-col">
          <h2 className="footer-logo">
            <a href="/">
              <img src={Logo} alt="야나두 푸터 로고" />
            </a>
          </h2>
          <div className="footer-nav-menu">
            <dl>
              <dt>고객센터</dt>
              <dd>
                <a href="/service/center">
                  <span>고객센터 바로가기</span>
                </a>
              </dd>
              <dd>
                <a href="/service/faq/all/1">
                  <span>자주 묻는 질문</span>
                </a>
              </dd>
              <dd>
                <button type="button" onClick={handleOpenModalMyInquiry}>
                  <span>1:1 문의하기</span>
                </button>
              </dd>
            </dl>
            <dl>
              <dt>카카오톡 상담</dt>
              <dd>
                <a href="https://pf.kakao.com/_xeWxjMu" target="_blank" rel="noreferrer">
                  &#64;야나두 친구추가
                </a>
              </dd>
            </dl>
            <dl>
              <dt>제휴 및 단체수강 문의</dt>
              <dd>
                <a href="/promotion-landing/b2bLanding">
                  <span>B2B 및 단체수강 소개</span>
                </a>
              </dd>
              <dd>
                <button type="button" onClick={handleOpenModalPartnershipInquiry}>
                  <span>제휴문의</span>
                </button>
              </dd>
              <dd>
                <button type="button" onClick={handleOpenModalCourseInquiry}>
                  <span>단체 수강 문의</span>
                </button>
              </dd>
            </dl>
          </div>
          <div className="footer-info">
            <strong>무엇을 도와드릴까요?</strong>
            <a href="tel:1600-0563" className="tel">
              1600.0563
            </a>
            <span>평일 : 09:00 &#126; 18:00</span>
            <span>점심시간 : 12:00 &#126; 13:00</span>
          </div>
          <div className="footer-link-area">
            <div className="link-lists">
              <a
                href="#;"
                onClick={() => window.open('https://itunes.apple.com/kr/app/id1539582420')}
              >
                <img src={IcoIos} alt="아이폰 앱 다운로드" />
              </a>
              <a
                href="#;"
                onClick={() =>
                  window.open('https://play.google.com/store/apps/details?id=kr.co.yanadoo.app2')
                }
              >
                <img src={IcoAndroid} alt="안드로이드 앱 다운로드" />
              </a>
            </div>
            <div className="link-lists">
              <a
                href="https://www.instagram.com/yanadoo_official/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={IcoInsta} alt="인스타그램" />
              </a>
              <a href="https://www.facebook.com/yanadoo1/?fref=ts" target="_blank" rel="noreferrer">
                <img src={IcoFacebook} alt="페이스북" />
              </a>
              <a href="https://band.us/band/56561305" target="_blank" rel="noreferrer">
                <img src={IcoBand} alt="네이버밴드" />
              </a>
              <a href="https://blog.naver.com/yanadooblog" target="_blank" rel="noreferrer">
                <img src={IcoBlog} alt="네이버블로그" />
              </a>
              <a
                href="https://post.naver.com/my.nhn?memberNo=25566629"
                target="_blank"
                rel="noreferrer"
              >
                <img src={IcoNaverPost} alt="네이버포스트" />
              </a>
            </div>
          </div>
          <select name="footerSelect" id="footerSelect" onChange={handleChangeSelect}>
            <option value="#;" defaultValue="default">
              Related Sites
            </option>
            <option value="https://www.yanadookids.com">야나두 키즈</option>
            <option value="https://www.yanadookidsmall.com">야나두 키즈몰</option>
          </select>
          <div className="footer-utility">
            <a href="/promotion/company">회사소개</a>
            <a href="https://yanadoo.career.greetinghr.com/" target="_blank" rel="noreferrer">
              야나두 채용
            </a>
            <a href="/promotion/yanadooInfo">야나두 서비스 소개</a>
            <a href="/service/yanadoo/service">이용약관</a>
            <a href="/service/yanadoo/privacy_policy?privacyOnly=y">
              <span>개인정보처리방침</span>
            </a>
            <a href="/service/official/1">공고사항</a>
            <br />
            <br />
          </div>
          <address>
            <p>주식회사 야나두 &#40;야나두 원격평생교육원&#41;</p>
            <p>사업자등록번호 129-86-23477 &middot; 통신판매업 신고번호 제2018-서울강남-02139호</p>
            <p>대표 김정수, 김민철 &middot; 개인정보보호 책임자 이정훈</p>
            <p>주소 서울시 강남구 봉은사로 619 JBK Tower 4,5층</p>
            <p>원격 평생교육 시설신고 서울특별시 강남 서초교육지원청 &#40;제 원516호&#41;</p>
          </address>
          <p className="footer-notice">
            (주)야나두는 통신판매중개자로서 야나두 사이트의 야나두와 야핏 이용권을 제외하면 거래
            당사자가 아니며,
            <br />
            입점판매자가 등록한 상품정보 및 거래에 대해 (주)야나두는 일체 책임을 지지 않습니다.
          </p>
        </div>
      </footer>

      {modalopenMyInquiry ? (
        <ModalPortal>
          <ModalMyInquiry btnClose handleCloseModal={handleCloseModalMyInquiry} />
        </ModalPortal>
      ) : null}
      {modalopenCourseInquiry ? (
        <ModalPortal>
          <ModalCourseInquiry btnClose handleCloseModal={handleCloseModalCourseInquiry} />
        </ModalPortal>
      ) : null}
      {modalopenPartnershipInquiry ? (
        <ModalPortal>
          <ModalPartnershipInquiry btnClose handleCloseModal={handleCloseModalPartnershipInquiry} />
        </ModalPortal>
      ) : null}
    </>
  )
}

export default Footer
