import React from 'react'
export default function AiFeedIntro({ ai }) {
  return (
    <>
      {ai === 'EMILY_ROSE' && (
        <>
          러닝을 좋아하는 Emily와의 대화가 시작되었습니다.
          <br />
          AI 친구니까! 부담없이 대화를 이어나가 보세요.😉
        </>
      )}
      {ai === 'SAM_ANDERSON' && (
        <>
          탐험을 좋아하는 Sam과의 대화가 시작되었습니다.
          <br />
          익스트림 스포츠를 주제로 대화를 시작해 보세요.😉
        </>
      )}
      {ai === 'TONY_SCOTT' && (
        <>
          축구를 좋아하는 Tony와의 대화가 시작되었습니다.
          <br />
          스포츠를 주제로 자유로운 대화를 나눠보세요!😉
        </>
      )}
      {ai === 'RACHAEL_SMITH' && (
        <>
          Rachael과의 대화가 시작되었습니다.
          <br />
          드라마를 주제로 자유로운 대화를 나눠보세요! 😉
        </>
      )}
      {ai === 'LILY_THOMPSON' && (
        <>
          Lily와의 대화가 시작되었습니다.
          <br />
          요리를 주제로 자유로운 대화를 나눠보세요! 😉
        </>
      )}
      {ai === 'LAURA_PARKER' && (
        <>
          Laura와의 대화가 시작되었습니다.
          <br />
          패션을 주제로 자유로운 대화를 나눠보세요! 😉
        </>
      )}
      {ai === 'TOM_KIM' && (
        <>
          Tom와의 대화가 시작되었습니다.
          <br />
          관심사로 대화를 나누며 기초 문법 퀴즈를 풀어보세요! 😉
        </>
      )}
      {ai === 'JENNY_JUNG' && (
        <>
          Jenny와의 대화가 시작되었습니다.
          <br />
          관심사로 대화를 나누며 신조어와 슬랭을 배워보세요! 😉
        </>
      )}
    </>
  )
}
