import React from 'react';
import NoContent from '../../components/NoContent';
import icoImg from '../../assets/images/ico_time_196x196.png';

export default function Expiration() {
  return (
    <div className="class-empty expiration">
      <NoContent
        icoImg={icoImg}
        titleMsg={'인증이 완료되었거나, 유효시간이 지났습니다.'}
        descMsg={'인증을 다시 시도하려면 고객센터로 문의해주시기 바랍니다.'}
      />
      <button className="btn positive">
        <span className="call">call</span>1600 - 0563
      </button>
    </div>
  );
}
