import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import styled, { ThemeProvider } from 'styled-components'

import api from 'common/api'
import * as config from 'common/config'
import theme from 'common/theme'
import MobileHeader from 'components/common/MobileHeader'
import Lnb from 'components/Lnb'

import icoCheck216x216 from 'assets/images/ico_check_216x216w.png'
import icoCheck_38x30b from 'assets/images/ico_check_38x30b.png'
import icoDeviceDesktop from 'assets/images/ico_device_desktop_65x65.png'
import icoDevicePhone from 'assets/images/ico_device_phone_65x65.png'
import icoDeviceTablet from 'assets/images/ico_device_tablet_65x65.png'

export default function Device() {
  const navigate = useNavigate()
  const [userInfo, setUserInfo] = useState([])
  const [deviceList, setDeviceList] = useState([])
  const [itemChk, setItemChk] = useState([])
  const [resetChk, setResetChk] = useState([])

  // 유저정보 가져오기
  const getUserInfo = () => {
    api
      .get(config.AUTH_API_HOST + '/auth/v2/user')
      .then((response) => {
        setUserInfo(response.data.data)
        // console.log(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleCheckItem = (checked, seq, idx) => {
    if (checked) {
      setItemChk([...itemChk, idx])
      setResetChk([...resetChk, seq])
    } else {
      setItemChk(itemChk.filter((e) => e !== idx))
      setResetChk(resetChk.filter((e) => e !== seq))
    }
  }

  const getDeviceList = () => {
    api
      .get(`/v1/deviceManagement`)
      .then((response) => {
        setDeviceList(response.data.data)
        // console.log(response.data.data);
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const putDevice = () => {
    const data =
      resetChk &&
      resetChk.map((item) => {
        return {
          seq: item
        }
      })

    console.log(data)
    api
      .put(`/v1/deviceManagement/application/reset`, data)
      .then((response) => {
        // console.log(response);
        alert('기기 초기화 신청 접수 완료되었습니다.')
        window.location.reload()
      })
      .catch((e) => {
        console.log('잠시 후 다시 시도해 주세요.')
      })
  }

  function getDeviceCnt() {
    let dData = deviceList
    if (dData.length < 5) {
      Array.prototype.push.apply(dData, new Array(5 - dData.length))
    }
    setDeviceList(dData)
    // console.log(dData)
  }

  useEffect(() => {
    getUserInfo()
    getDeviceList()
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <div className="help-col device">
        <div className="inner-col">
          <div className="container mypage-sub">
            <MobileHeader title="학습 기기 관리" onClick={() => (document.referrer == '' ? navigate('/mypage/1') : navigate(-1))}/>
            <Lnb />
            <section className="content">
              <h3>학습 기기 관리</h3>
              <DeviceSection>
                <p className="device-title">
                  <span>
                    {userInfo && userInfo.nickName}({userInfo.userId})
                  </span>
                  님의 계정에 아래 기기
                  <span>{deviceList.filter((e) => e !== undefined).length}건</span>이 등록되어있습니다.
                </p>
                <div className="device-box">
                  <ul className="device-list">
                    {Array.from(Array(5), (e, idx) => {
                      return (
                        <li key={idx}>
                          <p>기기가 등록되지 않았습니다.</p>
                        </li>
                      )
                    })}
                  </ul>
                  <ul className="device-list">
                    {deviceList &&
                      deviceList.map((item, idx) => {
                        return (
                          <li key={idx} className={'active ' + (item.mobileYn === '1' || item.mobileYn === '2' || item.mobileYn === '3' ? 'phone' : 'desktop')}>
                            <label htmlFor={'check' + idx} className={item.reqYn === 1 ? 'progress' : ''}>
                              <input type="checkbox" id={'check' + idx} checked={itemChk.includes(idx) ? true : false} onChange={(e) => handleCheckItem(e.target.checked, item.seq, idx)} disabled={item.reqYn === 1 ? 'disabled' : ''} />
                              <span></span>
                              <dl>
                                <dt>
                                  <strong>{item.deviceName}</strong>
                                </dt>
                                <dd>
                                  <span>{item.registDate.substring(0, 10)}</span>
                                </dd>
                              </dl>
                            </label>
                          </li>
                        )
                      })}
                  </ul>
                </div>
                <div className="device-notice-box">
                  <p>기기관리 유의사항</p>
                  <ul>
                    <li>야나두 강의는 한 ID당 기기 5대(PC, Mobile, App, Pad)까지 수강이 가능합니다.</li>
                    <li>기기 초기화 신청 시 사유 문의를 위해 고객센터(1600-0563)연락 받으실 수 있습니다.</li>
                    <li>초기화 요청 시, 선택한 기기에 "처리중”이라는 표시를 확인 해주시기 바랍니다.</li>
                  </ul>
                </div>
                <button type="button" className={'btn-reset ' + (itemChk.length > 0 ? 'active' : '')} onClick={putDevice}>
                  <span>초기화 신청</span>
                </button>
              </DeviceSection>
            </section>
          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}

const DeviceSection = styled.section`
  padding-top: 3.8rem;

  .device-title {
    font-size: 1.4rem;
    color: #9999aa;

    span {
      color: #000;
    }

    span + span {
      display: inline-block;
      margin-left: 0.25rem;
    }
  }
  .device-box {
    position: relative;

    ul + ul {
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      margin: 0;
      box-sizing: border-box;
      box-shadow: 0 0 1rem #eee;
    }
  }

  .device-list {
    margin-top: 1.5rem;

    li {
      position: relative;
      min-height: 9.6rem;
      font-size: 1.6rem;
      color: #a0a0b6;
      border: 0.2rem dashed #cfcfda;
      border-radius: 0.6rem;
      box-sizing: border-box;
      text-align: center;

      + li {
        margin-top: 1.5rem;
      }

      &.active {
        border: none;
      }

      p {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 1.8rem;
        margin: auto;
      }

      label {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        height: 9.6rem;
        padding: 0 3.5rem 0 3.6rem;
        background-color: #fff;
        border-radius: 0.6rem;
        box-sizing: border-box;
        cursor: pointer;

        input[type='checkbox'] {
          display: none;

          + span {
            -webkit-box-flex: 0;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            width: 2.4rem;
            height: 2.4rem;
            margin-right: 1.8rem;
            background-color: #ececf6;
            background-image: url(${icoCheck216x216});
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: auto 1rem;
            border: 0.1rem solid #ececf6;
            border-radius: 50%;
          }

          &:checked {
            + span {
              background-color: #ffb900;
              background-image: url(${icoCheck_38x30b});
              border-color: #ffb900;
            }
          }

          &:disabled {
            + span {
              background-color: #fff;
              border-color: #ececf6;
            }
          }
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 2.2rem;
          bottom: 0;
          width: 6.5rem;
          height: 6.5rem;
          margin: auto;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: 100%;
        }

        &.progress {
          dt {
            &::before {
              content: '처리중';
              color: #ff5647;
            }
          }
        }
      }

      &.phone {
        label {
          &::before {
            background-image: url(${icoDevicePhone});
          }
        }
      }

      &.tablet {
        label {
          &::before {
            background-image: url(${icoDeviceTablet});
          }
        }
      }

      &.desktop {
        label {
          &::before {
            background-image: url(${icoDeviceDesktop});
          }
        }
      }

      dl {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        font-weight: 700;
        text-align: left;

        dt {
          margin-top: 0.4rem;
          font-size: 1.4rem;
          color: #000;

          &::before {
            content: '정상';
            display: block;
            margin-bottom: 0.3rem;
            font-weight: 700;
            font-size: 1.2rem;
            color: #a0a0b6;
          }
        }

        dd {
          margin-top: 0.7rem;
          font-weight: 400;
          font-size: 1.1rem;
        }
      }
    }
  }

  .device-notice-box {
    margin-top: 3.7rem;
    color: #717188;

    p {
      font-weight: 700;
      font-size: 1.6rem;
    }

    ul {
      margin-top: 1.5rem;

      li {
        font-size: 1.2rem;

        &::before {
          content: '-';
          display: inline-block;
          margin-right: 0.2rem;
          line-height: 1.8;
        }
      }
    }
  }

  .btn-reset {
    display: block;
    width: 27rem;
    margin: 5rem auto 0;
    padding: 2.2rem 0;
    font-weight: 400;
    font-size: 2rem;
    background-color: #ffb900;
    border-radius: 0.3rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    .device-title {
      display: none;
    }

    .device-list {
      padding: 0 3.1rem;

      li {
        min-height: 20rem;
        border-width: 0.3rem;
        border-radius: 1.4rem;

        p {
          height: 3.2rem;
          font-size: 2.7rem;
        }

        label {
          height: 20rem;
          padding: 0 3.8rem;
          border-radius: 1.4rem;

          input[type='checkbox'] {
            + span {
              display: block;
              width: 5rem;
              height: 5rem;
              margin-right: 3rem;
              background-size: auto 2.1rem;
              border-width: 0.4rem;
            }
          }

          &::before {
            right: 4.4rem;
            width: 12.5rem;
            height: 12.5rem;
          }
        }

        dl {
          dt {
            font-size: 2.9rem;

            &::before {
              margin-bottom: 0.8rem;
              font-size: 2.5rem;
            }
          }

          dd {
            margin-top: 1.4rem;
            font-size: 2.3rem;
          }
        }

        + li {
          margin-top: 2rem;
        }
      }
    }

    .device-notice-box {
      margin-top: 11.7rem;
      padding: 0 5.2rem;

      p {
        font-size: 2.9rem;
      }

      ul {
        li {
          font-size: 2.5rem;
        }
      }
    }

    .btn-reset {
      width: 88%;
      padding: 3.4rem 0;
      font-size: 2.7rem;
    }
  }
`
