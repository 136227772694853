import React, { useState, useRef, useCallback, useEffect } from 'react'
import DaumPostCode from 'react-daum-postcode'
import Skeleton from 'react-loading-skeleton'
import { useMediaQuery } from 'react-responsive'
import { useParams, useNavigate, useLocation } from 'react-router-dom'

import useInterval from 'hooks/useInterval'
import ModalPortal from 'ModalPortal'
import queryString from 'query-string'
import styled, { ThemeProvider } from 'styled-components'

import api from 'common/api'
import * as config from 'common/config'
import theme from 'common/theme'
import AgreeOrder from 'components/cart/AgreeOrder'
import PaymentType from 'components/cart/PaymentType'
import TotalPriceInfo from 'components/cart/TotalPriceInfo'
import UserInfo from 'components/cart/UserInfo'
import Loading from 'components/common/Loading'
import ModalCouponDownload from 'components/modal/ModalCouponDownload'
import ModalCouponDuplicate from 'components/modal/ModalCouponDuplicate'

import AddBookItem from './AddBookItem'
import ico_button_download from 'assets/images/ico_button_download.png'
import ico_check_active_96x96 from 'assets/images/ico_check_active_96x96.png'

import 'assets/styles/cart.scss'

const MINIMUM_PRICE_FOR_FREE_DELIVERY = 50_000 // 배송비 무료를 위한 최소 금액.
const DELIVERY_FEE = 3_500 // 배송비.
const DELIVERY_FEE_TARGET_ITEMS = ['REAL']
const MINIMUM_PRICE = 50_000 // 할부 가능 기준 금액.
const MINIMUM_MONTH = 1 // 기본 할부 월.

let entrancePackageSeq = -1
let hasEntrancePackage = false
let orderInfo = {}
let cartItems = []
let cartMonth = ''
let installmentMonth = ''

export default function DetailCart() {
  const [paymentSrc, setPaymentSrc] = useState(null)
  const { search } = useLocation()
  const { orderNo } = useParams()
  const navigate = useNavigate()
  const certifiedCodeTime = useRef(300)
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false
  const isDesktop = useMediaQuery({ minWidth: 1025 })
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  const [userData, setUserData] = useState(null)
  const [userDataStatus, setUserDataStatus] = useState(false) // 모바일 - 유저 주소 정보가 있을 경우 true
  const [isEntrancedUser, setIsEntrancedUser] = useState(false) // 입학 여부
  const [isIdentityCertification, setIsIdentityCertification] = useState(false) // 실명 인증 여부
  const [isValidForm, setIsValidForm] = useState(false) // 폼 유효성 체크
  const popCouponDataList = useRef([]) // 각 상품별 쿠폰 전체 목록.
  const [orderMasters, setOrderMasters] = useState([]) // 주문 상품
  const [totalOrgPrice, setTotalOrgPrice] = useState(0) // 총 주문 금액
  const [totalSalePrice, setTotalSalePrice] = useState(0) // 할인 적용 금액
  const [applyCouponData, setApplyCouponData] = useState(0) // 할인 적용할 쿠폰 데이터.
  const [totalCouponDiscountPrice, setTotalCouponDiscountPrice] = useState(0) // 총 쿠폰 할인 금액
  const [totalDiscountPrice, setTotalDiscountPrice] = useState(0) // 총 할인 받은 금액 (할인 적용 금액 + 총 쿠폰 할인 금액)
  const [totalPrice, setTotalPrice] = useState(0)
  const [deliveryFee, setDeliveryFee] = useState(0)
  const [hasDeliveryProduct, setHasDeliveryProduct] = useState(false)
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [couponApplyDisplay, setCouponApplyDisplay] = useState(false)
  const [couponPriceLimit, setCouponPriceLimit] = useState(0)
  const [orderListSeq, setOrderListSeq] = useState([]) // 추가 교재 관련 구매 정보, 최종 결제 시 params 값에 전달 필요.
  const [addBookTotalPrice, setAddBookTotalPrice] = useState(0) // 추가 교재 구매 금액 합계.
  const [addBookOrderCheck, setAddBookOrderCheck] = useState(false) // 추가 교재 체크 여부.
  const [isAddBookReady, setAddBookReady] = useState(false)
  const [deliveryMessage, setDeliveryMessage] = useState('') // 배송 요청사항.
  const [isTotalPriceType, setTotalPriceType] = useState(true) // ENGLISH:"영어, YA_CLASS:"야클" true / YA_FITNESS:"야핏" false. 매출 인식 설정값에 따라 보여지는 영역 분기.
  const [isProductFixed, setProductFixed] = useState(false) // 결제 시도된 주문번호인지 체크.
  const [isLoadingOrderList, setLoadingOrderList] = useState(true) // 주문 상품 로딩 여부.

  // 쿠폰 관련 상태값
  const [couponListData, setCouponListData] = useState([]) // 사용 가능 쿠폰 리스트
  const [couponDownloadData, setCouponDownloadData] = useState([]) // 다운로드 받을 수 있는 쿠폰
  const [availableCoupon, setAvailableCoupon] = useState([]) // 특정 패키지 별 사용가능한 쿠폰
  const [limitedCoupon, setLimitedCoupon] = useState([]) // 사용 제한 있는 쿠폰
  const [couponSelectIdx, setCouponSelectIdx] = useState(0) // 쿠폰 선택 상품 index 상태값;
  const [allCoupon, setAllCoupon] = useState([]) // 사용 제한 없는 전체 쿠폰
  const [modalopen2, setModalopen2] = useState(false)
  const [modalopen3, setModalopen3] = useState(false) // 쿠폰적용 모달 상태값
  const [niceModalOpen, setNiceModalOpen] = useState(false)
  const [niceStatus, setNiceStatus] = useState(false)
  const [requestNum, setRequestNum] = useState('')
  const [inputValue, setInputValue] = useState({
    name: '',
    cellPhone: '',
    zipcode: '',
    defaultAddress: '',
    detailAddress: '',
    message: '',
    paymentType: '',
    paymentWay: ''
  })

  const [isPaymentType, setPaymentType] = useState([]) // 결제타입.
  const paymentTypeRef = useRef(null)
  const deliveryInfoRef = useRef(null)
  const deliveryMessageList = [
    { content: '배송 시 요청사항을 선택해주세요', value: '' },
    { content: '문 앞에 놓아주세요', value: '문 앞에 놓아주세요' },
    { content: '경비실에 맡겨주세요', value: '경비실에 맡겨주세요' },
    { content: '택배함에 넣어주세요', value: '택배함에 넣어주세요' },
    { content: '직접 입력', value: 'custom' }
  ]

  // 결제창 닫기 새로고침 state
  const [isOpen, setIsOpen] = useState(false)
  const [closeType, setCloseType] = useState(false)

  const scrollMove = (refItem) => {
    const topHeight = refItem.current.offsetTop - (deviceCheck ? 100 : 40)
    window.scrollTo({ left: 0, top: topHeight, behavior: 'smooth' })
  }

  const addComma = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const getSalePrice = (packageItem, productItem) => {
    return hasEntrancePackage || isEntrancedUser
      ? productItem.membershipPrice
      : packageItem.saleTypeCode === 'EARLY_BIRD'
      ? productItem.earlybirdPrice || 0
      : productItem.salePrice
  }

  const handleInputValue = (e) => {
    let { name, value } = e.target
    setInputValue({ ...inputValue, [name]: value })
  }

  const toggleFold = (e) => {
    if (e.target.parentElement.parentElement.classList.value.indexOf('fold') !== -1) {
      e.target.parentElement.parentElement.classList.remove('fold')
      e.target.innerHTML = '접기'
    } else {
      e.target.parentElement.parentElement.classList.add('fold')
      e.target.innerHTML = '펼치기'
    }
  }

  const handleOpenModal2 = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopen2(true)
  }, [])

  const handleCloseModal2 = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopen2(false)
  }, [])

  const handleOpenModal3 = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopen3(true)
  }, [])

  const handleCloseModal3 = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopen3(false)
  }, [])

  const [isChecked, setIsChecked] = useState({
    defaultData: true,
    updateData: false,
    agreeOrder: false,
    couponChk: false
  })

  const handleCheckedItem = (e) => {
    let { id } = e.target
    setIsChecked({ ...isChecked, [id]: !isChecked[id] })
  }

  const getUserData = () => {
    api
      .get(config.AUTH_API_HOST + '/auth/v2/user')
      .then((response) => {
        const userInfo = response.data.data
        setUserData(userInfo)
        setInputValue({
          ...inputValue,
          name: userInfo.username,
          cellPhone: userInfo.cellPhone,
          zipcode: userInfo.address.zipcode ? userInfo.address.zipcode : '',
          defaultAddress: userInfo.address.address ? userInfo.address.address : '',
          detailAddress: userInfo.address.addressDetail ? userInfo.address.addressDetail : ''
        })
        setIsEntrancedUser(
          userInfo && userInfo.entrance && userInfo.entrance.entranceStatus === 'ENTRANCE'
            ? true
            : false
        )
        setIsIdentityCertification(userInfo && userInfo.isIdentityCertification ? true : false)

        if (userInfo.address.zipcode) {
          setUserDataStatus(true)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getOrderInfo = (orderCode) => {
    api
      .get(`/payment/v2/order/${orderCode}`)
      .then((response) => {
        orderInfo = response.data.data.orderInfo
        cartMonth = response.data.data.learningMonth
        cartItems = response.data.data.cartList.cartItems
        entrancePackageSeq = response.data.data.entrancePackageSeq || -1
        installmentMonth = response.data.data.installmentMonth

        makeOrderMasters()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const makeOrderMasters = () => {
    let orderMasterItems = orderInfo.order_master_list
    let orderDetailIndex = 0
    let orderListSeqGroup = []

    for (let orderMaster of orderMasterItems) {
      orderMaster.discountPrice = 0
      orderMaster.orderDetails = []
      for (; orderDetailIndex < orderInfo.order_detail.length; orderDetailIndex++) {
        let orderDetail = orderInfo.order_detail[orderDetailIndex]
        orderDetail.discountPrice = 0
        orderDetail.paymentPrice = 0
        if (orderMaster.orderSeq === orderDetail.orderSeq) {
          orderMaster.orderDetails.push(orderDetail)
        } else {
          break
        }
      }

      // 상품 + 추가교재 seq 정보를 orderListSeqGroup 값에 셋팅.
      // 가능 결제 타입 지정.
      cartItems.forEach((item) => {
        // 가능 결제 타입 지정.
        if (!!item.pgName && item.pgName === 'yanadoo005') {
          // 바우처몰 : 신용카드.
          setPaymentType(['CARD'])
        } else {
          // 카드결제, 네이버페이, 토스, 카카오페이, 계좌이체, 무통장입금.
          setPaymentType(['CARD', 'NPAY', 'TOSS', 'ONLYKAKAOPAY', 'ACCOUNT', 'REMITTANCE'])
        }
        orderListSeqGroup.push({
          standardPackage: item.packageSeq,
          additionalPackageList: []
        })
        if (item.salesRecognition === 'YA_FITNESS') setTotalPriceType(false) // ENGLISH:"영어, YA_CLASS:"야클" true / YA_FITNESS:"야핏" false.
      })

      // 썸네일 세팅
      for (let packageItem of cartItems) {
        if (orderMaster.packageSeq === packageItem.packageSeq) {
          orderMaster.thumbnailPath = packageItem.thumbnailPath
          break
        }
      }

      if (!hasEntrancePackage && orderMaster.packageSeq === entrancePackageSeq) {
        hasEntrancePackage = true
      }
    }
    setOrderMasters(orderMasterItems)
    setOrderListSeq(orderListSeqGroup)
    setAddBookReady(true)
    setLoadingOrderList(false)
    gaDataSet()
  }

  const gaDataSet = () => {
    const productCategory = document.querySelectorAll('.ga-product-category')
    const productName = document.querySelectorAll('.ga-product-name')
    const productPrice = document.querySelectorAll('.ga-product-price')
    const productId = document.querySelectorAll('.ga-product-id')
    let itemList = []
    let totalPrice = 0

    if (window['checkURL'] !== window.location.href) {
      productName.forEach((item, idx) => {
        if (productCategory[idx] && productPrice[idx]) {
          itemList.push({
            productCategory: productCategory[idx].innerHTML,
            productName: item.innerHTML,
            productPrice: Number(productPrice[idx].innerHTML.replace(',', '').replace(',', '')),
            productId: productId[idx].id
          })
          totalPrice += Number(productPrice[idx].innerHTML.replace(',', '').replace(',', ''))
        }
      })
      window['checkURL'] = window.location.href
      window['dataChkPaymentDetail'](itemList, totalPrice)
    }
  }

  // 금액 계산. (쿠폰 선택 상태가 변경되었을때, 결제정보가 변경되었을때 실행됨)
  const calcTotalPrice = () => {
    let orgPriceSum = 0
    let salePriceSum = 0
    let couponDiscountSum = 0 // 쿠폰 할인가의 총 합계.
    let deliveryItemCount = 0

    orderMasters.map((packageItem) => {
      packageItem.totalOrgPrice = 0
      packageItem.totalSalePrice = 0
      packageItem.deliveryItemCount = 0

      packageItem.orderDetails.map((productItem) => {
        packageItem.totalOrgPrice += productItem.originalPrice
        packageItem.totalSalePrice += getSalePrice(packageItem, productItem)

        if (DELIVERY_FEE_TARGET_ITEMS.indexOf(productItem.productTypeCode) > -1) {
          packageItem.deliveryItemCount++
        }
      })

      orgPriceSum += packageItem.originalPrice
      salePriceSum += packageItem.totalSalePrice
      deliveryItemCount += packageItem.deliveryItemCount
    })

    if (salePriceSum <= 100) {
      // 네이버페이, 토스.
      setPaymentType(['NPAY', 'TOSS'])
    }

    // 선택한 쿠폰 금액을 couponDiscountSum 값에 더함.
    popCouponDataList.current.forEach((item) => {
      item.couponList.forEach((detailItem) => {
        if (detailItem.couponChecked) couponDiscountSum += Number(detailItem.discountPrice)
      })
    })

    // 배송비 체크
    const totalDeliveryFee =
      deliveryItemCount > 0 &&
      orgPriceSum > 0 &&
      salePriceSum + couponDiscountSum < MINIMUM_PRICE_FOR_FREE_DELIVERY
        ? DELIVERY_FEE
        : 0

    setTotalOrgPrice(isTotalPriceType ? salePriceSum : orgPriceSum) // 매출인식 설정값에 따라 총 주문금액 표기값 분기.
    setDeliveryFee(totalDeliveryFee)
    if (deliveryItemCount > 0) {
      setHasDeliveryProduct((hasEntrancePackage) => true)
    }
    setTotalCouponDiscountPrice(couponDiscountSum)
    setTotalDiscountPrice(orgPriceSum - salePriceSum + couponDiscountSum)
    setTotalSalePrice(salePriceSum)
    setTotalPrice(salePriceSum + totalDeliveryFee - couponDiscountSum)
  }

  // 1. 적용쿠폰 리스트 셋팅 함수.
  // ㄴ 페이지에 진입 후, API에서 쿠폰 데이터 호출이 완료되었을때, popCouponDataList 값에 적용 가능한 쿠폰 목록을 푸시합니다.
  // ㄴ 쿠폰 데이터는 추가로, couponDisplay:true (쿠폰 노출 여부), couponChecked:false(쿠폰 적용 여부) 를 기본값으로 갖습니다.
  const setCouponList = () => {
    let targetCoupon = []
    let couponJoinCheck = true

    // 결제 상품 반복.
    orderMasters.forEach((item, idx) => {
      targetCoupon = [] // 쿠폰 적용 리스트 배열 초기화.
      // item.packageSeq 기준으로 매칭되는 쿠폰이 있는지 체크.
      if (checkCouponBoolean(availableCoupon.concat(couponDownloadData), item.packageSeq)) {
        setIsChecked({ ...isChecked, couponChk: true })
        setCouponApplyDisplay(true)
      }
      // 적용 가능한 쿠폰 목록을 반복.
      availableCoupon.concat(allCoupon).forEach((detailItem, detailIdx) => {
        couponJoinCheck = true
        // 사용되지 않은 쿠폰만 체크.
        if (detailItem.couponUseYn === 'N') {
          detailItem.couponToPackageInfoDTOList.forEach((subItem) => {
            if (subItem.packageSeq === item.packageSeq && couponJoinCheck) {
              targetCoupon.push({
                ...detailItem,
                couponChecked: false,
                couponDisplay: true
              }) // 해당 상품의 전용 쿠폰인 경우 couponCheckList 에 push.
              couponJoinCheck = false
            }
          })
        }
      })

      // 쿠폰 정렬 순서 수정. (중복쿠폰금액 > 최대할인금액인 쿠폰은 후순위로 정렬)
      targetCoupon = [...targetCoupon]
        .filter(
          (couponItem) =>
            couponItem.discountPrice <= item.overlapMaxDiscountPrice ||
            couponItem.overlapUseYn !== 'Y'
        )
        .concat(
          [...targetCoupon].filter(
            (couponItem) =>
              couponItem.discountPrice > item.overlapMaxDiscountPrice &&
              couponItem.overlapUseYn === 'Y'
          )
        )

      popCouponDataList.current[idx] && popCouponDataList.current[idx].id === idx
        ? (popCouponDataList.current[idx].couponList = targetCoupon)
        : popCouponDataList.current.push({
            id: idx,
            couponList: targetCoupon,
            couponPriceLimit: item.overlapMaxDiscountPrice
          })
    })
  }

  // 2. 쿠폰 적용 팝업 호출 함수
  // ㄴ 쿠폰 적용 팝업 시 호출하는 함수입니다.
  // ㄴ 몇번째 상품에 대한 팝업인지 ,index 값과, 쿠폰 데이터를 매개변수로 갖습니다.
  const callCouponList = (idx, couponData, productCouponLimit) => {
    setCouponSelectIdx(idx)
    setApplyCouponData(couponData)
    setCouponPriceLimit(productCouponLimit)
    handleOpenModal3()
  }

  // 3. 쿠폰 적용 업데이트 함수.
  // ㄴ 선택된 쿠폰 데이터를 popCouponDataList 값에 업데이트 합니다.
  // ㄴ 넘겨받은 데이터가 체크되었다면 해당 값의 couponChecked 값이 true 로 업데이트 되고, 선택한 쿠폰 목록 중, 타 상품 쿠폰 목록에 동일한 쿠폰이 존재한다면, couponDisplay 값을 false로 업데이트 합니다.
  const couponSelected = (couponSeqList) => {
    let checkedYn = false
    let selectSeqList = []

    // 선택한 쿠폰 seq를 배열로 갖고있는 couponSeqList.
    // couponSeqList에 담긴 seq 들을 해당 상품이 갖고있는 쿠폰의 seq와 비교하면서, 서로 같다면, 체크표기를 boolean 값으로 업데이트함. (checkedYn)
    popCouponDataList.current[couponSelectIdx].couponList.forEach((item) => {
      checkedYn = false
      couponSeqList.forEach((subItem) => {
        if (item.couponSeq === Number(subItem)) checkedYn = true
      })
      item.couponChecked = checkedYn
    })

    // 위 조건문을 통해 popCouponDataList 값에 업데이트가 되었고, 해당 기준으로 selectSeqList 값에 쿠폰 seq를 push함.
    popCouponDataList.current[couponSelectIdx].couponList.forEach((item) => {
      if (item.couponChecked) selectSeqList.push(item.couponSeq)
    })

    // 수익배분 쿠폰이 선택된 상태인지 체크.
    // 체크되었다면, 몇번째 상품에 체크된 상태인지 couponShareIdx 변수에 저장.
    let couponShareCheck = false
    let couponShareIdx = 0
    popCouponDataList.current.forEach((item, idx) => {
      item.couponList.forEach((subItem) => {
        if (subItem.couponKindCode === 'SHARE' && subItem.couponChecked) {
          couponShareCheck = true
          couponShareIdx = idx
        }
      })
    })

    // checked 된 쿠폰목록을 checkedList에 push함.
    let checkedList = []
    popCouponDataList.current.forEach((item, idx) => {
      item.couponList.forEach((subItem) => {
        if (subItem.couponChecked) {
          checkedList.push({ checkedIndex: idx, checkedSeq: subItem.couponSeq })
        }
      })
    })

    // 자신의 상품이 아닌 타 상품에서 해당 쿠폰이 선택되었다면, 중복 노출되지 않도록 couponDisplay값을 false로 업데이트.
    let checkedBoolean = true
    popCouponDataList.current.forEach((item, idx) => {
      item.couponList.forEach((subItem) => {
        checkedBoolean = true
        // 수익배분 쿠폰이 존재하고, 그 쿠폰이 바라보는 상품 인덱스가 아닐때
        if (couponShareCheck && couponShareIdx !== idx && subItem.couponKindCode === 'SHARE') {
          checkedBoolean = false
        } else {
          checkedList.forEach((detailItem) => {
            // 체크리스트에 있는 시퀀스 === 대상 쿠폰 시퀀스 && 체크리스트의 인덱스값 !== 비교중인 상품 인덱스
            if (detailItem.checkedSeq === subItem.couponSeq && detailItem.checkedIndex !== idx)
              checkedBoolean = false
          })
        }
        subItem.couponDisplay = checkedBoolean
      })
    })
    calcTotalPrice()
  }

  // 다운로드 가능한 쿠폰 API
  const getCouponDownloadData = () => {
    api
      .get(`/v2/coupon/possible/download/list`)
      .then((response) => {
        setCouponDownloadData(response.data.data.content)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 특정 패키지 별 사용가능 쿠폰 API
  const getAvailableCouponList = () => {
    // /v2/coupon-publish/list?index=1&keys=restrictPackageSeq&keys=couponUseStatus&size=50&types=in&types=equal&values=798%2C808&values=N
    // 이런식으로 넘겨줘야 해서 주문하는 상품 패키지 배열을 가공함
    const packageUrl = orderMasters.map((item) => item.packageSeq).join('%2C')
    if (!packageUrl || packageUrl.length === 0) return false
    api
      .get(
        '/v2/coupon-publish/list?index=1&keys=restrictPackageSeq&keys=couponUseStatus&size=50&types=in&types=equal&values=' +
          packageUrl +
          '&values=N'
      )
      .then((response) => {
        setAvailableCoupon(response.data.data.content)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 사용가능 쿠폰 API
  const getCouponListData = () => {
    api
      .get(`/v2/coupon-publish/list?index=1&size=100&types&keys=couponUseYn&types=equal&values=N`)
      .then((response) => {
        setCoupons(response.data.data.content)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const setCoupons = (coupons) => {
    let filteredCoupons = coupons.filter(
      (coupon) => coupon.couponTypeCode !== 'SHOPPING_BASKET' && coupon.discountType !== 'PERCENT'
    )

    setCouponListData(filteredCoupons)
    setLimitedCoupon(filteredCoupons.filter((item) => item.restrictedPackageYn === 'Y')) // restrictedPackageYn 특정 상품에만 사용가능 설정 여부 (Y : 제한, N : 전체)
    setAllCoupon(filteredCoupons.filter((item) => item.restrictedPackageYn === 'N')) // restrictedPackageYn 특정 상품에만 사용가능 설정 여부 (Y : 제한, N : 전체)
  }

  // 주소 팝업
  const handlePostCode = (e) => {
    e.preventDefault()
    new window.daum.Postcode({
      onComplete: (data) => {
        let fullAddress = data.address
        let extraAddress = ''

        if (data.addressType === 'R') {
          if (data.bname !== '') {
            extraAddress += data.bname
          }
          if (data.buildingName !== '') {
            extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
          }
          fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
        }
        setInputValue({
          ...inputValue,
          defaultAddress: fullAddress,
          zipcode: data.zonecode
        })
      }
    }).open()
  }

  const getRequestNumber = () => {
    api
      .get(config.AUTH_API_HOST + '/auth/v2/nice/request-number')
      .then((response) => {
        setRequestNum(response.data.data.requestNumber)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleNiceModal = () => {
    setNiceModalOpen(true)
    window.open(
      `${config.AUTH_API_HOST}/views/nice/checkplus/web/${requestNum}`,
      '_blank',
      'width=500, height=800'
    )
  }

  // 모바일 - 배송지 변경 버튼 클릭시 사용
  const handleNewAddress = () => {
    setUserDataStatus(false)

    setInputValue({
      ...inputValue,
      name: '',
      cellPhone: '',
      zipcode: '',
      defaultAddress: '',
      detailAddress: '',
      message: '',
      paymentType: 'NPAY',
      paymentWay: 'SIMPLE_PAY'
    })
  }

  // 회원 기본 정보 불러오기
  const userDefaultData = (checked) => {
    setIsChecked({ ...isChecked, defaultData: !isChecked.defaultData })

    if (checked && userData) {
      setInputValue({
        ...inputValue,
        name: userData.username,
        cellPhone: userData.cellPhone,
        zipcode: userData.address.zipcode ? userData.address.zipcode : '',
        defaultAddress: userData.address.address ? userData.address.address : '',
        detailAddress: userData.address.addressDetail ? userData.address.addressDetail : ''
      })
    } else {
      setInputValue({
        ...inputValue,
        name: '',
        cellPhone: '',
        zipcode: '',
        defaultAddress: '',
        detailAddress: '',
        message: '',
        paymentType: 'NPAY',
        paymentWay: 'SIMPLE_PAY'
      })
    }
  }

  // 회원정보 업데이트
  const userDeliveryUpdate = () => {
    if (userData) {
      const data = {
        address: {
          address: inputValue.defaultAddress,
          addressDetail: inputValue.detailAddress,
          zipcode: inputValue.zipcode
        },
        allowance: {
          email: userData.emailYn === 1 ? true : false,
          sms: userData.smsYn === 1 ? true : false
        },
        birthday: userData.birthDate.split(' ')[0],
        email: userData.email,
        userSex: userData.userSex
      }

      api
        .put(config.AUTH_API_HOST + '/auth/v2/user', data)
        .then((response) => {
          console.log('결과', response.data)
        })
        .catch((e) => {
          alert(config.MESSAGE['common-error'])
        })
    }
  }

  const getPaymentParams = (deviceType) => {
    let totalOriginPrice = 0,
      totalSalePrice = 0,
      totalDiscountPrice = 0, // 쿠폰 할인
      totalPaymentPrice = 0,
      productDiscountPrice = 0,
      productCouponSum = 0
    let paymentPrices = []
    let detailCounts = []
    let detailPaymentPrices = []
    let discountPrices = []
    let usedCoupons = [] // 선택한 쿠폰 데이터

    popCouponDataList.current.forEach((item, idx) => {
      productCouponSum = 0
      item.couponList.forEach((subItem) => {
        if (subItem.couponChecked) {
          usedCoupons.push({
            couponSeq: subItem.couponSeq,
            packageSeq: orderMasters[idx].packageSeq,
            couponCode: subItem.couponCode,
            discountPrice: subItem.discountPrice
          })
          totalDiscountPrice += subItem.discountPrice
          productCouponSum += subItem.discountPrice
        }
      })
      discountPrices.push(productCouponSum)
    })

    orderMasters.forEach((orderMaster, idx) => {
      productDiscountPrice = 0
      popCouponDataList.current[idx].couponList.forEach((item) => {
        if (item.couponChecked) productDiscountPrice += Number(item.discountPrice)
      })

      totalOriginPrice += orderMaster.originalPrice
      totalSalePrice += orderMaster.salePrice
      totalDiscountPrice += orderMaster.discountPrice
      totalPaymentPrice += orderMaster.salePrice - orderMaster.discountPrice
      paymentPrices.push(orderMaster.salePrice - orderMaster.discountPrice - productDiscountPrice)

      for (const orderDetail of orderMaster.orderDetails) {
        if (
          orderDetail.packageSeq === orderMaster.packageSeq &&
          ['CLASS', 'REAL', 'APP'].indexOf(orderDetail.productTypeCode) > -1
        ) {
          detailCounts.push(orderDetail.cnt)
          detailPaymentPrices.push(orderDetail.salePrice)
        }
      }
    })

    // paymentPrices 배열에 교재 정보 추가.
    orderListSeq.forEach((item) => {
      item.additionalPackageList.forEach((item) => paymentPrices.push(item.price))
    })

    let params = {
      deviceType: deviceType, // getPaymentParams 함수 호출 시 인자값으로 넘어오는 deviceType. (PC or MOBILE)
      deliveryRequest: {
        // 배송지 정보 데이터.
        addr1: inputValue.defaultAddress,
        addr2: inputValue.detailAddress,
        cellphone: inputValue.cellPhone,
        message: deliveryMessage === 'custom' ? inputValue.message : deliveryMessage,
        name: inputValue.name,
        zipcode: inputValue.zipcode
      },
      detailCounts: detailCounts, // 각 상품별 orderDetail.cnt 값 (배열).
      detailPaymentPrices: detailPaymentPrices, // 각 상품별 orderDetail.salePrice 값 (배열).
      discountPrices: discountPrices, // 각 상품별 discountPrices 값 (배열).
      orderNo: orderNo, // orderNo
      paymentPrices: paymentPrices,
      paymentType: inputValue.paymentType, // 결제 수단 (어떤 결제 )
      paymentWay: paymentWayRealType(inputValue.paymentType), // 결제 방식 (일반결제 or 간편결제)
      price: totalPaymentPrice + deliveryFee - totalDiscountPrice + addBookTotalPrice, // 결제금액 = totalPaymentPrice + deliveryFee(배송비) - 할인 + 교재 추가 금액 합계.
      couponRequests: usedCoupons, // [{couponSeq: couponSeq, packageSeq: packageSeq, couponCode: couponCode, discountPrice:discountPrice}, {...}] 구조로 전달 필요.
      additionalPackages: orderListSeq // 교재 관련 정보 백엔드와 협의된 규격으로 데이터 전달. (additionalPackages:[{standardPackage:상품seq, additionalPackageList: [{price:교재금액, packageSeq: 교재seq},{price:교재금액, packageSeq: 교재seq},...]}])
    }

    return params
  }

  const isEmpty = (val) => {
    return (
      val === undefined ||
      val === null ||
      val === '' ||
      (val !== null && typeof val === 'object' && !Object.keys(val).length)
    )
  }

  const removeCartItems = () => {
    api
      .delete(`/payment/v2/cart/all`)
      .then((response) => {
        if (response.data.meta?.code === 200) {
          // 정상인 경우 처리할 내용 없음
        } else if (response.data.meta.message) {
          alert(response.data.meta.message)
        } else {
          alert(config.MESSAGE['common-error'])
        }
      })
      .catch((e) => {
        console.log('잠시 후 다시 시도해 주세요.')
      })
  }

  // 주문번호 재셋팅 함수 : sessionStorage에 담긴 productParam 정보로 주문번호 재셋팅.
  const reOrderAction = () => {
    const productParam = JSON.parse(window.sessionStorage.getItem('productParam'))
    if (!!productParam) {
      if (productParam.orderReset) {
        setLoadingOrderList(true)
        api
          .post(`/payment/v2/order/${productParam.param}`)
          .then((response) => {
            if (response.data.meta?.code === 200) {
              reOrderSet(false)
              window.location.href = `/mypage/order/cart/detail/${response.data.data}`
            } else {
              console.log('잠시 후 다시 시도해 주세요.')
              navigate(`/`)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }

  // sessionStorage 의 productParam 값에 orderReset값을 boolean 타입의 인자값을 기준으로 전환.
  const reOrderSet = (type) => {
    let productParam = JSON.parse(window.sessionStorage.getItem('productParam'))
    productParam.orderReset = type
    window.sessionStorage.setItem('productParam', JSON.stringify(productParam))
  }

  // 교재 선택/해제 시 실행되는 함수.
  // idx : number (몇번째 패키지인가)
  // checkYn : boolean (교재 체크여부)
  // packageSeq : number (교재 packageSeq)
  // price : number (교재 가격)
  const addBookCheck = (idx, checkYn, packageSeq, price) => {
    let orderGroup = [...orderListSeq]
    if (checkYn) {
      orderGroup[idx].additionalPackageList.push({ packageSeq: packageSeq, price: price })
    } else {
      let checkIdx = -1
      orderGroup[idx].additionalPackageList.forEach((item, idx) => {
        if (item.packageSeq === packageSeq) checkIdx = idx
      })
      if (checkIdx !== -1) orderGroup[idx].additionalPackageList.splice(checkIdx, 1)
    }
    setOrderListSeq(orderGroup)
    addBookPriceSet()
  }

  // 선택된 추가 교재 금액 계산 후 결제 금액에 합계 적용.
  const addBookPriceSet = () => {
    let totalAddBookPrice = 0
    let addBookCheck = false
    let isPackageReal = false
    orderListSeq.forEach((item) => {
      item.additionalPackageList.forEach((item) => (totalAddBookPrice += item.price))
      if (item.additionalPackageList.length > 0) addBookCheck = true
    })
    orderMasters.forEach((item) => {
      if (item.packageTypeCode === 'REAL') isPackageReal = true
    })
    setAddBookOrderCheck(addBookCheck)
    setAddBookTotalPrice(totalAddBookPrice)

    // 실물상품이나 교재가 선택된 경우 && 총 합계금액이 50000원 미만이 경우, 배송비 3,500원 적용.
    setDeliveryFee(
      (isPackageReal || addBookCheck) &&
        totalSalePrice + totalAddBookPrice < MINIMUM_PRICE_FOR_FREE_DELIVERY
        ? DELIVERY_FEE
        : 0
    )
  }

  // 별도의 배송비 체크 로직.
  const deliveryFeeCheck = () => {
    let isPackageReal = false
    orderMasters.forEach((item) => {
      if (item.packageTypeCode === 'REAL') isPackageReal = true
    })
    setDeliveryFee(
      (isPackageReal || addBookCheck) &&
        totalSalePrice + addBookTotalPrice < MINIMUM_PRICE_FOR_FREE_DELIVERY
        ? DELIVERY_FEE
        : 0
    )
  }

  // 결제하기 버튼 클릭 시 실행 함수. deviceType별로 결제 처리 방식 차이가 있어, 매개변수로 분기처리됨. ('PC' or 'MOBILE')
  const getPaymentUid = (deviceType) => {
    reOrderSet(true)
    // 최종 결제 금액이 1,000원 미만일때 결제 진행되지 않도록 조건 추가. (2022-09-27 카일 확인)
    // if (totalPrice < 1000) {
    //   alert('1,000원 미만의 결제는 불가능합니다.\n 고객센터로 문의 바랍니다.');
    //   return false;
    // }

    // 최종 결제 금액이 100원 미만일때 결제 진행되지 않도록 조건 추가. (2023-05-22 100원 결제 이벤트로 인한 분기처리 수정.)
    if (totalPrice < 100) {
      alert('100원 미만의 결제는 불가능합니다.\n 고객센터로 문의 바랍니다.')
      return false
    }

    // 배송정보 유효성 체크.
    if (hasDeliveryProduct || addBookOrderCheck) {
      if (
        isEmpty(inputValue.name) ||
        isEmpty(inputValue.defaultAddress) ||
        isEmpty(inputValue.detailAddress) ||
        isEmpty(inputValue.cellPhone)
      ) {
        alert('배송정보를 입력해 주세요.')
        scrollMove(deliveryInfoRef)
        return false
      }
    }

    // 본인인증 유효성 체크.
    if (!isIdentityCertification) {
      if (!niceStatus) {
        alert('본인인증을 진행해 주세요')
        return false
      }
    }

    // paymentType: '', paymentWay: '',
    if (inputValue.paymentType === '') {
      alert('결제 수단을 선택해 주세요')
      scrollMove(paymentTypeRef)
      return false
    }

    // 동의 여부 체크.
    if (!isChecked.agreeOrder) {
      alert('상품정보에 동의해 주세요')
      return false
    }

    // 결제하기 유효성체크 후 GTM실행 (서비스 기획 요청 / ETD-857)
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'paymentpage_button',
        product_name: '결제버튼 이벤트'
      })
    }

    // 결제 정보를 해당 값에 적용 필요.
    const params = getPaymentParams(deviceType)

    // 2022-01-10 kookyh update start (01)
    api
      .post(`/payment/v2/request`, JSON.stringify(params))
      .then((response) => {
        if (response.data.meta.code !== 200) {
          if (response.data.meta.message) {
            alert(response.data.meta.message)
          } else {
            alert(config.MESSAGE['common-error'])
          }
          return
        }
        let uuid = response.data.data

        if (params.paymentType === 'NPAY' || params.paymentType === 'TOSS') {
          //paymentType이 NPAY, TOSS인 경우
          callPaymentSimplepayWindow(uuid, params.orderNo)
        } else {
          callPaymentWindow(uuid, deviceType, params.paymentType)
        }
      })
      .catch((e) => {
        console.log(e)
        alert('안전한 결제 진행을 위해 결제를 다시 시도해 주세요.')
        reOrderAction()
        reOrderSet(false)
      })
    // 2022-01-10 kookyh update end (01)
  } // 2022-01-10 kookyh update start (02)
  let simpleOrderNo
  const callPaymentSimplepayWindow = (uuid, orderNo) => {
    let paymentUrl = uuid
    simpleOrderNo = orderNo
    window.paymentSimplepayWindow = window.open(paymentUrl, 'paymentSimplepayWindow')

    if (
      !window.paymentSimplepayWindow ||
      window.paymentSimplepayWindow.closed ||
      typeof window.paymentSimplepayWindow.closed === 'undefined'
    ) {
      alert('팝업이 차단되었습니다. 팝업 차단을 해제 후 결제를 진행해주세요.')
    }

    simplepayInterval('start')
  }

  let simplepayCheck
  const simplepayInterval = (type) => {
    if (type === 'start') {
      simplepayCheck = setInterval(function () {
        if (
          !window.paymentSimplepayWindow ||
          window.paymentSimplepayWindow.closed ||
          typeof window.paymentSimplepayWindow.closed === 'undefined'
        ) {
          clearInterval(simplepayCheck)
          api
            .get(config.API_HOST + '/payment/v2/result/' + simpleOrderNo)
            .then((response) => {
              checksimplepayResult(response)
            })
            .catch((e) => {
              console.log(e)
              checksimplepayResult(null)
            })
        }
      }, 3000)
    } else if (type === 'end') {
      clearInterval(simplepayCheck)
    }
  }

  const checksimplepayResult = (result) => {
    try {
      if (
        result != null &&
        result.data.meta.code === 200 &&
        ['PAY_SUCCESS', 'PAY_FAIL'].indexOf(result.data.data.paymentResult) > -1
      ) {
        checkResult(simpleOrderNo, result.data.data.paymentResult, result.data.data.resultMessage)
        return
      } else {
        window.location.reload()
      }
    } catch (e) {
      console.log(e)
    }
  }
  // 2022-01-10 kookyh update end (02)

  let npayOrderNo
  const checkNpayResult = (result) => {
    try {
      if (
        result != null &&
        result.data.meta.code === 200 &&
        ['PAY_SUCCESS', 'PAY_FAIL'].indexOf(result.data.data.paymentResult) > -1
      ) {
        checkResult(npayOrderNo, result.data.data.paymentResult, result.data.data.resultMessage)
        return
      } else {
        window.location.reload()
      }
    } catch (e) {
      console.log(e)
    }
  }

  useInterval(
    () => {
      console.log('payment close')
      // 결제 새창이 open check
      if (isOpen) {
        if (window.paymentPopupWindow.closed) {
          // 팝업이 닫혔을 때
          if (!closeType) {
            // closeType === false 면 브라우저 닫기 버튼으로 닫았다고 판단
            alert('결제가 취소되었습니다.\n보유중이셨던 할인쿠폰, 할인코드를 재입력 하셔야 합니다.')
            reOrderAction()
          }
          setIsOpen(false)
        }
      }
    },
    isOpen ? 1000 : null
  )

  const callPaymentWindow = (uuid, deviceType, paymentType) => {
    let paymentUrl = `${config.PAYMENT_HOST}/payment/view/frame/${uuid}?deviceType=${deviceType}`
    if (deviceType === 'PC') {
      if (paymentType === 'ONLYKAKAOPAY') {
        setPaymentSrc(paymentUrl)
        return
      }
      window.paymentPopupWindow = window.open(
        paymentUrl,
        'paymentPopupWindow',
        'width=840, height=620'
      )

      setIsOpen(true)
    } else {
      window.paymentPopupWindow = window.open(paymentUrl, 'paymentPopupWindow')
    }

    if (
      !window.paymentPopupWindow ||
      window.paymentPopupWindow.closed ||
      typeof window.paymentPopupWindow.closed === 'undefined'
    ) {
      alert('팝업이 차단되었습니다. 팝업 차단을 해제 후 결제를 진행해주세요.')
    }
  }

  let mobileOrderNo
  const receiveMessage = async (e) => {
    if (e.data.hasOwnProperty('device') && e.data.device === 'MOBILE') {
      mobileOrderNo = e.data.p_OID || ''
      setTimeout(getPaymentResult, 5000)
    } else if (e.data.hasOwnProperty('paymentResult')) {
      const paymentResult = e.data.paymentResult
      const orderNo = e.data.orderNo || ''
      const resultMessage = e.data.resultMessage || ''

      setCloseType(true)
      checkResult(orderNo, paymentResult, resultMessage)
    }
  }
  const checkResult = (orderNo, paymentResult, resultMessage) => {
    switch (paymentResult) {
      case 'CLOSE':
        console.log('- CLOSE')
        closePaymentPopup()
        alert('결제가 취소되었습니다.\n보유중이셨던 할인쿠폰, 할인코드를 재입력 하셔야 합니다.')
        reOrderAction()
        break
      case 'PAY_FAIL':
        console.log('- PAY_FAIL')
        closePaymentPopup()
        if (resultMessage) {
          alert(resultMessage)
        } else {
          alert('오류가 발생했습니다.')
        }
        reOrderAction()
        break
      case 'PAY_SUCCESS':
        console.log('- PAY_SUCCESS')
        console.log('결제 성공 : ' + orderNo)
        window.sessionStorage.removeItem('productOrderSet') // 주문 선택 정보 세션 스토리지 삭제.
        setPaymentSuccess(true)
        removeCartItems()
        closePaymentPopup()
        navigate('/mypage/order/PaymentComplete', {
          state: { orderNo: orderNo, oProduct: { prod_id: orderNo, pur_price: totalPrice } }
        })
        break
      default:
        console.log('- window.paymentPopupWindow', window.paymentPopupWindow)
        if (window.paymentPopupWindow.closed && window.paymentPopupWindow.closed) {
          checkResult(mobileOrderNo, 'CLOSE', '')
          break
        }
        break
    }
  }

  const getPaymentResult = () => {
    api
      .get(config.API_HOST + '/payment/v2/result/' + mobileOrderNo)
      .then((response) => {
        checkMobileResult(response)
      })
      .catch((e) => {
        console.log(e)
        checkMobileResult(null)
      })
  }

  let retryCount = 0
  const checkMobileResult = (result) => {
    try {
      if (
        result != null &&
        result.data.meta.code === 200 &&
        ['PAY_SUCCESS', 'PAY_FAIL'].indexOf(result.data.data.paymentResult) > -1
      ) {
        checkResult(mobileOrderNo, result.data.data.paymentResult, result.data.data.resultMessage)
        return
      }
    } catch (e) {
      console.log(e)
    }

    // 2022-01-10 kookyh update start (03)
    if (++retryCount < 200) {
      if (
        !window.paymentPopupWindow ||
        window.paymentPopupWindow.closed ||
        typeof window.paymentPopupWindow.closed === 'undefined'
      ) {
        clearTimeout(getPaymentResult)
      } else {
        setTimeout(getPaymentResult, 3000)
      }
    } else {
      checkResult(mobileOrderNo, 'PAY_FAIL', '')
    }
    // 2022-01-10 kookyh update end (03)
  }

  const closePaymentPopup = () => {
    if (window.paymentPopupWindow && !window.paymentPopupWindow.closed) {
      window.paymentPopupWindow.close()
    }
  }

  // 쿠폰 리스트(배열)을 매개변수로 갖는 함수. 선택한 쿠폰의 total 금액을 return 합니다.
  const selectCouponPrice = (couponList) => {
    let totalPrice = 0
    couponList.forEach((item) => {
      if (item.couponChecked) totalPrice += item.discountPrice
    })
    return totalPrice
  }

  // 쿠폰 리스트(배열)과 packageSeq 값을 매개변수로 갖는 함수. packageSeq 기준 쿠폰 적용 가능한 상품이 있는지, 체크하고 결과를 boolean 타입으로 return 합니다.
  const checkCouponBoolean = (couponList, seq) => {
    let checkResult = false
    couponList.forEach((item) => {
      item.couponToPackageInfoDTOList.forEach((subItem) => {
        if (Number(subItem.packageSeq) === Number(seq)) checkResult = true
      })
    })
    return checkResult
  }

  // payment SUCCESS를 체크하는 비동기 함수 입니다.
  // kakaopay 결제 타입과 같이 주문 완료 여부를 체크해야 할때 사용되는 함수입니다. (made by. Milo)
  // payment SUCCESS가 아닌 경우, reOderAction, deliveryFeeCheck 함수를 실행합니다.
  const checkPayComplete = async () => {
    let PAY_SUCCESS_check = false
    try {
      const response = await api.get('/payment/v2/result/' + orderNo)
      if (response.data.data.paymentResult === 'PAY_SUCCESS') {
        checkResult(orderNo, 'PAY_SUCCESS', null)
        PAY_SUCCESS_check = true
      }
    } catch (e) {
      console.log(e)
    } finally {
      if (!PAY_SUCCESS_check) reOrderAction()
      deliveryFeeCheck()
    }
    return false
  }

  useEffect(() => {
    getRequestNumber()
    window.removeEventListener('message', receiveMessage)
    window.addEventListener('message', receiveMessage, false)
    return () => {
      hasEntrancePackage = false
    }
  }, [])

  useEffect(() => {
    getUserData()
    checkPayComplete()
  }, [orderNo])

  useEffect(() => {
    if (cartItems.length > 0 && orderListSeq.length > 0 && isAddBookReady) {
      try {
        cartItems.forEach((item, idx) => {
          if (item.packageToAdditionalPackageResponses.length > 0) {
            addBookCheck(
              idx,
              true,
              item.packageToAdditionalPackageResponses[0].additionalPackageSeq,
              item.packageToAdditionalPackageResponses[0].exceptOptionSalePrice
            )
          }
        })
      } catch (e) {
        console.log(e)
      }
    }
  }, [isAddBookReady])

  // 2022-01-10 kookyh update start (04)
  useEffect(() => {
    return () => simplepayInterval('end')
  })
  // 2022-01-10 kookyh update end (04)

  useEffect(() => {
    if (paymentSuccess && isChecked.updateData) {
      userDeliveryUpdate()
    }
  }, [paymentSuccess])

  useEffect(() => {
    setIsValidForm((isValidForm) => isChecked.agreeOrder && (niceStatus || isIdentityCertification))
  }, [isIdentityCertification, isChecked.agreeOrder, niceStatus])

  useEffect(() => {
    if (!userData || userData.length === 0) {
      return false
    }

    if (deviceCheck && userData) {
      userDefaultData(true)
    }
    getOrderInfo(orderNo)
  }, [userData])

  useEffect(() => {
    if (!orderMasters || orderMasters.length === 0) return false
    calcTotalPrice()
    getCouponListData()
    getCouponDownloadData()
    getAvailableCouponList()
  }, [orderMasters])

  useEffect(() => {
    setCouponList()
  }, [availableCoupon, allCoupon])

  useEffect(() => {
    // 전체 구매 금액 + 배송비 - 쿠폰 할인 + 추가 교재 토탈 금액.
    setTotalPrice(totalSalePrice + deliveryFee - totalCouponDiscountPrice + addBookTotalPrice)
  }, [totalSalePrice, deliveryFee, totalCouponDiscountPrice, addBookTotalPrice])

  useEffect(() => {
    if (niceModalOpen) {
      let getNiceResult = setInterval(() => {
        api
          .get(config.AUTH_API_HOST + '/auth/v2/nice/result?requestNumber=' + requestNum)
          .then((response) => {
            setNiceStatus(response.data.data.status === 'success' ? true : false)

            if (response.data.data.status === 'success') {
              clearInterval(getNiceResult)
            }
          })
          .catch((e) => {
            console.log(e)
          })
      }, 2000)

      let isTimer = setInterval(() => {
        if (certifiedCodeTime.current > 0) {
          certifiedCodeTime.current -= 1
        } else {
          clearInterval(isTimer)
          clearInterval(getNiceResult)
        }
      }, 1000)

      return () => clearInterval(getNiceResult)
    }
  }, [niceModalOpen])

  useEffect(() => {
    // 본인인증 성공 후 정보 업데이트
    if (niceStatus) {
      api
        .put(config.AUTH_API_HOST + '/auth/v2/nice/identity?requestNumber=' + requestNum)
        .then((response) => {
          if (response.data.meta.code !== 200) {
            alert(response.data.meta.message)

            return false
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }, [niceStatus])

  useEffect(() => {
    const mallCategory = window.localStorage.getItem('mallCategory')
    const mallDetail = window.localStorage.getItem('mallDetail')
    const packageNum = window.localStorage.getItem('packageSeq')

    if (!mallCategory || !mallDetail || !packageNum) return

    api
      .post(
        `/v2/mall/collect-data?groupData=${mallCategory}&detailGroupData=${mallDetail}&orderNo=${orderNo}`
      )
      .then((response) => {
        if (response.data.meta.code !== 200) {
          console.error(response.data.meta.message)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }, [orderNo])

  useEffect(() => {
    if (search) {
      const params = queryString.parse(search, { parseBooleans: true })
      window.sessionStorage.setItem('productParam', JSON.stringify(params))
    }
  }, [search])

  // 결제타입 탭 선택에 관계없이 결제 방식으로 paymentWay 값 지정.
  // 결제타입 추가 시 해당 부분도 체크 필요.
  const paymentWayRealType = (code) => {
    return code === 'NPAY' || code === 'TOSS' ? 'SIMPLE_PAY' : 'GENERAL_PAY'
  }

  // 결제 수단 선택 함수.
  const paymentTypeSelect = (code) => {
    setInputValue({ ...inputValue, paymentType: code, paymentWay: paymentWayRealType(code) })
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <HideIframe src={paymentSrc}></HideIframe>
        <article className={'cart-col' + (deviceCheck ? ' detail' : '')}>
          {/* 주문자 정보 */}
          {userData && <UserInfo userName={userData.username} cellPhone={userData.cellPhone} />}
          {/* 배송지 스크롤 이동을 위한 div. 추후 배송지 정보 컴포넌트로 분리 필요. */}
          <div ref={deliveryInfoRef}></div>
          {/* 배송지 정보 */}
          {isDesktop && (
            <>
              {hasDeliveryProduct || addBookOrderCheck ? (
                <section className="box-tb-col">
                  <div className="inner-col">
                    <h3>
                      <strong>배송지 정보</strong>
                    </h3>
                    {!!isChecked ? (
                      <React.Fragment>
                        <div className="delivery-chk-box">
                          <label htmlFor="copyDeliveryInfo">
                            <input
                              type="checkbox"
                              id="copyDeliveryInfo"
                              name="copyDeliveryInfo"
                              checked={isChecked.defaultData ? true : false}
                              onChange={(e) => userDefaultData(e.target.checked)}
                            />
                            <i className="chk"></i>
                            <span>주문자 정보 동일</span>
                          </label>
                          <label htmlFor="updateDeliveryInfo">
                            <input
                              type="checkbox"
                              id="updateDeliveryInfo"
                              name="updateDeliveryInfo"
                              checked={isChecked.updateData ? true : false}
                              onChange={(e) =>
                                setIsChecked({
                                  ...isChecked,
                                  updateData: !isChecked.updateData
                                })
                              }
                            />
                            <i className="chk"></i>
                            <span>아래 주소를 회원정보에 업데이트</span>
                          </label>
                        </div>
                        <div className="desc-box right">
                          <p>
                            <em>교재는 평일 오후 2시 일괄적으로 발송됩니다.</em>
                          </p>
                        </div>
                        <div className="tb-default cart-detail">
                          <table>
                            <colgroup>
                              <col width="20%" />
                              <col width="30%" />
                              <col width="20%" />
                              <col width="30%" />
                            </colgroup>
                            <tbody>
                              <tr>
                                <th>
                                  <em>이름</em>
                                </th>
                                <td>
                                  <input
                                    type="text"
                                    placeholder="이름을 입력해 주세요"
                                    name="name"
                                    value={inputValue.name}
                                    onChange={handleInputValue}
                                  />
                                </td>
                                <th>
                                  <em>휴대폰 번호</em>
                                </th>
                                <td>
                                  <input
                                    type="text"
                                    placeholder="휴대폰 번호를 입력해 주세요."
                                    name="cellPhone"
                                    value={inputValue.cellPhone}
                                    onChange={handleInputValue}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <em>주소</em>
                                </th>
                                <td colSpan="3">
                                  <div className="flex-form">
                                    <button className="btn-address" onClick={handlePostCode}>
                                      <span>주소찾기</span>
                                    </button>
                                    {inputValue.zipcode && inputValue.defaultAddress ? (
                                      <React.Fragment>
                                        <input
                                          type="text"
                                          className={
                                            'input-value zone-code ellipsis' +
                                            (inputValue.zipcode ? ' active' : '')
                                          }
                                          name="zipcode"
                                          value={inputValue.zipcode}
                                          readOnly
                                        />
                                        <input
                                          type="text"
                                          className={
                                            'input-value' +
                                            (inputValue.defaultAddress ? ' active' : '')
                                          }
                                          name="defaultAddress"
                                          value={inputValue.defaultAddress}
                                          readOnly
                                        />
                                      </React.Fragment>
                                    ) : null}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <em>상세 주소</em>
                                </th>
                                <td colSpan="3">
                                  <input
                                    type="text"
                                    placeholder="상세 주소를 입력해주세요."
                                    name="detailAddress"
                                    value={inputValue.detailAddress}
                                    onChange={handleInputValue}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>배송 요청 사항</th>
                                <td colSpan="3">
                                  <DeliverySelectBox>
                                    <select
                                      onChange={(e) => setDeliveryMessage(e.target.value)}
                                      defaultValue={deliveryMessage}
                                      className={deliveryMessage === `custom` ? `bold` : ``}
                                    >
                                      {deliveryMessageList.map((item, idx) => (
                                        <option key={idx} value={item.value}>
                                          {item.content}
                                        </option>
                                      ))}
                                    </select>
                                  </DeliverySelectBox>
                                  {deliveryMessage === `custom` ? (
                                    <DeliveryInputBox>
                                      <input
                                        type="text"
                                        placeholder="최대 50자까지 입력이 가능합니다."
                                        name="message"
                                        value={inputValue.message}
                                        onChange={handleInputValue}
                                        maxLength={50}
                                      />
                                    </DeliveryInputBox>
                                  ) : null}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </React.Fragment>
                    ) : (
                      <>
                        <Skeleton height={300} />
                      </>
                    )}
                  </div>
                </section>
              ) : null}
            </>
          )}
          {isMobile && (
            <>
              {hasDeliveryProduct || addBookOrderCheck ? (
                <section className="card shipping">
                  <div className="inner-col">
                    <h3>배송지 정보</h3>
                    <button className="btn-accordion" onClick={toggleFold}>
                      접기
                    </button>
                    <div className="shipping-address-box frm">
                      <p>
                        <em>※ 교재는 평일 오후 2시 일괄적으로 발송됩니다.</em>
                        {userData && (
                          <span className="ellipsis">{`${userData.address.address} ${userData.address.addressDetail}`}</span>
                        )}
                      </p>
                      <form>
                        {userData && userDataStatus ? (
                          <>
                            <div className="address-info">
                              <p>{userData.username}</p>
                              <p>{userData.cellPhone}</p>
                              <p className="address-item">{`(${userData.address.zipcode}) ${userData.address.address} ${userData.address.addressDetail}`}</p>
                              <button
                                type="button"
                                className="btn-block"
                                onClick={handleNewAddress}
                              >
                                배송지 변경하기
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <dl>
                              <dt>
                                <label htmlFor="name" className="mark">
                                  이름
                                </label>
                              </dt>
                              <dd>
                                <input
                                  type="text"
                                  name="name"
                                  placeholder="이름을 입력해 주세요."
                                  value={inputValue.name}
                                  onChange={handleInputValue}
                                />
                              </dd>
                            </dl>
                            <dl>
                              <dt>
                                <label htmlFor="cellPhone" className="mark">
                                  휴대폰 번호
                                </label>
                              </dt>
                              <dd>
                                <input
                                  type="text"
                                  name="cellPhone"
                                  placeholder="휴대폰 번호를 입력해 주세요."
                                  value={inputValue.cellPhone}
                                  onChange={handleInputValue}
                                />
                              </dd>
                            </dl>
                            <dl>
                              <dt>
                                <label htmlFor="userPhone" className="mark">
                                  주소
                                </label>
                              </dt>
                              <dd className="address">
                                <div className="flex-form">
                                  <input
                                    type="text"
                                    placeholder="우편번호"
                                    className={
                                      'input-value zone-code ellipsis' +
                                      (inputValue.zipcode ? ' active' : '')
                                    }
                                    name="zipcode"
                                    value={inputValue.zipcode}
                                    readOnly
                                  ></input>
                                  <button className="btn-address" onClick={handlePostCode}>
                                    <span>우편번호</span>
                                  </button>
                                </div>
                                <input
                                  type="text"
                                  placeholder="주소를 입력해주세요."
                                  className={
                                    'input-value' + (inputValue.defaultAddress ? ' active' : '')
                                  }
                                  name="defaultAddress"
                                  value={inputValue.defaultAddress}
                                  readOnly
                                />
                                <input
                                  type="text"
                                  placeholder="상세 주소를 입력해주세요."
                                  name="detailAddress"
                                  value={inputValue.detailAddress}
                                  onChange={handleInputValue}
                                />
                              </dd>
                            </dl>
                          </>
                        )}
                        <dl>
                          <dt>
                            <label htmlFor="userMessage">배송 요청사항</label>
                          </dt>
                          <dd>
                            <DeliverySelectBox>
                              <select
                                onChange={(e) => setDeliveryMessage(e.target.value)}
                                defaultValue={deliveryMessage}
                                className={deliveryMessage === `custom` ? `bold` : ``}
                              >
                                {deliveryMessageList.map((item, idx) => (
                                  <option key={idx} value={item.value}>
                                    {item.content}
                                  </option>
                                ))}
                              </select>
                            </DeliverySelectBox>
                            {deliveryMessage === `custom` ? (
                              <DeliveryInputBox>
                                <input
                                  type="text"
                                  placeholder="최대 50자까지 입력이 가능합니다."
                                  name="message"
                                  value={inputValue.message}
                                  onChange={handleInputValue}
                                  maxLength={50}
                                />
                              </DeliveryInputBox>
                            ) : null}
                            <label htmlFor="updateDeliveryInfo" className="update-info-check">
                              <input
                                type="checkbox"
                                id="updateDeliveryInfo"
                                name="updateDeliveryInfo"
                                checked={isChecked.updateData ? true : false}
                                onChange={(e) =>
                                  setIsChecked({
                                    ...isChecked,
                                    updateData: !isChecked.updateData
                                  })
                                }
                              />
                              <i className="chk"></i>
                              <span>위 주소를 회원정보에 업데이트</span>
                            </label>
                          </dd>
                        </dl>
                      </form>
                    </div>
                  </div>
                </section>
              ) : null}
            </>
          )}
          {/* 주문 상품 */}
          {isDesktop && (
            <>
              <section className="box-tb-col">
                <div className="inner-col">
                  <h3>
                    <strong>주문 상품</strong>
                  </h3>
                  <div className="tb-default cart-detail">
                    <table>
                      <colgroup>
                        <col width="15%" />
                        <col width="55%" />
                        <col width="30%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th colSpan="2" style={{ textAlign: 'center' }}>
                            상품 정보
                          </th>
                          <th style={{ textAlign: 'center' }}>상품 금액</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoadingOrderList ? (
                          <tr>
                            <td colSpan="3" style={{ height: `25rem` }}>
                              <Loading />
                            </td>
                          </tr>
                        ) : (
                          orderMasters &&
                          orderMasters.map((item, idx) => {
                            return (
                              <React.Fragment key={idx}>
                                <tr
                                  className="ga-product-id"
                                  id={cartItems[idx] ? cartItems[idx].cartSeq : null}
                                >
                                  <td>
                                    <div className="item-box">
                                      <div
                                        className={`item-img ${
                                          item.packageTypeCode === 'REAL' && 'delivery'
                                        }`}
                                      >
                                        <img src={item.thumbnailPath} alt={item.orderName} />
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="item-info">
                                      <p>
                                        <span className="ga-product-category">
                                          {cartItems[idx]
                                            ? cartItems[idx].packageCategoryGroupName
                                            : null}
                                          &middot;
                                          {cartItems[idx]
                                            ? cartItems[idx].packageCategoryName
                                            : null}
                                        </span>
                                        <em>
                                          {cartItems[idx] ? cartItems[idx].teacherName : null}
                                        </em>
                                      </p>
                                      <p>
                                        <strong className="ga-product-name">
                                          {item.orderName}
                                        </strong>
                                      </p>
                                      <p className="tag-group">
                                        {cartItems[idx] &&
                                          cartItems[idx].packageTagResponses.map((item, idx) => (
                                            <span key={idx} className="tag-item">
                                              {item.tag}
                                            </span>
                                          ))}
                                      </p>
                                      <p style={{ display: 'none' }}>
                                        {cartItems[idx] &&
                                          cartItems[idx].packageSeq === entrancePackageSeq &&
                                          cartItems[idx].productItems.map((productItem) => {
                                            return (productItem.requiredYn === 'N' &&
                                              productItem.checkedYn) ||
                                              'N' === 'Y'
                                              ? productItem.packageName
                                              : null
                                          })}
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="item-price">
                                      <p style={{ display: 'none' }}>
                                        <em>{addComma(Number(item.originalPrice))}</em>
                                      </p>
                                      <div>
                                        {item.salePrice < MINIMUM_PRICE ||
                                        installmentMonth <= MINIMUM_MONTH ? (
                                          <strong className="ga-product-price">
                                            {addComma(Math.floor(Number(item.salePrice)))}
                                          </strong>
                                        ) : (
                                          <>
                                            <strong>
                                              <span>월</span>
                                              {addComma(
                                                Math.floor(
                                                  Number(item.salePrice) / installmentMonth
                                                )
                                              )}
                                            </strong>
                                            <p>
                                              <strong className="ga-product-price">
                                                {addComma(Number(item.salePrice))}
                                              </strong>
                                              <span>{installmentMonth}개월 무이자 할부 시</span>
                                            </p>
                                          </>
                                        )}
                                        {isEntrancedUser && (
                                          <span className="ico-member marginNone" />
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                {!isProductFixed &&
                                cartItems[idx] &&
                                cartItems[idx].packageToAdditionalPackageResponses.length > 0 ? (
                                  <tr>
                                    <td colSpan="3" className="none-padding">
                                      <TextBookBox>
                                        <dl>
                                          <dt>교재를 추가로 구매할 수 있어요!</dt>
                                          <dd>
                                            <ul>
                                              {cartItems[idx] &&
                                                cartItems[
                                                  idx
                                                ].packageToAdditionalPackageResponses.map(
                                                  (subItem, subIdx) => (
                                                    <li key={subIdx}>
                                                      <AddBookItem
                                                        packageIdx={idx}
                                                        title={subItem.title}
                                                        productSeq={subItem.exceptOptionSalePrice}
                                                        bookSeq={subItem.additionalPackageSeq}
                                                        imgUrl={subItem.packageImageUrl}
                                                        addBookCheck={addBookCheck}
                                                        orderListSeq={orderListSeq}
                                                      />
                                                    </li>
                                                  )
                                                )}
                                            </ul>
                                          </dd>
                                        </dl>
                                      </TextBookBox>
                                    </td>
                                  </tr>
                                ) : null}
                              </React.Fragment>
                            )
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
              <section className="box-tb-col">
                <div className="inner-col">
                  <h3>
                    <strong>할인쿠폰 적용</strong>
                  </h3>
                  <div className="tb-default cart">
                    <table>
                      <colgroup>
                        <col width="20%" />
                        <col width="70%" />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>적용가능 쿠폰</th>
                          <td>
                            {!couponApplyDisplay ? (
                              <strong>사용 가능한 쿠폰이 없습니다.</strong>
                            ) : (
                              <MyCounponBox>
                                <span>
                                  사용 가능 : <em>{availableCoupon.length + allCoupon.length}</em>장
                                </span>
                                <span>
                                  전체 : <em>{couponListData.length}</em>장
                                </span>
                                <BtnDownload onClick={handleOpenModal2}>쿠폰받기</BtnDownload>
                              </MyCounponBox>
                            )}
                          </td>
                        </tr>
                        <tr className={'use-coupont-list' + (isChecked.couponChk ? ' active' : '')}>
                          <td colSpan="2">
                            <table>
                              <colgroup>
                                <col width="120px" />
                                <col width="calc(100% - 322px)" />
                                <col width="200px" />
                              </colgroup>
                              <tbody>
                                {orderMasters &&
                                  orderMasters.map((item, idx) => {
                                    return (
                                      <tr key={idx}>
                                        <td>
                                          <div
                                            className={`item-img ${
                                              item.packageTypeCode === 'REAL' && 'delivery'
                                            }`}
                                          >
                                            <img src={item.thumbnailPath} alt={item.orderName} />
                                          </div>
                                        </td>
                                        <FlexTd>
                                          <div className="item-info">
                                            <p>
                                              <span>
                                                {cartItems[idx]
                                                  ? cartItems[idx].packageCategoryGroupName
                                                  : null}
                                                &middot;
                                                {cartItems[idx]
                                                  ? cartItems[idx].packageCategoryName
                                                  : null}
                                              </span>
                                              &nbsp;
                                              <em>
                                                {cartItems[idx] ? cartItems[idx].teacherName : null}
                                              </em>
                                            </p>
                                            <p>
                                              <strong>{item.orderName}</strong>
                                            </p>
                                            {item.packageTypeCode === 'REAL' && (
                                              <p>
                                                <em>{item.cnt}</em>
                                              </p>
                                            )}
                                          </div>
                                          {popCouponDataList &&
                                          popCouponDataList.current[idx] &&
                                          selectCouponPrice(
                                            popCouponDataList.current[idx].couponList
                                          ) > 0 ? (
                                            <>
                                              <CouponResultBox>
                                                <em>쿠폰 적용됨</em>
                                                <strong>
                                                  {addComma(
                                                    Number(
                                                      selectCouponPrice(
                                                        popCouponDataList.current[idx].couponList
                                                      )
                                                    ) * -1
                                                  )}
                                                  원
                                                </strong>
                                              </CouponResultBox>
                                            </>
                                          ) : null}
                                        </FlexTd>
                                        <td>
                                          <ApplyCouponState>
                                            {/* 조건 1. 다운로드 가능한 쿠폰이 있고, 해당 상품에 적용이 가능한가? */}
                                            {couponDownloadData &&
                                            checkCouponBoolean(
                                              couponDownloadData,
                                              item.packageSeq
                                            ) ? (
                                              <p>
                                                <em>
                                                  즉시 할인 가능!
                                                  <br />
                                                  상단 쿠폰 받기 버튼을 눌러주세요!
                                                </em>
                                              </p>
                                            ) : // 조건 2. 적용 가능한 쿠폰이 존재하고, 쿠폰 적용 이력이 있는가?
                                            popCouponDataList.current[idx] &&
                                              checkCouponBoolean(
                                                popCouponDataList.current[idx].couponList,
                                                item.packageSeq
                                              ) &&
                                              popCouponDataList.current[idx].couponList.filter(
                                                (item) => {
                                                  return item.couponChecked
                                                }
                                              ).length > 0 ? (
                                              <ButtonBlue
                                                type="button"
                                                onClick={() =>
                                                  callCouponList(
                                                    idx,
                                                    popCouponDataList.current[idx].couponList,
                                                    popCouponDataList.current[idx].couponPriceLimit
                                                  )
                                                }
                                              >
                                                쿠폰 변경하기
                                              </ButtonBlue>
                                            ) : // 조건 3. 적용 가능한 쿠폰이 있는가?
                                            popCouponDataList.current[idx] &&
                                              checkCouponBoolean(
                                                popCouponDataList.current[idx].couponList,
                                                item.packageSeq
                                              ) ? (
                                              <ButtonBlue
                                                type="button"
                                                onClick={() =>
                                                  callCouponList(
                                                    idx,
                                                    popCouponDataList.current[idx].couponList,
                                                    popCouponDataList.current[idx].couponPriceLimit
                                                  )
                                                }
                                              >
                                                쿠폰 적용하기
                                              </ButtonBlue>
                                            ) : (
                                              <p>사용 가능한 쿠폰이 없습니다.</p>
                                            )}
                                          </ApplyCouponState>
                                        </td>
                                      </tr>
                                    )
                                  })}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        {Number(totalCouponDiscountPrice) > 0 ? (
                          <tr>
                            <td colSpan="2">
                              <TotalDiscountCouponBox>
                                <strong>총 쿠폰 적용 금액</strong>
                                <em>{addComma(Number(totalCouponDiscountPrice) * -1)}</em>
                              </TotalDiscountCouponBox>
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
            </>
          )}
          {isMobile && (
            <>
              <section className="card">
                <div className="inner-col">
                  <h3>주문 상품</h3>
                  {isLoadingOrderList ? (
                    <div style={{ height: `25rem` }}>
                      <Loading />
                    </div>
                  ) : (
                    orderMasters &&
                    orderMasters.map((item, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          <div className="item-box ga-product-id" id={item.orderSeq}>
                            <div
                              className={`item-img ${
                                item.packageTypeCode === 'REAL' && 'delivery'
                              }`}
                            >
                              <img src={item.thumbnailPath} alt={item.orderName} />
                            </div>
                            <div className="item-info">
                              <p>
                                <em className="ga-product-category">
                                  {cartItems[idx] ? cartItems[idx].packageCategoryGroupName : null}
                                  &middot;
                                  {cartItems[idx] ? cartItems[idx].packageCategoryName : null}
                                </em>
                                <em>{cartItems[idx] ? cartItems[idx].teacherName : null}</em>
                              </p>
                              <p>
                                <strong className="ellipsis ga-product-name">
                                  {item.orderName}
                                </strong>
                              </p>
                              <div className="item-price">
                                <div>
                                  {isEntrancedUser && <span className="ico-member marginNone" />}
                                  {item.salePrice < MINIMUM_PRICE ||
                                  installmentMonth <= MINIMUM_MONTH ? (
                                    <strong>{addComma(Math.floor(Number(item.salePrice)))}</strong>
                                  ) : (
                                    <>
                                      <p className="month-price">
                                        <strong style={{ fontWeight: 'bold' }}>
                                          월{' '}
                                          {addComma(
                                            Math.floor(Number(item.salePrice) / installmentMonth)
                                          )}
                                        </strong>
                                      </p>
                                      <p>
                                        <em className="ga-product-price">
                                          총 {addComma(Number(item.salePrice))}
                                        </em>
                                        <em>{installmentMonth}개월 무이자 할부 시</em>
                                      </p>
                                    </>
                                  )}
                                  <em style={{ display: 'none' }}>
                                    {addComma(Number(item.originalPrice))}
                                  </em>
                                </div>
                              </div>
                            </div>
                          </div>
                          {!isProductFixed &&
                          cartItems[idx] &&
                          cartItems[idx].packageToAdditionalPackageResponses.length > 0 ? (
                            <TextBookBox>
                              <dl>
                                <dt>교재를 추가로 구매할 수 있어요!</dt>
                                <dd>
                                  <ul>
                                    {cartItems[idx] &&
                                      cartItems[idx].packageToAdditionalPackageResponses.map(
                                        (subItem, subIdx) => (
                                          <li key={subIdx}>
                                            <AddBookItem
                                              packageIdx={idx}
                                              title={subItem.title}
                                              productSeq={subItem.exceptOptionSalePrice}
                                              bookSeq={subItem.additionalPackageSeq}
                                              imgUrl={subItem.packageImageUrl}
                                              addBookCheck={addBookCheck}
                                              orderListSeq={orderListSeq}
                                            />
                                          </li>
                                        )
                                      )}
                                  </ul>
                                </dd>
                              </dl>
                            </TextBookBox>
                          ) : null}
                        </React.Fragment>
                      )
                    })
                  )}
                </div>
              </section>
              <section className="card">
                <div className="inner-col">
                  <h3>할인쿠폰 적용</h3>
                  <div className="descount-box price-sort">
                    <ul>
                      {/* <li className="descount-total">
                        <span>{isEntrancedUser && '입학회원'} 할인 적용</span>
                        <span>
                          {isEntrancedUser ? (
                            <span>{addComma(Number(totalOrgPrice - totalSalePrice))}</span>
                          ) : (
                            <span>{addComma(Number(totalOrgPrice - totalSalePrice))}</span>
                          )}
                        </span>
                      </li> */}
                      <li>
                        {couponApplyDisplay ? (
                          <>
                            {/* <span>{addComma(Number(totalCouponDiscountPrice))}</span> */}
                            <CouponStateBox>
                              <dl>
                                <dt>적용 가능 쿠폰</dt>
                                <dd>
                                  <strong>
                                    <span>
                                      사용 가능 :{' '}
                                      <em>{availableCoupon.length + allCoupon.length}</em>장
                                    </span>
                                    <span>
                                      전체 : <em>{couponListData.length}</em>장
                                    </span>
                                  </strong>
                                  <BtnDownload onClick={handleOpenModal2}>쿠폰 받기</BtnDownload>
                                </dd>
                              </dl>
                            </CouponStateBox>
                          </>
                        ) : (
                          <>
                            <span>적용 가능 쿠폰</span>
                            <strong>사용 가능한 쿠폰이 없습니다.</strong>
                          </>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
              <ToggleCouponList className={isChecked.couponChk ? `active` : null}>
                <ul>
                  {orderMasters &&
                    orderMasters.map((item, idx) => {
                      return (
                        <li key={idx}>
                          <div className="product-item">
                            <div
                              className={`item-img ${
                                item.packageTypeCode === 'REAL' && 'delivery'
                              }`}
                            >
                              <img src={item.thumbnailPath} alt={item.orderName} />
                            </div>
                            <div className="item-info">
                              <p>
                                <span>
                                  {cartItems[idx] ? cartItems[idx].packageCategoryGroupName : null}
                                  &middot;
                                  {cartItems[idx] ? cartItems[idx].packageCategoryName : null}
                                </span>
                                <em>{cartItems[idx] ? cartItems[idx].teacherName : null}</em>
                              </p>
                              <p>
                                <strong>{item.orderName}</strong>
                              </p>
                              {popCouponDataList &&
                              popCouponDataList.current[idx] &&
                              selectCouponPrice(popCouponDataList.current[idx].couponList) > 0 ? (
                                <>
                                  <CouponResultBox>
                                    <em>쿠폰 적용됨</em>
                                    <strong>
                                      {addComma(
                                        Number(
                                          selectCouponPrice(
                                            popCouponDataList.current[idx].couponList
                                          )
                                        ) * -1
                                      )}
                                      원
                                    </strong>
                                  </CouponResultBox>
                                </>
                              ) : null}
                              {item.packageTypeCode === 'REAL' && (
                                <p>
                                  <em>{item.cnt}</em>
                                </p>
                              )}
                            </div>
                          </div>
                          <ApplyCouponState>
                            {/* 조건 1. 다운로드 가능한 쿠폰이 있고, 해당 상품에 적용이 가능한가? */}
                            {couponDownloadData &&
                            checkCouponBoolean(couponDownloadData, item.packageSeq) ? (
                              <p>
                                <em>즉시 할인 가능! 상단 쿠폰 받기 버튼을 눌러주세요!</em>
                              </p>
                            ) : // 조건 2. 적용 가능한 쿠폰이 존재하고, 쿠폰 적용 이력이 있는가?
                            popCouponDataList.current[idx] &&
                              checkCouponBoolean(
                                popCouponDataList.current[idx].couponList,
                                item.packageSeq
                              ) &&
                              popCouponDataList.current[idx].couponList.filter((item) => {
                                return item.couponChecked
                              }).length > 0 ? (
                              <ButtonBlue
                                type="button"
                                onClick={() =>
                                  callCouponList(
                                    idx,
                                    popCouponDataList.current[idx].couponList,
                                    popCouponDataList.current[idx].couponPriceLimit
                                  )
                                }
                              >
                                쿠폰 변경하기
                              </ButtonBlue>
                            ) : // 조건 3. 적용 가능한 쿠폰이 있는가?
                            popCouponDataList.current[idx] &&
                              checkCouponBoolean(
                                popCouponDataList.current[idx].couponList,
                                item.packageSeq
                              ) ? (
                              <ButtonBlue
                                type="button"
                                onClick={() =>
                                  callCouponList(
                                    idx,
                                    popCouponDataList.current[idx].couponList,
                                    popCouponDataList.current[idx].couponPriceLimit
                                  )
                                }
                              >
                                쿠폰 적용하기
                              </ButtonBlue>
                            ) : (
                              <p>사용 가능한 쿠폰이 없습니다.</p>
                            )}
                          </ApplyCouponState>
                        </li>
                      )
                    })}
                </ul>
                {Number(totalCouponDiscountPrice) > 0 ? (
                  <TotalDiscountCouponBox>
                    <strong>총 쿠폰 적용 금액</strong>
                    <em>(-) {addComma(Number(totalCouponDiscountPrice))}</em>
                  </TotalDiscountCouponBox>
                ) : null}
              </ToggleCouponList>
            </>
          )}
          {/* 결제 수단 */}
          <PaymentType
            paymentTypeList={isPaymentType}
            returnFn={paymentTypeSelect}
            childRef={paymentTypeRef}
          />
          {/* 결제 정보 */}
          <TotalPriceInfo
            priceViewType={isTotalPriceType}
            totalOrderPrice={addComma(Number(totalOrgPrice + addBookTotalPrice))} // 총 주문금액
            deliveryFee={addComma(Number(deliveryFee))} // 배송비
            couponDiscountSum={addComma(Number(totalCouponDiscountPrice))} // 쿠폰 할인금액
            totalDiscountPrice={addComma(Number(totalDiscountPrice))} // 총 할인 금액
            totalPrice={
              // 최종 결제 금액
              totalPrice < MINIMUM_PRICE || installmentMonth <= MINIMUM_MONTH ? (
                <strong>{addComma(Math.floor(Number(totalPrice)))}</strong>
              ) : (
                <>
                  <strong className="point">
                    월 {addComma(Math.floor(Number(totalPrice) / installmentMonth))}
                    <span>
                      총 {addComma(Number(totalPrice))}원 {installmentMonth}개월 무이자 할부 시
                    </span>
                  </strong>
                </>
              )
            }
            isCheck={isChecked.agreeOrder}
            handleCheckedItem={handleCheckedItem}
          />
          <AgreeOrder isChecked={isChecked.agreeOrder} setIsChecked={setIsChecked} />
          {isDesktop && (
            <>
              <section className="box-tb-col">
                <div className="inner-col">
                  {!isIdentityCertification && (
                    <>
                      <div className="certification-box">
                        <p>
                          결제 진행 전 안심 본인인증을 먼저 진행해주세요. (
                          <strong>최초 1회만 진행하시면 됩니다.</strong>)
                        </p>
                        <button
                          type="button"
                          className={'btn-block' + (niceStatus ? ' btn-complete' : '')}
                          onClick={handleNiceModal}
                          disabled={niceStatus ? true : false}
                        >
                          {niceStatus ? '안심 본인인증 완료' : '안심 본인인증'}
                        </button>
                      </div>
                    </>
                  )}
                  <button
                    type="button"
                    className={isValidForm ? ' active' : ' disabled'}
                    onClick={() => {
                      getPaymentUid('PC')
                    }}
                    disabled={
                      (isChecked.agreeOrder ? false : true) &&
                      (!isIdentityCertification || niceStatus ? true : false)
                    }
                  >
                    결제하기
                  </button>
                </div>
              </section>
            </>
          )}
          {isMobile && (
            <>
              <section className="card">
                <div className="inner-col">
                  <div className="order-btn-box">
                    {/* <label htmlFor="agreeOrder">
                      <input type="checkbox" id="agreeOrder" name="agreeOrder" checked={isChecked.agreeOrder ? true : false} onChange={handleCheckedItem} />
                      <i className="chk"></i>
                      <span>주문할 상품정보에 동의하십니까?</span>
                      <p>
                        <em>전자상거래법 제8조 2항</em>
                      </p>
                    </label> */}

                    {!isIdentityCertification && (
                      <>
                        <div>
                          <p>결제 진행 전 안심 본인인증을 먼저 진행해주세요.</p>
                          <p>
                            <strong>최초 1회</strong>만 진행하시면 됩니다.
                          </p>
                        </div>
                        <button
                          type="button"
                          className={'btn-block' + (niceStatus ? ' btn-complete' : '')}
                          onClick={handleNiceModal}
                          disabled={niceStatus ? true : false}
                        >
                          {niceStatus ? '안심 본인인증 완료' : '안심 본인인증'}
                        </button>
                      </>
                    )}

                    <button
                      type="button"
                      className={isValidForm ? 'btn-block active' : 'btn-block disabled'}
                      onClick={() => getPaymentUid('MOBILE')}
                      disabled={
                        (isChecked.agreeOrder ? false : true) &&
                        (!isIdentityCertification || niceStatus ? true : false)
                      }
                    >
                      결제하기
                    </button>
                  </div>
                </div>
              </section>
            </>
          )}
        </article>
        <DaumPostCode className="post-code" onComplete={handlePostCode} autoClose animation />
        {modalopen2 ? (
          <ModalPortal>
            {couponDownloadData && (
              <ModalCouponDownload
                btnClose
                handleCloseModal={handleCloseModal2}
                couponDownloadData={couponDownloadData}
                getCouponDownloadData={getCouponDownloadData}
              />
            )}
          </ModalPortal>
        ) : null}
        {modalopen3 ? (
          <ModalPortal>
            {applyCouponData && (
              <ModalCouponDuplicate
                btnClose
                handleCloseModal={handleCloseModal3}
                couponListData={applyCouponData}
                couponSelected={couponSelected}
                couponPriceLimit={couponPriceLimit}
              />
            )}
          </ModalPortal>
        ) : null}
      </ThemeProvider>
    </>
  )
}

const MyCounponBox = styled.div`
  padding: 1.7rem 0;
  span {
    display: inline-block;
    position: relative;
    font-weight: 400;
    font-size: 1.6rem;
    color: #000;
    em {
      font-weight: 700;
      color: #269cff;
    }
    & + span {
      padding-left: 1.3rem;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0.5rem;
        bottom: 0;
        width: 0.1rem;
        height: 1rem;
        margin: auto;
        border-right: 0.1rem solid #d4d4d4;
      }
    }
  }
`

const BtnDownload = styled.button`
  position: relative;
  height: 3.6rem;
  margin-left: 3rem;
  padding: 0.2rem 4rem 0 1.5rem;
  font-weight: 700;
  font-size: 1.2rem;
  color: #269cff;
  background: #fff url(${ico_button_download}) no-repeat calc(100% - 1rem) 50% / 1.7rem auto;
  border: 0.1rem solid #269cff;
  border-radius: 0.3rem;
  box-sizing: border-box;
  @media ${({ theme }) => theme.device.mobile} {
    height: 5.2rem;
    padding: 0.2rem 5.4rem 0 2rem;
    font-size: 2rem;
    border: 0.2rem solid #269cff;
    border-radius: 0.6rem;
    background: #fff url(${ico_button_download}) no-repeat calc(100% - 1rem) 50% / 2.5rem auto;
  }
`

const ApplyCouponState = styled.div`
  p {
    font-weight: 700;
    font-size: 1.2rem;
    color: #a0a0b6;
    line-height: 1.4;
    text-align: center;
    em {
      color: #269cff;
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    p {
      min-height: 7.2rem;
      padding: 2.2rem 0;
      font-size: 2rem;
      background-color: #ececf6;
      box-sizing: border-box;
      border-radius: 0.5rem;
    }
  }
`

const MoCouponFold = styled.label`
  display: block;
  width: 100%;
  height: 10rem;
  margin: 4.2rem auto -5rem;
  border-top: 0.1rem dashed #b7b7c8;
  text-align: center;
  span {
    display: inline-block;
    position: relative;
    padding: 3.7rem 4.5rem 3.7rem 0;
    font-weight: 400;
    font-size: 2.7rem;
    line-height: 100%;
    color: #000;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 1.2rem;
      bottom: 0.4rem;
      width: 0.8rem;
      height: 0.8rem;
      margin: auto;
      border-bottom: 0.2rem solid #a0a0b6;
      border-left: 0.2rem solid #a0a0b6;
      transform: rotate(-45deg);
      transition: 0.2s;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 3rem;
      height: 3rem;
      margin: auto;
      border: 0.2rem solid #a0a0b6;
      border-radius: 100%;
    }
  }
  input:checked + span {
    &:before {
      top: 0.6rem;
      transform: rotate(-225deg);
    }
  }
`

const ToggleCouponList = styled.section`
  display: none;
  padding: 5rem 4.2rem;
  background-color: #f7f7fc;
  &.active {
    display: block;
  }
  h4 {
    font-weight: 700;
    font-size: 3rem;
    color: #000;
    text-align: left;
  }
  li {
    padding: 4rem 0 3rem;
    .product-item {
      display: flex;
      margin-bottom: 3.4rem;
      .item-info {
        p {
          font-weight: 400;
          font-size: 2.2rem;
          text-align: left;
          strong {
            width: 100%;
            font-weight: 700 !important;
            font-size: 2.7rem !important;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            em {
              font-weight: 400;
              font-size: 2.4rem;
              color: #ff5647;
            }
          }
        }
      }
    }
  }
`

const TextBookBox = styled.div`
  position: relative;
  width: 100%;
  margin-top: -0.1rem;
  padding: 3.2rem 4.2rem;
  background-color: #f7f7fc;
  box-sizing: border-box;
  dl {
    dt {
      padding-bottom: 1.6rem;
      font-weight: 700;
      font-size: 1.6rem;
      color: #000;
      text-align: left;
      border-bottom: 0.1rem solid #e8e8f2;
    }
    dd {
      ul {
        li {
          border-bottom: 0.1rem solid #e8e8f2;
          &:last-child {
            border-bottom: 0;
          }
          label {
            display: flex !important;
            justify-content: center;
            align-items: center;
            padding: 2rem 0 !important;
            input:checked + span {
              &:before {
                background: url(${ico_check_active_96x96}) no-repeat 50% 50% / 100% auto;
                border: 0;
              }
            }
            span {
              display: inline-block;
              position: relative;
              width: 2.4rem;
              height: 2.4rem;
              &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #fff;
                border: 0.1rem solid #cfcfda;
                border-radius: 100%;
                box-sizing: border-box;
              }
              & + strong {
                margin-left: 1.1rem;
              }
            }
            .imgBox {
              margin: 0px 1.6rem 0px 1.1rem;
              width: 8rem;
              height: 5.5rem;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: fill;
              border-radius: 0.4rem;
            }
            input:checked + span + strong,
            input:checked + span + img + strong {
              font-weight: 700;
            }
            strong {
              display: inline-block;
              flex: 1 auto;
              font-weight: 400;
              font-size: 1.6rem;
              color: #000;
              text-align: left;
            }
            em {
              font-weight: 700;
              font-size: 1.8rem;
              color: #000;
            }
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    width: calc(100% + 8.4rem);
    margin: -4.7rem 0 -5rem -4.2rem;
    padding: 4.2rem 4rem 0;
    dl {
      dt {
        padding-bottom: 2.4rem;
        font-size: 2.4rem;
      }
      dd {
        ul {
          li {
            label {
              padding: 3.6rem 0 !important;
              span {
                width: 4.8rem;
                height: 4.8rem;
              }
              .imgBox {
                margin: 0 2.4rem;
                width: 11.8rem;
                height: 8rem;
              }

              strong {
                font-size: 2.4rem;
                color: #28284b;
                em {
                  font-size: 2.8rem;
                  color: #000;
                }
              }
            }
          }
        }
      }
    }
  }
`

const DeliverySelectBox = styled.div`
  position: relative;
  width: 62.8rem;
  padding: 0 !important;
  z-index: 2;
  select {
    width: 100%;
    padding: 1.4rem 1.5rem;
    outline: 0;
    border: 0.1rem solid #e7e7f0;
    border-radius: 0.2rem;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 1.6rem;
    color: #b7b7c8;
    background-color: transparent;
    &.bold {
      color: #000;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 1.4rem;
    bottom: 0;
    width: 0.5rem;
    height: 0.5rem;
    margin: auto;
    border-bottom: 0.1rem solid #000;
    border-left: 0.1rem solid #000;
    transform: rotate(-45deg);
    z-index: -1;
  }
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    padding: 1.6rem 0 0 0 !important;
    select {
      padding: 2.8rem 2.5rem;
      font-size: 2.9rem;
      border: 0.2rem solid #e7e7f0;
    }
    &:after {
      top: 0.4rem;
      right: 3.5rem;
      width: 1.2rem;
      height: 1.2rem;
      border-bottom: 0.2rem solid #000;
      border-left: 0.2rem solid #000;
    }
  }
`

const DeliveryInputBox = styled.div`
  position: relative;
  width: 62.8rem;
  margin-top: 1.2rem;
  z-index: 2;
  input {
    width: 100%;
    padding: 1.4rem 1.5rem;
    outline: 0;
    border: 0.1rem solid #e7e7f0;
    border-radius: 0.2rem;
    box-sizing: border-box;
    &::placeholder {
      font-weight: 400;
      font-size: 1.6rem;
      color: #b7b7c8;
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    input {
      padding: 2.8rem 2.5rem !important;
      font-size: 2.9rem;
      border: 0.2rem solid #e7e7f0 !important;
      &::placeholder {
        font-size: 2.9rem;
      }
    }
  }
`

const ButtonBlue = styled.button`
  width: 20rem;
  height: 4.3rem;
  font-weight: 700;
  font-size: 1.2rem;
  color: #fff;
  background-color: #269cff;
  border-radius: 0.3rem;
  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 7.2rem;
    font-size: 2.2rem;
    border-radius: 0.4rem;
  }
`

const FlexTd = styled.td`
  display: flex;
  & > div:first-child {
    flex: 1 auto;
  }
`

const CouponResultBox = styled.div`
  display: block;
  width: 24rem;
  height: 100%;
  padding-top: 1.4rem;
  text-align: center;
  flex-shrink: 0;
  em {
    display: inline-block;
    margin-bottom: 0.6rem;
    padding: 0.2rem 0.6rem;
    font-size: 1rem;
    color: #ff5647;
    background-color: #fddfdf;
    border-radius: 0.2rem;
  }
  strong {
    display: block;
    text-align: center;
    font-weight: 700;
    font-size: 1.8rem;
    color: #ff5647;
  }
  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    width: 100%;
    text-align: left;
    padding-top: 0;
    em {
      margin: 0 1.5rem 0 0 !important;
      padding: 0.4rem 1rem !important;
      font-weight: 700;
      color: #ff5647 !important;
      border-radius: 0.4rem;
    }
    strong {
      font-size: 2.4rem !important;
      color: #ff5647 !important;
    }
  }
`

const TotalDiscountCouponBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 9.4rem;
  text-align: right;
  strong {
    display: inline-block;
  }
  em {
    display: inline-block;
    margin: 0 4.6rem 0 1.5rem;
    font-weight: 700;
    font-size: 2.6rem;
    color: #000;
    letter-spacing: 0.1rem;
    &:after {
      content: '원';
      font-size: 2.2rem;
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    height: 13.4rem;
    margin: 0 -4.2rem -5rem -4.2rem;
    padding: 0 4.2rem;
    strong {
      font-size: 3rem;
      font-weight: 700;
    }
    em {
      flex: 1 auto;
      margin: 0;
      font-weight: 700;
      font-size: 2.6rem;
      color: #28284b;
      &:after {
        font-size: 2.6rem;
      }
    }
    background-color: #fff;
  }
`

const CouponStateBox = styled.div`
  flex-shrink: 0;
  display: block;
  min-width: 100%;
  padding-top: 0.5rem;
  dl {
    dd {
      display: flex;
      align-items: flex-end;
      padding-top: 0.5rem;
      strong {
        font-weight: 400;
        flex: 1 auto;
        span {
          font-size: 2.6rem;
          font-weight: 400;
          &:before {
            content: '' !important;
          }
          & + span {
            display: inlnie-block;
            position: relative;
            margin-left: 1rem;
            padding-left: 1.2rem;
            font-weight: 400 !important;
            &:before {
              content: '' !important;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              width: 0.1rem;
              height: 2rem;
              margin: auto;
              border-right: 0.1rem solid #d4d4d4;
            }
            &:after {
              display: none;
            }
          }
          em {
            font-weight: 700;
            color: #269cff;
          }
        }
      }
    }
  }
`

const HideIframe = styled.iframe`
  display: none;
`
