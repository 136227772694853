import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import useGetUser from 'hooks/useGetUser'
import useModalLogin from 'store/useModalLogin'
import { Swiper, SwiperSlide } from 'swiper/react'
import { v4 as uuid } from 'uuid'

import CharacterItem from './CharacterItem'
import s from '../aiTalk.module.css'
import 'swiper/swiper.min.css'
import { aiCharacterValuse } from '../config'

export default function SwiperCharacter({ setIdx }) {
  const { user } = useGetUser()
  const [searchParams] = useSearchParams()
  const [swiper, setSwiper] = useState(null)
  const { setIsModalLogin } = useModalLogin()

  const swiperParams = {
    // autoplay: {
    //   delay: 5000,
    //   disableOnInteraction: false,
    //   pauseOnMouseEnter: true
    // },
    centeredSlides: true,
    loop: true,
    pagination: { el: '#pagination', clickable: true, bulletActiveClass: s.bulletActive },
    breakpoints: {
      1024: {
        threshold: 50
      },
      750: {
        threshold: 0
      }
    },
    onSlideChange: function (swiper) {
      setIdx(swiper.realIndex)
    },
    onSwiper: setSwiper
  }

  return (
    <>
      <div id="pagination" className={s.pagination}></div>
      <div className={s.charCard}>
        <Swiper {...swiperParams}>
          {aiCharacterValuse.map((item) => {
            return (
              <SwiperSlide key={uuid()}>
                <CharacterItem data={item} userSeq={user ? user.userSeq : searchParams.get('seq')} key={item.key} setModalLogin={setIsModalLogin} />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
      <button className={s.btnPrev} onClick={() => swiper.slidePrev()}>
        PREV
      </button>
      <button className={s.btnNext} onClick={() => swiper.slideNext()}>
        NEXT
      </button>
    </>
  )
}
