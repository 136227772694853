import { useEffect, useState, useCallback, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'

import ModalPortal from 'ModalPortal'
import AuthService from 'services/authService'
import useModalLogin from 'store/useModalLogin'
import styled, { ThemeProvider } from 'styled-components'
import { Pagination, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import api from 'common/api'
import * as config from 'common/config'
import { setCookie, getCookie } from 'common/Cookie'
import theme from 'common/theme'
import ModalCourse from 'components/modal/ModalCourse'
import ModalInstallments from 'components/modal/ModalInstallments'

import bg_thumnail from 'assets/images/bg_thumb_dim_v2.png'
import IcoInfo2 from 'assets/images/ico_info2.png'
import IcoStar from 'assets/images/ico_star_40x40_orange.png'

import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'

const MINIMUM_PRICE = 50_000
const MINIMUM_MONTH = 1

export default function StoreDetailCard({ classData }) {
  const navigate = useNavigate()
  const [isEntrancedUser, setIsEntrancedUser] = useState(false) // 입학 여부
  const [isEntrancedCheck, setIsEntrancedCheck] = useState(false) // 입학 체크여부

  const [modalCourse, setModalCourse] = useState(false)
  const [isProductSeq, setProductSeq] = useState('')
  const [installmentsModal, setInstallmentsModal] = useState(false)
  const [installPnt, setInstallPnt] = useState(false)
  const menuBarRef = useRef()
  const thumnailRef = useRef()
  const isDesktop = useMediaQuery({ minWidth: 1024 })
  const { setIsModalLogin } = useModalLogin()

  const addComma = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const scrollCheck = () => {
    if (thumnailRef.current && thumnailRef.current.getBoundingClientRect().top < 150) {
      setInstallPnt(true)
      menuBarRef.current.classList.add('fixed')
    } else {
      menuBarRef.current.classList.remove('fixed')
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', scrollCheck)

    return () => {
      window.removeEventListener('scroll', scrollCheck)
      document.body.classList.remove('modal-open')
    }
  }, [])
  // 유저정보 가져오기
  const getUserInfo = () => {
    if (AuthService.getUserInfo()) {
      api
        .get(config.AUTH_API_HOST + '/auth/v2/user')
        .then((response) => {
          setIsEntrancedUser(response.data.data?.entrance?.entranceStatus === 'ENTRANCE')
          setIsEntrancedCheck(true)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }
  useEffect(() => {
    getUserInfo()
  }, [])

  const handleOpenModalLogin = useCallback((e) => {
    document.body.classList.add('modal-open')
    setCookie('orderClickCheck', 'Y')
    setIsModalLogin(true)
  }, [])

  const handleOpenModalCourse = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalCourse(true)
  }, [])

  const handleCloseModalCourse = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalCourse(false)
  }, [])

  const handleOpenModalInstallments = useCallback((e) => {
    document.body.classList.add('modal-open')
    setInstallmentsModal(true)
  }, [])

  const handleCloseModalInstallments = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setInstallmentsModal(false)
  }, [])
  const showErrorMessage = (response) => {
    if (response.data.meta.message) {
      alert(response.data.meta.message)
    } else {
      alert(config.MESSAGE['common-error'])
    }
  }

  const orderStart = (productSeq) => {
    typeof productSeq === `string` && productSeq.length > 0 ? setProductSeq(productSeq) : setProductSeq(``)
    directOrder(productSeq)
  }

  const directOrder = (productSeq) => {
    const packageSeq = classData.packageSeq
    const goodsParam = typeof productSeq === `string` && productSeq.length > 0 ? `?presentProductSeq=${productSeq}` : ``

    // 주문번호 재셋팅을 위한 productParam 값 셋팅.
    window.sessionStorage.setItem('productParam', JSON.stringify({ param: `${packageSeq}${goodsParam}`, orderReset: false }))

    api
      .post(`/payment/v2/order/${packageSeq}${goodsParam}`)
      .then((response) => {
        if (response.data.meta?.code === 200) {
          navigate(`/mypage/order/cart/detail/${response.data.data}`)
        } else {
          showErrorMessage(response)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const dataChkPriceInfo = () => {
    try {
      if (classData.packageSeq) getUserInfo()
      if (window['checkURL'] !== window.location.href && classData.packageSeq !== undefined) {
        window['dataChkPriceInfo'](classData.title, !!document.getElementById('productPrice') ? Number(document.getElementById('productPrice').innerText.replace(',', '').replace(',', '')) : 0, classData.packageCategoryGroupName + ', ' + classData.packageCategoryName, classData.packageSeq)
        window['checkURL'] = window.location.href
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    dataChkPriceInfo()

    return () => {
      document.body.classList.remove('scroll-fixed')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (isEntrancedCheck) {
      // 렌더링 후 orderClickCheck, orderLoginSuccess 쿠키값 체크, 로그인 여부 값을 체크합니다.
      // 1. getCookie('orderClickCheck') === 'Y' : 비로그인 상태에서 수강신청버튼을 클릭한 경우를 체크하기 위한 목적.
      // 2. getCookie('orderLoginSuccess') === 'Y' : 로그인에 성공했으며, reloadType 인 경우를 체크하기 위한 목적. (Login.jsx)
      // 3. AuthService.getUserInfo() : 유저 정보 유무를 체크하기 위한 목적.
      // 1~3 모두 true 라면 .btn-cart 버튼을 클릭 시켜 결제 화면 or 입학 팝업이 노출되도록 처리하고, 쿠키값과 state 값을 초기화 합니다.
      if (getCookie('orderClickCheck') === 'Y' && getCookie('orderLoginSuccess') === 'Y' && AuthService.getUserInfo()) {
        if (document.querySelector('.btn-cart')) {
          setCookie('orderLoginSuccess', 'N')
          setCookie('orderClickCheck', 'N')
          document.querySelector('.btn-cart').click()
        }
      }
    }
  }, [isEntrancedCheck])
  // console.log(classData);
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <ImgBox>
          {classData.packageImages && classData.packageImages.length > 1 ? (
            <Swiper
              autoplay={{ delay: 30000 }}
              navigation={true}
              pagination={{
                type: 'fraction'
              }}
              modules={[Pagination, Navigation]}
            >
              {classData.packageImages.map((imgUrl, idx) => {
                return (
                  <SwiperSlide key={idx} className="swiper-slide">
                    <img src={imgUrl} alt="" />
                  </SwiperSlide>
                )
              })}
            </Swiper>
          ) : (
            <div className="img-box">
              <img src={classData.packageImages} alt="" />
            </div>
          )}
          <TagBox>
            <ul>{classData.hashTags && classData.hashTags.map((el, idx) => <li key={idx}>#{el.hashTagName}</li>)}</ul>
          </TagBox>
        </ImgBox>
        <TitleContainer>
          <TitleBox>
            <div className="category">
              {classData.packageCategoryGroupName}
              &middot;
              {classData.packageCategoryName}
            </div>
            <div className="title">{classData.title}</div>

            <div className="scoreBox">
              <span className="score">{classData.reviewSatisfaction}</span>
              <em>{classData.reviewCount >= 100 ? '100+' : classData.reviewCount}</em>
              <span className="teacher">{classData.teacherInfo}</span>{' '}
            </div>
          </TitleBox>

          <BtnBox>
            <BtnCart
              onClick={
                !AuthService.getUserInfo()
                  ? handleOpenModalLogin // 로그인 모달
                  : classData.requireEntranceYn === 'Y' && !isEntrancedUser // 입학 포함 상품
                  ? handleOpenModalCourse // 입학 안내 모달
                  : orderStart // 구매
              }
              style={{ cursor: 'default' }}
              disabled
            >
              {isEntrancedUser ? (
                //입학회원
                <>
                  {classData.exceptOptionSalePrice < MINIMUM_PRICE || classData.installmentMonth <= MINIMUM_MONTH ? (
                    <>
                      <strong>
                        월 {addComma(Number(classData.exceptOptionMembershipSalePrice))}원 {isDesktop ? '으로 시작하기' : null}
                      </strong>
                      ({classData.exceptOptionDiscountPercent}&#37;할인)
                    </>
                  ) : (
                    <>
                      {classData.exceptOptionSalePrice !== classData.exceptOptionMembershipSalePrice ? (
                        <>
                          <strong>
                            월 {addComma(Number(classData.monthlyMembershipSalePrice))}원 {isDesktop ? '으로 시작하기' : null}
                          </strong>
                          ({classData.exceptOptionDiscountPercent}&#37;할인)
                        </>
                      ) : (
                        <strong>
                          월 {addComma(Number(classData.monthlyMembershipSalePrice))}원 {isDesktop ? '으로 시작하기' : null}
                        </strong>
                      )}
                    </>
                  )}

                  <span className="member">
                    입학
                    <br />
                    특가
                  </span>
                </>
              ) : (
                //일반회원
                <>
                  {classData.exceptOptionSalePrice < MINIMUM_PRICE || classData.installmentMonth <= MINIMUM_MONTH ? (
                    <>
                      <strong>
                        월 {addComma(Number(classData.exceptOptionMembershipSalePrice))}원 {isDesktop ? '으로 시작하기' : null}
                      </strong>{' '}
                      ({Math.floor(((Number(classData.exceptOptionOriginalPrice / classData.installmentMonth) - Number(classData.exceptOptionSalePrice / classData.installmentMonth)) / Number(classData.exceptOptionOriginalPrice / classData.installmentMonth)) * 100)}
                      &#37;할인)
                    </>
                  ) : (
                    <>
                      {classData.exceptOptionSalePrice !== classData.exceptOptionOriginalPrice ? (
                        <>
                          <strong>
                            월 {addComma(Math.floor(Number(classData.exceptOptionSalePrice / classData.installmentMonth)))}원 {isDesktop ? '으로 시작하기' : null}
                          </strong>{' '}
                          ({Math.floor(((Number(classData.exceptOptionOriginalPrice / classData.installmentMonth) - Number(classData.exceptOptionSalePrice / classData.installmentMonth)) / Number(classData.exceptOptionOriginalPrice / classData.installmentMonth)) * 100)}
                          &#37;할인)
                        </>
                      ) : (
                        <strong>
                          월 {addComma(Number(classData.monthlySalePrice))}원{isDesktop ? '으로 시작하기' : null}
                        </strong>
                      )}
                    </>
                  )}

                  <span>
                    타임
                    <br />
                    특가
                  </span>
                </>
              )}
            </BtnCart>
            <BtnInstallmentsInfo type="button" onClick={handleOpenModalInstallments} style={{ cursor: 'default' }} disabled>
              <span>무이자 안내</span>
            </BtnInstallmentsInfo>
          </BtnBox>
        </TitleContainer>
        {/* 가격 고정버튼 */}
        <div ref={thumnailRef}>
          <FixedBtnBox ref={menuBarRef}>
            <div className="FixedBtnContainer">
              <BtnInstallmentsInfo className={installPnt === true ? 'fixed' : ''} type="button" onClick={handleOpenModalInstallments} style={{ cursor: 'default' }} disabled>
                <span>무이자 안내</span>
              </BtnInstallmentsInfo>
              <BtnCart
                onClick={
                  !AuthService.getUserInfo()
                    ? handleOpenModalLogin // 로그인 모달
                    : classData.requireEntranceYn === 'Y' // 입학 포함 상품
                    ? handleOpenModalCourse // 입학 안내 모달
                    : orderStart // 구매
                }
                style={{ cursor: 'default' }}
                disabled
              >
                {isEntrancedUser ? (
                  //입학회원
                  <>
                    {classData.exceptOptionSalePrice < MINIMUM_PRICE || classData.installmentMonth <= MINIMUM_MONTH ? (
                      <>
                        <strong>
                          월 {addComma(Number(classData.exceptOptionMembershipSalePrice))}원 {isDesktop ? '으로 시작하기' : null}
                        </strong>
                        ({classData.exceptOptionDiscountPercent}&#37;할인)
                      </>
                    ) : (
                      <>
                        {classData.exceptOptionSalePrice !== classData.exceptOptionMembershipSalePrice ? (
                          <>
                            <strong>
                              월 {addComma(Number(classData.monthlyMembershipSalePrice))}원 {isDesktop ? '으로 시작하기' : null}
                            </strong>
                            ({classData.exceptOptionDiscountPercent}&#37;할인)
                          </>
                        ) : (
                          <strong>
                            월 {addComma(Number(classData.monthlyMembershipSalePrice))}원 {isDesktop ? '으로 시작하기' : null}
                          </strong>
                        )}
                      </>
                    )}

                    <span className="member">
                      입학
                      <br />
                      특가
                    </span>
                  </>
                ) : (
                  //일반회원
                  <>
                    {classData.exceptOptionSalePrice < MINIMUM_PRICE || classData.installmentMonth <= MINIMUM_MONTH ? (
                      <>
                        <strong>
                          월 {addComma(Number(classData.exceptOptionMembershipSalePrice))}원 {isDesktop ? '으로 시작하기' : null}
                        </strong>{' '}
                        ({Math.floor(((Number(classData.exceptOptionOriginalPrice / classData.installmentMonth) - Number(classData.exceptOptionSalePrice / classData.installmentMonth)) / Number(classData.exceptOptionOriginalPrice / classData.installmentMonth)) * 100)}
                        &#37;할인)
                      </>
                    ) : (
                      <>
                        {classData.exceptOptionSalePrice !== classData.exceptOptionOriginalPrice ? (
                          <>
                            <strong>
                              월 {addComma(Math.floor(Number(classData.exceptOptionSalePrice / classData.installmentMonth)))}원 {isDesktop ? '으로 시작하기' : null}
                            </strong>{' '}
                            ({Math.floor(((Number(classData.exceptOptionOriginalPrice / classData.installmentMonth) - Number(classData.exceptOptionSalePrice / classData.installmentMonth)) / Number(classData.exceptOptionOriginalPrice / classData.installmentMonth)) * 100)}
                            &#37;할인)
                          </>
                        ) : (
                          <strong>
                            월 {addComma(Number(classData.monthlySalePrice))}원{isDesktop ? '으로 시작하기' : null}
                          </strong>
                        )}
                      </>
                    )}
                    <span>
                      타임
                      <br />
                      특가
                    </span>
                  </>
                )}
              </BtnCart>
            </div>
          </FixedBtnBox>
        </div>

        {modalCourse ? (
          <ModalPortal>
            <ModalCourse btnClose goodsDirectOrder={orderStart} handleCloseModal={handleCloseModalCourse} showErrorMessage={showErrorMessage} />
          </ModalPortal>
        ) : null}

        {installmentsModal ? (
          <ModalPortal>
            <ModalInstallments handleClose={handleCloseModalInstallments} btnClose title={'무이자 할부 혜택'} />
          </ModalPortal>
        ) : null}
      </Container>
    </ThemeProvider>
  )
}
const Container = styled.div`
  position: relative;
  background-color: black;
`
const ImgBox = styled.div`
  position: relative;
  max-width: 130rem;
  height: 87.2rem;
  margin: 0 auto;
  .swiper-container {
    z-index: auto;
  }
  .swiper-button-prev {
    color: #fff;
    &::after {
      font-size: 3.2rem;
    }
    margin-left: -1rem;
  }
  .swiper-button-next {
    color: #fff;
    &::after {
      font-size: 3.2rem;
    }
    margin-right: -1rem;
  }
  .swiper-pagination {
    text-align: right;
    left: -4.5rem;
    z-index: 10;
    color: #fff;
    top: 3.5rem;
    font-size: 1.4rem;
  }
  .swiper-slide {
    img {
      min-width: 130rem;
      height: 87.2rem;
    }
  }
  .img-box {
    img {
      width: 100%;
      /* min-height: 87.2rem; */
      max-height: 87.2rem;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: calc(50% - 96rem);
    width: 192rem;
    height: 87.2rem;
    background: url(${bg_thumnail}) no-repeat center / 100% auto;
    z-index: 1;
    @media ${({ theme }) => theme.device.mobile} {
      z-index: 1;
      width: 100%;
      height: 100%;
      left: 0;
      background: none;
      background: linear-gradient(0deg, #000 0%, #000 5%, #000 35%, transparent 100%);
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    max-width: 100%;
    height: 114rem;
    .img-box {
      /* height: 114rem; */
      img {
        width: 100%;
        height: 53vh;
        object-fit: cover;
        margin-bottom: 37rem;
      }
    }

    .swiper-slide {
      img {
        min-width: 100%;
        height: 53vh;
        object-fit: cover;
        margin-bottom: 37rem;
      }
    }
    .swiper-button-prev {
      display: none;
    }
    .swiper-button-next {
      display: none;
    }
    .swiper-pagination {
      font-size: 2.4rem;
      left: -4.2rem;
      font-weight: 700;
    }
  }
`
const TagBox = styled.div`
  position: absolute;
  color: #fff;
  font-size: 1.4rem;
  top: 3.5rem;
  left: 4.5rem;
  z-index: 2;
  /* transform: translate(-23%, 0); */
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 80%;
  }
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 2.4rem;
    left: 4.2rem;
  }
`
const TitleContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 12;
`

const TitleBox = styled.div`
  /* background-color: white; */
  color: #ffffff;

  .category {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    width: fit-content;
    height: 2.7rem;
    background-color: #ffffff;
    padding: 0.5rem 1.8rem;
    color: black;
    text-align: center;
    border-radius: 10rem;
    margin: 0 auto;
  }
  .title {
    padding: 2rem 0;
    font-size: 4rem;
    font-weight: 700;
    max-width: 70rem;
    min-width: 70rem;
    text-align: center;
    margin: 0 auto;
    word-break: keep-all;
  }
  .scoreBox {
    font-size: 1.8rem;
    margin: 0 auto;
    text-align: center;

    .score {
      display: inline-block;
      vertical-align: middle;
      font-weight: 700;
    }
    em {
      display: inline-block;
      vertical-align: middle;
      font-weight: 700;
      :before {
        content: '(';
        display: inline-block;
      }
      :after {
        content: ')';
        display: inline-block;
      }
    }
    .teacher {
      margin-left: 1.8rem;
      vertical-align: middle;
    }
  }

  .score:before {
    content: '';
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin: -0.3rem 0.7rem 0 0;
    background: url(${IcoStar}) 0 0/100% no-repeat;
    vertical-align: middle;
  }
  @media ${({ theme }) => theme.device.mobile} {
    .category {
      font-size: 2.8rem;
      padding: 1.1rem 2.5rem;
    }
    .title {
      font-size: 4.8rem;
      padding: 3rem 0;
      font-weight: 700;
    }
    .scoreBox {
      font-size: 2.8rem;
    }
  }
`
const FixedBtnBox = styled.div`
  display: none;
  background-color: rgba(40, 40, 75, 0.7);
  padding: 0.8rem 0;
  max-width: 78.6rem;
  width: 100%;
  margin: 0 auto;
  border-radius: 0.8rem;

  .FixedBtnContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.8rem;
  }
  &.fixed {
    position: fixed;
    display: block;
    bottom: 0.8rem;
    left: 0;
    right: 0;
    z-index: 10;
  }
  @media ${({ theme }) => theme.device.mobile} {
    padding: 1.6rem 0;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    max-width: 100%;
    .FixedBtnContainer {
      padding: 0 3.2rem;
    }
    &.fixed {
      bottom: 0;
    }
  }
`
const BtnBox = styled.div`
  display: flex;
  padding: 3rem 0 4.4rem;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const BtnCart = styled.button`
  position: relative;
  padding: 1.7rem 5.8rem;
  color: #000;
  text-align: center;
  background-color: #ffb900;
  border-radius: 0.5rem;
  font-size: 1.6rem;
  strong {
    font-weight: 700;
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -1.6rem;
    right: -2rem;
    width: 5.2rem;
    height: 5.2rem;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.1;
    color: #fff;
    background-color: #ff5647;
    border-radius: 100%;
    z-index: 2;
    &:after {
      content: '';
      position: absolute;
      left: 0.1rem;
      bottom: 0.3rem;
      width: 0;
      height: 0;
      border-bottom: 1rem solid transparent;
      border-right: 1rem solid #ff5647;
      transform: rotate(65deg);
      z-index: -1;
    }
  }
  .member {
    background-color: #17c624;
    &:after {
      border-bottom: 1rem solid transparent;
      border-right: 1rem solid #17c624;
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 2.8rem;
    padding: 2.3rem 9rem;
    span {
      font-size: 2rem;
      top: -2rem;
      right: -1.7rem;
      width: 7.2rem;
      height: 7.2rem;
      &:after {
        left: 0.36rem;
        bottom: 0.35rem;
      }
    }
  }
`
const FixedBtnCart = styled.button`
  position: relative;
  padding: 1.4rem 3.9rem;
  color: #000;
  text-align: center;
  background-color: #ffb900;
  border-radius: 0.5rem;
  font-size: 1.6rem;
  strong {
    font-weight: 700;
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -3.5rem;
    right: -2rem;
    width: 5.2rem;
    height: 5.2rem;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.1;
    color: #fff;
    background-color: #ff5647;
    border-radius: 100%;
    z-index: 2;
    &:after {
      content: '';
      position: absolute;
      left: 0.1rem;
      bottom: 0.3rem;
      width: 0;
      height: 0;
      border-bottom: 1rem solid transparent;
      border-right: 1rem solid #ff5647;
      transform: rotate(65deg);
      z-index: -1;
    }
  }
  .member {
    background-color: #17c624;
    &:after {
      border-bottom: 1rem solid transparent;
      border-right: 1rem solid #17c624;
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 2.8rem;
    padding: 1.9rem 5.2rem;
    span {
      font-size: 2rem;
      top: -4.8rem;
      right: -2.3rem;
      width: 7.2rem;
      height: 7.2rem;
      &:after {
        left: 0.36rem;
        bottom: 0.35rem;
      }
    }
  }
`
const BtnInstallmentsInfo = styled.button`
  padding: 1rem 0;
  text-align: center;
  &.fixed {
    width: 10rem;
    margin-left: 2rem;
  }
  span {
    display: inline-block;
    padding: 0.2rem 2.1rem 0 0;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1;
    color: rgba(236, 236, 246, 1);
    background: url(${IcoInfo2}) no-repeat 99% 50% / 1.9rem auto;
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 3rem;
    &.fixed {
      margin-top: 0;
      width: 19rem;
      margin-left: 0;
    }
    span {
      font-size: 2.8rem;
      padding: 0.2rem 3.6rem 0 0;
      background: url(${IcoInfo2}) 99% 0 /3.4rem auto no-repeat;
    }
  }
`
