import { useState, useEffect, useCallback } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'

import { v4 as uuid } from 'uuid'

import api from 'common/api'

import Pagination from '../../components/Pagination'

import '../../assets/styles/faq.scss'
import MobileHeader from 'components/common/MobileHeader'

export default function Faq() {
  const navigate = useNavigate()
  const { itemSeq, currentSeq } = useParams()
  const [faqData, setFaqData] = useState([])
  const [tabMenu, setTabmenu] = useState(itemSeq.toUpperCase())
  const [search, setSearch] = useState(faqData)
  const [searchCont, setSearchCont] = useState(0)
  const [faqCategory, setFaqCategory] = useState([])
  const [totalPages, setTotalPages] = useState()
  const [content, setContent] = useState('')

  const handleTabMenu = (item) => {
    setTabmenu(item.code)
    setSearchCont(0)
  }

  const getFaqCategory = () => {
    api
      .get(`/v2/faq/group`)
      .then((response) => {
        setFaqCategory(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getFaqData = useCallback(async () => {
    try {
      const response = await api.get(`/v2/faq/list?faqGroup=${itemSeq.toUpperCase()}&index=${currentSeq}&size=5`)
      const data = response.data.data
      setFaqData(data.content)
      setTotalPages(data.totalPages)
    } catch (error) {
      console.error('Error: ', error)
    }
  }, [itemSeq, currentSeq])

  const getSearchData = () => {
    setTabmenu('ALL')
    setSearchCont(1)

    api
      .get('/v2/faq/list?faqGroup=ALL&size=999')
      .then((response) => {
        setFaqData(response.data.data.content)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const filter = (e) => {
    const keyword = e.target.value

    if (keyword !== '') {
      const results = faqData.filter((item) => {
        return item.title.toLowerCase().indexOf(keyword.toLowerCase()) > -1
      })
      setSearch(results)
    } else {
      setSearch(faqData)
    }

    setContent(keyword)
  }

  useEffect(() => {
    getFaqData()
    getFaqCategory()
  }, [getFaqData])

  const fetchContent = async (seq) => {
    try {
      const response = await api.get(`/v2/faq/${seq}`)
      return response.data
    } catch (error) {
      console.error('Error fetching content:', error)
    }
  }

  const Accordion = ({ title, seq }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [content, setContent] = useState('')
    return (
      <div className="accordion-wrapper">
        <div
          className={`title ${isOpen ? 'open' : ''}`}
          onClick={async () => {
            // 아코디언 메뉴를 열 때 해당 seq의 content를 호출하여 렌더링
            if (!isOpen) {
              const { data } = await fetchContent(seq)
              setContent(data)
            }
            setIsOpen(!isOpen)
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: title }}></span>
        </div>
        <div className="accordion-item">
          <div className="content">
            <div
              dangerouslySetInnerHTML={{
                __html: content
              }}
            ></div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <section>
      <div className="faq-col">
        <div className="inner-col">
          <div className="container mypage-sub">
          <MobileHeader title="자주 묻는 질문" onClick={() => (document.referrer === '' ? navigate('/service/center') : navigate(-1))}/>
            <section style={{ padding: '8rem 0' }}>
              <h2>자주 묻는 질문</h2>
              <div className="search-col">
                <label htmlFor="">
                  <input type="text" value={content} onChange={filter} onClick={getSearchData} className="input" placeholder="검색어를 입력해주세요." />
                </label>
                <span className="ico-search">
                  <i className="blind">search</i>
                </span>
              </div>
              <div>
                <ul className="faq-tabs">
                  {faqCategory &&
                    faqCategory.map((item, idx) => {
                      return (
                        <li key={idx} className={tabMenu === item.code ? 'active' : null}>
                          <Link to={`/service/faq/${item.code.toLowerCase()}/1`} className="tab-menu" onClick={() => handleTabMenu(item)}>
                            {item.value}
                          </Link>
                        </li>
                      )
                    })}
                </ul>
              </div>
              <section className={searchCont === 0 ? 'active' : null}>
                {faqCategory &&
                  faqCategory.map((item) => {
                    return (
                      <div key={uuid()} className={'faq-box tab-content' + (tabMenu === item.code ? ' active' : '')}>
                        {faqData &&
                          faqData.map((item) => {
                            return (
                              <div key={uuid()} className="item">
                                <Accordion className="title" title={item.title} seq={item.faqSeq} />
                              </div>
                            )
                          })}
                      </div>
                    )
                  })}
                <Pagination type={'SEQ'} totalPages={totalPages} limitPage={5} maxNum={5} minNum={0} pathName={`/service/faq/${itemSeq}`} currentSeq={currentSeq} />
              </section>
              <section className={searchCont !== 0 ? 'active' : null}>
                <div className="faq-box tab-content search-content">
                  {search && search.length > 0 ? (
                    search.map((item, idx) => {
                      return (
                        <div key={uuid()} className="item">
                          <Accordion className="title" title={item.title} seq={item.faqSeq} />
                        </div>
                      )
                    })
                  ) : (
                    <p>일치하는 값이 없습니다.</p>
                  )}
                </div>
              </section>
            </section>
          </div>
        </div>
      </div>
    </section>
  )
}
