import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import EntranceData from 'data/EntranceData.json'
import AuthService from 'services/authService'

import api from 'common/api'
import * as config from 'common/config'
import Banner from 'components/Banner'
import BestSlide from 'components/entrance/BestSlide'
import ClassList from 'components/entrance/ClassList'
import RoadMap from 'components/entrance/RoadMap'
import 'assets/styles/EntranceFree.scss'

const mainBanner = [
  {
    imagePath1:
      'https://english.yanadoocdn.com/upload/yanadoo/new/banner/bigbnr_pc_entrance_v2.png',
    imagePath2: 'https://english.yanadoocdn.com/upload/yanadoo/new/banner/bigbnr_m_entrance_v2.png',
    imageInfo: '자기 계발 필수 클래스',
    link: '#;',
    bgColor: '#000'
  }
]

export default function EntranceFree() {
  const navigate = useNavigate()
  const [entranceItems, setEntranceItems] = useState([])
  const [entranceSeq, setEntranceSeq] = useState(null)
  const userInfoCheck = AuthService.getUserInfo()
  const [isEntrancedUser, setIsEntrancedUser] = useState(false)

  const getEntranceItemsFromJson = () => {
    setEntranceItems(EntranceData)
  }

  const handleClickClass = (e) => {
    e.preventDefault()
    if (!userInfoCheck || (userInfoCheck && !isEntrancedUser)) {
      alert('입학 회원만 이용 가능합니다')
      navigate(`/store/entrance/${entranceSeq}`)
    } else if (userInfoCheck && isEntrancedUser) {
      alert('야나두 앱에서 입학 클래스를 수강해 보세요')
    }
  }

  const getUserInfo = () => {
    if (AuthService.getUserInfo()) {
      api
        .get(config.AUTH_API_HOST + '/auth/v2/user')
        .then((response) => {
          setIsEntrancedUser(response.data.data?.entrance?.entranceStatus === 'ENTRANCE')
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  const getEntranceSeq = () => {
    api
      .get('/v2/store/sale/entrance-package/packageSeq')
      .then((response) => {
        setEntranceSeq(response.data.data)
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  useEffect(() => {
    getEntranceItemsFromJson()
    getUserInfo()
    getEntranceSeq()
  }, [])

  return (
    <>
      <section className="submain-banner-col entrance">
        <div className="inner-col">
          <Banner loop autoPlay pagination slideView={1} type={'big'} data={mainBanner} />
        </div>
      </section>
      <section className="entrance-class-col">
        {entranceItems &&
          entranceItems.map((itemGroup, index) => {
            switch (itemGroup.groupType) {
              case 'SLIDE':
                return (
                  <BestSlide
                    key={index}
                    itemGroup={itemGroup}
                    handleClickClass={handleClickClass}
                  />
                )
                break
              case 'CLASSFREE':
                return <ClassList key={index} itemGroup={itemGroup} />
                break
              case 'ROADMAP':
                switch (itemGroup.groupSubType) {
                  case 'MINDSET':
                    return (
                      <RoadMap
                        key={index}
                        itemGroup={itemGroup}
                        type={itemGroup.groupSubType}
                        first
                        handleClickClass={handleClickClass}
                      />
                    )
                  case 'ENGLISH':
                    return (
                      <RoadMap
                        key={index}
                        itemGroup={itemGroup}
                        type={itemGroup.groupSubType}
                        handleClickClass={handleClickClass}
                      />
                    )
                  case 'MONEY':
                    return (
                      <RoadMap
                        key={index}
                        itemGroup={itemGroup}
                        type={itemGroup.groupSubType}
                        handleClickClass={handleClickClass}
                      />
                    )
                  case 'FITNESS':
                    return (
                      <RoadMap
                        key={index}
                        itemGroup={itemGroup}
                        type={itemGroup.groupSubType}
                        handleClickClass={handleClickClass}
                      />
                    )
                    break
                }
            }

            return
          })}
      </section>
    </>
  )
}
