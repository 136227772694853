import { Link } from 'react-router-dom'

import styled from 'styled-components'
import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import ResponsiveImage from 'components/ResponsiveImage'

import NumberMotion from './NumberMotion'

import 'swiper/swiper.scss'
SwiperCore.use([Autoplay])

export default function MainSlideSection({ bannerList }) {
  return (
    <SliderSection>
      {bannerList.length > 0 && (
        <Swiper
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false
          }}
        >
          {bannerList?.map((item, index) => (
            <SwiperSlide style={{ backgroundColor: item.bgColor }} key={index}>
              <Link
                to={item.link}
                target={item.linkType === 'NEW' ? '_blank' : '_self'}
                rel="noreferrer"
              >
                <ResponsiveImage
                  mo={item.bannerFileMobile}
                  pc={item.bannerFilePc}
                  maxWidth={1024}
                />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <NumberMotion />
    </SliderSection>
  )
}

const SliderSection = styled.section`
  position: relative;

  .swiper-wrapper {
    text-align: center;
  }
  img {
    width: 100%;
    max-width: 1920px;
  }
  @media only screen and (max-width: 1024px) {
    img {
      max-width: 100%;
    }
  }
`
