import React from 'react'
import Lottie from 'react-lottie'

import cx from 'classnames'
import LottieData from 'pages/aiTalk/lottieData.json'

import useChatFeedListStore from 'store/useChatFeedListStore'

import s from './Chat.module.scss'
import ChatFeedItem from './ChatFeedItem'
import { AI_TRAVEL_CONFIG } from '../config'

export default function ChatFeedList({ metaInfo, isAiResponse, userSeq, feedback }) {
  const { feedList } = useChatFeedListStore()
  const { roleAiEng } = metaInfo
  const { TRAVEL_PROFILE_IMAGE } = AI_TRAVEL_CONFIG
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <ul className={cx(s.feedList, { [s.feedbackActive]: feedback })}>
      {feedList.map(({ type, message }, index) => (
        <li className={cx(s.feedItem, s[type.toLowerCase()])} key={index}>
          <ChatFeedItem
            type={type}
            message={message}
            metaInfo={type === 'AI' ? metaInfo : null}
            userSeq={userSeq}
          />
        </li>
      ))}
      {isAiResponse && (
        <li className={s.feedItem}>
          <div className={s.aiMessage}>
            <div className={s.msgThumb}>
              <img src={TRAVEL_PROFILE_IMAGE} alt={roleAiEng} />
            </div>
            <dl>
              <dt className={s.msgName}>{roleAiEng}</dt>
              <dd className={s.message}>
                <div className={s.lottieBox}>
                  <Lottie options={defaultOptions} />
                </div>
              </dd>
            </dl>
          </div>
        </li>
      )}
    </ul>
  )
}
