import { YANADOO_AI_HOST } from 'common/config'

const API_HOST = {
  chat: `${YANADOO_AI_HOST}/qna/chat`,
  enter: `${YANADOO_AI_HOST}/qna/id`
}

const IMG_HOST = 'https://english.yanadoocdn.com/upload/yanadoo/new/ainadoo/'

const DAILY_TIP = [
  {
    number: 1,
    content: [
      '명사란 이름을 나타내는 품사이며, 영어는 명사를 셀 수 있는 것과 셀 수 없는 것으로 구분합니다.',
      '원어민은 수에 민감한 문화의 영향으로 셀 수 있는 명사에 반드시 수 표기를 합니다.',
      'pen이 아닌 a pen 또는 pens라고 발음하기 때문에 새로운 단어로 들릴 수 있으니 반드시 덩어리로 훈련해주세요.'
    ]
  },
  {
    number: 2,
    content: [
      '한국어(농경문화)와 영어(사냥문화)의 어순 차이는 문화차이에서 비롯합니다.',
      '사냥문화는 사냥감을 잡았는지 행위의 결과가 중요하기 때문에 [주어+동사]의 어순으로 결론을 먼저 이야기하고 뒤에 부가 설명을 합니다.'
    ]
  },
  {
    number: 3,
    content: [
      '영어의 동사는 크게 일반동사 / be동사로 나뉘며, 일반동사는 동작 또는 행위를 나타내는 동사입니다.',
      '한국어는 주어에 따른 동사 변화가 없지만, 영어는 주어의 개수에 따라 동사의 소리가 변합니다.',
      '주어가 한 명(개)인지 여러 명(개)인지 상상하면서 동사가 변하는 소리에 집중해서 연습해주세요.'
    ]
  },
  {
    number: 4,
    content: [
      '영어는 결론이 중요하기에 부정의 소리도 앞에 나옵니다.',
      '주어가 한 명(개)인지 여러 명(개)인지 개수에 따라 부정의 (don’t / doesn’t) 소리가 변합니다.',
      '어떠한 동작을 부정하는 것으로 ~하지 않는다 / 안 한다로 해석됩니다.'
    ]
  },
  {
    number: 5,
    content: [
      '영어는 의문을 가장 먼저 던집니다.',
      '주어가 한 명(개)인지 여러 명(개)인지 개수에 따라 Do / Does로 구분해 질문합니다.',
      '어떠한 동작에 대한 의문을 갖는 것으로 ~해?로 해석됩니다.'
    ]
  },
  {
    number: 6,
    content: [
      '원어민은 어떠한 현상을 바라볼 때 움직임이 있는지, 아니면 어떠한 상태인지로 나누어 생각합니다.',
      '움직임이 있는 행위는 일반동사, 상태는 be동사로 구분하여 말합니다.',
      'be동사는 ~한(인) 상태다의 개념으로 상태를 나타내는 단어와 함께 사용합니다.'
    ]
  },
  {
    number: 7,
    content: [
      '일반동사와 마찬가지로 주어의 개수에 따라 be가 am, are, is로 바뀝니다.',
      '주어가 한 명(개)인지 여러 명(개)인지 상상하면서 동사가 변하는 소리에 집중해서 연습해주세요.'
    ]
  },
  {
    number: 8,
    content: [
      '주어 개수에 따라 부정의 소리(am not, are not, is not)가 변합니다.',
      '어떠한 상태를 부정하는 것으로 ~가 아니다, ~한 상태가 아니다로 해석됩니다.'
    ]
  },
  {
    number: 9,
    content: [
      '일반동사 의문문과 달리, 상태를 나타낼 땐 be동사가 먼저 나오면서 의문문이 됩니다.',
      '어떠한 상태에 대한 의문을 갖는 것으로 ~한 상태야?로 해석됩니다.'
    ]
  },
  {
    number: 10,
    content: [
      '영어는 어순 차이뿐 아니라 행위와 상태의 문장 표기 방법이 다르고, 수에 민감하기 때문에 기본 문장이 익숙해질 때까지 훈련하는 것이 중요합니다. 문장이 쉽다고 그냥 넘어가면 안됩니다.',
      '헷갈리지 않고 바로 입에서 툭 나올 수 있도록 일반동사와 be동사를 섞어서 누적 복습하는 것을 추천합니다.'
    ]
  },
  {
    number: 11,
    content: [
      '영어에는 can, could, should, will, would, may, might, must와 같은 8가지의 필수 느낌동사 (조동사)가 있습니다. 느낌동사를 사용하면 내가 전하고자 하는 의도와 뉘앙스를 표현할 수 있습니다. (추측, 허락, 경고, 의무, 능력, 요청, 의지 등)',
      '느낌동사는 단독으로 쓸 수 없으며, 일반동사나 be동사 앞에 쓰입니다.',
      '주어의 개수와 상관없이 뒤에는 반드시 동사 원형이 옵니다.'
    ]
  },
  {
    number: 12,
    content: [
      '[can+일반동사]는 대체로 ~할 수 있다 (능력)이나 ~해도 된다 (허락)의 의미입니다.',
      '[can+be동사]는 대체로 ~일 수도 있다 (추측)의 뉘앙스로 해석됩니다.'
    ]
  },
  {
    number: 13,
    content: [
      'could는 50%의 가능성을 나타내는 추측 표현으로 ~일지도 모른다, ~일 수도 있다는 의미입니다.',
      '의문문일 때는 윗사람이나 손님에게 공손하게 말해야 할 경우 사용합니다.'
    ]
  },
  {
    number: 14,
    content: [
      'can은 능력적인 부분을 물어볼 때 사용하고, will은 계획을 묻는 표현입니다.',
      '주어가 3인칭(그, 그녀, 그들, 그것)일 때 will은 ~가 할 거야, ~가 할까?로 뉘앙스가 바뀝니다.',
      'Will의 부정문은 will + not이 되고, 축약형으로 표현하면 won’t가 됩니다.'
    ]
  },
  {
    number: 15,
    content: [
      'would는 주로 be동사와 함께 쓰이며, 80% 정도의 강한 추측을 나타냅니다.',
      'would는 ~나라면 하겠다라는 뉘앙스로, 어떤 계획이 없더라도 의향을 나타낼 때 사용합니다.',
      '느낌동사 중 would가 가장 공손한 표현입니다.'
    ]
  },
  {
    number: 16,
    content: [
      'should는 추측보다 의무의 뜻으로 더 많이 쓰입니다.',
      '꼭 해야 하는 의무감보다는 권유의 느낌입니다.',
      'should는 70% 정도의 확신을 보이는 뉘앙스입니다.'
    ]
  },
  {
    number: 17,
    content: [
      'may, might는 ~일지도 모른다는 의미로, 추측 느낌동사들 중 가장 희박한 가능성을 표현합니다.',
      'may 의문문은 잘 사용하지 않고, could와 비슷한 느낌의 공손함을 표현합니다.',
      'can과 마찬가지로 ~해도 된다는 허락, 양보의 의미도 쓰입니다.'
    ]
  },
  {
    number: 18,
    content: [
      'must는 ~할 필요가 있다는 뉘앙스로 꼭 해야 할 때만 사용되고, 강한 의무감을 보이는 표현입니다.',
      'must 의문문은 잘 사용하지 않기 때문에 have to를 활용해 Do you have to ~? / Does she have to ~?로 말해야 합니다.',
      'must는 99%의 매우 높은 확신을 보이며, ~일리 없다처럼 강한 부정의 의미로 표현하고 싶다면 can’t를 사용합니다.'
    ]
  },
  {
    number: 19,
    content: [
      '필수 느낌동사는 크게 추측, 의무, 허락/요청, 의지와 같이 네 개의 의미로 나눠볼 수 있습니다.'
    ]
  },
  {
    number: 20,
    content: [
      'had better는 아랫사람이나 동년배에게 ~하는 게 좋을 것이다라고 경고나 협박의 뉘앙스를 줄 때 사용합니다.',
      'would like to는 want to의 정중한 표현입니다.',
      '손님 또는 윗사람에게 서비스를 제공할 때도 쓰이고, 상대방이 내가 원하는 바를 들어줄지 확신할 수 없을 때도 사용합니다.'
    ]
  },
  {
    number: 21,
    content: [
      'would rather는 차라리 ~하는 게 낫다는 의미입니다.',
      '비교 대상이 있을 때는 than (~보다)과 함께 사용 가능합니다. (생략 가능)',
      "단, 문맥에 따라 다양한 의미로 번역할 수 있습니다. ex. I'd rather go. = 나 갈 거야/나 갈래/나 가는 게 낫겠어."
    ]
  },
  {
    number: 22,
    content: [
      'have to는 ~해야 한다는 뉘앙스로 사용합니다. (must보다는 약한 의무)',
      '부정문 don’t have to는 ~할 필요가 없다는 뜻입니다.',
      'have to는 주어가 3인칭 단수일 경우 has to로 표현합니다.'
    ]
  },
  {
    number: 23,
    content: [
      'will(미래)과 be able to(능력)를 함께 사용하면 ~할 수 있을 것이다는 의미가 됩니다.',
      'can, could는 일반적인 능력을 나타낸다면, be able to는 특정 상황에서 ~할 수 있다는 것을 표현할 수 있습니다.'
    ]
  },
  {
    number: 24,
    content: [
      'need to는 ~할 필요가 있다 그리고 ~해야 한다는 의미도 포함합니다.',
      '의무를 의미하는 다른 느낌동사들과 비교하면 need to는 비교적 부드러운 표현입니다.',
      '강제성이나 의무감보다는 가벼운 의무 정도의 뉘앙스를 표현할 때 사용합니다.'
    ]
  },
  {
    number: 25,
    content: [
      'be supposed to는 ~해야 한다, ~하기로 되어있다는 의미입니다.',
      '규칙, 다짐, 약속, 상식, 관습, 법과 같은 기준에 따른 의무나 책임을 나타내는 표현입니다.',
      '부정문 be not supposed to는 해서는 안 되는 일을 했을 때 사용합니다.'
    ]
  },
  {
    number: 26,
    content: [
      '느낌동사 + have pp를 활용해 과거에 대한 후회, 아쉬움 또는 추측을 표현할 수 있습니다.',
      '여기서 중요한 것은 have 단어 뒤에 pp 형태의 빠른 전환이므로 많은 훈련이 필요합니다.'
    ]
  },
  {
    number: 27,
    content: [
      'could have pp는 할 수도 있었지만 결국 못했다는 아쉬움의 뉘앙스를 의미합니다.',
      '부정문 could not have pp는 못 할 수도 있었지만 해서 다행이다의 뉘앙스를 의미합니다.',
      '여기서 could는 can의 과거형으로, ~할 수 있었는데, ~할 수 있었을 텐데로 해석합니다.'
    ]
  },
  {
    number: 28,
    content: [
      'would have pp는 어떤 사실을 알았다면 ~했었을 텐데, ~하려고 했는데, ~했었을 거다는 아쉬움의 뉘앙스 표현입니다.',
      'could have pp는 ~할 수도 있었지만 못했다의 의미로 능력을 강조한 뉘앙스라면, would have pp는 ~하려고 했지만 결국 안했다의 의미로 의지를 강조할 때 사용합니다.',
      '부정문 would not have pp는 어떤 사실을 알았다면 ~하지 않았을 텐데로 해석됩니다.'
    ]
  },
  {
    number: 29,
    content: [
      'should have pp는 의무적인 뉘앙스로 결국 안 한 것에 대한 아쉬움을 표현합니다.',
      '(과거에) ~했어야 했는데 안 했네로 해석되며, 이미 한 일에 대해 후회나 의무를 저버렸거나 아쉬움이 클 때 사용됩니다.'
    ]
  },
  {
    number: 30,
    content: [
      'must have pp는 다른 [느낌동사 + have pp]와는 달리 아쉬움보다는 강한 추측의 뉘앙스입니다.',
      '과거 시제의 느낌으로 ~했던 게 분명하다로 해석할 수 있습니다.',
      '반대로 ~했을 리가 없다라고 과거에 대한 단정적인 추측을 나타낼 때는 부정문 cannot have pp로 표현할 수 있습니다.'
    ]
  },
  {
    number: 31,
    content: [
      'used to는 과거에는 그랬지만 지금은 더 이상 ~하지 않는다라는 의미입니다.',
      '과거의 규칙적인 습관을 말하고 싶을 때 말할 수 있습니다.',
      '부정문은 did not use to로 표현할 수 있으며, 해석은 ~하지 않곤 했다, ~하지 않았었다가 자연스럽습니다.'
    ]
  },
  {
    number: 32,
    content: [
      '영어 동사는 크게 be동사와 일반동사로 나눌 수 있습니다.',
      '일반동사를 다시 나누어 보면 느낌동사 / 기본동사 / 지각동사 / 사역동사로 구분할 수 있습니다.',
      'get, have, take와 같이 원어민들이 일상생활에서 자주 쓰는 쉬운 단어들을 기본동사라고 합니다.'
    ]
  },
  {
    number: 33,
    content: [
      'get의 기본적인 의미는 사다, 얻다, 받다 입니다.',
      'get의 뜻에는 주어의 상태가 ~로 변하다라는 뜻도 있습니다.',
      '이외에도 ~하게 되다라고 해석할 수 있습니다.'
    ]
  },
  {
    number: 34,
    content: [
      'have의 기본적인 의미는 ~을 가지고 있다입니다.',
      'have의 현재형은 have와 has, 과거형과 과거분사형은 had입니다.',
      'have 뒤에 오는 목적어에 따라 해석이 다양해집니다.'
    ]
  },
  {
    number: 35,
    content: [
      'take의 가장 기본적인 뜻은 데려가다, 가져가다 (옮기다)입니다.',
      'take 단어 뒤에 10 minutes, 2 hours, a day와 같이 시간을 나타내는 표현이 오면 (~시간만큼) 걸리다는 의미로 해석합니다.'
    ]
  },
  {
    number: 36,
    content: [
      '지각동사는 보고(see, watch) 듣고(hear, listen to) 냄새 맡고(smell) 느끼는(feel) 감각을 나타내는 동사입니다.',
      'see는 우연히 보게 된 경우에 쓰이고, watch는 움직이는 것을 지켜보는 경우에 쓰입니다.',
      'hear는 우연히 들린 경우에 쓰이고, listen to는 들으려고 노력하는 경우에 쓰입니다.',
      '지각동사 뒤에는 [목적어 + 동사원형] 또는 [목적어 + v-ing] 형태가 옵니다.'
    ]
  },
  {
    number: 37,
    content: [
      '사역동사는 ~하도록 시키다로 해석하며, make, have, let이 대표적인 사역동사입니다.',
      'make는 강제성이 담긴 뉘앙스로 목적어(사람)에게 억지로 시키는 뉘앙스가 있습니다.',
      'have는 강제성 없이 설득, 권유 등으로 목적어(사람)가 하도록 유도하는 것입니다.',
      'let은 목적어(사람)가 무언가를 하도록 허락을 구하는 뉘앙스입니다.'
    ]
  },
  {
    number: 38,
    content: [
      '사역동사 get은 have와 비슷하게 목적어(사람)을 설득, 권유하거나 분위기를 조성하여 어떤 행동을 하도록 유도하는 것입니다.',
      '사역동사 have와는 다르게 사역동사로 쓰이는 get 뒤에는 목적어 + to V 형태가 와야 합니다. (주어 + get + 목적어 + to V)'
    ]
  },
  {
    number: 39,
    content: [
      '한국어와 다르게 영어에는 시제가 12개나 될 정도로 다양한 시제가 있습니다.',
      '꽤 많고 복잡하다고 느낄 수 있지만 사용 빈도가 높은 단순 시제부터 한 단계씩 익히는 게 효과적입니다.'
    ]
  },
  {
    number: 40,
    content: [
      '일반동사의 과거형은 현재형(기본형태) 단어 뒤에 ?d, -ed를 붙여서 말합니다.',
      '-d, -ed를 붙이지 않고 말하는 과거형은 불규칙 과거형이라고 하며, 추가로 익혀야 합니다.',
      'be동사의 과거형은 주어에 따라 was/were로 바뀝니다.'
    ]
  },
  {
    number: 41,
    content: [
      '현재시제라고 해서 현재만을 나타내지 않고, 과거/현재/미래를 모두 포함하는 시제입니다.',
      '늘, 항상이라는 개념이며, 우리가 늘 하는 습관 개념의 뉘앙스로 생각하면 쉽습니다.',
      '주어가 3인칭 단수(he/she/it)일 때, 동사 뒤에 붙는 -s, -es 발음에 신경 쓰면서 연습합니다.'
    ]
  },
  {
    number: 42,
    content: [
      '미래시제 will은 ~할 거야처럼 당장 하기로 마음먹은 내용을 강조할 때 사용합니다.',
      '전부터 결정한 계획을 강조하려면 ~할 계획이야의 be going to를 활용할 수 있습니다.'
    ]
  },
  {
    number: 43,
    content: [
      '과거진행은 (과거에) ~하고 있었다는 의미로, 과거 어느 시점에 진행되고 있던 행동을 표현합니다.',
      '주어에 따라 be동사의 형태가 was나 were로 바뀌므로 주어의 단수 복수에 따른 훈련이 필요합니다.',
      '과거진행형의 형태는 was/were(be 동사) + 동사원형 ing입니다.'
    ]
  },
  {
    number: 44,
    content: [
      '현재진행은 현재 시점에서 어떤 동작이 진행되고 있는 상황을 나타내며, ~하고 있다, ~하고 있는 중이다는 의미로 해석합니다.',
      '주어의 단수 복수에 따라 be동사의 형태가 am/are/is로 바뀝니다.',
      '현재진행형의 형태는 be동사(am/are/is) + 동사원형 ing 입니다.'
    ]
  },
  {
    number: 45,
    content: [
      '미래 시점에 어떤 동작이 진행되고 있는 경우, 미래진행 시제라 합니다.',
      '미래시제 will + 동사원형과 달리 미래진행 시제는 will + be(예외 없음) + 동사원형 ing 형태로 이루어집니다.'
    ]
  },
  {
    number: 46,
    content: [
      '과거완료 시제는 과거보다 더 먼저 일어난 사건을 표현하며, ~했어로 해석합니다.',
      '단순 과거시제를 사용했을 때보다 옛날에 어느 정도 장기간 계속되었던 일에 대해서 시간적 의미를 더 정확하게 표현할 수 있습니다.',
      '과거완료 시제의 형태는 had + pp로 표현할 수 있으며, 과거분사의 형태에 유의합시다.'
    ]
  },
  {
    number: 47,
    content: [
      '현재완료 시제의 형태는 has/have + pp로 표현합니다.',
      '경험: [has/have + pp] + before(전에) 와 함께 사용하면 ~해본 적 있어로 해석해요.',
      '계속: [has/have + pp] + for(동안) 과 함께 사용하면 ~해왔어로 해석해요.'
    ]
  },
  {
    number: 48,
    content: [
      '현재완료 has/have + pp는 경험/계속뿐만 아니라 완료/결과를 표현할 수 있습니다.',
      '과거 어떠한 일의 결과가 현재까지 영향을 미칠 때 사용합니다',
      '현재완료 시제를 완료/결과로 표현할 경우, ~했어로 해석합니다.'
    ]
  },
  {
    number: 49,
    content: [
      '미래완료 시제는 [will have + pp] 형태로 표현합니다.',
      '미래의 어느 한 시점을 기준으로 이전의 행동이나 상태가 미래 시점에 영향을 미쳤을 때 사용하며, 이때 해석은 ~해놓을 거야로 해석합니다.',
      '미래완료 시제를 [will have + pp] + (숫자) times처럼 표현하는 경우 ~번째일 것이다로 해석합니다.'
    ]
  },
  {
    number: 50,
    content: [
      '현재완료진행은 ~하고 있다의 의미를 강조하고 싶을 때 사용합니다.',
      '현재완료 시제인 have/has pp에서 have/has been + ~ing 형태로 바꾸면 현재완료진행 시제가 됩니다.',
      '이때 해석은 계속 ~해오고 있어의 의미가 됩니다.'
    ]
  },
  {
    number: 51,
    content: [
      '동사 뒤에 ing를 붙이거나 앞에 to를 붙여서 동사의 형태를 변형시킬 수 있습니다.',
      '동사를 변형시키는 이유는 한국말의 ~은, ~을, ~위해, ~할 등의 다양한 의미를 표현할 수 있기 때문입니다.',
      '또한 동사 변형을 통해 뒤에 수식하는 말을 붙여 길게 늘여 표현할 수 있습니다.'
    ]
  },
  {
    number: 52,
    content: [
      '동사+ing는 문장에서 주어 역할을 할 수 있습니다.',
      '동사+ing가 주어 역할을 할 경우 위치는 동사 앞에 오고, 해석은 은, 는, 이, 가 중 하나로 할 수 있습니다.',
      '동명사 주어 동사+ing는 단수로 여기기 때문에 뒤에 동사로 be 동사가 올 경우에는 is가 쓰입니다.'
    ]
  },
  {
    number: 53,
    content: [
      'to+동사는 주어 역할을 할 수 있으며, 이 경우 은, 는, 이, 가 중 하나로 해석 가능합니다.',
      '동사+ing가 주어로 오는 경우와 다르게 to+동사를 주어로 사용할 경우 문장 앞, 뒤 모두 위치할 수 있습니다.',
      'to+동사가 주어로 쓰여 문장 뒤에 오는 경우, 문장 주어 자리에는 It을 써줍니다.'
    ]
  },
  {
    number: 54,
    content: [
      '동사+ing는 문장 바로 뒤에서 목적어 역할을 할 수 있습니다. 이때, 동사+ing의 해석은 을, 를로 할 수 있습니다.',
      '어떤 동사들은 동사 뒤에 오는 목적어로 반드시 동사+ing 형태를 써야 합니다. (ex. keep, mind, suggest, enjoy, miss, finish, avoid, resist, practice, quit, imagine, consider, discuss)',
      '일부 동사들은 목적어로 동사+ing 형태와 to+동사 형태를 모두 쓸 수 있습니다. (ex. like, love, start)'
    ]
  },
  {
    number: 55,
    content: [
      'to+동사는 문장의 동사 바로 뒤에서 목적어 역할을 할 수 있습니다.',
      'to+동사가 목적어 역할을 하는 경우, 을, 를로 해석합니다.',
      '어떤 동사들은 동사 뒤에 오는 목적어로 반드시 to+동사 형태를 써야 합니다. 이 동사들의 공통점은 주로 미래에 할 일을 이야기할 때 쓴다는 것입니다. (ex. want, plan, refuse, hope, expect, swear, promise, decide, fail, agree, wish, learn, need, choose, pretend)'
    ]
  },
  {
    number: 56,
    content: [
      'to+동사는 명사 바로 뒤에 위치해서 명사를 꾸며줄 수 있습니다. 이때 to+동사는 ~할, ~ㄹ로 해석합니다.',
      'to+동사가 명사를 꾸며주는 역할을 할 때, 뒤에 전치사를 함께 사용해야 하는 경우가 있습니다.'
    ]
  },
  {
    number: 57,
    content: [
      'to+동사는 동사를 꾸며주는 부사 역할을 할 수도 있습니다.',
      '이때 to+동사는 문장 안에서 목적의 의미를 나타내며, ~하려고, ~하기 위해서라고 해석합니다.',
      '문장 안에서 부사 역할로 쓰이는 to+동사의 부정은 앞에 not만 붙여 not to+동사로 하며, 해석은 ~하지 않으려고로 하면 자연스럽습니다.'
    ]
  },
  {
    number: 58,
    content: [
      '동사+ing는 문장의 주어, 목적어 역할을 할 수 있습니다.',
      'to+동사 또한 문장의 주어, 목적어로 쓰일 수 있으며, 명사의 의미를 꾸며주는 형용사, 동사의 의미를 더해주는 부사의 역할을 하기도 합니다.',
      '어떤 동사가 쓰였는지에 따라 뒤에 오는 목적어의 형태도 달라집니다. ex. I want to talk. (O) / I want talking. (X) I enjoy talking. (O) / I enjoy to talk. (X)'
    ]
  },
  {
    number: 59,
    content: [
      '어떤 단어 뒤에 위치해서 그 의미를 꾸며주는 것을 후치수식이라고 합니다.',
      '영어에서 명사를 긴 덩어리 뭉치로 꾸며주려면 그 명사 뒤에서 꾸며줘야 합니다.',
      '후치수식을 이용하면 접속사 and를 쓰지 않고도 문장을 길게 늘여 말할 수 있게 됩니다.'
    ]
  },
  {
    number: 60,
    content: [
      '한국에 있는 남자처럼 사람을 꾸며줄 때는 who를 사용해서 의미를 더 해주며, 사람 바로 뒤에 who를 써서 표현합니다.',
      '사람+who 뒤에 be동사를 쓰면 사람의 상태, 직업, 장소, 동작의 진행에 대해서 설명할 수 있습니다.',
      '설명하는 사람이 한 명일 경우, who 뒤 be동사는 is를 쓰고, 여럿이면 are을 씁니다.'
    ]
  },
  {
    number: 61,
    content: [
      '사람을 꾸며주거나 설명할 때는 사람 단어 뒤에 who를 써서 표현할 수 있습니다.',
      '어떤 사람이 습관처럼 늘 하는 행동이나 동작하고 있다고 설명하고 싶을 때는 사람+who 뒤에 현재 시제 형태의 일반동사로 말할 수 있습니다.'
    ]
  },
  {
    number: 62,
    content: [
      '사물을 설명하고 싶을 때는 사물 단어 뒤에 which를 써서 표현할 수 있습니다.',
      '어떤 사물 단어의 상태, 명칭, 장소, 진행 상황을 표현하고 싶을 때는 사물+which 뒤에 be동사를 써서 말할 수 있습니다.'
    ]
  },
  {
    number: 63,
    content: [
      '사물+which 뒤에 일반동사를 써서 사물 단어를 꾸며 말할 수 있습니다.',
      '일반동사의 현재시제를 사용하면 언제나, 늘, 항상의 뉘앙스입니다.'
    ]
  },
  {
    number: 64,
    content: [
      '사람을 꾸밀 때는 그 단어 뒤에 who, 사물을 꾸밀 때는 사물 단어 뒤에 which를 사용합니다.',
      '어떠한 상태, 직업, 장소, 동작의 진행을 나타내고 싶을 때는 be동사를 쓰고, 습관처럼 늘 하는 행동을 말하고 싶을 때는 일반동사를 사용합니다.'
    ]
  },
  {
    number: 65,
    content: [
      '사람이 어떤 행동을 하고 있다고 말하고 싶을 때는 사람 단어 뒤에 who+be동사+ing를 써서 표현할 수 있습니다.',
      '이때 who+be동사+ing 단어 중에서 who+be동사를 생략하고 사람+ing로 간단하게 말할 수도 있습니다.',
      '사람+ing 패턴을 사용하면 그 사람이 하고 있는 동작을 묘사할 수 있습니다. (ex. a man who is reading a book → a man reading a book / 책을 읽고 있는 남자)'
    ]
  },
  {
    number: 66,
    content: [
      '사람+ing 표현과 마찬가지로, 사람+who+be동사+pp 형태에서 who+be동사를 생략하고 사람+pp로 간단하게 사람을 꾸며 말할 수 있습니다. (ex. a man who is loved by people → a man loved by people)',
      'ing가 능동의 뉘앙스라면, pp는 수동적인 뉘앙스입니다.'
    ]
  },
  {
    number: 67,
    content: [
      '사람+pp와 마찬가지로 사물+pp를 사물 단어 뒤에 쓰면 그 사물을 꾸며줄 수 있습니다.',
      '사물+pp는 사물이 어떤 상태에 처한 수동적인 상태의 뉘앙스를 나타냅니다. ex. the car which is fixed today → the car fixed today (오늘 수리된 자동차)'
    ]
  },
  {
    number: 68,
    content: [
      '사람과 사물 모두 who+be동사 또는 which+be동사를 생략하여 간단히 후치수식 할 수 있습니다.',
      '사람 → 능동일 때 -ing, 수동일 때 pp를 사용',
      '사물 → 사물이 어떤 행동을 할 수 있는 상황은 극히 드물기에, 주로 수동으로 표현함으로 pp 사용'
    ]
  },
  {
    number: 69,
    content: [
      'where+주어+동사 패턴을 활용하면 어떤 장소를 설명할 수 있습니다.',
      '장소를 뜻하는 단어와 where 중 하나를 생략해서 간단히 표현할 수 있습니다. ex. the place where I study = the place I study = where I study'
    ]
  },
  {
    number: 70,
    content: [
      '시간을 설명할 때는 when을 활용하여 when+주어+동사 패턴을 써서 말할 수 있습니다.',
      '이때, 시간을 뜻하는 단어와 when 중 하나를 생략할 수 있습니다. ex. the day when I work = the day I work = when I work'
    ]
  },
  {
    number: 71,
    content: [
      '내가 피자를 좋아하는 이유는과 같이 이유를 설명하고 싶을 때는 why+주어+동사 패턴으로 말할 수 있습니다.',
      'where, when에서와 동일하게 the reason과 why 중 하나를 생략해서 말할 수 있습니다. ex. the reason why I teach = the reason I teach = why I teach'
    ]
  },
  {
    number: 72,
    content: [
      'how+주어+동사 패턴을 쓰면 그가 요리하는 방식은~과 같은 표현을 할 수 있습니다.',
      '(주의) where, when, why와는 달리 how는 수식을 받는 명사 the way와 동시에 사용할 수 없습니다. ex. The way I teach (O) = How I teach (O), The way how I teach (X)'
    ]
  },
  {
    number: 73,
    content: [
      'that은 사람, 사물, 장소, 시간, 이유, 방법과 같은 단어를 모두 꾸며서 말할 수 있는 단어입니다.',
      '명사+that+주어+동사 패턴으로 다양한 명사를 꾸며 길게 표현할 수 있습니다.'
    ]
  },
  {
    number: 74,
    content: [
      '문장의 부수적인 요소인 시간/장소/이유/방법을 꾸밀 때는 완전한 문장으로 꾸미게 됩니다.',
      '시간/장소/이유 → the day/the place/the reason 또는 when/where/why 중 하나 생략 가능합니다.',
      '방법 → the way 또는 how 중 반드시 하나만 사용합니다.'
    ]
  },
  {
    number: 75,
    content: [
      '다양하고 구체적인 질문을 하려면 의문문 앞에 의문사를 붙이면 됩니다.',
      'who(누가), what(무엇을), where(어디서), when(언제), why(왜), how(어떻게)라는 여섯 개의 의문사로 시작하는 의문문을 의문사 의문문이라고 합니다.',
      '간접 의문문은 문장 안에 의문사+주어+동사 어순을 활용해서 질문을 부드럽게 돌려서 할 수 있습니다.'
    ]
  },
  {
    number: 76,
    content: [
      '의문사 의문문은 who, when, where, why, what, how라는 의문사로 시작하는 의문문입니다.',
      'be동사와 일반동사, 느낌동사까지 활용하여 다양하게 말할 수 있습니다.'
    ]
  },
  {
    number: 77,
    content: [
      '간접 의문문을 활용하면 의문사 의문문보다 더 부드럽게 돌려서 질문할 수 있습니다. (ex. 그녀가 누구야? → 그녀가 누구인지 알아?)',
      '간접 의문문에서는 의문문 패턴 뒤에 [의문사 + 주어 + 동사]의 순서로 씁니다.'
    ]
  },
  {
    number: 78,
    content: [
      '간접 의문문을 통해 상대방의 생각을 물어볼 수도 있습니다. (ex. 그녀가 누구야? → 그녀가 누구라고 생각해?)',
      '앞서 배운 Do you know+의문사와 달리 의문사+Do you think에서는 의문사가 패턴 앞에 옵니다.'
    ]
  },
  {
    number: 79,
    content: [
      "'의문사 의문문은 [동사+주어]의 어순으로 쓰지만, 간접 의문문에서는 [주어+동사] 어순으로 바뀝니다.",
      '의문사 의문문: Who is she? (그녀는 누구야?)',
      '간접 의문문: Do you know who she is? (그녀가 누군지 알아?)',
      '간접 의문문 패턴 1, 2의 경우, 둘의 공통점은 [주어+동사] 어순이고, 둘의 차이점은 의문사의 위치입니다.',
      '간접 의문문 패턴 1: Do you know who he is? (그가 누군지 알아?)',
      '간접 의문문 패턴 2: Who do you think he is? (그가 누구라고 생각해?)'
    ]
  },
  {
    number: 80,
    content: [
      "'그녀는 너보다 더 어려'와 같이 비교급으로 표현하고 싶다면 형용사에 -er 또는 more를 붙여 표현할 수 있습니다.",
      '모음을 기준으로 2음절 이하일 경우 형용사 + -er 형태로, 3음절 이상일 경우 more + 형용사 형태로 비교급을 만듭니다.'
    ]
  },
  {
    number: 81,
    content: [
      "'이 집은 내 집만큼 깨끗해'와 같이 비교 대상의 동등함을 나타내고 싶을 때, as + 형용사/부사 + as로 표현합니다.",
      'as와 as 사이에 오는 형용사, 부사는 비교급이 아닌 기본형으로 써야하는 점을 꼭 기억합시다. ex. as strong as (O) / as stronger as (X)'
    ]
  },
  {
    number: 82,
    content: [
      "'가장 키가 큰'과 같이 최상급으로 표현하고 싶을 때에는 형용사에 the+est 또는 the most를 붙여서 말할 수 있습니다.",
      '모음 기준 2음절 이하일 경우에는 the 형용사 + est 형태로 최상급을 만듭니다.',
      '모음 기준 3음절 이상일 경우에는 the most 형용사 형태로 최상급을 만듭니다.'
    ]
  },
  {
    number: 83,
    content: [
      '형용사에 -er 또는 more를 붙이면 비교급 형태로 표현할 수 있습니다.',
      '형용사에 the+-est 또는 the most를 붙여 최상급을 만들어 말할 수 있습니다.',
      '(예외) 일부 단어들은 비교급, 최상급을 만들 때 형태가 불규칙하게 변합니다.',
      'as ~ as 패턴을 이용하여 비슷한 수준의 대상끼리 비교해서 표현할 수 있습니다.'
    ]
  },
  {
    number: 84,
    content: [
      "원어민들은 상황이나 감정을 표현할 때 It's ~ 로 말을 시작하는 경우가 많습니다.",
      '이때 It은 특별한 뜻이 없는 형식상 주어로 생각하면 됩니다.',
      'It+is+형용사 뒤에 의미가 담긴 to+동사 또는 that+주어+동사를 써서 상황이나 감정을 보충 설명할 수 있습니다.'
    ]
  },
  {
    number: 85,
    content: [
      "'그건 수리 됐어'와 같이 수동태 문장으로 말하고 싶을 때는 It is+pp 패턴으로 표현할 수 있습니다.",
      '이때 주어는 It 외에도 다른 단어로 다양하게 활용할 수 있습니다.',
      '동사의 pp 형태(과거분사)란 어떤 행동을 당할 때나 감정을 느끼는 수동적인 상태를 의미합니다.'
    ]
  },
  {
    number: 86,
    content: [
      "주어+be동사+ing/pp 문장에서 ing는 주어가 감정을 유발하는 의미를 갖고, pp가 쓰인 경우는 외부 상황에 의해 주어가 감정을 느끼는 의미를 갖습니다. ex. 'He is boring.'은 사람 자체가 고지식하고 지루한 사람이라는 의미",
      "'He is bored.'는 어떤 상황 때문에 지루함을 느낀다는 의미"
    ]
  },
  {
    number: 87,
    content: [
      "It's 뒤에 to+동사 또는 that+주어+동사를 써서 상황이나 감정을 보충 설명할 수 있습니다.",
      "It's 뒤에 ing 또는 pp 형태를 붙여 다양한 상황이나 기분을 나타낼 수 있습니다."
    ]
  },
  {
    number: 88,
    content: [
      "'내가 슈퍼맨이라면 날아갈 텐데...'와 같이 현재 상황에 반대되는 가정이나 상상을 할 때 가정법으로 표현합니다.",
      '가정법은 If+주어+과거 동사, 주어+could/would+동사원형 패턴으로 만듭니다.',
      '*이 경우 동사의 현재형이 아닌 과거형을 써서 말합니다.',
      '● If절에서 동사로 be동사가 쓰일 때는, 주어에 관계없이 was가 아닌 반드시 were를 씁니다.',
      'If+주어+현재 동사는 실현 가능한 상황에서, If+주어+과거 동사는 실현 가능성이 희박한 상황에서 씁니다.'
    ]
  },
  {
    number: 89,
    content: [
      "'그건 정말 비싸구나!'와 같이 감탄을 표현하고 싶을 때에는 How+형용사+(주어+동사)! 패턴을 사용합니다.",
      'What+a/an+(형용사)+명사+(주어+동사)! 패턴으로도 ‘정말 ~한 ~구나!’라는 의미의 감탄문을 만들 수 있습니다.',
      'How 감탄문에서는 형용사가 필수적으로 쓰이고, What 감탄문에서는 명사가 필수로 쓰입니다.'
    ]
  },
  {
    number: 90,
    content: [
      '전치사를 활용하면 시간, 장소, 방향, 수단, 출처, 대상, 기간 등에 대한 부가 정보를 전달할 수 있습니다.',
      '전치사 뒤에는 명사가 나오며, 이 둘은 항상 덩어리로 함께 쓰입니다.',
      '전치사마다 대표적인 이미지를 갖고 있으므로 각 전치사의 이미지를 파악하는 것이 중요합니다.'
    ]
  },
  {
    number: 91,
    content: [
      '전치사 at은 점의 개념으로 어떤 지점을 콕 찍어 시간과 장소 등을 표현하는 뉘앙스입니다.',
      '시간(시점) : 시각이나 나이 등 시간의 흐름 속에서 특정 시점을 나타내는 느낌입니다.',
      '장소(지점) : 주소가 있거나 (지도를 본다고 생각했을 때) 손으로 가리킬 수 있는 지점을 나타냅니다.'
    ]
  },
  {
    number: 92,
    content: [
      '전치사 on은 접촉의 개념으로 무언가의 위, 옆, 경사면에 딱 붙어 있는 뉘앙스 입니다.',
      '시간 : 날짜, 요일, 생일, 기념일에 사용합니다. 달력 위에 딱 붙어 있는 날짜를 생각하면 쉽습니다.',
      '장소 : 어떤 위치나 장소에 붙어 있는 경우에 사용합니다. 반드시 위에 닿아 있을 필요는 없고, 옆면/빗면 모두 가능합니다',
      '도구, 수단 : 도구에 의존하는 것을 붙어있는 개념으로 표현합니다.'
    ]
  },
  {
    number: 93,
    content: [
      '전치사 in은 공간 개념입니다. 시간과 장소에서 상대적으로 더 넓은 의미로 사용됩니다.',
      '시간 : 월, 계절, 연도 등에 사용됩니다. at(시각), on(날짜)과 비교했을 때 긴 시간의 경과를 나타냅니다.',
      '시간의 경과 : 경과 바로 직후를 뜻합니다. 반대 개념으로 within(안에)이 있습니다.',
      '장소 : at(지점), on(표면)과 비교했을 때 넓은 의미로 국가나 도시에 사용합니다.'
    ]
  },
  {
    number: 94,
    content: [
      '전치사 at/on/in은 모두 시간/공간 개념으로 사용할 수 있습니다.',
      '시간 : at ? on ? in 뒤로 갈수록 시간 개념이 상대적으로 길어집니다.',
      '공간 : at ? on ? in 뒤로 갈수록 공간 개념이 상대적으로 넓어집니다.'
    ]
  },
  {
    number: 95,
    content: [
      '전치사 by는 서로 영향을 미치는 관계를 떠올리면 됩니다.',
      '시간(완료 기한) : 어떤 행위가 그 시점까지 미치는 영향을 나타냅니다.',
      '장소(옆에) : A와 B 사이에 영향을 미치는 거리를 나타냅니다.',
      '행위자/교통수단 : 수단, 행위자, 에너지 등의 영향을 받을 때 사용됩니다.'
    ]
  },
  {
    number: 96,
    content: ['전치사 from은 시간과 장소의 출발점을 나타내며, 출처의 뉘앙스를 줍니다.']
  },
  {
    number: 97,
    content: [
      '전치사 for은 대상을 가리킬 때 사용합니다. 반드시 ~를 위해서으로 해석할 필요는 없지만 기본적으로 대상을 위함, 떠받듦의 뉘앙스를 가지고 있습니다.',
      '전치사 for의 대상은 사람, 사물, 추상 개념을 모두 포함합니다.',
      '어떤 대상을 위해 시간을 할애할 때도 사용합니다.'
    ]
  },
  {
    number: 98,
    content: [
      '전치사 to는 향하고 있는 방향 또는 그곳까지의 도달을 연상하면 됩니다.',
      '도달하는 목적지는 사람, 장소, 범위, 분량, 수치 등 다양한 것들을 포함합니다.',
      'up과 함께 사용해 특정 범위, 정도, 수량까지 도달한다는 의미를 강조할 수 있습니다.'
    ]
  },
  {
    number: 99,
    content: [
      '전치사 out of는 안쪽에서 바깥쪽으로 나오는 느낌입니다. 그렇기 때문에 설정된 범위 밖으로 벗어나고 이탈한 느낌을 주기도 합니다.',
      'out of는 무언가로부터 나왔기 때문에 출처나 원인을 표현하기도 합니다.'
    ]
  },
  {
    number: 100,
    content: [
      '전치사를 통해 시간과 장소 등에 관해 매끄럽게 표현이 가능해요.',
      '전치사를 통해 방향, 시간, 수단, 위치, 출처, 대상, 기간 등 부가 정보를 추가할 수 있어요.',
      '전치사를 통해 문장을 길게 늘려 말할 수 있어요. (ex. I looked at him from my car for 3 hours in Seoul.)'
    ]
  }
]

export const AINADOO_CONFIG = { API_HOST, IMG_HOST, DAILY_TIP }
