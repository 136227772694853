import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import Banner from "../../components/Banner";
import NoContent from "../../components/NoContent";
import ModalPortal from "ModalPortal";
import AlertModal from "components/modal/AlertModal";
import * as config from "common/config";
import api from "common/api";
import "../../assets/styles/cart.scss";
import icoImg from "../../assets/images/ico_no_cart_248x248.png";

// 배송비 무료를 위한 최소 금액
const MINIMUM_PRICE_FOR_FREE_DELIVERY = 50_000;
const DELIVERY_FEE = 3_000;
const DELIVERY_FEE_TARGET_ITEMS = ["REAL"];

let entrancePackageSeq = -1;
let hasEntrancePackage = false;

export default function ListCart() {
  const normalBanner = [
    {
      imagePath1:
        "https://english.yanadoocdn.com/upload/yanadoo/new/banner/combnr_pc_yafit_2301.png",
      imagePath2:
        "https://english.yanadoocdn.com/upload/yanadoo/new/banner/combnr_mo_yafit_2301.png",
      imageInfo: "세상의 모든 사이클이 야핏으로 연결된다. 야핏특가 최대 50% 할인",
      link: "/promotion/yafitFree",
      bgColor: "#ebebeb",
    },
  ];

  const navigate = useNavigate();
  const [modalopenAlert, setModalopenAlert] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [isEntrancedUser, setIsEntrancedUser] = useState(false);

  const [totalSalePrice, setTotalSalePrice] = useState(0);
  const [totalOrgPrice, setTotalOrgPrice] = useState(0);
  const [totalDiscountPrice, setTotalDiscountPrice] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [dummyCounter, setDummyCounter] = useState(0);

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 });
    return isDesktop ? children : null;
  };
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 });
    return isMobile ? children : null;
  };

  const addComma = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleOpenModalAlert = useCallback((e) => {
    document.body.classList.add("modal-open");
    setModalopenAlert(true);
  }, []);

  const handleCloseModalAlert = useCallback((e) => {
    document.body.classList.remove("modal-open");
    setModalopenAlert(false);
  }, []);

  const getSalePrice = (packageItem, productItem) => {
    return hasEntrancePackage || isEntrancedUser
      ? productItem.membershipSalePrice
      : packageItem.saleTypeCode === "EARLY_BIRD"
      ? productItem.earlyBirdSalePrice || 0
      : productItem.salePrice;
  };

  const bannerSlideLists = [
    {
      imageURL:
        "https://english.yanadoocdn.com/upload/2021/04/pc_1619161419806.png",
      imageAlt: "배너1",
      url: "#;",
    },
    {
      imageURL:
        "https://english.yanadoocdn.com/upload/2021/04/3_Bigbanner_pc_ff534d_1618897275885.jpg",
      imageAlt: "배너2",
      url: "#;",
    },
  ];

  // 장바구니 조회
  const getCartList = () => {
    api
      .get(`/payment/v2/cart/list`)
      .then((response) => {
        setIsEntrancedUser(response.data.data.isEntrancedUser);
        setCartItems(response.data.data.cartItems);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const setCartItems = (cartItems) => {
    // console.log("- cartData", cartItems);
    for (let packageItem of cartItems) {
      packageItem.checked = true;
      packageItem.totalOrgPrice = 0;
      packageItem.totalSalePrice = 0;
      packageItem.deliveryItemCount = 0;

      for (let productItem of packageItem.productItems) {
        if (productItem.requiredYn === "Y") {
          productItem.checked = true;
        }
      }

      if (entrancePackageSeq === packageItem.packageSeq) {
        hasEntrancePackage = true;

        for (let productItem of packageItem.productItems) {
          if (productItem.requiredYn === "N") {
            productItem.checked = true;
            break;
          }
        }
      } else {
        for (let productItem of packageItem.productItems) {
          productItem.checked = true;
        }
      }
    }

    setCartData(cartItems);
  };

  const isAllChecked = () => {
    if (!cartData || cartData.length === 0) {
      return false;
    }

    let checkedCount = 0;
    cartData.map((packageItem) => {
      if (packageItem.checked) {
        checkedCount++;
      }
    });

    return cartData.length === checkedCount;
  };

  const setCheckedAll = () => {
    if (!cartData || cartData.length === 0) {
      return false;
    }

    const checked = !isAllChecked();
    cartData.map((packageItem) => {
      packageItem.checked = checked;
    });
  };

  const setChecked = (item, packageItem) => {
    if (item.productSeq && packageItem.packageSeq === entrancePackageSeq) {
      packageItem.productItems.map((_productItem) => {
        if (_productItem.checked && _productItem.requiredYn === "N") {
          _productItem.checked = false;
        }
      });

      item.checked = true;
    } else {
      item.checked = !item.checked;
    }

    setDummyCounter((dummyCounter) => dummyCounter + 1);
  };

  const calcTotalPrice = () => {
    let orgPriceSum = 0;
    let salePriceSum = 0;
    let deliveryItemCount = 0;

    cartData.map((packageItem) => {
      packageItem.totalOrgPrice = 0;
      packageItem.totalSalePrice = 0;
      packageItem.deliveryItemCount = 0;

      // console.log("- package :", packageItem.packageName);

      packageItem.productItems.map((productItem) => {
        // console.log("- product :", productItem.packageName, productItem.packageTypeCode, productItem.originalPrice, getSalePrice(packageItem, productItem), productItem.checked);

        if (productItem.checked) {
          packageItem.totalOrgPrice += productItem.salePrice; // 일반가 : salePrice
          packageItem.totalSalePrice += getSalePrice(packageItem, productItem);

          if (
            DELIVERY_FEE_TARGET_ITEMS.indexOf(productItem.packageTypeCode) > -1
          ) {
            packageItem.deliveryItemCount++;
          }
        }
      });

      if (packageItem.checked && packageItem.productItems) {
        orgPriceSum += packageItem.totalOrgPrice;
        salePriceSum += packageItem.totalSalePrice;
        deliveryItemCount += packageItem.deliveryItemCount;
      }
    });

    const totalDeliveryFee =
      deliveryItemCount > 0 &&
      orgPriceSum > 0 &&
      salePriceSum < MINIMUM_PRICE_FOR_FREE_DELIVERY
        ? DELIVERY_FEE
        : 0;

    setTotalOrgPrice(orgPriceSum);
    setDeliveryFee(totalDeliveryFee);
    setTotalDiscountPrice(orgPriceSum - salePriceSum);
    setTotalSalePrice(salePriceSum + totalDeliveryFee);
  };

  const makeOrder = (packageSeq = -1, e) => {
    e.preventDefault();

    let params = [];
    let entrancePackagedChecked = false;
    let entranceProductChecked = false;

    cartData.map((packageItem) => {
      if (!packageItem.checked) return;

      if (
        entrancePackageSeq === packageItem.packageSeq &&
        packageItem.checked
      ) {
        entrancePackagedChecked = true;
      }

      if (
        hasEntrancePackage || // 장바구니에 입학상품이 있는 경우 바로 구매를 해도 전체 결제 처리
        (packageSeq === -1 && packageItem.checked) || // 바로 구매가 아닌 경우 체크한 패키지만
        packageItem.packageSeq === packageSeq
      ) {
        // 바로 구매인 경우 해당 패키지만
        let paramPackage = {};
        for (let key in packageItem) {
          paramPackage[key] = packageItem[key];
        }
        paramPackage["productItems"] = [];
        paramPackage.originalPrice = 0;
        paramPackage.salePrice = 0;
        paramPackage.discountPrice = 0;
        paramPackage.paymentPrice = 0;
        paramPackage.deliveryPrice = 0;
        paramPackage.cnt = 1;

        packageItem.productItems.map((productItem) => {
          if (productItem.checked) {
            let paramProduct = {};
            for (let key in productItem) {
              paramProduct[key] = productItem[key];
            }
            paramProduct.discountPrice = 0;
            paramProduct.salePrice = getSalePrice(packageItem, productItem);

            paramProduct["requiredYn"] = "Y";
            paramPackage["productItems"].push(paramProduct);

            paramPackage.originalPrice += productItem.originalPrice;
            paramPackage.salePrice += paramProduct.salePrice;

            // 입학 굿즈 선택 여부
            if (
              productItem.requiredYn === "N" &&
              hasEntrancePackage &&
              entrancePackageSeq === packageItem.packageSeq
            ) {
              entranceProductChecked = true;
            }
          }
        });

        params.push(paramPackage);

        if (deliveryFee > 0) {
          params[0].deliveryPrice = deliveryFee;
        }
      }
    });

    if (hasEntrancePackage && !entrancePackagedChecked) {
      alert("심화코스는 입학 상품과 함께 주문해야합니다.");
      return false;
    }

    if (params.length === 0) {
      alert("구매할 상품을 선택해주세요.");
      return false;
    }

    if (hasEntrancePackage && !entranceProductChecked) {
      alert("입학 선물을 선택해주세요.");
      return false;
    }

    // console.log(params);

    api
      .post("/payment/v2/order", JSON.stringify(params))
      .then((response) => {
        navigate(`/mypage/order/cart/detail/${response.data.data}`);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const removeCartItems = () => {
    let packageSeqs = [];
    cartData.map((packageItem) => {
      // console.log("- checked : ", packageItem.packageSeq, packageItem.checked);
      if (packageItem.checked) {
        packageSeqs.push(packageItem.packageSeq);
      }
    });

    if (packageSeqs.length === 0) {
      handleCloseModalAlert();
      alert("삭제할 상품을 선택해 주세요.");
      return false;
    }

    api
      .delete(`/payment/v2/cart`, { data: packageSeqs })
      .then((response) => {
        if (response.data.meta?.code === 200) {
          handleCloseModalAlert();
          alert("삭제되었습니다.");
          window.location.reload();
        } else if (response.data.meta.message) {
          alert(response.data.meta.message);
        } else {
          alert(config.MESSAGE["common-error"]);
        }
      })
      .catch((e) => {
        console.log("잠시 후 다시 시도해 주세요.");
      });
  };

  // 입학상품 번호
  const getEntrancePackageSeq = () => {
    api
      .get("/v2/store/sale/entrance-package/packageSeq")
      .then((response) => {
        entrancePackageSeq = response.data?.data;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        getCartList();
      });
  };

  useEffect(() => {
    calcTotalPrice();
  }, [cartData]);

  useEffect(() => {
    getEntrancePackageSeq();
  }, []);

  return (
    <>
      <article className="cart-col">
        <Desktop>
          <>
            <h2>Cart</h2>
            <section className="box-tb-col">
              <section className="inner-col">
                <h3>
                  <strong>장바구니</strong>
                </h3>
                <div className="desc-box left">
                  <p>
                    <em>
                      장바구니 상품은 최대 30일간 보관되며, 종료되거나 품절될
                      경우 자동으로 삭제됩니다.
                    </em>
                  </p>
                  <p>
                    <em>
                      총 주문금액은 결제시 쿠폰을 적용하거나 추가 할인을 받을
                      경우 금액이 달라질 수 있습니다.
                    </em>
                  </p>
                </div>
              </section>
            </section>

            {cartData.length > 0 ? (
              <>
                {/*<section className="box-tb-col">*/}
                  <section className="inner-col">
                    <div className="tb-default cart">
                      <table>
                        <colgroup>
                          <col width="5%" />
                          <col width="12%" />
                          <col width="56%" />
                          <col width="15%" />
                          <col width="12%" />
                        </colgroup>
                        <thead>
                          <tr>
                            <th colSpan="4">
                              <label htmlFor="checkbox-all">
                                <input
                                  type="checkbox"
                                  id="checkbox-all"
                                  name="checkbox-all"
                                  checked={isAllChecked()}
                                  onClick={() => setCheckedAll()}
                                  onChange={() => calcTotalPrice()}
                                />
                                <span className="chk"></span>
                                전체선택
                              </label>
                            </th>
                            <th>
                              <button
                                type="button"
                                className="btn btn-select-delete"
                                onClick={handleOpenModalAlert}
                              >
                                선택삭제
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {cartData &&
                            cartData.map((packageItem, index) => {
                              return (
                                <>
                                  {packageItem.sortOrder === 0 && (
                                    <>
                                      <tr key={index}>
                                        <td>
                                          <label htmlFor={`checkbox${index}`}>
                                            <input
                                              type="checkbox"
                                              id={`checkbox${index}`}
                                              name={`checkbox${index}`}
                                              value={packageItem.packageSeq}
                                              checked={packageItem.checked}
                                              onClick={() =>
                                                setChecked(packageItem)
                                              }
                                              onChange={() => calcTotalPrice()}
                                            />
                                            <span className="chk"></span>
                                          </label>
                                        </td>
                                        <td>
                                          <div
                                            className={`item-img ${
                                              packageItem.deliveryItemCount >
                                                0 && "delivery"
                                            }`}
                                          >
                                            <img
                                              src={packageItem.thumbnailPath}
                                              alt={packageItem.packageName}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="item-info">
                                            <p>
                                              <span>
                                                {
                                                  packageItem.packageCategoryGroupName
                                                }
                                                &middot;
                                                {
                                                  packageItem.packageCategoryName
                                                }
                                              </span>
                                              <em>{packageItem.teacherName}</em>
                                            </p>
                                            <p>
                                              <strong>
                                                {packageItem.packageName}
                                              </strong>
                                            </p>
                                            {/* {packageItem.packageTypeCode === 'REAL' && <Counter />} */}
                                            {packageItem.productItems && (
                                              <div className="item-detail">
                                                {packageItem.productItems.map(
                                                  (productItem, idx) => {
                                                    return (
                                                      productItem.requiredYn ===
                                                        "N" && (
                                                        <div
                                                          key={idx}
                                                          className="option"
                                                        >
                                                          {isEntrancedUser ===
                                                            false &&
                                                          packageItem.packageSeq ===
                                                            entrancePackageSeq ? (
                                                            <label
                                                              htmlFor={`scheckbox${index}-${idx}`}
                                                            >
                                                              <input
                                                                type="checkbox"
                                                                className="test"
                                                                id={`scheckbox${index}-${idx}`}
                                                                name={`scheckbox${index}-${idx}`}
                                                                value={
                                                                  productItem.packageSeq
                                                                }
                                                                checked={
                                                                  productItem.checked
                                                                }
                                                                onClick={(e) =>
                                                                  setChecked(
                                                                    productItem,
                                                                    packageItem
                                                                  )
                                                                }
                                                                onChange={(e) =>
                                                                  calcTotalPrice()
                                                                }
                                                              />
                                                              <span className="chk"></span>
                                                            </label>
                                                          ) : (
                                                            <label
                                                              htmlFor={`scheckbox${index}-${idx}`}
                                                            >
                                                              <input
                                                                type="checkbox"
                                                                id={`scheckbox${index}-${idx}`}
                                                                name={`scheckbox${index}-${idx}`}
                                                                value={
                                                                  productItem.packageSeq
                                                                }
                                                                checked={
                                                                  productItem.checked
                                                                }
                                                                onClick={(e) =>
                                                                  setChecked(
                                                                    productItem,
                                                                    packageItem
                                                                  )
                                                                }
                                                                onChange={(e) =>
                                                                  calcTotalPrice()
                                                                }
                                                              />
                                                              <span className="chk"></span>
                                                            </label>
                                                          )}
                                                          <div className="item-list">
                                                            <p>
                                                              {
                                                                productItem.packageName
                                                              }
                                                            </p>
                                                            {packageItem.packageSeq !==
                                                              entrancePackageSeq && (
                                                              <p>
                                                                <strong>
                                                                  {addComma(
                                                                    Number(
                                                                      getSalePrice(
                                                                        packageItem,
                                                                        productItem
                                                                      )
                                                                    )
                                                                  )}
                                                                </strong>
                                                                <em>
                                                                  {addComma(
                                                                    Number(
                                                                      productItem.originalPrice
                                                                    )
                                                                  )}
                                                                </em>
                                                              </p>
                                                            )}
                                                          </div>
                                                        </div>
                                                      )
                                                    );
                                                  }
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        </td>
                                        <td>
                                          <div className="item-price">
                                            <p>
                                              <em>
                                                {addComma(
                                                  Number(
                                                    packageItem.totalOrgPrice
                                                  )
                                                )}
                                              </em>
                                            </p>
                                            <p>
                                              {packageItem.FreeYn === "Y" ? (
                                                <strong>무료</strong>
                                              ) : (
                                                <strong>
                                                  {addComma(
                                                    Number(
                                                      packageItem.totalSalePrice
                                                    )
                                                  )}
                                                </strong>
                                              )}
                                            </p>
                                            {!hasEntrancePackage && (
                                              <button
                                                type="button"
                                                onClick={(e) =>
                                                  makeOrder(
                                                    packageItem.packageSeq,
                                                    e
                                                  )
                                                }
                                              >
                                                바로 구매
                                              </button>
                                            )}
                                          </div>
                                        </td>
                                        <td>
                                          {packageItem.deliveryItemCount ==
                                          0 ? (
                                            <span className="delivery">
                                              &ndash;
                                            </span>
                                          ) : packageItem.totalSalePrice >=
                                            MINIMUM_PRICE_FOR_FREE_DELIVERY ? (
                                            <span className="delivery">
                                              배송비
                                              <br />
                                              무료
                                            </span>
                                          ) : (
                                            <span className="delivery">
                                              배송비
                                              <br />
                                              {addComma(Number(DELIVERY_FEE))}원
                                            </span>
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                </>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </section>
                {/*</section>*/}
                <section className="box-tb-col">
                  <section className="inner-col">
                    <h3>
                      <strong>주문정보</strong>
                    </h3>
                    <div className="tb-default order">
                      <table>
                        <colgroup>
                          <col width="25%" />
                          <col width="25%" />
                          <col width="25%" />
                          <col width="25%" />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>총 상품 금액</th>
                            <th>배송비</th>
                            <th>할인 금액</th>
                            <th>총 주문 금액</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span>{addComma(Number(totalOrgPrice))}</span>
                            </td>
                            <td>
                              <span>{addComma(Number(deliveryFee))}</span>
                            </td>
                            <td>
                              <span>
                                {addComma(Number(totalDiscountPrice))}
                              </span>
                            </td>
                            <td>
                              <strong>
                                {addComma(Number(totalSalePrice))}
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <button type="button" onClick={(e) => makeOrder(-1, e)}>
                      주문하기
                    </button>
                  </section>
                </section>
              </>
            ) : (
              <div className="inner-col">
                <NoContent
                  icoImg={icoImg}
                  titleMsg={"장바구니에 담긴 상품이 없습니다."}
                  descMsg={"원하는 상품을 장바구니에 담아 보세요."}
                />
              </div>
            )}
          </>
        </Desktop>
        <Mobile>
          {cartData.length > 0 ? (
            <>
              <section className="cart-list-col">
                <div className="inner-col">
                  <div className="total-chk">
                    <label htmlFor="checkbox-all">
                      <input
                        type="checkbox"
                        id="checkbox-all"
                        name="checkbox-all"
                        checked={isAllChecked()}
                        onClick={() => setCheckedAll()}
                        onChange={() => calcTotalPrice()}
                      />
                      <em>chk</em>
                      전체선택
                    </label>
                    <button type="button" onClick={handleOpenModalAlert}>
                      선택삭제
                    </button>
                  </div>
                  <div className="cart-item">
                    <ul>
                      {cartData &&
                        cartData.map((packageItem, index) => {
                          return (
                            packageItem.sortOrder === 0 && (
                              <li key={index}>
                                <label htmlFor={`checkbox${index}`}>
                                  {/*{packageItem.deliveryItemCount > 0 && (
                                    <span class="ico-delivery">배송상품</span>
                                  )}*/}
                                  <input
                                    type="checkbox"
                                    id={`checkbox${index}`}
                                    name={`checkbox${index}`}
                                    value={packageItem.packageSeq}
                                    checked={packageItem.checked}
                                    onClick={() => setChecked(packageItem)}
                                    onChange={() => calcTotalPrice()}
                                  />
                                  <span className="chk"></span>
                                  <div className="item-box">
                                     <div className={`item-img ${
                                          packageItem.deliveryItemCount >
                                          0 && "delivery"
                                      }`}
                                     >
                                        <img src={packageItem.thumbnailPath} alt={packageItem.packageName} />
                                    </div>
                                    <div className="item-info">
                                      <p>
                                        <em>
                                          {packageItem.packageCategoryGroupName}
                                          &middot;
                                          {packageItem.packageCategoryName}
                                        </em>
                                        <em>{packageItem.teacherName}</em>
                                      </p>
                                      <p>
                                        <strong>
                                          {packageItem.packageName}
                                        </strong>
                                      </p>
                                      {/* {packageItem.packageTypeCode === 'REAL' && <Counter />} */}

                                      {packageItem.productItems && (
                                          <div className="item-detail">
                                            {packageItem.productItems.map(
                                                (productItem, idx) => {
                                                  return (
                                                      productItem.requiredYn === "N" && (
                                                          <div key={idx} className="option">
                                                            <label
                                                                htmlFor={`scheckbox${index}-${idx}`}
                                                            >
                                                              <input
                                                                  type="checkbox"
                                                                  id={`scheckbox${index}-${idx}`}
                                                                  name={`scheckbox${index}-${idx}`}
                                                                  value={productItem.packageSeq}
                                                                  checked={productItem.checked}
                                                                  onClick={(e) =>
                                                                      setChecked(
                                                                          productItem,
                                                                          packageItem
                                                                      )
                                                                  }
                                                                  onChange={(e) =>
                                                                      calcTotalPrice()
                                                                  }
                                                              />
                                                              <span className="chk"></span>
                                                            </label>
                                                            <div className="item-list">
                                                              <p>{productItem.packageName}</p>
                                                              {packageItem.packageSeq !==
                                                              entrancePackageSeq && (
                                                                  <p>
                                                                    <strong>
                                                                      {addComma(
                                                                          Number(
                                                                              getSalePrice(
                                                                                  packageItem,
                                                                                  productItem
                                                                              )
                                                                          )
                                                                      )}
                                                                    </strong>

                                                                    <em>
                                                                      {addComma(
                                                                          Number(
                                                                              productItem.originalPrice
                                                                          )
                                                                      )}
                                                                    </em>
                                                                  </p>
                                                              )}
                                                            </div>
                                                          </div>
                                                      )
                                                  );
                                                }
                                            )}
                                          </div>
                                      )}
                                      <div className="item-price">
                                        <div>
                                          <p>
                                            <em>
                                              {addComma(
                                                  Number(packageItem.totalOrgPrice)
                                              )}
                                            </em>
                                          </p>
                                          <p>
                                            {packageItem.FreeYn === "Y" ? (
                                                <strong>무료</strong>
                                            ) : (
                                                <strong>
                                                  {addComma(
                                                      Number(packageItem.totalSalePrice)
                                                  )}
                                                </strong>
                                            )}
                                          </p>
                                        </div>
                                        {!hasEntrancePackage && (
                                            <button
                                                type="button"
                                                onClick={(e) =>
                                                    makeOrder(packageItem.packageSeq, e)
                                                }
                                            >
                                              바로 구매
                                            </button>
                                        )}
                                      </div>

                                    </div>
                                  </div>
                                </label>
                                {/*{packageItem.productItems && (
                                  <div className="item-detail">
                                    {packageItem.productItems.map(
                                      (productItem, idx) => {
                                        return (
                                          productItem.requiredYn === "N" && (
                                            <div key={idx} className="option">
                                              <label
                                                htmlFor={`scheckbox${index}-${idx}`}
                                              >
                                                <input
                                                  type="checkbox"
                                                  id={`scheckbox${index}-${idx}`}
                                                  name={`scheckbox${index}-${idx}`}
                                                  value={productItem.packageSeq}
                                                  checked={productItem.checked}
                                                  onClick={(e) =>
                                                    setChecked(
                                                      productItem,
                                                      packageItem
                                                    )
                                                  }
                                                  onChange={(e) =>
                                                    calcTotalPrice()
                                                  }
                                                />
                                                <span className="chk"></span>
                                              </label>
                                              <div className="item-list">
                                                <p>{productItem.packageName}</p>
                                                {packageItem.packageSeq !==
                                                  entrancePackageSeq && (
                                                  <p>
                                                    <strong>
                                                      {addComma(
                                                        Number(
                                                          getSalePrice(
                                                            packageItem,
                                                            productItem
                                                          )
                                                        )
                                                      )}
                                                    </strong>

                                                    <em>
                                                      {addComma(
                                                        Number(
                                                          productItem.originalPrice
                                                        )
                                                      )}
                                                    </em>
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                          )
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                                <div className="item-price">
                                  <div>
                                    <p>
                                      <em>
                                        {addComma(
                                          Number(packageItem.totalOrgPrice)
                                        )}
                                      </em>
                                    </p>
                                    <p>
                                      {packageItem.FreeYn === "Y" ? (
                                        <strong>무료</strong>
                                      ) : (
                                        <strong>
                                          {addComma(
                                            Number(packageItem.totalSalePrice)
                                          )}
                                        </strong>
                                      )}
                                    </p>
                                  </div>
                                  {!hasEntrancePackage && (
                                    <button
                                      type="button"
                                      onClick={(e) =>
                                        makeOrder(packageItem.packageSeq, e)
                                      }
                                    >
                                      바로 구매
                                    </button>
                                  )}
                                </div>*/}
                              </li>
                            )
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </section>
              <section className="order-list-col">
                <div className="inner-col">
                  <div className="order-item">
                    <h3>주문 정보</h3>
                    <div>
                      <ul>
                        <li>
                          <span>총 주문 금액</span>
                          <span>{addComma(Number(totalOrgPrice))}</span>
                        </li>
                        <li>
                          <span>배송비</span>
                          <span>{addComma(Number(deliveryFee))}</span>
                        </li>
                        <li>
                          <span>할인 금액</span>
                          <span>{addComma(Number(totalDiscountPrice))}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="price-item">
                    <p>{addComma(Number(totalSalePrice))}</p>
                    <button type="button" onClick={(e) => makeOrder(-1, e)}>
                      주문하기
                    </button>
                  </div>
                </div>
              </section>
            </>
          ) : (
            <div className="inner-col">
              <NoContent
                icoImg={icoImg}
                titleMsg={"장바구니에 담긴 상품이 없습니다."}
                descMsg={"원하는 상품을 장바구니에 담아 보세요."}
              />
            </div>
          )}
        </Mobile>
        <Banner
          loop
          autoPlay
          slideView={1}
          type={"normal"}
          data={normalBanner}
        />
      </article>
      {modalopenAlert ? (
        <ModalPortal>
          <AlertModal
            // btnClose
            btnGroup
            msg={"선택한 상품을 삭제하시겠습니까?"}
            handleCloseModal={handleCloseModalAlert}
            handleConfirmClose={removeCartItems}
          />
        </ModalPortal>
      ) : null}
    </>
  );
}
