import React, { useState, useRef, useEffect } from 'react';
import api from 'common/api';
import * as config from 'common/config';

export default function ModalMyInquiry({ btnClose, inquirySeq, handleCloseModal }) {
  const inquiryRef = useRef();
  let fileMaxSize = 2 * 1024 * 1024; // 2MB

  const [inquiryStatus, setInquiryStatus] = useState(false);
  const [categoryList, setCategoryList] = useState(null);
  const [inquireList, setInquireList] = useState(null);
  const [selectedServiceCategory, setSelectedServiceCategory] = useState(null);
  const [selectInquiryCategory, setSelectInquiryCategory] = useState(null);
  const [textareaValue, setTextareaValue] = useState('');
  const [textareaLength, setTextareaLength] = useState(0);
  const [selectFileName, setSelectFileName] = useState(null);
  const [selectFileData, setSelectFileData] = useState('');
  const [uploadImg, setUploadImg] = useState(null);

  const handleInputFile = (e) => {
    setSelectFileName(e.target.value);
    setSelectFileData(e.target.files[0]);
  };

  const handleInquiryValue = (e) => {
    setTextareaValue(e.target.value);
    setTextareaLength(e.target.value.length);
  };

  const handleInputValue = (e) => {
    setSelectInquiryCategory(e.target.value);
  };

  const handleServiceCategory = (e) => {
    setSelectedServiceCategory(e.target.value);
  };

  // 이미지 업로드
  const postImage = (e) => {
    const formData = new FormData();

    formData.append('file', selectFileData);
    formData.append('resizeType', 'DEFAULT');

    api
      .post('/v2/file/image-upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.data.meta.code !== 200) {
          if (response.data.meta.message) {
            alert(response.data.meta.message);
          } else {
            alert(config.MESSAGE['common-error']);
          }
          return;
        }

        setUploadImg(response.data.data[0]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // 문의 카테고리 리스트
  const getCategoryList = () => {
    api
      .get(`/v2/inquiry/service/category/list`)
      .then((response) => {
        setCategoryList(response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // 내가 작성한 문의가 있다면 데이터 업데이트
  const getMyInquiry = () => {
    api
      .get(`/v2/inquiry/${inquirySeq}`)
      .then((response) => {
        if (response.data.meta.code === 200) {
          setInquiryStatus(true);
          setTextareaValue(response.data.data.contents);
          setTextareaLength(response.data.data.contents.length);
          setSelectFileName(response.data.data.inquiryFilePath);
          setSelectInquiryCategory(response.data.data.category);
          setSelectedServiceCategory(response.data.data.serviceType);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // 문의 등록
  const postMyInquiry = () => {
    const data = {
      serviceType: selectedServiceCategory,
      category: selectInquiryCategory,
      contents: textareaValue,
      inquiryFilePath: uploadImg ? uploadImg.filePath : '',
    };

    api
      .post('/v2/inquiry', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message);

          return false;
        }

        alert('문의가 저장되었습니다.');
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // 문의 수정
  const editMyInquiry = () => {
    const data = {
      serviceType: selectedServiceCategory,
      category: selectInquiryCategory,
      contents: textareaValue,
      inquiryFilePath: uploadImg ? uploadImg.filePath : selectFileName,
      inquirySeq: inquirySeq,
    };

    api
      .put('/v2/inquiry', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message);

          return false;
        }

        alert('문의가 수정되었습니다.');
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleRegistMyInquiry = () => {
    if (!selectedServiceCategory) {
      alert('서비스 영역을 선택하세요.');

      return false;
    }

    if (!selectInquiryCategory) {
      alert('문의 분류를 선택하세요.');

      return false;
    }

    if (textareaLength < 11) {
      alert('최소 10자 이상 입력해주세요.');
      inquiryRef.current.focus();

      return false;
    }

    if (
      (uploadImg && uploadImg.fileSize > fileMaxSize) ||
      (selectFileData && selectFileData.size > fileMaxSize)
    ) {
      alert('파일은 2MB 이하만 등록 가능 합니다.');
      setSelectFileName('');
      return false;
    }

    // 문의를 작성한 적이 있다면 수정 API, 없다면 등록 API
    if (inquiryStatus) {
      editMyInquiry();
    } else {
      postMyInquiry();
    }
  };

  useEffect(() => {
    if (selectFileData) {
      postImage();
    }
  }, [selectFileData]);

  useEffect(() => {
    if (inquirySeq) {
      getMyInquiry();
    }
    getCategoryList();
  }, []);

  useEffect(() => {
    if (categoryList) {
      setInquireList(
        categoryList.filter((item) => item.serviceType === selectedServiceCategory)[0]
      );
    }
  }, [selectedServiceCategory]);

  return (
    <>
      <div className="modal-wrap">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleCloseModal}>
              <span className="blind">닫기</span>
            </button>
          )}
          <div className="modal-header">
            <strong>1:1 문의</strong>
          </div>
          <div className="modal-content">
            <div className="modal-form-col write-form">
              <form action="frmMyInquiry">
                <dl>
                  <dt className="mark">서비스 선택</dt>
                  <dd>
                    <select
                      className="modal-select-box"
                      name="category"
                      value={selectedServiceCategory ? selectedServiceCategory : 'default'}
                      onChange={handleServiceCategory}
                    >
                      <option value="default" disabled>
                        서비스 영역을 선택해주세요
                      </option>
                      {categoryList &&
                        categoryList.map((service) => (
                          <option key={service.serviceType} value={service.serviceType}>
                            {service.serviceTypeName}
                          </option>
                        ))}
                    </select>
                  </dd>
                </dl>
                <dl>
                  <dt className="mark">문의분류</dt>
                  <dd>
                    <select
                      className="modal-select-box"
                      name="category"
                      value={selectInquiryCategory ? selectInquiryCategory : 'default'}
                      onChange={handleInputValue}
                    >
                      <option value="default" disabled>
                        문의 분류를 선택해주세요
                      </option>
                      {inquireList &&
                        inquireList.categoryList.map((list) => (
                          <option key={list.categoryCode} value={list.categoryCode}>
                            {list.categoryName}
                          </option>
                        ))}
                    </select>
                  </dd>
                </dl>
                <dl className="inquiry-area">
                  <dt>문의내용</dt>
                  <dd>
                    <textarea
                      placeholder="※ 현재 문의량이 많아 답변이 지연되고 있습니다. 순차적으로 답변 드리고 있으며, 빠른 답변이 될 수 있도록 하겠습니다. 불편을 드려 죄송합니다."
                      maxLength="2000"
                      ref={inquiryRef}
                      value={textareaValue}
                      onChange={handleInquiryValue}
                    ></textarea>
                    <p>
                      <span>{textareaLength}</span>/2000
                    </p>
                  </dd>
                </dl>
                <dl>
                  <dt>첨부파일</dt>
                  <dd className="flex-form">
                    <span className="txt ellipsis active">{selectFileName}</span>
                    <input
                      type="file"
                      accept="image/*"
                      id="fileUpload"
                      onChange={handleInputFile}
                    />
                    <label className="btn active" htmlFor="fileUpload">
                      파일찾기
                    </label>
                  </dd>
                </dl>
              </form>
              <div className="notice">
                <em>파일은 2MB 이하, jpg, png, gif 형식만 등록 가능 합니다.</em>
                <strong>문의 작성 안내</strong>
                <ul>
                  <li>
                    약관 및 법률에 위배(비속어, 허위사실 등), 해석이 불가능하거나 문의 외 다른
                    목적의 글을 작성한 경우 관리자에 의해 사전 동의 없이 미게시 될 수 있습니다.
                  </li>
                  <li>작성하신 내용은 홍보, 이벤트 등 다양한 방법으로 활용될 수 있습니다.</li>
                </ul>
                <div className="btn-flex-form">
                  <button type="button" className="btn" onClick={handleCloseModal}>
                    <span>취소</span>
                  </button>
                  <button type="button" className="btn active" onClick={handleRegistMyInquiry}>
                    <span>확인</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
