import { useState } from 'react'

import ResponsiveImage from 'components/ResponsiveImage'

import CharacterCircleList from './CharacterCircleList'
import Share from './Share'
import SwiperCharacter from './SwiperCharacter'
import s from '../aiTalk.module.css'
import { sectionTitleValue, imgGradientValue, AI_CDN_HOST } from '../config'

export default function CharactersList() {
  const [startIdx, setStartIdx] = useState(0)
  return (
    <>
      <section className={s.character}>
        <div className={s.inner}>
          <hgroup className={s.imgTitle}>
            <h2>
              <ResponsiveImage
                pc={sectionTitleValue.pc}
                mo={sectionTitleValue.mo}
                alt={sectionTitleValue.text}
                maxWidth={1024}
              />
            </h2>
            <h3>
              <img src={imgGradientValue} alt="오늘은 누구와 대화하고 싶나요?" />
            </h3>
            <h4>
              나와 관심사가 딱 맞는 AI 친구를 찾아보세요!
              <br />
              영어 대화가 더욱 재밌어집니다.
            </h4>
          </hgroup>
          <div className={s.charContainer}>
            <div className={s.charCardBox}>
              <SwiperCharacter setIdx={setStartIdx} />
            </div>
            <p className={s.charNoti}>
              * 야나두 모든 회원은 Emily Rose와 대화할 수 있으며,
              <br />
              <strong>야나두 영어</strong>의 유료 강의를 수강 중이라면 Emily Rose를 포함한
              <br />
              모든 AI 원어민 친구와 자유롭게 대화할 수 있어요.
              <br />
              (단, 일부 강의는 AI 원어민 톡이 제공되지 않을 수 있습니다.)
              <span>
                <ResponsiveImage
                  pc={`${AI_CDN_HOST}img_pc_ai_person_noti.png`}
                  mo={`${AI_CDN_HOST}img_m_ai_person_noti.png`}
                  maxWidth={1024}
                  alt={'모든 이미지는 AI로 제작되었습니다.'}
                />
              </span>
            </p>
            <CharacterCircleList idx={startIdx} />
          </div>
        </div>
        <Share />
      </section>
    </>
  )
}
