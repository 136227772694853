import React from 'react'
import { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useMediaQuery } from 'react-responsive'

import styled, { ThemeProvider } from 'styled-components'

import api from 'common/api'
import theme from 'common/theme'

import StoreClassGroupList from '../../../components/Store/List/ClassGroupList'
import StoreBanner from '../components/StoreBanner'
const StoreList = () => {
  const [isLoading, setLoading] = useState(true)
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  const [banner, setBanner] = useState([])
  const [list, setList] = useState([])
  const getBannerData = () => {
    api
      .get(`/v2/store/banner`)
      .then((response) => {
        setBanner(response.data.data)
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const getListData = () => {
    api
      .get(`/v2/store/curation`)
      .then((response) => {
        setList(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getBannerData()
    getListData()
  }, [])

  return (
    <>
      <ThemeProvider theme={theme}>
        {isLoading ? (
          <LoadingBox>
            <Skeleton height={isMobile ? '78rem' : '48rem'} />
            <ul>
              {new Array(8).fill('').map((item, idx) => (
                <li key={idx}>
                  <Skeleton height={isMobile ? '25rem' : '18rem'} />
                  <Skeleton width={'18rem'} height={isMobile ? '3rem' : '2rem'} />
                  <Skeleton width={'14rem'} height={isMobile ? '3rem' : '2rem'} />
                </li>
              ))}
            </ul>
          </LoadingBox>
        ) : (
          <>
            {' '}
            {banner.map((el, idx) => (
              <div key={idx}>
                {el.bannerType === 'CLASS_HOME_TOP' ? (
                  <StoreBanner
                    key="classTop"
                    loop
                    autoPlay
                    heightPC={480}
                    bannerData={el.bannerList}
                  />
                ) : null}
              </div>
            ))}
            {list.map((item, idx) => (
              <div key={idx}>
                {item.fixYn === 'Y' ? (
                  <StoreClassGroupList
                    title={item.storeCurationName}
                    storeListData={item.packageList}
                    detailLink={item.moreBtnLink}
                  />
                ) : null}
              </div>
            ))}
            {banner.map((el, idx) => (
              <div key={idx}>
                {el.bannerType === 'CLASS_HOME_MIDDLE' ? (
                  <StoreBanner
                    key="classMiddle"
                    loop
                    autoPlay
                    heightPC={320}
                    bannerData={el.bannerList}
                  />
                ) : null}
              </div>
            ))}
            {list.map((item, idx) => (
              <div key={idx}>
                {item.fixYn === 'N' ? (
                  <StoreClassGroupList
                    title={item.storeCurationName}
                    storeListData={item.packageList}
                    detailLink={item.moreBtnLink}
                  />
                ) : null}
              </div>
            ))}
          </>
        )}
      </ThemeProvider>
    </>
  )
}

export default StoreList
const LoadingBox = styled.div`
  margin: 0 auto;
  ul {
    display: flex;
    max-width: 120rem;
    flex-wrap: wrap;
    padding: 0 4.2rem;
    margin: 8rem auto 2rem;
    li {
      width: calc(25%);
      padding-left: 2rem;
      padding-bottom: 2rem;
      flex-shrink: 0;
      box-sizing: border-box;
      span {
        margin-bottom: 1rem;
      }
      &:nth-child(4n - 3) {
        padding-left: 0;
      }
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    ul {
      justify-content: center;
      padding: 0;
      li {
        width: calc(50% - 2rem);
        padding-right: 2rem;
        &:nth-child(4n - 3) {
          padding-left: 2rem;
        }
      }
    }
  }
`
