import AboutEng from 'pages/aboutEng'
import EnglishHome from 'pages/english/EnglishHome'
import EntranceFree from 'pages/entranceFree'
import Main from 'pages/home/index'
import LoginPage from 'pages/login'
import Logout from 'pages/logout'
import Performance from 'pages/performance'
import CategoryList from 'pages/product/CategoryList'
import CourseList from 'pages/product/CourseList'

import PublicRouter from 'common/publicRouter'
import AppleAuth from 'components/oauth/AppleAuth'
import FacebookAuth from 'components/oauth/FacebookAuth'
import KakaoAuth from 'components/oauth/KakaoAuth'
import NaverAuth from 'components/oauth/NaverAuth'

const MainRoutes = [
  { path: '/', element: <Main /> },
  { path: 'EnglishHome', element: <EnglishHome /> },
  {
    path: '/login',
    element: (
      <PublicRouter>
        <LoginPage />
      </PublicRouter>
    )
  },
  { path: '/logout', element: <Logout /> },
  {
    path: '/oauth/*',
    children: [
      { path: 'kakao', element: <KakaoAuth /> },
      { path: 'naver', element: <NaverAuth /> },
      { path: 'facebook', element: <FacebookAuth /> },
      { path: 'apple', element: <AppleAuth /> }
    ]
  },
  { path: '/aboutEng', element: <AboutEng /> },
  { path: '/courseList', element: <CourseList /> },
  { path: '/categoryList', element: <CategoryList /> },
  { path: '/entranceFree', element: <EntranceFree /> },
  { path: '/performance/:performanceSeq', element: <Performance /> }
]

export default MainRoutes
