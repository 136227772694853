import ArrowSvg from './components/ArrowSvg'
import { APPLE_PACKAGE_HOST_CDN } from 'pages/promotion/applePackage/config'
import { BEST_AWARDS_HOST_CDN } from 'pages/promotion/bestAwards/config'
import { BEST_PERFORM_CDN } from 'pages/promotion/bestAwardsDBOnly_performance/config'
import { IPAD_DOUBLE_HOST_CDN } from 'pages/promotion/ipadDouble/config'
import { MACBOOK_PACKAGE_HOST_CDN } from 'pages/promotion/macbookPackage/config'
import { STANDBYME_HOST_CDN } from 'pages/promotion/standByMe/config'
import { IPADTEN_HOST_CDN } from 'pages/promotion/tabPackage10th/config'
import { IPADAIR_HOST_CDN_UPDATE } from 'pages/promotion/tabPackageAir/config'

export const COUNSEL_TIME = [
  '상담가능시간', // 띄어쓰기 X 퍼포먼스 팀 요청
  '10:00 ~ 11:00',
  '11:00 ~ 12:00',
  '12:00 ~ 13:00',
  '13:00 ~ 14:00',
  '14:00 ~ 15:00',
  '15:00 ~ 16:00',
  '16:00 ~ 17:00',
  '17:00 ~ 18:00',
  '언제든 연락주세요'
]
export const DEVICE_OPTION = ['Apple 풀패키지', 'iPad 10세대', 'MacBook Air', 'LG gram 15']

export const dbCollectionFormValues = {
  macbookPackagePerformance: {
    category: 'New_macbookPackage',
    bottomBannerBackground: '#28284B',
    buttonBackground: 'linear-gradient(90deg, #15BAFF 0%, #621CF5 100%)',
    buttonColor: '#fff',
    modalButtonText: '빠른 상담하기',
    timer: true,
    speechBubbleBackground: '#0094FF',
    alertModalBanner: {
      pc: `${BEST_PERFORM_CDN}img_db-question-banner.png`,
      alt: '상담 신청 감사합니다! 전문가의 맞춤 상담을 위해 추가 정보를 기입해 주세요.'
    }
  },
  laptopPackagePerformance: {
    category: 'New_laptopPackage',
    bottomBannerBackground: '#28284B',
    buttonBackground: 'linear-gradient(90deg, #91E9FF 0%, #F3A5FE 100%)',
    buttonColor: '#000',
    modalButtonText: '빠른 상담하기',
    timer: false,
    alertModalBanner: {
      pc: `${BEST_PERFORM_CDN}img_db-question-banner.png`,
      alt: '상담 신청 감사합니다! 전문가의 맞춤 상담을 위해 추가 정보를 기입해 주세요.'
    }
  },
  bestAwardsDBOnlyPerformance: {
    category: '10min_DB',
    bottomBannerBackground: '#000000',
    buttonBackground: '#336bee',
    buttonColor: '#fff',
    modalBanner: {
      pc: `${BEST_PERFORM_CDN}img_db-banner.png`,
      alt: '지금 상담 신청하면 영어 평생지원 왕초보 탈출!'
    },
    modalButtonText: (
      <>
        상담 예약하기
        <ArrowSvg />
      </>
    ),
    timer: true,
    modalTitle: (
      <>
        지금 결제하지 마세요 <br />
        상담 먼저 받고 결정하세요!
      </>
    ),
    speechBubbleBackground: '#F53B00',
    alertModalBanner: {
      pc: `${BEST_PERFORM_CDN}img_db-question-banner.png`,
      alt: '상담 신청 감사합니다! 전문가의 맞춤 상담을 위해 추가 정보를 기입해 주세요.'
    }
  },
  applePackage: {
    category: 'applePackage',
    bottomBannerBackground: '#000',
    buttonBackground: 'linear-gradient(90deg, #FF0059 0%, #9316F7 100%)',
    buttonColor: '#fff',
    modalBanner: {
      pc: `${APPLE_PACKAGE_HOST_CDN}/2024/bnr_pc_popup_applepackage.png`,
      alt: '애플 풀 패키지 선착순 한정 추가 증정 혜택'
    },
    modalButtonText: (
      <>
        Apple 풀패키지 신청하기
        <ArrowSvg />
      </>
    ),
    bottomBannerTitle: (
      <>
        선착순 <strong style={{ color: '#F80264' }}>Apple 풀패키지</strong> 혜택 신청
      </>
    ),
    timer: false
  },
  applePackageUI: {
    category: 'applePackageUI',
    bottomBannerBackground: '#000',
    buttonBackground: 'linear-gradient(90deg, #FF0059 0%, #9316F7 100%)',
    buttonColor: '#fff',
    modalBanner: {
      pc: `${APPLE_PACKAGE_HOST_CDN}/2024/bnr_pc_popup_applepackage.png`,
      alt: '애플 풀 패키지 선착순 한정 추가 증정 혜택'
    },
    modalButtonText: (
      <>
        Apple 풀패키지 신청하기
        <ArrowSvg />
      </>
    ),
    bottomBannerTitle: (
      <>
        선착순 <strong style={{ color: '#F80264' }}>Apple 풀패키지</strong> 혜택 신청
      </>
    ),
    timer: false
  },
  macbookPackage: {
    category: 'macbookPackage',
    bottomBannerBackground: '#000',
    buttonBackground: 'linear-gradient(to right, #4200ff, #7200ff)',
    buttonColor: '#fff',
    modalBanner: {
      pc: `${MACBOOK_PACKAGE_HOST_CDN}2024/bnr_pc_popup_macbook.png`,
      alt: '애플 풀 패키지 선착순 한정 추가 증정 혜택'
    },
    modalButtonText: 'MacBook 신청하기',
    timer: true
  },
  macbookPackageImac: {
    category: 'macbookPackage',
    content: '상담 선택 기기 : iMac',
    bottomBannerBackground: '#000',
    buttonBackground: '#6511ff',
    buttonColor: '#fff',
    modalBanner: {
      pc: `${MACBOOK_PACKAGE_HOST_CDN}2023/nov/img_pc_imac_popup.png`,
      alt: '선착순 한정 수량 iMac 드려요'
    },
    modalButtonText: 'iMac 상담 신청하기',
    timer: true
  },
  ipadDouble: {
    category: 'ipadDouble',
    buttonBackground: `url(${IPAD_DOUBLE_HOST_CDN}bg-modal-button.png)`,
    buttonColor: '#000',
    modalBanner: {
      pc: `${IPAD_DOUBLE_HOST_CDN}img-db-modal.png`,
      alt: '아이패드10 더블득템 평생 수강 시작만 하면 조건없이 증정 재고 소진 시 종료'
    },
    modalButtonText: (
      <>
        상담 예약하기
        <ArrowSvg isBlack />
      </>
    ),
    timer: true
  },
  bestAwards: {
    category: 'mainLandingB',
    buttonBackground: '#F5420B',
    buttonColor: '#fff',
    modalBanner: {
      pc: `${BEST_AWARDS_HOST_CDN}img-db-banner.png`,
      alt: '오늘 한정 수량이 소진되었어요. 추가 수량 확보 시 실시간으로 알려드릴게요!'
    },
    modalButtonText: (
      <>
        10분 패키지 오픈 알림 신청하기
        <ArrowSvg />
      </>
    ),
    timer: false
  },
  standByMe: {
    category: 'standByMe',
    buttonBackground: '#19b644',
    buttonColor: '#fff',
    modalBanner: {
      pc: `${STANDBYME_HOST_CDN}img-db-modal.png`,
      alt: '오늘 한정 수량이 소진되었어요. 추가 수량 확보 시 실시간으로 알려드릴게요!'
    },
    modalButtonText: '스탠바이미Go 무료 신청하기',
    timer: false
  },
  tabPackageAir: {
    category: 'tabPackageAir5',
    buttonBackground: 'linear-gradient(90deg, #6555ff, #8543ff)',
    buttonColor: '#fff',
    modalTitle: (
      <>
        가장 합리적으로 공부하는 방법
        <br />
        지금 상당 받으세요
      </>
    ),
    modalBanner: {
      pc: `${IPADAIR_HOST_CDN_UPDATE}tabpackagesix_db_banner.png`,
      alt: '오늘 한정 수량이 소진되었어요. 추가 수량 확보 시 실시간으로 알려드릴게요!'
    },
    modalButtonText: (
      <>
        상담 예약하기
        <ArrowSvg />
      </>
    ),
    timer: false
  },
  tabPackage10th: {
    category: 'tabPackage10th',
    bottomBannerBackground: '#000',
    buttonBackground: '#ff0cc3',
    buttonColor: '#fff',
    modalTitle: (
      <>
        ↓ 아래 신청하기 버튼을 눌러 ↓<br />
        한정 혜택을 확인해보세요
        <span>
          <img src={`${IPADTEN_HOST_CDN}2023/may/ico_m_tabpackageten_pop_gift.png`} alt="" />
        </span>
      </>
    ),
    modalButtonText: (
      <>
        iPad 10세대 신청하기
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 31" fill="none">
          <path
            d="M22.033 17.113H0V12.9564H22.033L12.0336 2.96515L15 0.046875L30 15.0347L15 30.0225L12.0336 27.1043L22.033 17.113Z"
            fill="white"
          />
        </svg>
      </>
    ),
    timer: true
  },
  laptopPackage: {
    category: 'laptopPackage',
    bottomBannerBackground: '#07192d',
    buttonBackground: '#fff005',
    buttonColor: '#000',
    modalBanner: {
      pc: `https://english.yanadoocdn.com/upload/yanadoo/new/promotion/laptopPackage/2024/bnr_pc_popup_gram.png`,
      alt: 'LG 그램 패키지 선착순 한정 추가 증정 혜택'
    },
    modalButtonText: (
      <>
        노트북 무료 신청하기
        <ArrowSvg isBlack />
      </>
    ),
    bottomBannerTitle: (
      <>
        [NEW] <strong style={{ color: '#9880ff' }}>LG gram 패키지</strong> 선착순 추가 혜택 신청
      </>
    ),
    timer: false
  },
  DB_Performance: {
    category: 'DB_Performance',
    hasDeviceOption: true,
    buttonBackground: '#ff36e2',
    buttonColor: '#fff',
    modalBanner: {
      pc: `https://english.yanadoocdn.com/upload/yanadoo/new/promotion/db_performance/img_popup_banner.png`,
      alt: '영어 공부 필수템 증정 사라지기 전에 신청하세요'
    },
    modalButtonText: (
      <>
        상담 예약하기
        <ArrowSvg />
      </>
    )
  },
  DB_Motivation: {
    category: 'DB_Motivation',
    hasDeviceOption: true,
    buttonBackground: '#ff4912',
    buttonColor: '#fff',
    modalBanner: {
      pc: `https://english.yanadoocdn.com/upload/yanadoo/new/promotion/DBmotivation/2023/nov/img_pc_motivation_popup_banner.png`,
      alt: '영어 공부 필수템 증정 사라지기 전에 신청하세요'
    },
    modalButtonText: (
      <>
        상담 예약하기
        <ArrowSvg />
      </>
    )
  },
  n_nipowoopo2023: {
    category: 'n_nipowoopo2023',
    buttonBackground: 'linear-gradient(90deg, #ff4800 0%, #ff004e 100%)',
    buttonColor: '#fff',
    modalTitle: (
      <>
        <div style={{ marginBottom: '.5em' }}>
          <img
            src="https://english.yanadoocdn.com/upload/yanadoo/new/promotion/n_nipowoopo2023/2022/dec/ico_pc_nipowoopo_check_58x58.png"
            alt="체크이미지"
          />
        </div>
        선착순 영어회화 성공 공식
        <br />
        지금 신청하세요!
        <span style={{ fontSize: '0.6em', fontWeight: 400, display: 'block', marginTop: '0.5em' }}>
          받으실 연락처와 성함을 알려주세요
        </span>
      </>
    ),
    modalButtonText: '왕초보 탈출 노하우 신청하기'
  }
}
