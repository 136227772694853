import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate } from 'react-router-dom';

import styled, { ThemeProvider } from 'styled-components';



import api from 'common/api';
import * as config from 'common/config';
import MobileHeader from 'components/common/MobileHeader';

import TabQna from './TabQna';
// import '../../../assets/styles/main.scss';
import icoCheck_36x21 from '../../../assets/images/ico_check_36x21.png';
import icoImg from '../../../assets/images/ico_no_qna_285x285.png';
import IcoPhoto from '../../../assets/images/ico_photo_120x120.png';
import theme from '../../../common/theme';
import Lnb from '../../../components/Lnb';
import NoContent from '../../../components/NoContent';
import Pagination from '../../../components/Pagination';

const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 3rem;

  & + table {
    margin-top: 2rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    margin-top: 0;
  }
`;

const SearchTxtList = styled.ul`
  @media ${({ theme }) => theme.device.mobile} {
    padding: 3rem 3rem 3rem 4.2rem;
    background-color: #f7f7fc;
  }
`;

const SearchTxtItem = styled.li`
  position: relative;
  padding-left: 1rem;
  font-weight: 400;
  font-size: 1.4rem;
  color: #717188;
  line-height: 1.55;

  &::before {
    content: '-';
    position: absolute;
    top: 0;
    left: 0;
    color: #717188;
  }

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 2.5rem;
  }
`;

const SearchSelectBox = styled.div`
  select {
    display: inline-block;
    width: 13.8rem;
    padding: 0.7rem 1.5rem 0.6rem;
    font-weight: 400;
    font-size: 1.4rem;
    border: 0.1rem solid #dfdfea;
    background: url(${icoCheck_36x21}) 91% 50% / 1.2rem auto no-repeat;

    & + select {
      margin-left: 1rem;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 3rem 4.2rem;

    select {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 50%;
      flex: 1 1 50%;
      width: auto;
      padding: 3.6rem 2.7rem 3.5rem;
      font-size: 2.9rem;
      background-size: 2rem auto;
      background-position: 95% 50%;

      & + select {
        margin-left: 1.8rem;
      }
    }
  }
`;
const TableBox = styled.table`
  width: 100%;
  margin-top: 2rem;
  table-layout: fixed;
  border-bottom: 0.1rem solid #b7b7c8;

  .tal {
    text-align: left;
  }

  .tar {
    text-align: right;
  }

  thead {
    border-top: 0.3rem solid #000;

    th {
      padding: 1.6rem;
      font-weight: 400;
      font-size: 1.4rem;
    }
  }

  tbody {
    tr {
      border-top: 0.1rem solid #e7e7f0;

      td {
        position: relative;
        padding: 2.6rem 2.5rem;
        text-align: center;
        font-size: 1.4rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        .ico-new {
          &::before {
            content: 'N';
            position: absolute;
            top: 50%;
            right: 0;
            width: 2rem;
            height: 2rem;
            padding: 0.4rem 0;
            margin-top: -1rem;
            font-weight: 700;
            font-size: 1rem;
            color: #fff;
            background-color: #ff5647;
            border-radius: 50%;
            text-align: center;
            box-sizing: border-box;
          }
        }

        a {
          display: block;
          text-align: left;


          &:active {
            text-decoration: underline;
          }
        }

        p {
          margin-top: 0.8rem;

          font-weight: 400;
          color: #717188;

          &.check {
            font-weight: 700;
            color: #a0a0b6;

            &.completed {
              color: #17c624;
            }
          }
        }

        strong {
          display: block;
          position: relative;
          margin: 0 auto;
          padding: 0.5rem;
          font-weight: 700;
          color: #000;

          &.ico-new {
            display: inline-block;
            max-width: calc(100% - 1rem);
            padding-right: 2.5rem;
          }
          &.ico-photo {
            display: inline-block;
            max-width: calc(100% - 3.6rem);
            padding-right: 3.6rem;

            &::before {
              content: '';
              position: absolute;
              top: 50%;
              right: 0;
              width: 2.4rem;
              height: 2.4rem;
              margin-top: -1.4rem;
              background: url(${IcoPhoto}) 50% 50% no-repeat;
              background-size: 100% auto;
            }
          }
        }
      }
    }
  }

  .no-content {
    padding: 14rem 0 20rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
  }
`;

const PhotoSpan = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  width: 4rem;
  height: 4rem;
  margin-top: -0.8rem;
  background: url(${IcoPhoto}) 50% 50% no-repeat;
  background-size: 100% auto;

`;

const QnaListSection = styled.section`
  padding: 8.2rem 0 12.6rem;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0;
  }
`;

export default function Review() {
  const navigate = useNavigate();
  const [pageIdx, setPageIdx] = useState(1);
  const [reviewData, setReviewData] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [dataKeyword, setDataKeyword] = useState({
    period: 3,
  });

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 });
    return isDesktop ? children : null;
  };
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 });
    return isMobile ? children : null;
  };

  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ');
      return getDate[0];
    } else {
      return null;
    }
  }

  const handleCurrentIdx = (idx) => {
    setPageIdx(idx);
  };

  const handlePeriodCheck = (e) => {
    const targetValue = e.target.value;
    setPageIdx(1);
    setDataKeyword({ ...dataKeyword, period: targetValue });

    getReviewData(targetValue);
  };

  const getReviewData = (period) => {
    (period === 'ALL'
      ? api.get(`/v2/review/user/list?index=${pageIdx}&size=10`)
      : api.get(
          `/v2/review/user/list?index=${pageIdx}&size=10&keys=registDateMinusMonth&types=equal&values=${period}`
        )
    )
      .then((response) => {
        setReviewData(response.data.data.content);
        setTotalPages(response.data.data.totalPages);
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error']);
      });
  };

  useEffect(() => {
    getReviewData(dataKeyword.period);
  }, [pageIdx]);

  return (
    <ThemeProvider theme={theme}>
      <div className="qna-col">
        <div className="inner-col">
          <div className="container mypage-sub">
            <MobileHeader title="후기" onClick={() => navigate('/mypage/1')}/>
            <Lnb />
            <QnaListSection>
              <TabQna activeTab={'REVIEW'} />
              <div>
                <SearchBox>
                  <SearchTxtList>
                    <SearchTxtItem>
                      답변이 등록된 후에는 수정 또는 삭제하실 수 없습니다.
                    </SearchTxtItem>
                  </SearchTxtList>
                  <SearchSelectBox>
                    <select
                      name="reviewPeriod"
                      id="reviewPeriod"
                      value={dataKeyword.period}
                      onChange={handlePeriodCheck}
                    >
                      <option value="ALL">전체</option>
                      <option value="3">3개월</option>
                      <option value="6">6개월</option>
                      <option value="12">12개월</option>
                    </select>
                  </SearchSelectBox>
                </SearchBox>
                <Desktop>
                  <TableBox>
                    <colgroup>
                      <col width="12%" />
                      <col width="20%" />
                      <col width="" />
                      <col width="20%" />

                    </colgroup>
                    <thead>
                      <tr>
                        <th>분류</th>
                        <th>상품/클래스명</th>
                        <th>내용</th>
                        <th>별점/작성일</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reviewData.length > 0 ? (
                        reviewData.map((reviewItem, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <p className="ellipsis">{reviewItem.reviewTypeName}</p>
                              </td>
                              <td>
                                <p className="ellipsis">{reviewItem.title}</p>
                              </td>
                              <td>
                                <Link
                                  to={`/mypage/qna/review/detail/${reviewItem.learningReviewSeq}`}
                                >
                                  <strong
                                    className={
                                      'ellipsis' + (reviewItem.attachments ? ' ico-photo' : '')
                                    }
                                  >
                                    {reviewItem.contents}
                                  </strong>

                                </Link>
                              </td>
                              <td>
                                <div className="review-info">
                                  <strong className="star-point">
                                    <span
                                      style={{
                                        width: reviewItem.satisfaction * 20 + '%',
                                      }}
                                    />
                                  </strong>
                                  <p>{formatDate(reviewItem.registDate)}</p>
                                </div>
                              </td>

                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="4">
                            <NoContent icoImg={icoImg} titleMsg={'등록된 후기가 없습니다.'} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </TableBox>
                </Desktop>
                <Mobile>
                  <div className="table-form-mobile">
                    {reviewData.length > 0 ? (
                      <ul className="table-lists">
                        {reviewData.map((reviewItem, index) => {
                          return (
                            <li key={index}>
                              <Link
                                to={`/mypage/qna/review/detail/${reviewItem.learningReviewSeq}`}
                              >
                                <span className="ellipsis">{reviewItem.title}</span>
                                <strong className="ellipsis line-clamp-2">
                                  {reviewItem.contents}
                                </strong>
                                <strong className="star-point">
                                  <span
                                    style={{
                                      width: reviewItem.satisfaction * 20 + '%',
                                    }}
                                  />
                                </strong>
                                <div className="info">
                                  <span
                                    className={
                                      'check' + (reviewItem.answerYn === 'Y' ? ' completed' : '')
                                    }
                                  >
                                    {reviewItem.answerYn === 'Y' ? '답변완료' : '답변대기'}
                                  </span>
                                  <span className="date">{formatDate(reviewItem.registDate)}</span>
                                  {reviewItem.attachments && <PhotoSpan />}
                                </div>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <NoContent icoImg={icoImg} titleMsg={'등록된 후기가 없습니다.'} />
                    )}
                  </div>
                </Mobile>
                {reviewData.length > 0 && (
                  <Pagination
                    type={'IDX'}
                    handleCurrentIdx={handleCurrentIdx}
                    totalPages={totalPages}
                    limitPage={5}
                    maxNum={5}
                    minNum={0}
                    pageIdx={pageIdx}
                  />
                )}
              </div>
            </QnaListSection>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
