import React, { useState, useCallback } from 'react';
import ModalPortal from '../../ModalPortal';
import ModalLearningInquiry from '../../components/modal/ModalLearningInquiry';
import * as config from 'common/config';

export default function TabClassList({ curriculumData, lectureData, studySeq }) {
  const [modalopen, setModalopen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isActiveSeq, setIsActiveSeq] = useState(null);
  const [activeClassData, setActiveClassData] = useState({
    classSeq: curriculumData.classSeq,
    className: curriculumData.className,
  });
  const [activeCourseData, setActiveCourseData] = useState(null);

  const handleOpenModal = useCallback((lecture) => {
    document.body.classList.add('modal-open');
    setModalopen(true);
    setActiveCourseData(lecture);
  }, []);

  const handleCloseModal = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalopen(false);
  }, []);

  const handleDownloadBox = (status, seq) => {
    setIsActive(status);
    setIsActiveSeq(seq);
  };

  const handleOpenPlayer = (studySeq, curriculumAssetSeq) => {
    window.open(
      `/promotion-landing/playerV2.html?studyGroupUserSeq=${studySeq}&curriculumAssetSeq=${curriculumAssetSeq}&apiHost=${encodeURIComponent(
        config.API_HOST
      )}`,
      'yndWebPlayer',
      'width=1048,height=562, scrollbars=no'
    );
  };

  return (
    <>
      {curriculumData && !curriculumData.pdfFilePath ? null : (
        <div className="btn-all-download">
          <a href={curriculumData.pdfFilePath} className="btn-download" download target="_blank">
            학습자료 전체 다운로드
          </a>
        </div>
      )}
      <ul className="list-area">
        {lectureData &&
          lectureData.map((list) => (
            <li key={list.sortOrder}>
              <dl>
                <dt>
                  <p className="ellipsis">
                    <strong>{list.sortOrder}강.</strong> {list.title}
                  </p>
                  {
                    // 강의 리스트가 1개일 경우
                    list.mediaAssets.length < 2 &&
                      (list.lectureType === 'VOD' ? (
                        <button
                          type="button"
                          className="btn-play video"
                          onClick={(e) =>
                            handleOpenPlayer(studySeq, list.mediaAssets[0].curriculumAssetSeq)
                          }
                        >
                          <i className="blind">강의재생</i>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn-play audio"
                          onClick={(e) =>
                            handleOpenPlayer(studySeq, list.mediaAssets[0].curriculumAssetSeq)
                          }
                        >
                          <i className="blind">오디오재생</i>
                        </button>
                      ))
                  }
                </dt>
                <dd>
                  {list.mediaAssets.length < 2 ? (
                    // 강의 리스트가 1개일 경우
                    <p className="time">
                      <span>강의시간 : {list.mediaAssets[0].durationMin}분</span>
                    </p>
                  ) : (
                    // 강의 리스트가 1개이상일 경우
                    <div className="sub-list">
                      {list.mediaAssets.map((item) => {
                        return (
                          <div key={item.sortOrder}>
                            <p className="">
                              <em className="title ellipsis">{item.contentsTypeName}</em>
                              <span className="time">강의시간 : {item.durationMin}분</span>
                            </p>
                            {list.lectureType === 'VOD' ? (
                              <button
                                type="button"
                                className="btn-play video"
                                onClick={(e) => handleOpenPlayer(studySeq, item.curriculumAssetSeq)}
                              >
                                <i className="blind">강의재생</i>
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn-play audio"
                                onClick={(e) => handleOpenPlayer(studySeq, item.curriculumAssetSeq)}
                              >
                                <i className="blind">오디오재생</i>
                              </button>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <p className="progress-rate">
                    달성률 <span>{list.achievementRate}</span>
                  </p>
                  <p className="item">
                    {
                      // assetList 완료가 Y이면 active 클래스 추가
                      list.assets.map((aList) => {
                        return (
                          <em
                            key={aList.sortOrder}
                            className={'asset-item' + (aList.completeYn === 'Y' ? ' active' : '')}
                          >
                            {aList.assetType === 'TRAINING'
                              ? 'AI'
                              : aList.assetType === 'MEDIA'
                              ? '영상'
                              : aList.assetType === 'MISSION'
                              ? '미션'
                              : '핵심표현'}
                          </em>
                        );
                      })
                    }
                  </p>
                </dd>
              </dl>
              <div className="list-btn-area">
                {list.attachFiles &&
                  (list.attachFiles.length > 1 ? (
                    <div
                      className="file-download-box"
                      onMouseOver={() => handleDownloadBox(true, list.lectureSeq)}
                      onMouseLeave={() => handleDownloadBox(false, list.lectureSeq)}
                    >
                      <button>
                        <i className="blind">다운로드</i>
                      </button>
                      <div
                        className={
                          'file-list' +
                          (isActive && isActiveSeq === list.lectureSeq ? ' active' : '')
                        }
                      >
                        <span className="box-title">학습자료 받기</span>
                        <ul>
                          {list.attachFiles.map((file) => {
                            return (
                              <li key={file.seq}>
                                <a
                                  href={encodeURIComponent(file.attachFilePath)
                                    .replaceAll('%2F', '/')
                                    .replaceAll('%3A', ':')}
                                  className="btn-download"
                                  download
                                  target="_blank"
                                >
                                  <span className="ellipsis">{file.attachFileName}</span>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <a
                      key={list.attachFiles[0].seq}
                      href={encodeURIComponent(list.attachFiles[0].attachFilePath)
                        .replaceAll('%2F', '/')
                        .replaceAll('%3A', ':')}
                      className="btn-download"
                      download
                      target="_blank"
                    >
                      <i className="blind">다운로드</i>
                    </a>
                  ))}
                <button type="button" className="btn-qna" onClick={(e) => handleOpenModal(list)}>
                  <span>질문하기</span>
                </button>
              </div>
            </li>
          ))}
      </ul>
      {modalopen ? (
        <ModalPortal>
          <ModalLearningInquiry
            btnClose
            activeClassData={activeClassData}
            activeCourseData={activeCourseData}
            handleCloseModal={handleCloseModal}
          />
        </ModalPortal>
      ) : null}
    </>
  );
}
