import { useState, useCallback, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { NavLink, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

import classNames from 'classnames'
import ModalPortal from 'ModalPortal'
import AuthService from 'services/authService'
import styled from 'styled-components'
import { postGAEvent } from 'utils/postGAEvent'

import ModalJoin from 'components/modal/ModalJoin'
import useHeaderStore from 'store/useHeaderStore'
import useModalLogin from 'store/useModalLogin'

export default function NavLeft({ isHamburger }) {
  const currentPath = useLocation().pathname
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  const [currentIdx, setCurrentIdx] = useState(null)
  const [currentIdx2, setCurrentIdx2] = useState(null)
  const [modalopenJoin, setModalopenJoin] = useState(false)
  const { setIsModalLogin } = useModalLogin()

  const gnbItems = useHeaderStore((state) => state.gnbItems)
  const gnbActiveDepthIndex = useHeaderStore((state) => state.gnbActiveDepthIndex)

  const onClickGnbMenu = (idx) => {
    setCurrentIdx(idx)
  }
  const onClickGnbMenu2 = (idx) => {
    setCurrentIdx2(idx)
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 })
    return isMobile ? children : null
  }

  const handleOpenModalLogin = useCallback(
    (e) => {
      if (currentPath.includes('login')) {
        window.location.href = '/login'

        return false
      }

      document.body.classList.add('modal-open')
      setIsModalLogin(true)
    },
    [currentPath]
  )

  const handleCloseModalJoin = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenJoin(false)
  }, [])

  useEffect(() => {
    const mobileNav = document.querySelector('header').querySelectorAll('.nav-main')
    for (let i = 0; i < mobileNav.length; i++) {
      mobileNav[i].onclick = function () {
        if (mobileNav[i].parentElement.classList.value.indexOf('active') !== -1) {
          mobileNav[i].parentElement.classList.remove('active')
        } else {
          mobileNav[i].parentElement.classList.add('active')
        }
      }
    }
  }, [])

  useEffect(() => {
    if (gnbActiveDepthIndex !== null) {
      setCurrentIdx(gnbActiveDepthIndex.depth1)
      setCurrentIdx2(gnbActiveDepthIndex.depth2)
    } else {
      setCurrentIdx(0)
    }
  }, [gnbActiveDepthIndex])

  return (
    <>
      <Nav className={isHamburger ? 'active' : ''}>
        <Mobile>
          <Link to="/" className="logo">
            <i className="blind">야나두</i>
          </Link>
          <div className="lnb-m-btn-group">
            <Link to="/event/list/1" className="btn-m-event">
              이벤트
            </Link>
            <Link to="/promotion-landing/b2bLanding" className="btn-m-b2b">
              기업교육
            </Link>
          </div>
          <LeftCompanyCol>
            <ul className="tab-menu">
              {gnbItems &&
                gnbItems.map((item, idx) => (
                  <li
                    key={idx}
                    className={currentIdx === idx ? 'active ' : ''}
                    onClick={() => onClickGnbMenu(idx)}
                  >
                    <button type="button" style={{ borderBottomColor: item.backgroundColor }}>
                      {item.menuName}
                    </button>
                  </li>
                ))}
            </ul>
          </LeftCompanyCol>
          {gnbItems &&
            gnbItems.map((gnb, index) => (
              <Menu2dList key={index} className={currentIdx === index ? 'active ' : ''}>
                {gnb.childGnbMenus.map((item, idx) => (
                  <li
                    key={idx}
                    className={classNames(
                      item.iconType && item.iconType !== 'NONE' && item.iconType.toLowerCase(),
                      currentIdx2 === idx && 'active',
                      item.childGnbMenus.length <= 0 && 'nochild'
                    )}
                    onClick={() => onClickGnbMenu2(idx)}
                  >
                    {item.link.includes('http') ? (
                      <a
                        href={isMobile && item.childGnbMenus.length > 0 ? '#;' : item.link}
                        target={`_${item.linkTarget.toLowerCase()}`}
                        className="menu-name"
                      >
                        {item.menuName}
                        {/* 2A */}
                      </a>
                    ) : (
                      <NavLink
                        className="menu-name"
                        to={isMobile && item.childGnbMenus.length > 0 ? '#;' : item.link}
                      >
                        {item.menuName}
                        {/* 2L */}
                      </NavLink>
                    )}
                    {item.childGnbMenus?.length > 0 && (
                      <ul>
                        {item.childGnbMenus.map((sub, index, e) => (
                          <li
                            key={`${sub.depth}-${sub.gnbMenuSeq}`}
                            className={classNames(
                              sub.iconType && sub.iconType !== 'NONE' && sub.iconType.toLowerCase()
                            )}
                          >
                            <a
                              href={sub.link}
                              target={`_${sub.linkTarget.toLowerCase()}`}
                              className={classNames(
                                'depth-menu-name',
                                currentPath === sub.link && 'active'
                              )}
                              onClick={() => postGAEvent(sub.gaEventName, sub.gaEventName)}
                            >
                              {sub.menuName}
                              {/* 3A */}
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </Menu2dList>
            ))}

          <BtnGroupBottom>
            <Link to="/event/list/1">
              <span>이벤트</span>
            </Link>
            {!AuthService.isLoggedIn() && (
              <button onClick={handleOpenModalLogin}>
                <span>로그인</span>
              </button>
            )}
          </BtnGroupBottom>
        </Mobile>
      </Nav>

      {modalopenJoin ? (
        <ModalPortal>
          <ModalJoin
            title={'회원가입'}
            btnClose
            type={'DEFAULT'} // DEFAULT: 기존 회원가입 , SOCIAL: 소셜 회원가입, PAYMENT: 수기결제
            handleCloseModal={handleCloseModalJoin}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}

const BtnGroupBottom = styled.div`
  width: 80%;
  padding: 4.2rem;
  background-color: #fff;
  text-align: left;
  box-sizing: border-box;
  position: absolute;

  a,
  button {
    display: inline-block;
    padding: 2rem 0;
    width: unset !important;
    height: unset !important;
    font-size: 2.8rem;
  }
  button {
    margin-left: 15px;
  }
`
const LeftCompanyCol = styled.div`
  margin: 0;
  border-bottom: 1px solid #ddd;
  .tab-menu {
    li {
      padding-right: 4.8rem;
    }
  }
  & > ul {
    margin-left: 4.2rem;
    text-align: left;

    li {
      display: inline-block;
      position: relative;
      padding-right: 2rem;
      font-weight: 500;
      font-size: 2.8rem;

      &:last-child {
        padding-right: 0;
      }

      button {
        width: unset;
        height: unset;
        margin: unset;
        padding: 3.3rem 0 2.9rem;
        border-bottom: 0 solid transparent;
      }
      &.active {
        button {
          font-weight: 700;
          border-bottom: 0.4rem solid transparent;
        }
      }
    }
  }

  @media only screen and (max-width: 425px) {
    ul {
      margin-left: 4rem;
      li {
        padding-right: 1.5rem;
      }
    }
    .tab-menu {
      li {
        padding-right: 2.5rem;
        font-size: 13px;
      }
    }
  }
`

const Nav = styled.nav`
  &.active {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    padding: 19rem 0 8rem;
    font-size: 3rem;
    background-color: #fff;
    border-right: 1px solid #ddd;
    box-sizing: border-box;
    overflow-y: auto;
    z-index: 20;
    min-width: 290px;

    .logo {
      position: absolute;
      top: 7rem;
      left: 4rem;
      width: 21rem;
      height: 5rem;
    }

    > ul > li {
      display: block;
      width: 100%;

      &.nochild {
        a {
          &:after {
            display: none !important;
          }
        }
      }
      &.yafit > a {
        &::before {
          top: 0.5rem;
          left: 5rem;
          width: 5rem;
          height: 2.8rem;
          background-size: 5rem auto;
        }
      }
    }

    .btn-join-group {
      margin: 5rem 0 0;
      padding: 0 4.2rem;

      button {
        display: block;
        width: 100%;
        height: 9.6rem;
        margin: 0 0 1.7rem;
      }
    }

    .btn-app-download {
      margin: 16.5rem 0 0;
      padding: 0 4.2rem 0;

      .title {
        display: block;
        margin: 0 0 2.5rem;
      }

      button {
        width: 10rem;
        height: 10rem;
      }
    }
  }

  .lnb-m-btn-group {
    display: none;
  }
  @media only screen and (max-width: 1024px) {
    .lnb-m-btn-group {
      display: block;
      position: absolute;
      right: 5px;
      top: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .lnb-m-btn-group a {
      font-size: 14px;
      font-weight: 500;
      color: #000;
      display: block;
      padding: 16px 14px;
      position: relative;
    }
    .lnb-m-btn-group .btn-m-event:after {
      content: '';
      display: block;
      width: 1px;
      height: 16px;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -8px;
      background-color: #cfcfda;
    }
  }
  @media only screen and (max-width: 425px) {
    .lnb-m-btn-group {
      a {
        font-size: 13px;
      }
    }
  }
`

const Menu2dList = styled.ul`
  display: none;
  min-height: calc(100% - 26rem);
  &.active {
    display: block;
  }
  .menu-name {
    padding-left: 4.2rem;
    font-size: 2.8rem;
  }
  @media only screen and (max-width: 425px) {
    &.active {
      ul {
        padding-left: 4rem;
        li {
          padding-right: 1.5rem;
        }
      }
      .menu-name {
        padding: 3.4rem 4rem;
        font-size: 13px;
      }
      .depth-menu-name {
        font-size: 12px;
      }
    }
  }
`
