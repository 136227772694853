import React, { useState, useCallback, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

import Lnb from '../../components/Lnb';
import NoContent from '../../components/NoContent';

import icoImg from '../../assets/images/ico_no_order_420x420.png';

import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import 'react-datepicker/dist/react-datepicker.css';
import '../../assets/styles/myorder.scss';

import Pagination from '../../components/Pagination';

import ButtonOrderCancel from './ButtonOrderCancel';
import ButtonDelivery from './ButtonDelivery';
import ButtonReceipt from './ButtonReceipt';
import ButtonReview from './ButtonReview';

import api from 'common/api';
import * as config from 'common/config';

export default function ListOrder() {
  const [pageIdx, setPageIdx] = useState(1);

  const addComma = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleCurrentIdx = (idx) => {
    setPageIdx(idx);
  };

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 });
    return isDesktop ? children : null;
  };

  let isMobile;
  const Mobile = ({ children }) => {
    isMobile = useMediaQuery({ maxWidth: 1024 });
    return isMobile ? children : null;
  };

  const DateFilterData = [
    {
      id: 1,
      value: '1개월',
    },
    {
      id: 2,
      value: '3개월',
    },
    {
      id: 3,
      value: '6개월',
    },
    {
      id: 4,
      value: '12개월',
    },
  ];

  const [btnClicked, setBtnClicked] = useState('1개월');
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate())
  );
  const [endDate, setEndDate] = useState(new Date());
  const [totalPages, setTotalPages] = useState();

  const [orderListData, setOrderListData] = useState([]);

  // 주문리스트 API 호출
  const getOrderList = () => {
    if (startDate > endDate) {
      alert('시작날짜를 다시 확인해주세요.');
      return false;
    } else {
      const start = getToday(startDate);
      const end = getToday(endDate);

      api
        .get(
          `v2/order/list?keys=startDate&keys=endDate&pageIndex=${pageIdx}&pageSize=10&types=equal&types=equal&values=${start}&values=${end}`
        )
        .then((response) => {
          if (response.data.meta.code !== 200) {
            alert(response.data.meta.message);
          } else {
            setOrderListData(response.data.data);
            response.data.data[0] && setTotalPages(response.data.data[0].totalPages);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  // 날짜 형식 (yyyy-mm-ss) 로 변경
  const getToday = (date) => {
    if (typeof date === 'number') {
      date = new Date(date);
    }
    // const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (1 + date.getMonth())).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const today = year + '-' + month + '-' + day;

    return today;
  };

  // 시작날짜
  const changeStartDate = (date) => {
    setStartDate(date);
  };

  // 종료날짜
  const changeEndDate = (date) => {
    setEndDate(date);
  };

  useEffect(() => {
    getOrderList();
  }, [pageIdx]);

  useEffect(() => {
    return () => {
      // 페이지 벗어날때 모달 클래스 삭제
      document.body.classList.remove('modal-open');
    };
  }, []);

  const handleBtnClicked = (e) => {
    const { value } = e.target;

    setBtnClicked(value);
    // const currentDate = new Date();

    // 1주일 전부터 오늘까지의 기간
    // if (value === '1주일') {
    //   let weekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
    //   setStartDate(weekAgo);
    //   setEndDate(new Date());
    // }

    // 1개월 전부터 오늘까지의 기간
    if (value === '1개월') {
      let oneMonthAgo = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        new Date().getDate()
      );
      setStartDate(oneMonthAgo);
      setEndDate(new Date());
    }

    // 3개월 전부터 오늘까지의 기간
    if (value === '3개월') {
      let threeMonthAgo = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 3,
        new Date().getDate()
      );
      setStartDate(threeMonthAgo);
      setEndDate(new Date());
    }

    // 6개월 전부터 오늘까지의 기간
    if (value === '6개월') {
      let sixMonthAgo = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 6,
        new Date().getDate()
      );
      setStartDate(sixMonthAgo);
      setEndDate(new Date());
    }

    // 12개월 전부터 오늘까지의 기간
    if (value === '12개월') {
      let oneYearAgo = new Date(
        new Date().getFullYear() - 1,
        new Date().getMonth(),
        new Date().getDate()
      );
      setStartDate(oneYearAgo);
      setEndDate(new Date());
    }
  };

  useEffect(() => {
    if (isMobile) {
      setPageIdx(1);

      if (pageIdx === 1) {
        getOrderList();
      }
    }
  }, [startDate, endDate]);

  const handleinquiryData = () => {
    setPageIdx(1);
    if (pageIdx === 1) {
      getOrderList();
    }
  };

  return (
    <>
      <div className="order-col">
        <div className="inner-col">
          <div className="container mypage-sub">
            <Lnb />
            <section className="order-list-col">
              <h3>주문 배송 조회</h3>
              <Desktop>
                <div className="period-search-col">
                  <div className="period">
                    {DateFilterData.map((btn, idx) => {
                      return (
                        <button
                          onClick={handleBtnClicked}
                          key={idx}
                          type="button"
                          value={btn.value}
                          className={'btn-period' + (btnClicked === btn.value ? ' active' : '')}
                        >
                          {btn.value}
                        </button>
                      );
                    })}
                  </div>
                  <div className="date-picker">
                    <DatePicker
                      locale={ko}
                      selected={startDate}
                      dateFormat="yyyy-MM-dd"
                      onChange={changeStartDate}
                      placeholderText="클릭해주세요."
                      value={startDate}
                    />
                    <span className="wave">~</span>
                    <DatePicker
                      locale={ko}
                      selected={endDate}
                      dateFormat="yyyy-MM-dd"
                      onChange={changeEndDate}
                      placeholderText="클릭해주세요."
                      value={endDate}
                    />
                    <button className="btn positive" onClick={handleinquiryData}>
                      <span>조회</span>
                    </button>
                  </div>
                </div>
              </Desktop>
              <Mobile>
                <div className="period-search-col">
                  <div className="period">
                    {DateFilterData.map((btn, idx) => {
                      return (
                        <button
                          onClick={handleBtnClicked}
                          key={idx}
                          type="button"
                          value={btn.value}
                          className={'btn-period' + (btnClicked === btn.value ? ' active' : '')}
                        >
                          {btn.value}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </Mobile>
              {orderListData.length > 0 ? (
                <ul className="order-lists">
                  {orderListData &&
                    orderListData.map((orderListData, idx) => {
                      return (
                        <li key={idx}>
                          <div className="order-tit">
                            <div className="date">
                              <span className="order-date">
                                {orderListData.orderDate.substring(0, 10)}
                              </span>
                              <span className="notice">
                                {/* 무통장입금 && 입금확인중 && 입금기한 -> 입금 기한 노출 */}
                                {orderListData.paymentTypeCode === 'REMITTANCE' &&
                                orderListData.paymentStatusCode === 'PAY_CONFIRM' &&
                                orderListData.depositDate !== undefined
                                  ? `* 입금기한 : ` +
                                    orderListData.depositDate +
                                    ` 까지 (` +
                                    addComma(orderListData.depositPrice) +
                                    `원)`
                                  : null}
                              </span>
                            </div>
                            <Link
                              to={{
                                pathname: `/mypage/order/detail/${orderListData.orderNo}`,
                              }}
                              className="btn-order-detail"
                            >
                              <span>주문 상세</span>
                            </Link>
                          </div>
                          {orderListData.orderDetailVOList.map((data, idx) => {
                            return (
                              <div className="order-item" key={data.orderNo + idx}>
                                <div className="order-desc">
                                  <span
                                    // to={{
                                    //   pathname:
                                    //     data.packageTypeCode === 'ENTRANCE'
                                    //       ? `/store/entrance`
                                    //       : `/store/detail/${data.packageSeq}`,
                                    // }}
                                    className="thumb"
                                    style={{
                                      backgroundImage: `url(${data.thumbnailPath})`,
                                    }}
                                  >
                                    {/* 배송상태는 현물일 때에만 노출
                                        배송상태 아이콘 : 배송접수거나 배송준비중일 때엔 남색
                                        그 외엔 초록색
                                    */}
                                    {data.deliveryStatus &&
                                      data.deliveryStatus !== 'TERM_EXPIRATION' &&
                                      data.deliveryStatusName &&
                                      data.paymentStatusName !== '결제취소' && (
                                        <span
                                          className={
                                            'delivery-state-mobile' +
                                            (data.deliveryStatusName === '결제완료' ||
                                            data.deliveryStatusName === '배송대기' ||
                                            data.deliveryStatusName === '배송요청' ||
                                            data.deliveryStatusName === '배송완료' ||
                                            data.deliveryStatusName === '배송접수'
                                              ? ' positive'
                                              : '')
                                          }
                                        >
                                          {data.deliveryStatusName}
                                        </span>
                                      )}
                                  </span>
                                  <div className="desc">
                                    {/* 결제상태 */}
                                    <span
                                      className={
                                        'order-state-mobile' +
                                        (data.deliveryStatus === 'ORDER_CANCEL' ||
                                        data.deliveryStatus === 'CANCEL_SUCCESS' ||
                                        data.paymentStatusName === '결제취소'
                                          ? ' cancel'
                                          : '')
                                      }
                                    >
                                      {data.paymentStatusName}
                                    </span>
                                    {/* 상품 이름 */}
                                    <strong className="tit ellipsis">{data.orderName}</strong>
                                    <span className="quantity">수량 : {data.cnt}개</span>
                                  </div>
                                  <div className="state">
                                    {/* 결제상태 */}
                                    <span
                                      className={
                                        'order-state' +
                                        (data.deliveryStatus === 'ORDER_CANCEL' ||
                                        data.deliveryStatus === 'CANCEL_SUCCESS' ||
                                        data.paymentStatusName === '결제취소'
                                          ? ' cancel'
                                          : '')
                                      }
                                    >
                                      {data.paymentStatusName}
                                    </span>
                                    {/* 배송상태는 현물일 때에만 노출
                                      배송상태 아이콘 : 배송접수거나 배송준비중일 때엔 남색
                                      그 외엔 초록색
                                    */}
                                    {data.deliveryStatus &&
                                      data.deliveryStatus !== 'TERM_EXPIRATION' &&
                                      data.deliveryStatusName &&
                                      data.paymentStatusName !== '결제취소' && (
                                        <span
                                          className={
                                            'delivery-state' +
                                            (data.deliveryStatusName === '결제완료' ||
                                            data.deliveryStatusName === '배송대기' ||
                                            data.deliveryStatusName === '배송요청' ||
                                            data.deliveryStatusName === '배송완료' ||
                                            data.deliveryStatusName === '배송접수'
                                              ? ' positive'
                                              : '')
                                          }
                                        >
                                          {data.deliveryStatusName}
                                        </span>
                                      )}
                                  </div>
                                </div>
                                <div className="btn-flex-form">
                                  {/* 결제상태(paymentStatusCode), 배송상태(deliveryStatus)에 따라 분기처리
                                    주문 취소 : 
                                      - 현물 : 배송접수, 입금확인중 일 때 
                                    결제 취소 : 
                                      - 현물 : 배송접수, 결제완료 일 때
                                      - 디지털 : 결제 완료일로 부터 7일 이내(결제일 미포함) && 디지털 강의 신청 기록X (무료 지급 강의 불포함)
                                    배송지 변경 : 
                                      - 현물 : 배송접수, 결제완료 일 때
                                    배송조회 :
                                      - 현물 : 배송중, 결제완료 일 때
                                    후기작성 : 
                                      - 현물 : 배송완료, 결제완료 일 때
                                      - 디지털 : 결제완료 일 때
                                  */}
                                  {data.paymentStatusCode === 'PAY_CONFIRM' ? (
                                    <ButtonOrderCancel
                                      orderNo={data.orderNo}
                                      orderDate={data.orderDate}
                                    />
                                  ) : null}
                                  {data.deliveryStatusName &&
                                  data.deliveryStatusName === '배송접수' ? (
                                    <>
                                      {/* <Link
                                        to={{
                                          pathname: `/mypage/order/cancel/${data.seq}`,
                                        }}
                                        className="button stroke"
                                      >
                                        <span>결제취소</span>
                                      </Link> */}
                                      <ButtonDelivery orderNo={data.orderNo} />
                                    </>
                                  ) : null}
                                  {data.deliveryStatusName === '배송중' ||
                                  data.deliveryStatusName === '송장정보업로드' ? (
                                    <>
                                      <button
                                        className="button stroke"
                                        onClick={() =>
                                          window.open(
                                            `http://nplus.doortodoor.co.kr/web/detail.jsp?slipno=${data.invoiceNum}`,
                                            '_blank'
                                          )
                                        }
                                      >
                                        <span>배송조회</span>
                                      </button>
                                      <ButtonReceipt
                                        invoiceNum={data.invoiceNum}
                                        orderNum={data.orderNo}
                                      />
                                    </>
                                  ) : null}
                                  {data.paymentStatusCode === 'PAY_SUCCESS' ? (
                                    <>
                                      {data.deliveryStatusName &&
                                      data.deliveryStatusName === '배송완료' ? (
                                        <>
                                          <button
                                            className="button stroke"
                                            onClick={() =>
                                              window.open(
                                                `http://nplus.doortodoor.co.kr/web/detail.jsp?slipno=${data.invoiceNum}`,
                                                '_blank'
                                              )
                                            }
                                          >
                                            <span>배송조회</span>
                                          </button>
                                          <ButtonReview
                                            productLists={data.detailProductVOList}
                                            packageSeq={data.packageSeq}
                                            thumbnailPath={data.thumbnailPath}
                                          />
                                        </>
                                      ) : null}
                                      {data.packageTypeCode === 'APP' ||
                                      data.packageTypeCode === 'REAL' ||
                                      data.packageTypeCode === 'COMBINATION' ? (
                                        <ButtonReview
                                          productLists={data.detailProductVOList}
                                          packageSeq={data.packageSeq}
                                          thumbnailPath={data.thumbnailPath}
                                        />
                                      ) : null}
                                    </>
                                  ) : null}
                                  {data.paymentStatusCode === 'ORDER_CANCEL' && (
                                    <span className="slash"></span>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </li>
                      );
                    })}
                </ul>
              ) : (
                <NoContent icoImg={icoImg} titleMsg={'주문/배송 내역이 없습니다.'} />
              )}
              {orderListData.length > 0 && (
                <Pagination
                  type={'IDX'}
                  totalPages={totalPages}
                  limitPage={5}
                  maxNum={5}
                  minNum={0}
                  handleCurrentIdx={handleCurrentIdx}
                  pathName={'/mypage/order'}
                  pageIdx={pageIdx}
                />
              )}
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
