import { useEffect, useState, useRef, useCallback } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useParams, useLocation } from 'react-router-dom'

import ModalPortal from 'ModalPortal'
import CurriculumList from 'pages/product/CurriculumList'
import Notice from 'pages/product/Notice'
import ReviewList from 'pages/product/ReviewList'
import TeacherInfo from 'pages/product/TeacherInfo'
import AuthService from 'services/authService'
import styled, { ThemeProvider } from 'styled-components'

import api from 'common/api'
import { setCookie } from 'common/Cookie'
import theme from 'common/theme'
import ModalCouponDownload from 'components/modal/ModalCouponDownload'
import ModalShare from 'components/modal/ModalShare'
import StoreDetailCardPreView from 'components/Store/Detail/StoreDetailCardPreView'

import ico_button_download from 'assets/images/ico_download_type2_48x42w.png'
import ico_pack_item04 from 'assets/images/ico_pack_item04.png'
import ico_pack_item05 from 'assets/images/ico_pack_item05.png'
import ico_pack_item06 from 'assets/images/ico_pack_item06.png'
import ico_shareBtn from 'assets/images/ico_shareBtn.png'
import useModalLogin from 'store/useModalLogin'

export default function StoreProductPreview() {
  const { packageSeq } = useParams()
  const { pathname } = useLocation()

  const [classData, setClassData] = useState([])
  const [modalopen2, setModalopen2] = useState(false)
  const [couponDownloadData, setCouponDownloadData] = useState([])
  const [productPnt, setProductPnt] = useState(false)
  const [curriculumPnt, setCurriculumPnt] = useState(false)
  const [reviewPnt, setReviewPnt] = useState(false)
  const [questionPnt, setQuestionPnt] = useState(false)
  const [modalopen3, setModalopen3] = useState(false)
  const productDetailRef = useRef()
  const curriculumPointRef = useRef()
  const reviewPointRef = useRef()
  const questionPointRef = useRef()
  const menuBarRef = useRef()
  const detailWrapRef = useRef(null)
  const [prodDetail, setProdDetail] = useState(false)
  const { setIsModalLogin } = useModalLogin()

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 })

    return isDesktop ? children : null
  }

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 })

    return isMobile ? children : null
  }

  const handleOpenModal3 = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopen3(true)
  }, [])

  const handleCloseModal3 = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopen3(false)
  }, [])
  const onScroll = (moveTarget) => {
    window.scrollTo(0, document.querySelector(moveTarget).offsetTop - 10)
  }
  // 다운로드 가능한 쿠폰 API
  const getCouponDownloadData = async () => {
    let couponViewList = []
    try {
      if (classData.existDownloadCoupon && classData.existDownloadCoupon === 'Y') {
        await api
          .get(`/v2/coupon/possible/download/list?keys=packageSeq&types=equal&values=${packageSeq}`)
          .then((response) => {
            couponViewList = response.data.data.content
          })
          .catch((e) => {
            console.log(e)
          })
      }
      await api
        .get(`/v2/coupon-publish/list?index=1&size=100&types&keys=couponUseYn&types=equal&values=N`)
        .then((response) => {
          const couponData = response.data.data.content

          couponData.forEach((item) => {
            item.couponToPackageInfoDTOList.forEach((detailItem, idx) => {
              if (Number(detailItem.packageSeq) === Number(packageSeq)) {
                item.getCouponYn = true
                couponViewList.push(item)
              }
            })
          })
        })
        .catch((e) => {
          console.log(e)
        })
      setCouponDownloadData(couponViewList)
    } catch (error) {
      console.log(error)
    }
  }
  const dataChkPriceInfo = () => {
    try {
      if (AuthService.getUserInfo()) getCouponDownloadData(packageSeq)
    } catch (e) {
      console.log(e)
    }
  }
  const handleOpenModalLogin = useCallback((e) => {
    document.body.classList.add('modal-open')
    setCookie('orderClickCheck', 'Y')
    setIsModalLogin(true)
  }, [])

  const handleOpenModal2 = useCallback((e) => {
    // 로그인 여부 체크
    if (!AuthService.getUserInfo()) {
      alert('로그인이 필요합니다.')
      window.location.href = '/login'
    } else {
      document.body.classList.add('modal-open')
      setModalopen2(true)
    }
  }, [])
  const handleCloseModal2 = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopen2(false)
  }, [])

  const scrollCheck = () => {
    if (questionPointRef.current && questionPointRef.current.getBoundingClientRect().top < 150) {
      setQuestionPnt(true)
      setProductPnt(false)
      setCurriculumPnt(false)
      setReviewPnt(false)
      menuBarRef.current.classList.add('fixed')
    } else if (curriculumPointRef.current && curriculumPointRef.current.getBoundingClientRect().top < 150) {
      setReviewPnt(false)
      setQuestionPnt(false)
      setProductPnt(false)
      setCurriculumPnt(true)
      menuBarRef.current.classList.add('fixed')
    } else if (reviewPointRef.current && reviewPointRef.current.getBoundingClientRect().top < 150) {
      setReviewPnt(true)
      setQuestionPnt(false)
      setProductPnt(false)
      setCurriculumPnt(false)
      menuBarRef.current.classList.add('fixed')
    } else if (productDetailRef.current && productDetailRef.current.getBoundingClientRect().top < 250) {
      setReviewPnt(false)
      setQuestionPnt(false)
      setProductPnt(true)
      setCurriculumPnt(false)
      menuBarRef.current.classList.add('fixed')
    } else {
      menuBarRef.current.classList.remove('fixed')
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', scrollCheck)

    return () => {
      window.removeEventListener('scroll', scrollCheck)
      document.body.classList.remove('modal-open')
    }
  }, [])
  const getClassData = (seq) => {
    api
      .get(`/v2/store/sale/package-preview/${seq}/detail`)
      .then((response) => {
        setClassData(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (packageSeq) {
      getClassData(packageSeq)
    }
  }, [packageSeq, pathname])

  useEffect(() => {
    dataChkPriceInfo()
  }, [classData])

  useEffect(() => {
    if (detailWrapRef.current !== null && classData) {
      let pArr = detailWrapRef.current.children
      Object.entries(pArr).forEach(([key, value]) => {
        if (value.innerHTML === '&nbsp;') {
          value.classList.add('empty_block')
        }
      })
    }
  }, [detailWrapRef, classData])

  return (
    <ThemeProvider theme={theme}>
      <StoreDetailCardPreView classData={classData} />
      <ScrollSection ref={menuBarRef}>
        <ul>
          <li className={productPnt === true ? 'active' : ''}>
            <button type="button" onClick={() => onScroll('.product-detail')}>
              상품소개
            </button>
          </li>
          <li className={reviewPnt === true ? 'active' : ''}>
            <button type="button" onClick={() => onScroll('.review-list-col')}>
              수강생 후기
            </button>
          </li>
          {classData.packageTypeCode === 'REAL' ? null : (
            <li className={curriculumPnt === true ? 'active' : ''}>
              <button type="button" onClick={() => onScroll('.curriculum-col')}>
                커리큘럼
              </button>
            </li>
          )}
          <li className={questionPnt === true ? 'active' : ''}>
            <button type="button" onClick={() => onScroll('.question-col')}>
              상품문의
            </button>
          </li>
        </ul>
      </ScrollSection>

      <StoreProductContainer>
        <StoreProductBox>
          <Information>
            <div className="information-title product-detail" ref={productDetailRef}>
              <h1>상품소개</h1>
              <button type="button" className="btn-share" onClick={handleOpenModal3} style={{ cursor: 'default' }} disabled>
                공유
              </button>
            </div>
            <Desktop>
              <CurriculumContainer>
                {/* {console.log(classData)} */}
                {classData.packageTypeCode === 'CLASS' || classData.packageTypeCode === 'COMBINATION' || classData.packageTypeCode === 'COURSE' ? (
                  <div className="curriculum-box">
                    <p>{classData.contentInfo}</p>
                    <p className="period-info">{classData.learningMonth}개월</p>
                    <p className="class-level-info">{classData.studyLevelName === '' ? classData.classLevelInfo : classData.studyLevelName}</p>
                  </div>
                ) : null}
                {classData.packageTypeCode === 'APP' ? (
                  <div className="curriculum-box">
                    <p className="period-info">{classData.periodInfo}</p>
                  </div>
                ) : null}

                {couponDownloadData && couponDownloadData.length > 0 ? (
                  <BtnDownload onClick={!AuthService.getUserInfo() ? handleOpenModalLogin : handleOpenModal2} style={{ cursor: 'default' }} disabled>
                    쿠폰 받고 추가 할인
                  </BtnDownload>
                ) : null}
              </CurriculumContainer>
              {/* <DetailBanner classData={classData} /> */}
            </Desktop>
            <Mobile>
              <CurriculumContainer>
                {classData.packageTypeCode === 'CLASS' || classData.packageTypeCode === 'COMBINATION' || classData.packageTypeCode === 'COURSE' ? (
                  <div className="mobile-curriculum-box">
                    <p>{classData.contentInfo}</p>
                    <p className="period-info">{classData.learningMonth}개월</p>
                    <p className="class-level-info">{classData.studyLevelName === '' ? classData.classLevelInfo : classData.studyLevelName}</p>
                    {couponDownloadData && couponDownloadData.length > 0 ? (
                      <BtnDownload onClick={!AuthService.getUserInfo() ? handleOpenModalLogin : handleOpenModal2} style={{ cursor: 'default' }} disabled>
                        쿠폰 받고 추가 할인
                      </BtnDownload>
                    ) : null}
                  </div>
                ) : (
                  <>
                    {couponDownloadData && couponDownloadData.length > 0 ? (
                      <BtnDownload onClick={!AuthService.getUserInfo() ? handleOpenModalLogin : handleOpenModal2} style={{ cursor: 'default' }} disabled>
                        쿠폰 받고 추가 할인
                      </BtnDownload>
                    ) : null}
                  </>
                )}
                {classData.packageTypeCode === 'APP' ? (
                  <div className="curriculum-box">
                    <p className="period-info">{classData.periodInfo}</p>
                  </div>
                ) : null}
              </CurriculumContainer>
              {/* <DetailBanner classData={classData} /> */}
            </Mobile>

            <div
              ref={detailWrapRef}
              className={`store_detail${prodDetail === true ? ' active' : ''}`}
              dangerouslySetInnerHTML={{
                __html: classData.detail
              }}
            ></div>
            <button className="btn-detail-more" type="button" onClick={() => setProdDetail(true)} style={{ display: 'none' }}>
              <span>상품정보 더보기</span>
            </button>
          </Information>
          <div className="point-review" ref={reviewPointRef}></div>
          <ReviewList packageSeq={packageSeq} classData={classData} />
          <TeacherInfo classData={classData} />
          {/* 실물상품, 앱 이용권인 경우 비노출 */}
          {classData.packageTypeCode === 'REAL' || classData.packageTypeCode === 'APP' ? null : (
            <>
              <div className="point-curriculum" ref={curriculumPointRef}></div>
              <CurriculumList packageSeq={packageSeq} />
            </>
          )}
          <div className="point-question" ref={questionPointRef}></div>
          <section className="review-list-col question-col">
            <h3>
              문의하기
              <button type="submit" className="button" style={{ cursor: 'default' }} disabled>
                등록하기
              </button>
            </h3>
            <textarea placeholder="문의할 내용을 작성해주세요." id="questionText"></textarea>
          </section>
          <Notice />
        </StoreProductBox>
      </StoreProductContainer>
      {modalopen2 ? <ModalPortal>{couponDownloadData && <ModalCouponDownload btnClose handleCloseModal={handleCloseModal2} couponDownloadData={couponDownloadData} getCouponDownloadData={getCouponDownloadData} priceInfoSeq={classData.packageSeq} />}</ModalPortal> : null}

      {modalopen3 ? (
        <ModalPortal>
          <ModalShare title={'페이지를'} snsShareTitle={classData.title} btnClose data={classData} banner={classData.packageImages[0]} handleCloseModal={handleCloseModal3} />
        </ModalPortal>
      ) : null}
    </ThemeProvider>
  )
}
const StoreProductContainer = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
`
const StoreProductBox = styled.div`
  max-width: 78.6rem;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 12rem;

  @media ${({ theme }) => theme.device.mobile} {
    max-width: 100%;
    padding-bottom: 10rem;
  }
`

const ScrollSection = styled.section`
  position: relative;
  z-index: 8;
  /* margin-top: 4rem; */
  transition: all 0.3s;
  background-color: #fff;
  width: 100%;
  border-bottom: 0.2rem solid #ececf6;

  &.fixed {
    position: fixed;
    top: 11.2rem;
    width: 100%;
  }

  ul {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    li {
      flex: 1;
      text-align: center;

      button {
        display: block;
        padding: 1.6rem 0;
        width: 100%;
        font-weight: 400;
        font-size: 1.5rem;
      }

      &.active {
        /* background: #28284b; */
        border-bottom: 0.2rem solid #28284b;
        margin-bottom: -0.2rem;
        button {
          font-weight: 700;
        }
      }
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    border-bottom: 0.3rem solid #ececf6;
    &.fixed {
      position: fixed;
      top: 27rem;
      width: 100%;
      max-width: 100%;
      z-index: 10;
    }

    ul {
      li {
        button {
          height: 9rem;
          padding: 3.6rem 0;
          font-size: 2.4rem;
        }
        &.active {
          /* background: #28284b; */
          border-bottom: 0.3rem solid #28284b;
          margin-bottom: -0.3rem;
        }
      }
    }
  }
`
const Information = styled.div`
  padding-top: 10rem;
  .information-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.3rem solid #000;
    padding: 0 0 1.8rem;
    h1 {
      font-size: 2.5rem;
    }
    .btn-share {
      flex: 0 0 16%;
      max-width: 16%;
      margin-left: 0.6rem;
      text-indent: -9999rem;
      background: url(${ico_shareBtn}) no-repeat 99% 50% / 2.4rem auto;
    }
  }

  &.active {
    height: auto;

    & ~ .btn-detail-more {
      display: none;
    }
  }
  img {
    width: 100% !important;
    height: auto !important;
  }

  .store_detail {
    * {
      margin: 0;
      padding: revert;
      line-height: 1.45;
      font-size: 16px;
      vertical-align: bottom;
      font-family: 'NotoSansCJKkr', 'Inter', sans-serif !important;
    }
    p {
      span,
      strong {
        font-size: inherit;
        margin: 0;
        padding: 0;
      }
      :has(img) {
        margin-top: 0;
      }
    }
    br,
    .empty_block {
      margin: 14px 0;
      font-size: 0;
      line-height: 0;
      display: block;
    }
    img {
      width: 100% !important;
      height: auto !important;
      margin: 0;
      max-width: 100%;
    }
    table {
      all: revert;
      max-width: 100%;
      table-layout: fixed;
      border-width: 1px;
      border-style: solid;
      border-color: initial;
      border-spacing: 0px;
      border-collapse: collapse;
      th,
      td {
        all: revert;
        border-width: 1px;
        border-style: solid;
        border-color: #cfcfda;
        padding: 10px;
        vertical-align: top;
        word-wrap: break-word;
        word-break: break-all;
      }
      th {
        background-color: #f7f7fc;
        font-weight: bold;
      }
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 10rem 4.2rem 0;
    .information-title {
      h1 {
        font-size: 3.2rem;
      }
      .btn-share {
        background: url(${ico_shareBtn}) no-repeat 99% 50% / 3rem auto;
      }
    }
    .store_detail {
      table {
        width: 100% !important;
      }
    }
  }
`
const CurriculumContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4.2rem 0;
  .curriculum-box {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0;
    row-gap: 1.5rem;
    column-gap: 4rem;
    p {
      display: flex;
      font-size: 1.8rem;
      align-items: center;
      font-weight: 700;
      width: 23.5rem;
      /* height: 4.8rem; */
      margin-bottom: 0;
      &::before {
        content: '강의 수';
        color: #a0a0b6;
        flex: 0 0 auto;
        width: 5.6rem;
        margin-right: 1.8rem;
        padding-left: 3.1rem;
        font-weight: 400;
        background: url(${ico_pack_item04}) 0 70% /2.75rem auto no-repeat;
        image-rendering: -webkit-optimize-contrast;
        transform: translateZ(0);
        backface-visibility: hidden;
      }

      &.period-info {
        &::before {
          content: '수강기간';
          width: 6.63rem;
          background-image: url(${ico_pack_item05});
        }
      }

      &.class-level-info {
        &::before {
          content: '난이도';
          width: 4.98rem;
          background-image: url(${ico_pack_item06});
        }
      }
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    .mobile-curriculum-box {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 2.4rem;
      p {
        display: flex;
        font-size: 2.45rem;
        align-items: center;
        font-weight: 700;
        width: 48%;
        height: 4rem;
        margin-bottom: 0;
        &::before {
          content: '강의 수';
          color: #a0a0b6;
          flex: 0 0 auto;
          width: 7.5rem;
          margin-right: 2rem;
          padding-left: 4rem;
          font-weight: 400;
          background: url(${ico_pack_item04}) 0 60% /3.6rem auto no-repeat;
          image-rendering: -webkit-optimize-contrast;
          transform: translateZ(0);
          backface-visibility: hidden;
        }

        &.period-info {
          &::before {
            content: '수강기간';
            width: 9.1rem;
            background-image: url(${ico_pack_item05});
          }
        }

        &.class-level-info {
          &::before {
            content: '난이도';
            width: 7rem;
            background-image: url(${ico_pack_item06});
          }
        }
      }
    }
  }
`
const BtnDownload = styled.button`
  position: relative;
  height: 4.8rem;
  min-width: 20rem;
  padding: 0.2rem 4rem 0 1.5rem;
  font-weight: 700;
  font-size: 1.6rem;

  color: #fff;
  background: #269cff url(${ico_button_download}) no-repeat calc(100% - 2rem) 50% / 2.2rem auto;
  border: 0.1rem solid #269cff;
  border-radius: 0.3rem;
  box-sizing: border-box;
  @media ${({ theme }) => theme.device.mobile} {
    height: 6.4rem;
    width: 27.8rem;
    padding: 0.2rem 5.4rem 0 2rem;
    font-size: 2.4rem;
    border: 0.2rem solid #269cff;
    border-radius: 0.6rem;
    background: #269cff url(${ico_button_download}) no-repeat calc(100% - 2rem) 50% / 3.2rem auto;
  }
`
