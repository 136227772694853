import { useEffect, useState } from 'react'
import Lottie from 'react-lottie'

import useInterval from 'hooks/useInterval'
import TranslateBtn from 'pages/aiTalk/components/TranslateBtn'
import LottieData from 'pages/aiTalk/lottieData.json'
import useChatStateStore from 'pages/aiTalk/store/useChatStateStore'
import useScrollStore from 'pages/aiTalk/store/useScrollStore'

import s from './Chat.module.css'
import ButtonAudio from 'pages/aiTalk/components/ButtonAudio'

// ETD-3489 last update
export default function TypingMessage({ text = '', speed = 100, aiInfo, userSeq }) {
  const { images, info, key } = aiInfo
  const [message, setMessage] = useState('')
  const [count, setCount] = useState(0)
  const { setScroll } = useScrollStore()
  const { setChatState } = useChatStateStore()

  // translate
  const [translateText, setTranslateText] = useState(null)
  const [transLoading, setTransLoading] = useState(false)
  const [translated, setTranslated] = useState(false)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const typingEffect = () => {
    setMessage((msg) => {
      let typing = msg
      typing = msg + text[count]
      return typing
    })
    setCount(count + 1)
  }

  useInterval(typingEffect, count === text.length ? null : speed)

  useEffect(() => {
    if (count < text.length) {
      setScroll(true)
    }
    if (count === text.length) {
      setChatState(true)
    }
  }, [count])

  return (
    <div className={s.aiMessage}>
      <div className={s.msgThumb}>
        <img src={images.circle} alt={info.fullname} />
      </div>
      <dl>
        <dt className={s.msgName}>{info.firstname}</dt>
        <dd className={s.message}>
          {message}
          {count === text.length && (
            <div className={s.btnGroup}>
              <ButtonAudio text={message} promptType={key} userSeq={userSeq} />
              <TranslateBtn type={'AI'} message={message} translated={translated} translateText={translateText} setTransLoading={setTransLoading} setTranslated={setTranslated} setTranslateText={setTranslateText} />
            </div>
          )}
        </dd>
        {transLoading && !translateText && (
          <dd className={s.translateText}>
            <div className={s.lottieBox}>
              <Lottie options={defaultOptions} />
            </div>
          </dd>
        )}
        {translateText && translated && <dd className={s.translateText}>{translateText}</dd>}
      </dl>
    </div>
  )
}
