import Signin from 'pages/iframe/Signin'
import LevelTestAdvancedAnswer from 'pages/levelTestAdvanced/answer/index'
import LevelTestAdvancedExam from 'pages/levelTestAdvanced/exam'
import LevelTestAdvancedResult from 'pages/levelTestAdvanced/result/index'
import AppAInativespeakerChat from 'webview/ai/chat'
import AppAInativespeakertalk from 'webview/ai/intro'
import AiTravelEnglish from 'webview/travel'
import AiTravelChat from 'webview/travel/AiTravelChat'

import PrivateRoute from 'common/PrivateRoute'
import AiNadoo from 'pages/aiNadoo'

const FramelessRoutes = [
  {
    path: '/iframe/*',
    children: [
      {
        path: 'signin',
        element: <Signin />
      }
    ]
  },
  {
    path: '/webview/*',
    children: [
      {
        path: 'ai/intro',
        element: <AppAInativespeakertalk />
      },
      {
        path: 'ai/chat',
        element: <AppAInativespeakerChat />
      },
      {
        path: 'ai/srr/qna',
        element: <AiNadoo pageType={'srr'} />
      },
      {
        path: 'ai/qna',
        element: <AiNadoo pageType={'ainadoo'} />
      },
      {
        path: 'travel',
        element: <AiTravelEnglish />
      },
      {
        path: 'travel/chat',
        element: <AiTravelChat />
      }
    ]
  },
  {
    path: 'levelTestAdvanced/*',
    children: [
      {
        path: 'exam',
        element: (
          <PrivateRoute>
            <LevelTestAdvancedExam />
          </PrivateRoute>
        )
      },
      {
        path: 'result',
        element: (
          <PrivateRoute>
            <LevelTestAdvancedResult />
          </PrivateRoute>
        )
      },
      {
        path: 'answer',
        element: (
          <PrivateRoute>
            <LevelTestAdvancedAnswer />
          </PrivateRoute>
        )
      }
    ]
  }
]

export default FramelessRoutes
