import { useEffect } from 'react'

import CharactersList from 'pages/aiTalk/components/CharactersList'
import SectioinNotice from 'pages/aiTalk/components/SectioinNotice'
import SectionAlways from 'pages/aiTalk/components/SectionAlways'
import SectionSpecial from 'pages/aiTalk/components/SectionSpecial'
import SectionStep from 'pages/aiTalk/components/SectionStep'
import SectionVoice from 'pages/aiTalk/components/SectionVoice'
import { callIOS, callNative } from 'webview/bridge'

import MetaInfo from 'components/common/MetaInfo'

import TitleBar from './components/TitleBar'

export default function AppAInativespeakertalk() {
  useEffect(() => {
    callNative(
      function () {
        callIOS({ action: 'hideTitleBar' })
      },
      function () {
        window.Android.hideTitleBar()
      }
    )
  }, [])

  return (
    <>
      <MetaInfo
        props={{
          title: '나만의 AI 원어민 친구! AI 원어민톡',
          description: '언제 어디서나 편하게 찐친과 톡하듯이!',
          site_name: '나와 관심사가 딱 맞는 AI 친구와 대화하기',
          imgsrc: 'https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/img_og_aiTalk_v2.png'
        }}
      />
      <TitleBar title={'AI 원어민 톡 · 나만의 AI 원어민 친구'} type="close" />
      <article style={{ paddingTop: '52px' }}>
        <CharactersList />
        <SectionSpecial />
        <SectionAlways />
        <SectionVoice />
        <SectionStep />
        <SectioinNotice />
      </article>
    </>
  )
}
