import s from '../aiTalk.module.css'

export default function SectioinNotice() {
  return (
    <section className={s.notice}>
      <div className={s.inner}>
        <dl>
          <dt>
            AI와 대화할 때는
            <br />
            주의해 주세요.
          </dt>
          <dd>
            <ul className={s.numberNoti}>
              <li>
                AI를 너무 신뢰하지 마세요.
                <p>Chat GPT는 강력한 언어 모델이지만 정확하지 않을 수 있고, 최신 정보가 아닐 수도 있어요. 조언이나 중요한 정보는 신뢰할 수 있는 출처를 통해 확인해 주세요.</p>
              </li>
              <li>
                AI는 아직 발전 중이에요.
                <p>
                  Chat GPT는 문맥이나 뉘앙스를 이해하지 못할 수도 있어요.
                  <br />
                  대화 중 때때로 흐름에 맞지 않거나 엉뚱한 답변을 할 수도 있으니 이용에 참고해 주세요.
                </p>
              </li>
              <li>
                개인정보는 AI에게도 공유하지 마세요.
                <p>
                  필요시, 야나두 개발팀에 의해 대화가 열람될 수도 있습니다.
                  <br />
                  여러분의 소중한 개인정보는 AI에게도 비밀로 지켜주세요.
                </p>
              </li>
              <li>
                사람처럼 대해주세요.
                <p>
                  욕설, 비방, 성적인 표현 등 부적절한 표현을 AI에게 사용하지 마세요. <br />
                  서비스 이용이 제한될 수 있습니다.
                </p>
              </li>
              <li>
                불법, 비윤리적인 대화는 AI에게도 해선 안 돼요.
                <p>
                  Chat GPT와 야나두의 AI는 비윤리적인 주제를 회피하도록 프로그래밍되어 있습니다.
                  <br />
                  불법적인 주제는 서비스 이용 제한 사유가 될 수 있으니 유의해 주세요.
                </p>
              </li>
            </ul>
            <ul className={s.discNoti}>
              <li>AI 원어민 톡의 서비스 제공 방식 등은 별도의 사전 고지 없이 변경될 수 있습니다.</li>
            </ul>
          </dd>
        </dl>
      </div>
    </section>
  )
}
