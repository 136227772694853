import { lazy } from 'react'

import { PromotionLanding } from 'router/OutletStore'

import RedirectTo from 'components/RedirectTo'

const AITravelEnglish = lazy(() => import('pages/promotion/AITravelEnglish'))
const BestAwardsDBOnlyPerformance = lazy(() =>
  import('pages/promotion/bestAwardsDBOnly_performance')
)
const DBMotivation = lazy(() => import('pages/promotion/DB_Motivation'))
const DBPerformance = lazy(() => import('pages/promotion/DB_Performance'))
const MacbookPackage = lazy(() => import('pages/promotion/macbookPackage'))
const LaptopPackage = lazy(() => import('pages/promotion/laptopPackage'))
const ApplePackage = lazy(() => import('pages/promotion/applePackage'))
const B2BLanding = lazy(() => import('pages/promotion/B2BLanding'))
const BestAwards = lazy(() => import('pages/promotion/bestAwards'))
const BestAwardsDBOnly = lazy(() => import('pages/promotion/bestAwardsDBOnly'))
const FrontEnglish = lazy(() => import('pages/promotion/frontEnglish'))
const IpadDouble = lazy(() => import('pages/promotion/ipadDouble'))
const NewBenefit = lazy(() => import('pages/promotion/newBenefit'))
const NewYear = lazy(() => import('pages/promotion/newyear2024'))
const QuizEvent = lazy(() => import('pages/promotion/quizEvent'))
const SrrBooks = lazy(() => import('pages/promotion/SRR_books'))
const SrrCareer = lazy(() => import('pages/promotion/srrCareer'))
const StandByMe = lazy(() => import('pages/promotion/standByMe'))
const Buddytalk = lazy(() => import('pages/promotion/buddyTalk'))
const Curriculum = lazy(() => import('pages/promotion/curriculum'))
const TabPackageAir = lazy(() => import('pages/promotion/tabPackageAir'))
const TabPackage10th = lazy(() => import('pages/promotion/tabPackage10th'))
const Brain = lazy(() => import('pages/promotion/brain'))
const LaptopPackagePerformance = lazy(() => import('pages/promotion/laptopPackage_performance'))
const MacbookPackagePerformance = lazy(() => import('pages/promotion/macbookPackage_performance'))
const SRRfullpackagePerformance = lazy(() => import('pages/promotion/SRRfullpackage_performance'))
const Whatclass2024 = lazy(() => import('pages/promotion/whatclass2024'))
const Nipowoopo2024 = lazy(() => import('pages/promotion/n_nipowoopo2024'))
const SRRfullpackage = lazy(() => import('pages/promotion/SRRfullpackage'))
const SRRworkbookbrand = lazy(() => import('pages/promotion/SRRworkbookbrand'))
const Class9promotion = lazy(() => import('pages/promotion/class9promotion'))
const PromotionRoutes = [
  // react promotion
  {
    path: 'promotion-landing',
    element: <PromotionLanding />,
    children: [
      { path: 'DB_Motivation', element: <DBMotivation /> },
      { path: 'DB_performance', element: <DBPerformance /> },
      { path: 'b2bLanding', element: <B2BLanding /> },
      { path: 'bestAwards', element: <BestAwards /> },
      { path: 'quizevent', element: <QuizEvent /> },
      { path: 'buddytalk', element: <Buddytalk /> },
      { path: 'newyear2024', element: <NewYear /> },
      { path: 'applePackage', element: <ApplePackage /> },
      { path: 'applePackageUI', element: <ApplePackage isAdType /> },
      { path: 'macbookPackage', element: <MacbookPackage /> },
      { path: 'macbookPackage_performance', element: <MacbookPackagePerformance /> },
      { path: 'SRRbasic', element: <SrrBooks pageType="basic" /> },
      { path: 'SRRnative', element: <SrrBooks pageType="native" /> },
      { path: 'SRRspeech', element: <SrrBooks pageType="speech" /> },
      { path: 'newBenefit', element: <NewBenefit /> },
      { path: 'srrCareer', element: <SrrCareer /> },
      { path: 'frontEnglish', element: <FrontEnglish /> },
      { path: 'ipadDouble', element: <IpadDouble /> },
      { path: 'tabPackageAir', element: <TabPackageAir /> },
      { path: 'tabPackage10th', element: <TabPackage10th /> },
      { path: 'standByMe', element: <StandByMe /> },
      { path: 'SRRfullpackage_performance', element: <SRRfullpackagePerformance /> },
      { path: 'laptopPackage_performance', element: <LaptopPackagePerformance /> },
      { path: 'bestAwardsDBOnly', element: <BestAwardsDBOnly /> },
      { path: 'curriculum', element: <Curriculum /> },
      { path: 'laptopPackage', element: <LaptopPackage /> },
      { path: 'bestAwardsDBOnly_performance', element: <BestAwardsDBOnlyPerformance /> },
      { path: 'brain', element: <Brain /> },
      { path: 'AITravelEnglish', element: <AITravelEnglish /> },
      { path: 'whatclass2024', element: <Whatclass2024 /> },
      { path: 'n_nipowoopo2024', element: <Nipowoopo2024 /> },
      { path: 'SRRfullpackage', element: <SRRfullpackage /> },
      { path: 'SRRworkbookbrand', element: <SRRworkbookbrand /> },
      { path: 'class9promotion', element: <Class9promotion /> }
    ]
  },
  // public html promotion
  {
    path: '/promotion/*',
    children: [
      { path: 'bestAwards', element: <BestAwards /> },
      { path: 'brain', element: <Brain /> },
      { path: 'curriculum', element: <Curriculum /> },
      { path: 'laptopPackage', element: <LaptopPackage /> },
      { path: 'n_nipowoopo2023', element: <Nipowoopo2024 /> },
      { path: 'n_nipowoopo2024', element: <Nipowoopo2024 /> },
      { path: 'tabPackage_air', element: <TabPackageAir /> },
      { path: 'bestAwards_db_only', element: <BestAwardsDBOnly /> },
      { path: 'applePackage', element: <ApplePackage /> },
      { path: 'macbookPackage', element: <MacbookPackage /> },
      { path: 'tabPackage10th', element: <TabPackage10th /> },
      { path: 'SRRworkbookbrand', element: <SRRworkbookbrand /> },
      { path: 'SRRfullpackage', element: <SRRfullpackage /> },
      { path: 'frontEnglish', element: <FrontEnglish /> },
      { path: 'b2bLanding', element: <B2BLanding /> },
      { path: 'curriculum', element: <Curriculum /> },
      { path: 'yanadooInfo', element: <RedirectTo htmlFileName="yanadooInfo" /> },
      { path: 'yanadooUniversity', element: <RedirectTo htmlFileName="yanadooUniversity" /> },
      { path: 'reviewBnA', element: <RedirectTo htmlFileName="reviewBnA" /> },
      { path: 'yafitPremium', element: <RedirectTo htmlFileName="yafitPremium" /> },
      { path: 'yafitLite', element: <RedirectTo htmlFileName="yafitLite" /> },
      { path: 'yafitFree', element: <RedirectTo htmlFileName="yafitFree" /> },
      { path: 'yafitNotice', element: <RedirectTo htmlFileName="yafitNotice" /> },
      { path: 'yafitTalk', element: <RedirectTo htmlFileName="yafitTalk" /> },
      { path: 'yafitTalkB', element: <RedirectTo htmlFileName="yafitTalkB" /> },
      { path: 'bestAwardsB', element: <RedirectTo htmlFileName="bestAwardsB" /> },
      { path: 'tabPackage', element: <RedirectTo htmlFileName="tabPackage" /> },
      { path: 'tabPackageTM', element: <RedirectTo htmlFileName="tabPackageTM" /> },
      { path: 'curriculumSystem', element: <RedirectTo htmlFileName="curriculumSystem" /> },
      { path: 'voiceCare', element: <RedirectTo htmlFileName="voiceCare" /> },
      { path: 'scholarShip', element: <RedirectTo htmlFileName="scholarShip" /> },
      { path: 'previewYanadoo', element: <RedirectTo htmlFileName="previewYanadoo" /> },
      { path: 'engTalk', element: <RedirectTo htmlFileName="engTalk" /> },
      { path: 'milchakCare', element: <RedirectTo htmlFileName="milchakCare" /> },
      { path: 'milchakCareReview', element: <RedirectTo htmlFileName="milchakReview" /> },
      { path: 'tab_secret_plus1', element: <RedirectTo htmlFileName="tab_secret_plus1" /> },
      { path: 'tap_plus_air5', element: <RedirectTo htmlFileName="tap_plus_air5" /> },
      { path: 'SRRworkbook', element: <RedirectTo htmlFileName="SRRworkbook" /> },
      { path: 'SRRworkbook02', element: <RedirectTo htmlFileName="SRRworkbook02" /> },
      { path: 'tripEnglish', element: <RedirectTo htmlFileName="tripEnglish" /> },
      { path: 'yndGuide', element: <RedirectTo htmlFileName="yndGuide" /> },
      { path: 'voiceReview', element: <RedirectTo htmlFileName="voiceReview" /> },
      { path: 'trainingEnglish', element: <RedirectTo htmlFileName="trainingEnglish" /> },
      { path: 'tab_plus2_air5', element: <RedirectTo htmlFileName="tab_plus2_air5" /> },
      { path: 'Persnal01', element: <RedirectTo htmlFileName="Persnal01" /> },
      { path: 'tabPackage_air5th', element: <RedirectTo htmlFileName="tabPackage_air5th" /> },
      { path: 'audioEnglish', element: <RedirectTo htmlFileName="audioEnglish" /> },
      { path: 'audioEngPlus', element: <RedirectTo htmlFileName="audioEngPlus" /> },
      { path: 'realEnglish', element: <RedirectTo htmlFileName="realEnglish" /> },
      { path: 'cyclePackage', element: <RedirectTo htmlFileName="cyclePackage" /> },
      { path: 'cheerupSongSeason3', element: <RedirectTo htmlFileName="cheerupSongSeason3" /> },
      { path: 'cheerupSongIntro', element: <RedirectTo htmlFileName="cheerupSongIntro" /> },
      { path: 'cheerupSongPick', element: <RedirectTo htmlFileName="cheerupSongPick" /> },
      { path: 'company', element: <RedirectTo htmlFileName="company" /> },
      { path: 'yafitPreInfo', element: <RedirectTo htmlFileName="yafitPremium" /> },
      { path: 'yafitPreSales', element: <RedirectTo htmlFileName="yafitPremium" /> },
      { path: 'yafitPreEmp', element: <RedirectTo htmlFileName="yafitPreEmp" /> },
      { path: 'yafitPreRev', element: <RedirectTo htmlFileName="yafitPreRev" /> }
    ]
  }
]

export default PromotionRoutes
