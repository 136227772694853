import { useEffect, useState, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'

import SwiperCore, { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import '../../assets/styles/productDetail.scss'
import { convertHtmlCode } from 'utils/convertHtmlCode'
//style
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'

SwiperCore.use([Pagination, Autoplay])

export default function TeacherInfo(classData) {
  const [tabMenu, setTabMenu] = useState(0)
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false

  const swiperSpaceBetween = deviceCheck ? 15 : 25
  const swiperSlidesPerView = deviceCheck ? 5 : 4
  const swiperConditionsLength = deviceCheck ? 3 : 4

  const handleTabMenu = (id) => {
    setTabMenu(id)
  }

  const replaceBrTag = (str) => {
    if (str === undefined || str === null) {
      return ''
    }

    str = str.replace(/\r\n/gi, '<br>')
    str = str.replace(/\\n/gi, '<br>')
    str = str.replace(/\n/gi, '<br>')
    return str
  }

  const flag = useRef(false)
  const [teacherData, setTeacherData] = useState([])

  useEffect(() => {
    if (!flag.current) {
      flag.current = true
    } else {
      setTeacherData(classData.classData.teachers)
    }
    // console.log('----------------teacher--------------');
    // console.log(teacherData);
  }, [classData])

  return (
    <>
      <section className="teacher-col">
        <h3>강사소개</h3>
        {teacherData && teacherData.length > swiperConditionsLength ? (
          <div className="tab-menu-area tab-slide">
            <ul className="tab-menu">
              <Swiper
                className="swiper-container"
                spaceBetween={swiperSpaceBetween}
                slidesPerView={swiperSlidesPerView}
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current
                }}
                onSwiper={(swiper) => {
                  setTimeout(() => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current
                    swiper.params.navigation.nextEl = navigationNextRef.current

                    // Re-init navigation
                    swiper.navigation.destroy()
                    swiper.navigation.init()
                    swiper.navigation.update()
                  })
                }}
              >
                {teacherData.map((item, idx) => {
                  return (
                    <SwiperSlide key={idx} className="swiper-slide">
                      <li
                        key={idx}
                        className={tabMenu === idx ? 'active' : null}
                        style={{
                          backgroundImage: `url("${item.profileFilePath}")`
                        }}
                        navigation={{
                          prevEl: navigationPrevRef.current,
                          nextEl: navigationNextRef.current
                        }}
                        onSwiper={(swiper) => {
                          setTimeout(() => {
                            swiper.params.navigation.prevEl = navigationPrevRef.current
                            swiper.params.navigation.nextEl = navigationNextRef.current

                            // Re-init navigation
                            swiper.navigation.destroy()
                            swiper.navigation.init()
                            swiper.navigation.update()
                          })
                        }}
                      >
                        <button type="button" onClick={() => handleTabMenu(idx)}>
                          {convertHtmlCode(item.teacherName)}
                        </button>
                      </li>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
              <button type="button" className="btn-slide prev" ref={navigationPrevRef}>
                <em className="blind">이전</em>
              </button>
              <button type="button" className="btn-slide next" ref={navigationNextRef}>
                <em className="blind">다음</em>
              </button>
            </ul>
          </div>
        ) : (
          <div className="tab-menu-area">
            <ul className="tab-menu">
              {teacherData &&
                teacherData.map((item, idx) => {
                  return (
                    <li
                      key={idx}
                      className={tabMenu === idx ? 'active' : null}
                      style={{
                        backgroundImage: `url("${item.profileFilePath}")`
                      }}
                    >
                      <button type="button" onClick={() => handleTabMenu(idx)}>
                        {convertHtmlCode(item.teacherName)}
                      </button>
                    </li>
                  )
                })}
            </ul>
          </div>
        )}
        {teacherData &&
          teacherData.map((item, idx) => (
            <div key={idx} className={'tab-content' + (tabMenu === idx ? ' active' : '')}>
              <div>
                <h4>
                  <strong> {convertHtmlCode(item.teacherName)}강사</strong>
                </h4>
                <div className="history">
                  {item.briefHistory && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: replaceBrTag(item.briefHistory)
                      }}
                    ></div>
                  )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.introduction
                    }}
                  ></div>
                </div>
              </div>
            </div>
          ))}
      </section>
    </>
  )
}
